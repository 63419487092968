import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ConfirmationTemplate {
    title: string;
    contentText: string;
    preliminaryConfirmationText?: string;
    actionText = 'Ok';
    cancelText = 'Cancel';
}

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: 'confirm-dialog.component.html',
    styles: [],
})
export class ConfirmCancelDialogComponent {

    enableFinalConfirmation: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public readonly template: ConfirmationTemplate) {
        // If there is no extra confirmation, confirmation can happen in one step.  If there
        // is an extra confirmation, the final confirmation should be disabled until the
        // preliminary confirmation occurs.
        this.enableFinalConfirmation = !template.preliminaryConfirmationText;
    }

    hasPreliminaryConfirmationText() {
        return !!this.template.preliminaryConfirmationText;
    }

    showCancel(): boolean {
        return this.template.cancelText.length > 0;
    }

    showAction(): boolean {
        return this.template.actionText.length > 0;
    }
}
