import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Case, CaseService, Flow } from '../../case.service';
import { ErrorCode } from '../../case.errors';
import { FormControl } from '@angular/forms';
import { Subscriptions } from '../../../tools/subscriptions.class';


@Directive()
export abstract class FlowReviewDirective implements OnInit, OnDestroy {

    @Input() caseUpdated: Subject<Case>;

    protected subscriptions = new Subscriptions();

    public exitCode = new FormControl<number>({value: null, disabled: true});

    protected caseData: Case;
    protected latestFlow: Flow | null = null;

    protected abstract updateFlowData(data: Case): void;

    protected constructor(protected caseService: CaseService, public codes: Array<ErrorCode>) {
    }

    get flowReady(): boolean {
        return this.latestFlow !== null && this.latestFlow.exit_code !== null && this.latestFlow.end_time !== null;
    }

    ngOnInit(): void {
        this.subscriptions.add(this.caseUpdated, (data: Case) => {
            this.updateFlowData(data);
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
    }

    protected updateFlow(payload: any): void {
        this.subscriptions.add(this.caseService.updateFlow(this.latestFlow.id, payload), (data: Case) => {
            this.caseUpdated.next(data);
        });
    }
}
