import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SettingsNavigationService } from './settings-navigation.service';
import { MaterialModule } from '../material.module';
import { TranslatePipe } from '@ngx-translate/core';
import { MenuItem } from '../tools/menu-item/menu-item.interface';
import { MenuItemComponent } from '../tools/menu-item/menu-item.component';

@Component({
    selector: 'app-settings',
    standalone: true,
    imports: [
        MaterialModule,
        RouterModule,
        TranslatePipe,
        MenuItemComponent,
    ],
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    private static usersRoute = '/settings/users';
    private static groupsRoute = '/settings/groups';
    private static accountsRoute = '/settings/accounts';

    permits = {};

    settingsMenu: Array<MenuItem> = [
        {
            titleKey: 'settingsMenuUsers',
            route: SettingsComponent.usersRoute,
            // Additional urls that should keep the item style as active (i.e. - settings/accounts and settings/account)
            activeUrls: ['/settings/user']
        },
        {
            titleKey: 'settingsMenuGroups',
            route: SettingsComponent.groupsRoute,
            activeUrls: ['/settings/group']
        },
        {
            titleKey: 'settingsMenuAccounts',
            route: SettingsComponent.accountsRoute,
            activeUrls: ['/settings/account']
        },
    ];

    constructor(public router: Router, private settingsNavigationService: SettingsNavigationService) {
    }

    public ngOnInit() {
        this.settingsNavigationService.canActivateSettingsUsers().subscribe(allowed => {
            this.permits[SettingsComponent.usersRoute] = allowed;
        });
        this.settingsNavigationService.canActivateSettingsGroups().subscribe(allowed => {
            this.permits[SettingsComponent.groupsRoute] = allowed;
        });
        this.settingsNavigationService.canActivateSettingsAccounts().subscribe(allowed => {
            this.permits[SettingsComponent.accountsRoute] = allowed;
        });
    }

    public permittedMenu(): Array<MenuItem> {
        return this.settingsMenu.filter((item: MenuItem) => this.permits[item.route]);
    }
}
