import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LeadSimulatorReadiness } from './lead-simulator-readiness.class';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
    ElectrodeDescription,
    ElectrodesDetectionReport,
    HeadPose,
    RollCandidate
} from '../../../services/api.service';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { LoadStlReport } from '../stl-view/stl-view.service';
import { map, mergeMap, take } from 'rxjs/operators';
import { ElectrodeModelService } from '../../electrode-model/electrode-model.service';
import { AppConfigService } from '../../../app-config.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { CoordinateSystem, IMAGE_CS, LEFT, RIGHT, STANDARD_CS } from '../../case.constants';
import * as THREE from 'three';
import { flipVector, NO_FLIP } from '../../../tools/coordinate-systems.constants';
import { degToRad, radToDeg } from 'three/src/math/MathUtils';

import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../../material.module';
import { CommonModule } from '@angular/common';

type ClockWiseAngle = number;
type CounterClockWiseAngle = number;

class RotationsWithCcwRoll extends THREE.Vector3 {
}

export class ElectrodeContext {
    constructor(
        public side: string = null,
        public sideIndex: number = -1,
        public name: string = '') {
    }
}

export class RollChangeEvent {
    constructor(public side: string, public index: number, public from: CounterClockWiseAngle, public to: CounterClockWiseAngle) {
    }
}

interface RollCandidateExt extends RollCandidate {
    rTemplateImage?: THREE.Matrix4;
    rTemplateCcs?: THREE.Matrix4;
    templateCcsRotationAngles?: RotationsWithCcwRoll;
}

interface ElectrodeDescriptionExt extends ElectrodeDescription {
    roll_candidates?: Array<RollCandidateExt>;
    rTemplateCcs?: THREE.Matrix4;
}

interface ElectrodesDetectionReportExt extends ElectrodesDetectionReport {
    electrodes: Array<ElectrodeDescriptionExt>;
}

@Component({
    selector: 'app-case-lead-simulator-controller',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        TranslatePipe,
    ],
    templateUrl: 'lead-simulator-controller.component.html',
    styleUrls: ['lead-simulator-controller.component.scss'],
    animations: [
        trigger('showElectrodePanel', [
            state('enabled', style({opacity: 1})),
            state('disabled', style({opacity: 0})),
            transition('enabled => disabled', animate('500ms')),
            transition('disabled => enabled', animate('500ms'))
        ])
    ],
})
export class LeadSimulatorControllerComponent implements OnInit, OnDestroy {

    @Input() numOfUnclearedElectrodes: number;
    @Input() defaultTitle: string;

    @Input() displayView: Subject<LeadSimulatorReadiness>;
    @Input() electrodesReady: Subject<ElectrodesDetectionReport>;
    @Input() stlReport: Subject<LoadStlReport>;
    @Input() electrodeRollChange: Subject<RollChangeEvent>;
    @Input() electrodeContextChange: Subject<ElectrodeContext>;
    @Input() postopHeadPoseObservable: Observable<HeadPose>;
    @Input() changeCoordinatesObservable: Observable<CoordinateSystem>;

    public electrodesReport: ElectrodesDetectionReportExt = {electrodes: [], noReportAvailable: true};
    public selectedElectrode: ElectrodeDescriptionExt = null;
    public rollCandidate: RollCandidateExt = null;
    public displayElectrodePanel = 'disabled';

    private subscriptions: Subscriptions = new Subscriptions();
    private electrodeRotations: Map<string, Map<number, RotationsWithCcwRoll>>; // z => CounterClockWiseAngle

    private cs: CoordinateSystem | null = null;
    private hpr: HeadPose | null = null;

    // the rotation matrix from the image coordinates to the CCS
    private rImageCcs = new THREE.Matrix4();

    constructor(private electrodeModels: ElectrodeModelService, private appConfig: AppConfigService,
                private translate: TranslateService) {
        this.electrodeRotations = new Map<string, Map<number, RotationsWithCcwRoll>>();
        this.electrodeRotations.set(LEFT, new Map<number, RotationsWithCcwRoll>());
        this.electrodeRotations.set(RIGHT, new Map<number, RotationsWithCcwRoll>());
    }

    public ngOnInit(): void {
        // combined is responsible to get the initial data for the component. It should be triggered once.
        // Once the data is set, only listen to coordinates change events
        const combined = combineLatest([this.stlReport, this.electrodesReady, this.postopHeadPoseObservable]).pipe(
            take(1),
            map(data => {
                    this.electrodesReport = data[1];
                    this.hpr = data[2];
                }
            )
        );
        this.subscriptions.add(combined, () => {
            // Subscribe to changeCoordinatesObservable. Initiate all values based on the combined response
            // Get the default CS from the changeCoordinatesObservable
            this.subscribeToCoordinatesChange();
            this.initRollCalculations();
            if (this.hpr !== null) {
                this.initHeadPoseCalculations();
            }
            // if there are detected electrodes, select the first electrode
            this.selectedElectrode = this.electrodesReport.electrodes.length ? this.electrodesReport.electrodes[0] : null;
            if (this.selectedElectrode) {
                this.onElectrodeSelected();
            }
            this.displayElectrodePanel = this.electrodesReady ? 'enabled' : 'disabled';
        });
    }

    private initRollCalculations(): void {
        // rotate each detected electrode to the first orientation candidate and save as current orientation
        this.electrodesReport.electrodes.forEach((e: ElectrodeDescriptionExt) => {
            // for each roll candidate, calculate the rotation matrix
            const flip = flipVector(e.coordinate_system);
            e.roll_candidates.forEach((rc: RollCandidateExt) => {
                rc.rTemplateImage = this.rotationFromAngles(flip, e.pitch, e.yaw, rc.roll);
            });

            let to: CounterClockWiseAngle = 0;
            let roll: CounterClockWiseAngle = 0;
            const candidates = this.getRollCandidates(e);
            if (candidates.length > 0) {
                roll = candidates[0].roll;
                to = degToRad(roll);
            }
            if (to !== 0) {
                const changeEvent = new RollChangeEvent(e.side.toLowerCase(), e.side_index, 0, to);
                this.electrodeRollChange.next(changeEvent);
            }
            // save the lead rotations in RAS
            this.saveElectrodeRotations(e.side, e.side_index,
                flip.y * flip.z * e.pitch, flip.x * flip.z * e.yaw, flip.x * flip.y * roll
            );
        });
    }

    private initHeadPoseCalculations(): void {
        this.rImageCcs = this.rotationFromAngles(
            flipVector(this.hpr.coordinate_system), this.hpr.pitch, this.hpr.yaw, this.hpr.roll
        ).invert();
        this.electrodesReport.electrodes.forEach((ed: ElectrodeDescriptionExt) => {
            ed.roll_candidates.forEach((rc: RollCandidateExt) => {
                if (rc.rTemplateImage !== undefined) {
                    rc.rTemplateCcs = this.rImageCcs.clone().multiply(rc.rTemplateImage);
                    rc.templateCcsRotationAngles = this.anglesFromRotation(rc.rTemplateCcs);
                }
            });
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions.cancel();
    }

    public getModelDescription(model: ElectrodeDescription): Observable<string> {
        if (model.side_index === -1) {
            return this.translate.get('caseLeadLocationNone');
        }
        const key = (model.side.toLowerCase() === LEFT.toLowerCase()) ? 'caseLeadLocationLeftSide' : 'caseLeadLocationRightSide';
        return this.translate.get(model.model).pipe(
            mergeMap((m: string) => this.translate.get(key, {side_index: model.side_index, model_name: m}))
        );
    }

    public getElectrodes(): Array<ElectrodeDescriptionExt> {
        return this.electrodesReport.electrodes;
    }

    public onElectrodeSelected() {
        if (this.selectedElectrode) {
            this.electrodeContextChange.next(this.getElectrodeContext());
            const selectedElectrodeRoll: RotationsWithCcwRoll = this.getElectrodeRotations(this.selectedElectrode.side, this.selectedElectrode.side_index);
            this.rollCandidate = this.findCandidateByRoll(selectedElectrodeRoll.z);
        }
        else {
            this.electrodeContextChange.next(new ElectrodeContext());
            this.rollCandidate = null;
        }
    }

    public onRollCandidateChange() {
        this.updateElectrodeRoll(
            this.selectedElectrode.side, this.selectedElectrode.side_index,
            this.ccw2cw(this.cs === IMAGE_CS ? this.rollCandidate.roll : this.rollCandidate.templateCcsRotationAngles.z)
        );
    }

    public onRollChanged(value: ClockWiseAngle) {
        this.updateElectrodeRoll(this.selectedElectrode.side, this.selectedElectrode.side_index, value);
    }

    /***
     * The electrode roll is stored and saved as CCW. The UI (Slider) shows values in CW, this method converts
     * the current stored roll for the selected electrode in the UI CW value
     */
    get cwRollValue(): number {
        return this.selectedElectrode ? this.ccw2cw(this.getElectrodeRotations(this.selectedElectrode.side, this.selectedElectrode.side_index).z) : 0;
    }

    public displayRotation(): boolean {
        if (this.selectedElectrode) {
            const model = this.electrodeModels.findModelData(this.selectedElectrode.model);
            return model.canRoll;
        }
        return false;
    }

    public cw2ccw(value: ClockWiseAngle): CounterClockWiseAngle {
        return -value;
    }

    public ccw2cw(value: CounterClockWiseAngle): ClockWiseAngle {
        return -value;
    }

    private updateElectrodeRoll(side: string, index: number, value: ClockWiseAngle) {
        // report the roll change in radians
        const eRotations = this.getElectrodeRotations(side, index);
        this.rollCandidate = this.findCandidateByRoll(this.cw2ccw(value));
        const from: CounterClockWiseAngle = degToRad(this.getElectrodeRotations(side, index).z);
        const to: CounterClockWiseAngle = degToRad(this.cw2ccw(value));
        if (from !== to) {
            this.electrodeRollChange.next(new RollChangeEvent(side.toLowerCase(), index, from, to));
        }
        this.saveElectrodeRotations(side, index, eRotations.x, eRotations.y, this.cw2ccw(value));
    }

    /***
     * Find a candidate that is within the marginal error of the current roll in the current CS.
     * When we switch CS we sometimes lose a bit of the accuracy of numbers but in very small margins
     * @param roll - the roll value to match the candidate with
     * @private
     */
    private findCandidateByRoll(roll: CounterClockWiseAngle): RollCandidateExt {
        function equalRolls(cRoll: number, r: number, threshold: number): boolean {
            return Math.abs(cRoll - r) < threshold;
        }

        return this.getRollCandidates(this.selectedElectrode).find(c => equalRolls(this.getRoll(c), roll, 0.0001)) || null;
    }

    /**
     * Saves a rotation of an electrode into the map that tracks the rotation of each electrode.
     * All values should be RAS (to match three.js)
     * @param side - LEFT or RIGHT
     * @param index - number that indicates the electrode index in the side. Comes from the API
     * @param pitch - number
     * @param yaw - number
     * @param roll - number (in CCW direction, UI slider is CW)
     * @private
     */
    private saveElectrodeRotations(side: string, index: number, pitch: number, yaw: number, roll: CounterClockWiseAngle): void {
        this.getElectrodeRotations(side, index).set(pitch, yaw, roll);
    }

    private getElectrodeContext(): ElectrodeContext {
        return new ElectrodeContext(this.selectedElectrode.side.toLowerCase(), this.selectedElectrode.side_index, this.selectedElectrode.name);
    }

    public getRollCandidates(e: ElectrodeDescriptionExt): Array<RollCandidateExt> {
        return e ? e.roll_candidates.filter(
            candidate => candidate.confidence > this.appConfig.get(AppConfigService.CONFIDENCE_THRESHOLD)
        ) : [];
    }

    public getElectrodeRotations(side: string, sideIndex: number): RotationsWithCcwRoll {
        const sideMap = this.electrodeRotations.get(side.toLowerCase());
        let rotations = sideMap.get(sideIndex);
        if (rotations === undefined) {
            rotations = new RotationsWithCcwRoll();
            sideMap.set(sideIndex, rotations);
        }
        return rotations;
    }

    private subscribeToCoordinatesChange(): void {
        this.subscriptions.add(this.changeCoordinatesObservable, (cs: CoordinateSystem) => {
            if (this.cs !== cs) {
                this.cs = cs;
                this.updateRollValues(cs);
            }
        });
    }

    /**
     * This method updates the roll of the electrode when the CS changes between Image CS and the CCS.
     * The method will apply changes to the roll and keep pitch and yaw in the electrodeRollValues for the reverse
     * process
     * @param moveTo - the coordinate system (IMAGE or CCS) we are moving to (from the other CS)
     * @private
     */
    private updateRollValues(moveTo: CoordinateSystem): void {
        // we should change the roll value for each electrode
        if (this.electrodesReport && this.hpr) {
            this.electrodesReport.electrodes.forEach((e: ElectrodeDescriptionExt) => {
                let rotationMatrix = new THREE.Matrix4().makeRotationFromEuler(new THREE.Euler(0, 0, 0));
                const er = this.getElectrodeRotations(e.side, e.side_index);
                switch (moveTo) {
                    case IMAGE_CS: {
                        // we move from the CCS to the image space. Since we flipped when we moved from image to CCS
                        // we should not flip when we go the other way
                        const rTemplateCcs = this.rotationFromAngles(NO_FLIP, er.x, er.y, er.z);
                        const rCcsImage = this.rImageCcs.clone().invert();
                        rotationMatrix = rCcsImage.clone().multiply(rTemplateCcs);
                        break;
                    }
                    case STANDARD_CS: {
                        // moving from image to CCS require flip if the electrode values in the server are in LPS
                        const rTemplateImage = this.rotationFromAngles(NO_FLIP, er.x, er.y, er.z);
                        rotationMatrix = this.rImageCcs.clone().multiply(rTemplateImage);
                        break;
                    }
                }
                const erInNewCs = this.anglesFromRotation(rotationMatrix);
                this.saveElectrodeRotations(e.side, e.side_index, erInNewCs.x, erInNewCs.y, erInNewCs.z);
            });
        }
    }

    /***
     * Convert pitch, yaw and roll in degrees to THREE.Matrix4 rotation matrix
     * @param flip lps/ras conversion vector
     * @param pitch - pitch in degrees
     * @param yaw - yaw in degrees
     * @param roll - roll in degrees
     * @return a THREE.Matrix4 object with the rotation matrix
     * @private
     */
    private rotationFromAngles(flip: THREE.Vector3, pitch: number, yaw: number, roll: number): THREE.Matrix4 {
        const rX = new THREE.Matrix4().makeRotationX(degToRad(flip.y * flip.z * pitch));
        const rY = new THREE.Matrix4().makeRotationY(degToRad(flip.x * flip.z * yaw));
        const rZ = new THREE.Matrix4().makeRotationZ(degToRad(flip.x * flip.y * roll));
        return rX.clone().multiply(rY).multiply(rZ);
    }

    /***
     * Extract the angles from a rotation matrix and return a RotationsWithCcwRoll object with the pitch, yaw and roll
     * as x, y, z
     * @param rotation - a THREE.Matrix4 with the top 3x3 being a rotation matrix
     * @return a RotationsWithCcwRoll (THREE.Vector3) object with the pitch, yaw and CCW roll as x, y, z
     * @private
     */
    private anglesFromRotation(rotation: THREE.Matrix4): RotationsWithCcwRoll {
        return new RotationsWithCcwRoll(...new THREE.Euler().setFromRotationMatrix(rotation).toArray().map(radToDeg));
    }

    public getRoll(rc: RollCandidateExt): CounterClockWiseAngle {
        return (this.cs === STANDARD_CS && rc.templateCcsRotationAngles !== undefined) ? rc.templateCcsRotationAngles.z : rc.roll;
    }
}
