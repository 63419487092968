import { DataElement } from './data-element.class';
import { FlowType } from './flow-type.class';
import { ImageUse } from './dicom.constants';

export const LEFT = 'left';
export const RIGHT = 'right';

export const SIDES = [LEFT, RIGHT];

// Coordinate Systems used by the code
export interface CoordinateSystem {
    titleKey: string;
    id: string;
}

export const IMAGE_CS: CoordinateSystem = {titleKey: 'coordinateSystemImage', id: 'image_coordinates'};
export const STANDARD_CS: CoordinateSystem = {titleKey: 'coordinateSystemStandard', id: 'standard_coordinates'};

export const COORDINATE_SYSTEMS: Array<CoordinateSystem> = new Array<CoordinateSystem>(IMAGE_CS, STANDARD_CS);

// brain elements
export const STN_L = 'stn_left';
export const STN_R = 'stn_right';
export const RN_L = 'rn_left';
export const RN_R = 'rn_right';
export const GPE_L = 'gpe_left';
export const GPE_R = 'gpe_right';
export const GPI_L = 'gpi_left';
export const GPI_R = 'gpi_right';
export const VIM_L = 'vim_left';
export const VIM_R = 'vim_right';
export const VC_L = 'vc_left';
export const VC_R = 'vc_right';
export const MD_L = 'md_left';
export const VO_L = 'vo_left';
export const VO_R = 'vo_right';
export const MD_R = 'md_right';
export const PU_L = 'pu_left';
export const PU_R = 'pu_right';

export const BRAIN_STRUCTURES = new Array<string>(
    STN_L, STN_R,
    RN_L, RN_R,
    GPE_L, GPE_R,
    GPI_L, GPI_R,
    VIM_L, VIM_R,
    VC_L, VC_R,
    VO_L, VO_R,
    MD_L, MD_R,
    PU_L, PU_R
);

// Electrode Elements
export const ELECTRODE = 'electrode';
export const TIP = 'tip';
export const CONTACTS = 'contacts';
export const MARKER = 'marker';
export const RING = 'ring';

export const SELECTED_CONTACT = 'selected_contact';
export const T1_PLANE = 'plane';
export const INITIAL_IMG_OPACITY = 0.8;

// Brain Structures
export const STN = 'stn';
export const RN = 'rn';
// noinspection JSUnusedGlobalSymbols
export const SN = 'sn';
export const GPE = 'gpe';
export const GPI = 'gpi';
export const VIM = 'vim';
export const VC = 'vc';
export const VO = 'vo';
export const MD = 'md';
export const PU = 'pu';

// See sisdefs.data_elements
export const Elements = {
    FLOW_LOG: new DataElement('flow_log'),
    T1_DICOM: new DataElement('t1_dicom'),
    T2_DICOM: new DataElement('t2_dicom'),
    POSTOP_DICOM: new DataElement('postop_dicom'),
    PLANNING_TO_POSTOP_TRX: new DataElement('planning_to_postop_transformation'),
    STN_VISUALIZATION_HEATMAP_T1_DICOM: new DataElement('stn_visualization_heatmap_t1_dicom'),
    GPI_VISUALIZATION_HEATMAP_T1_DICOM: new DataElement('gpi_visualization_heatmap_t1_dicom'),
    VIM_VISUALIZATION_HEATMAP_T1_DICOM: new DataElement('vim_visualization_heatmap_t1_dicom'),
    ELECTRODE_VISUALIZATION_STL_LEFT: new DataElement('electrode_visualization_stl_left'),
    ELECTRODE_VISUALIZATION_STL_RIGHT: new DataElement('electrode_visualization_stl_right'),
    ELECTRODE_DETECTION_REPORT: new DataElement('electrode_detection'),
    USER_ELECTRODE_MODELS_SELECTION: new DataElement('user_selected_electrode_models'),
    PLANNING_ALPHA_REGTO_POSTOP_CT: new DataElement('t1_alpha_regto_postop_ct_nifti'),
    SEGMENTATION_VALIDATION: new DataElement('segmentation_validation'),
    POSTOP_HEAD_POSE: new DataElement('postop_head_pose'),
};

export const ImageUseToDataElement: Map<ImageUse, DataElement> = new Map<ImageUse, DataElement>([
    [ImageUse.planning, Elements.T1_DICOM],
    [ImageUse.targeting, Elements.T2_DICOM],
    [ImageUse.postop, Elements.POSTOP_DICOM]
]);

// See sisdefs.flows
export const Flows = {
    BG_PREDICTION: new FlowType(1, 'Basal Ganglia Visualization', 'bg_prediction'),
    BG_POST_OP: new FlowType(2, 'Visualize DBS Lead Location', 'bg_post_op'),
    BG_TARGETING_PREDICTION: new FlowType(3, 'Basal Ganglia Targeting Visualization', 'bg_targeting_prediction'),
    BG_PLANNING_PREDICTION: new FlowType(4, 'Basal Ganglia Planning Visualization', 'bg_planning_prediction')
};

export const AllFlowTypes: Array<FlowType> = Object.values(Flows);

// The Targets are an enum representation of the strings used in case data flow (approved_targets) strings
export enum Targets {
    ALL = 'ALL',
    STN = 'STN',
    GP = 'GP',
    VIM = 'VIM'
}

export interface DbsTarget {
    key: Targets;
    position: number;
    titleKey: string;
    elements: Array<DataElement>;
    associatedStructures: Array<string> | null;
    centerCalcObj: Array<string>;
}

export const StnTarget: DbsTarget = {
    key: Targets.STN, position: 1, titleKey: 'targetSTN',
    elements: [Elements.STN_VISUALIZATION_HEATMAP_T1_DICOM],
    associatedStructures: [STN, RN],
    centerCalcObj: [STN_L, STN_R]
};
export const GpTarget: DbsTarget = {
    key: Targets.GP, position: 2, titleKey: 'targetGP',
    elements: [Elements.GPI_VISUALIZATION_HEATMAP_T1_DICOM],
    associatedStructures: [GPE, GPI],
    centerCalcObj: [GPI_L, GPI_R]
};
export const VimTarget: DbsTarget = {
    key: Targets.VIM, position: 3, titleKey: 'targetVIM',
    elements: [Elements.VIM_VISUALIZATION_HEATMAP_T1_DICOM],
    associatedStructures: [VIM, VC, VO, MD, PU],
    centerCalcObj: [VIM_L, VIM_R]
};
export const AllTarget: DbsTarget = {
    key: Targets.ALL, position: 999, titleKey: 'targetAll',
    elements: [
        Elements.STN_VISUALIZATION_HEATMAP_T1_DICOM,
        Elements.GPI_VISUALIZATION_HEATMAP_T1_DICOM,
        Elements.VIM_VISUALIZATION_HEATMAP_T1_DICOM
    ],
    associatedStructures: null, centerCalcObj: null
};

export const DbsTargets: Map<string, DbsTarget> = new Map<string, DbsTarget>(
    [StnTarget, GpTarget, VimTarget, AllTarget].map(t => [t.key, t])
);
export const SWITCHABLE_TARGETS: Array<DbsTarget> = [StnTarget, GpTarget, VimTarget];

export interface AlphaImage {
    target: DbsTarget;
    elementId: DataElement;
    nameKey: string;
}

export const StnAlpha: AlphaImage = {
    target: StnTarget,
    elementId: Elements.STN_VISUALIZATION_HEATMAP_T1_DICOM,
    nameKey: 'caseDicomButtonDisplayStnAlpha',
};
export const GpAlpha: AlphaImage = {
    target: GpTarget,
    elementId: Elements.GPI_VISUALIZATION_HEATMAP_T1_DICOM,
    nameKey: 'caseDicomButtonDisplayGpiAlpha',
};
export const VimAlpha: AlphaImage = {
    target: VimTarget,
    elementId: Elements.VIM_VISUALIZATION_HEATMAP_T1_DICOM,
    nameKey: 'caseDicomButtonDisplayVimAlpha',
};
export const AlphaImages: Array<AlphaImage> = new Array<AlphaImage>(StnAlpha, GpAlpha, VimAlpha);

export const AlphaMap: Map<string, AlphaImage> = new Map<string, AlphaImage>(AlphaImages.map(a => [a.target.key, a]));
