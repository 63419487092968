export interface PasswordPolicy {
    accept(password: string): boolean;

    requirementsKey(): string | null;
}

class StrongPolicy implements PasswordPolicy {
    private static passwordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$?!"/\\{}<>()+\-_~%*&:;])[A-Za-z\d?@$!"/\\{}<>()+\-_~%*&:;]{8,}$/);

    public accept(password: string): boolean {
        return StrongPolicy.passwordRegex.test(password);
    }

    public requirementsKey(): string | null {
        return 'changePasswordStrongRequirements';
    }
}

class WeakPolicy implements PasswordPolicy {
    public accept(password: string): boolean {
        return password.length > 0;
    }

    public requirementsKey(): string | null {
        return null
    }
}

export const STRONG = new StrongPolicy();
export const WEAK = new WeakPolicy();
