<p class="mat-typography admin-title">Advanced Case Management</p>
<div class="case-admin-container">
    <app-case-results-review class="case-admin-cell"
                             [allowedAdvancedReview]="allowedCaseReview"
                             [caseUpdated]="caseUpdated">
    </app-case-results-review>
    <app-case-segmentation-review class="case-admin-cell" [caseUpdated]="caseUpdated"></app-case-segmentation-review>
    <app-case-postop-review class="case-admin-cell" [caseUpdated]="caseUpdated"></app-case-postop-review>
    @if (allowedLogCaseEvent) {
        <app-case-add-activity-message
                class="case-admin-cell"

                [caseUpdated]="caseUpdated">
        </app-case-add-activity-message>
    }
    @if (allowedChoosePlanningToPostopRegistrationMethod) {
        <app-case-tools-choose-registration-method
                class="case-admin-cell"

                [caseUpdated]="caseUpdated"
                [availableMethods]="availablePlanningToPostopRegistrationMethods"
                methodField="planning_to_postop_registration_method"
                kind="Planning to PostOp">
        </app-case-tools-choose-registration-method>
    }
    @if (allowedReRunFlow) {
        <app-case-tools-rerun-flow
                class="case-admin-cell"

                [caseUpdated]="caseUpdated">
        </app-case-tools-rerun-flow>
    }
    @if (allowedUploadPlanningPostopTransform) {
        <app-case-files-upload-transform
                class="case-admin-cell"

                [caseUpdated]="caseUpdated"
                [canUpload]="allowedUploadPlanningPostopTransform"
                [dataElement]="elements.PLANNING_TO_POSTOP_TRX"
                [titleKey]="'caseUploadTypePlanningPostopTrx'"
                [supportedFormats]="['TXT', 'TX.ZIP']"
                [uploadCount]="uploadCount(elements.PLANNING_TO_POSTOP_TRX)"
                [elementUploadReport]="uploadReport(elements.PLANNING_TO_POSTOP_TRX)">
        </app-case-files-upload-transform>
    }
    <div class="case-admin-cell-placeholder"></div>
    <div class="case-admin-cell-placeholder"></div>
</div>
<p class="mat-typography admin-title">Audit Log</p>
@if (allowedAuditQuery) {
    <div class="case-admin-audit-log">
        <mat-table class="mat-elevation-z8" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                <mat-cell *matCellDef="let auditRow"><span>{{ auditRow.created | localDate }}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="message">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Message</mat-header-cell>
                <mat-cell *matCellDef="let auditRow"><span>{{ auditRow.message }}</span>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let auditRow; columns: displayedColumns;" class="table-row">
            </mat-row>
        </mat-table>
        <mat-paginator class="audit-log-table" [pageSize]="10"></mat-paginator>
    </div>
}
