export const environment = {
    production: false,              // product level settings i.e. the way the application behaves logically
    ngProdMode: false,              // specify the Angular app mode of operation
    rootUri: 'https://ca.us.surgicalis.com',
    version: '7.3.0-alpha.0',
    releaseDate: '2024-10-18',
    enableLanguages: true,
    enableVideos: false,
    geography: 'us'
};
