import { Injectable } from '@angular/core';
import { Case, CaseService } from '../case.service';
import { Elements } from '../case.constants';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ElectrodeSelectionResponse } from '../../services/api.service';
import {
    AutoDetect,
    BSC_2201,
    BSC_2202,
    ElectrodeData,
    ElectrodeModel,
    ElectrodeModels,
    MDT_3387,
    MDT_3389,
    MDT_B33005,
    MDT_B33015,
    NoElectrode,
    SJM_6172,
    SJM_6173
} from './electrode-model.constants';

@Injectable({
    providedIn: 'root'
})

export class ElectrodeModelService {
    public static ELECTRODES = 'electrodes';

    static readonly NO_SELECTION: ElectrodeModel = new NoElectrode();
    static readonly AUTO_DETECT: ElectrodeModel = new AutoDetect();

    private readonly electrodeModelsList: Array<ElectrodeData> = [
        MDT_B33005, MDT_B33015, MDT_3389, MDT_3387, BSC_2201, BSC_2202, SJM_6172, SJM_6173
    ];

    constructor(private caseService: CaseService, private http: HttpClient) {
    }

    get autoDetect(): ElectrodeModel {
        return ElectrodeModelService.AUTO_DETECT;
    }

    get noSelection(): ElectrodeModel {
        return ElectrodeModelService.NO_SELECTION;
    }

    // this method trust the using component to call resetSelection
    // before it is called to return a valid list of electrodes.
    getAvailableModels(allowAutoDetect: boolean): ElectrodeModel[] {
        const models: ElectrodeModel[] = this.electrodeModelsList.filter(m => m.cleared).map(m => m.model);
        if (allowAutoDetect) {
            models.splice(0, 0, this.autoDetect);
        }
        models.splice(0, 0, this.noSelection);
        return models;
    }

    findModel(value: string): ElectrodeModel {
        switch (value) {
            case NoElectrode.NO_SELECTION:
                return this.noSelection;
            case AutoDetect.AUTO_DETECT:
                return this.autoDetect;
            default:
                return this.findModelData(value).model;
        }
    }

    public findModelData(model: string): ElectrodeData {
        const em = this.nameToElectrodeModel(model);
        return this.electrodeModelsList.find(data => data.value === em);
    }

    private nameToElectrodeModel(name: string): ElectrodeModels {
        const names: Array<string> = Object.values(ElectrodeModels);
        const index = names.indexOf(name, 0);
        if (index === -1) {
            throw new Error(`Unknown electrode model ${name}`);
        }
        return ElectrodeModels[(Object.keys(ElectrodeModels))[index]];
    }

    getLabel(model: string, contact: number): string {
        return this.findModelData(model).getLabel(contact);
    }

    public getResources(model: string): Array<string> {
        const electrodeData = this.findModelData(model);
        const urlItems: Array<string> = [];
        electrodeData.resources.forEach(item => {
            urlItems.push(`/assets/models/${electrodeData.resFolder}/${item}`);
        });
        return urlItems;
    }

    downloadElement(url: string) {
        return this.http.get(url, {responseType: 'blob'});
    }

    // This method will try to retrieve the user currentSetup data element
    // and set the service to the current data
    public loadLatestSaved(caseData: Case): Observable<ElectrodeSelectionResponse> {
        const electrodesSelectionReady: ReplaySubject<ElectrodeSelectionResponse> = new ReplaySubject<ElectrodeSelectionResponse>();
        if (!caseData) {
            return electrodesSelectionReady;
        }
        const urls = this.caseService.getElementUrls(caseData, Elements.USER_ELECTRODE_MODELS_SELECTION);
        if (urls.length > 0) {
            this.caseService.getCaseJsonResource(caseData.id, urls[0]).subscribe({
                next: (data: ElectrodeSelectionResponse) => {
                    electrodesSelectionReady.next(data);
                },
                error: () => {
                    electrodesSelectionReady.next(null);
                },
                complete: () => {
                    electrodesSelectionReady.complete();
                }
            });
        }
        else {
            electrodesSelectionReady.next(null);
            electrodesSelectionReady.complete();
        }
        return electrodesSelectionReady;
    }

    /**
     * Convert between what we send on the API as the payload of the electrode selections and the
     * models we use on the UI application. If there are multiple models in the payload this method will
     * only convert the first in the list
     * @param payload null, empty list or list with models
     * @return NoElectrode (for null), AutoDetect (for empty list), the model for a model in the list
     */
    public payloadToModel(payload: Array<string> | null): ElectrodeModel {
        return payload === null ? this.noSelection :
            payload.length === 0 ? this.autoDetect :
                this.findModel(payload[0]);
    }
}
