<div id="login-place-holder">
    <app-select-language class="m-r-30" color="black"/>
</div>

<mat-card appearance="outlined" id="login-card">
    <mat-card-title class="login-card-title" i18n="@@loginTitle">{{ 'loginTitle'| translate }}</mat-card-title>
    <mat-card-content>
        <form [formGroup]="loginForm" novalidate>
            <mat-form-field class="login-form-field" hideRequiredMarker appearance="fill">
                <mat-label>{{ 'email'|translate }}</mat-label>
                <input matInput [formControl]="email"
                       data-testid="login-email"
                       type="email"
                       i18n-placeholder="@@loginEmailPlaceHolder"
                       placeholder="{{'loginEmailPlaceHolder'| translate}}">
                @if (email.hasError('required')) {
                    <mat-error i18n="@@loginMissingEmailError">
                        {{ 'loginMissingEmailError' | translate }}
                    </mat-error>
                } @else if (email.hasError('email')) {
                    <mat-error
                            i18n="@@loginInvalidEmailError">
                        {{ 'loginInvalidEmailError' | translate }}
                    </mat-error>
                }
            </mat-form-field>
            <mat-form-field class="login-form-field" hideRequiredMarker appearance="fill">
                <mat-label>{{ 'password'|translate }}</mat-label>
                <input matInput [type]="'password'" [formControl]="password"
                       data-testid="login-password"
                       i18n-placeholder="@@loginPasswordPlaceHolder"
                       placeholder="{{'loginPasswordPlaceHolder'| translate}}">
                @if (password.hasError('required')) {
                    <mat-error i18n="@@loginMissingPasswordError">
                        {{ 'loginMissingPasswordError'| translate }}
                    </mat-error>
                }
            </mat-form-field>

            <button (click)="login()"
                    [disabled]="!loginForm.valid"
                    class="login-button"
                    color="primary"
                    i18n="@@loginMainButton"
                    data-testid="login-button"
                    mat-raised-button>
                {{ 'login' | translate }}
            </button>
        </form>
        <a class="mat-typography" [queryParams]="{'email': email.value}" routerLink="/forgot-password"
           i18n="@@loginForgotPassword">
            {{ 'loginForgotPassword' | translate }}
        </a>
    </mat-card-content>
</mat-card>
<div class="mat-typography" id="login-error">
    <div [hidden]="!failureMessage">
        <p class="mat-caption login-error-message" data-testid="login-error">{{ failureMessage }}</p>
    </div>
    <div [hidden]="!notificationMessage">
        <p class="mat-caption login-error-message">{{ notificationMessage }}</p>
    </div>
</div>
