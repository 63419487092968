<div class="fill-parent">
    <form class="account-main" #accountForm="ngForm">
        <div class="account-header">
            <div class="account-left-container">
                <mat-form-field class="width-250">
                    <mat-label>{{ 'settingsAccountsAccountName'|translate }}</mat-label>
                    <input matInput
                           name="accountName"
                           [(ngModel)]="model.name"
                           [disabled]="!modifyAccountAllowed"
                           required
                           maxlength="256">
                </mat-form-field>
                <mat-form-field class="width-450">
                    <mat-label>{{ 'description'|translate }}</mat-label>
                    <textarea matInput type="text" name="accountDescription" [(ngModel)]="model.description"
                              [disabled]="!modifyAccountAllowed" maxlength="2000"
                              cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="3">
                    </textarea>
                </mat-form-field>
                <mat-form-field class="width-150">
                    <mat-label>{{ 'state'|translate }}</mat-label>
                    <mat-select name="accountState" [(ngModel)]="model.state" required panelWidth=null>
                        @for (accountState of availableAccountStates; track accountState) {
                            <mat-option [value]="accountState">{{ accountState }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="width-450">
                    <mat-label>{{ 'settingsAccountsEditAccountAddressPlaceholder'|translate }}</mat-label>
                    <textarea matInput type="text" name="accountAddress" [(ngModel)]="model.address"
                              [disabled]="!modifyAccountAllowed" maxlength="512"
                              cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="3">
                    </textarea>
                </mat-form-field>
                <mat-form-field class="width-250">
                    <mat-label>{{ 'settingsAccountsEditAccountPhonePlaceholder'|translate }}</mat-label>
                    <input matInput name="accountPhone" [(ngModel)]="model.phone" [disabled]="!modifyAccountAllowed"
                           maxlength="256">
                </mat-form-field>
            </div>
            <div class="account-center-container">
                <div class="emails-inner">
                    <p class="section-header mat-typography" i18n="@@settingsAccountsEditAccountEmailAddressesTitle">
                        {{ 'settingsAccountsEditAccountEmailAddressesTitle'|translate }}
                    </p>
                    <div class="width-100-p">
                        <mat-table [dataSource]="emailDataSource" class="emails-table">
                            <ng-container matColumnDef="email" i18n="@@settingsAccountsEditAccountEmailsTableEmail">
                                <mat-header-cell *matHeaderCellDef class="account-emails-column-350-start">
                                    {{ 'email'|translate }}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="account-emails-column-350-start">
                                    <mat-form-field>
                                        <input matInput
                                               type="email"
                                               [name]="'emailEmail-' + row.rowId"
                                               [(ngModel)]="row.email"
                                               required>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="delete" i18n="@@settingsAccountsEditAccountEmailsTableDelete">
                                <mat-header-cell *matHeaderCellDef class="width-70-p">{{ 'delete'|translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let row"  class="width-70-p">
                                    <button mat-icon-button
                                            matTooltip="remove {{row.email || 'email'}} from account"
                                            matTooltipPosition="after"
                                            (click)="removeEmail(row.rowId)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedEmailColumns"></mat-header-row>
                            <mat-row *matRowDef="let email; columns: displayedEmailColumns;">
                            </mat-row>
                        </mat-table>
                        <mat-paginator #emailPaginator [pageSize]="2"></mat-paginator>
                    </div>
                    @if (modifyAccountAllowed) {
                        <button mat-mini-fab
                                class="add-email-btn"
                                i18n-matTooltip="@@settingsAccountsEditAccountAddEmailTooltip"
                                matTooltip="{{'settingsAccountsEditAccountAddEmailTooltip'|translate}}"
                                matTooltipPosition="before"
                                color="accent"

                                (click)="addEmail()">
                            <mat-icon>add</mat-icon>
                        </button>
                    }
                </div>
            </div>
            <div class="account-right-container">
                @if (listAccountCasesAllowed) {
                    <button mat-raised-button

                            class="min-width-200 m-b-20"
                            type="button"
                            i18n="@@settingsAccountsEditAccountViewCases"
                            (click)="viewCases($event.metaKey)">
                        {{ 'settingsAccountsViewCases'|translate }}
                    </button>
                }
                <button mat-raised-button
                        class="min-width-200 m-b-20"
                        type="submit"
                        i18n="@@settingsAccountsEditAccountButtonSave"
                        [disabled]="!accountForm.form.valid || disableSaveAccount()"
                        (click)="updateAccountInfo()">
                    {{ 'save'|translate }}
                </button>
                @if (deleteAccountAllowed) {
                    <button
                            mat-raised-button
                            class="min-width-200"
                            type="submit"
                            color="warn"
                            i18n="@@settingsAccountsEditAccountButtonDelete"
                            data-testid="account-delete"
                            (click)="deleteAccount()">
                        {{ 'delete'|translate }}
                    </button>
                }
            </div>
        </div>
        <div class="options-section">
            <p class="section-header mat-typography" i18n="@@settingsAccountsEditAccountOptionsTitle">
                {{ 'settingsAccountsEditAccountOptionsTitle'|translate }}
            </p>
            <mat-checkbox name="accountAutoPublish"
                          [(ngModel)]="model.autopublish"
                          [disabled]="!modifyAccountAllowed">
                {{ 'settingsAccountsEditAccountAutoPublish' | translate }}
            </mat-checkbox>
        </div>
        @if (dataSource.data.length > 0) {
            <div class="account-users">
                <p class="section-header mat-typography" i18n="@@settingsAccountsUsersTable">
                    {{ 'settingsAccountsEditAccountUsersList'|translate }}
                </p>
                <div class="account-users-table-container">
                    <div class="mat-elevation-z8 account-users-table">
                        <mat-table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="name" i18n="@@accountUsersTableName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'name'|translate }}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let userRow"> {{ userRow.name }}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="email" i18n="@@accountUsersTableEmail">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'email'|translate }}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let userRow"> {{ userRow.email }}</mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let userRow; columns: displayedColumns;"
                                     class="table-row" data-testid="account-users-rows"
                                     (click)="onActivate(userRow, $event.metaKey)">
                            </mat-row>
                        </mat-table>
                        <mat-paginator [pageSize]="20" [hidePageSize]="true" class="mat-elevation-z8"></mat-paginator>
                    </div>
                </div>
            </div>
        }
    </form>
</div>
