import { Injectable } from '@angular/core';
import { Case, CaseService } from '../case.service';
import { NEVER, Observable, Subject } from 'rxjs';
import { DataElement } from '../data-element.class';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class DicomViewerService {

    constructor(private caseService: CaseService) {
    }

    public hasAlphaElement(caseData: Case, dataElement: DataElement): boolean {
        return this.caseService.hasElement(caseData, dataElement);
    }

    public loadDicomSeries(caseData: Case, dataElement: DataElement, stop: Observable<boolean> = NEVER): Subject<any> {
        const urls = this.caseService.getElementUrls(caseData, dataElement);
        return urls.length > 0 ? this.loadSeries(caseData.id, urls, stop) : null;
    }

    private loadSeries(caseId: string, urls: Array<string>, stop: Observable<boolean>): Subject<any> {
        const seriesData = [];
        const seriesReady = new Subject<any>();

        urls.map(async (url) => {
            this.caseService.getCaseResource(caseId, url, -100).pipe(takeUntil(stop)).subscribe(
                async (data) => {
                    seriesData.push(await this.encodeBlobData(data));
                    if (seriesData.length === urls.length) {
                        seriesReady.next(seriesData);
                        seriesReady.complete();
                    }
                });
        });

        return seriesReady;
    }

    encodeBlobData = async (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const result = reader.result;
                resolve(result);
            };

            reader.onerror = (e) => {
                console.error(e);
                reject('Failed to encode blob data');
            };
        });
    }
}
