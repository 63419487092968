<div class="accept-invitation">
    <mat-card appearance="outlined" class="accept-invitation-card">
        <mat-card-title class="accept-invitation-card-title" i18n="@@acceptInvitationTitle">
            {{'acceptInvitationTitle'|translate}}
        </mat-card-title>
        <mat-card-content *ngIf="stateLoading" i18n="@@acceptInvitationLoadingInvitation">
            {{'acceptInvitationLoadingInvitation'|translate}}...
        </mat-card-content>
        <mat-card-content *ngIf="stateLoggingIn" i18n="@@acceptInvitationLoggingIn">
            {{'acceptInvitationLoggingIn'|translate}}...
        </mat-card-content>
        <mat-card-content *ngIf="stateAlreadyAccepted" i18n="@@acceptInvitationAlreadyAccepted">
            {{'acceptInvitationAlreadyAccepted'|translate}}.
        </mat-card-content>
        <mat-card-content *ngIf="stateSetPassword || stateSettingPassword">
            <p i18n="@@acceptInvitationContent">
                {{'acceptInvitationContent'|translate}}
            </p>
            <div class="password-policy">
                <p *ngIf="!!passwordPolicy.requirementsKey()" i18n="@@changePasswordStrongRequirements">
                    <b>{{'note'| translate}}:</b> {{passwordPolicy.requirementsKey()|translate}}.
                </p>
            </div>
            <form #setPasswordForm="ngForm"
                  autocomplete="off"
                  [hidden]="failed"
                  (ngSubmit)="setPassword();">
                <div class="set-password-input-and-warning">
                    <mat-form-field>
                        <mat-label>{{'newPassword'|translate}}</mat-label>
                        <input matInput class="accept-invitation-input" type="password"
                               name="newPassword" required autocomplete="off" [(ngModel)]="model.newPassword">
                    </mat-form-field>
                    <div [hidden]="!showNonCompliantPassword"
                         i18n="@@changePasswordDoNotMeetReq"
                         class="set-password-alert">
                        {{'changePasswordDoNotMeetReq'|translate}}
                    </div>
                </div>
                <br>
                <mat-form-field>
                    <mat-label>{{'confirmNewPassword'|translate}}</mat-label>
                    <input matInput class="accept-invitation-input" type="password"
                           name="confirmPassword" required autocomplete="off" [(ngModel)]="model.confirmPassword">
                </mat-form-field>
                <br>
                <button mat-raised-button
                        type="submit"
                        i18n="@@acceptInvitationSaveButton"
                        [disabled]="setPasswordForm.invalid || !readyToChange()">
                    {{'save'|translate}}
                </button>
            </form>
            <p *ngIf="passwordsDontMatch()" class="mat-typography accept-invitation-error"
               i18n="@@acceptInvitationPasswordNotMatchError">
                {{'passwordsDoNotMatch'|translate}}
            </p>
        </mat-card-content>
        <mat-card-content *ngIf="stateAccept" i18n="@@acceptInvitationAccepting">
            {{'acceptInvitationAccepting'|translate}}...
        </mat-card-content>
        <mat-card-content *ngIf="stateAccepted">
            <p i18n="@@acceptInvitationSuccessMessage">
                {{'acceptInvitationSuccessMessage'|translate}} <a
                    routerLink="/home">{{'acceptInvitationAllCases'|translate}}</a>.
            </p>
        </mat-card-content>
        <mat-card-content *ngIf="failed">
            <p *ngIf="stateSetPasswordFailed" class="mat-typography accept-invitation-error"
               i18n="@@acceptInvitationFailedToSetPassword">
                {{'acceptInvitationFailedToSetPassword'|translate}} - <a [routerLink]="['login']">login</a>.
            </p>
            <p *ngIf="stateInvalidInvitation || stateInvitationNotFound" class="mat-typography accept-invitation-error"
               i18n="@@acceptInvitationOldInvitationMessage">
                {{'acceptInvitationFailedLoginMessage'|translate}} - <a [routerLink]="['login']">login</a>
            </p>
            <p *ngIf="stateAuthCodeLoginFailed" class="mat-typography accept-invitation-error"
               i18n="@@acceptInvitationCouldNotAcceptInviteFirstMessageError">
                {{'acceptInvitationCouldNotAcceptInviteFirstMessageError'|translate}}
            </p>
            <p *ngIf="stateAcceptFailed" class="mat-typography accept-invitation-error"
               i18n="@@acceptInvitationCouldNotAcceptInviteSecondMessageError">
                {{'acceptInvitationCouldNotAcceptInviteSecondMessageError'|translate}}
            </p>
            <p class="mat-typography accept-invitation-error" i18n="@@acceptInvitationDetailsMessage">
                {{'details'|translate}}: {{failureMessage}}
            </p>
        </mat-card-content>
    </mat-card>
</div>
