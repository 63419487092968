import { Injectable } from '@angular/core';
import { ApiService, VideoResponse } from '../../services/api.service';
import { Observable } from 'rxjs';

@Injectable()
export class HelpVideoService {

    constructor(private apiService: ApiService) {
    }

    public getVideoUrl(videoId: string): Observable<VideoResponse> {
        return this.apiService.getVideoResourceUrl(videoId);
    }
}
