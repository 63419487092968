<div *ngIf="enableLangSelection()" class="language-selector">
    <mat-select class="mat-typography language-selector-select-text"
                [(ngModel)]="selected"
                data-testid="lang-selector"
                name="languageSelector"
                panelWidth=""
                ngDefaultControl
                (selectionChange)="onSelectLanguage($event)">
        @for (lang of translate.langs; track lang) {
            <mat-option [value]="lang" data-testid="lang-options">{{ lang|translate }}</mat-option>
        }
    </mat-select>
</div>
