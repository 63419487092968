import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Subscriptions } from '../tools/subscriptions.class';
import { UserService } from './user.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class LanguageService {

    private subscriptions: Subscriptions = new Subscriptions();

    constructor(private authService: AuthService, private userService: UserService, private translate: TranslateService) {
        this.subscriptions.add(this.translate.onLangChange, () => {
            this.maybeUpdateNotificationLanguage();
        });

        this.subscriptions.add(this.authService.afterLogin, () => {
            this.maybeUpdateNotificationLanguage();
        });
    }

    private maybeUpdateNotificationLanguage() {
        // Only attempt to update the user's notification language if there is an authenticated user
        if (!this.authService.isAuthed()) {
            return;
        }

        const currentUserId = this.authService.currentUserId;
        const currentUserName = this.authService.currentUsername;
        const currentLanguage = this.translate.currentLang;

        this.userService.getUser(currentUserId).subscribe(user => {
            if (user.language !== currentLanguage) {
                this.userService.updateUser(currentUserId, {language: currentLanguage}).subscribe({
                    next: () => {

                    },
                    error: () => {
                        console.log(`Failed to update notification language for ${currentUserName} to ${currentLanguage}`);
                    }
                });
            }
        });
    }
}
