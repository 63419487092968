import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CaseService } from '../../case.service';
import { DataElementResponse } from '../../../services/api.service';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { FlowRow, FlowsService } from '../../../services/flows.service';
import { FlowType } from '../../flow-type.class';
import { DataElement } from '../../data-element.class';

import { MaterialModule } from '../../../material.module';
import { LocalDatePipe } from '../../../tools/local-date.pipe';
import { MinuteSecondsPipe } from '../../../tools/min-sec.pipe';

export interface ViewFlowLogInput {
    caseId: string;
    flowData: FlowRow;
    logElement: DataElementResponse;
}

@Component({
    selector: 'app-case-admin-view-flow-log',
    standalone: true,
    imports: [
        LocalDatePipe,
        MaterialModule,
        MinuteSecondsPipe,
    ],
    templateUrl: './view-flow-log.component.html',
    styleUrls: ['./view-flow-log.component.scss']
})
export class ViewFlowLogComponent implements OnInit {

    private subscriptions = new Subscriptions();
    public logData?: string = null;
    public flow?: FlowType = null;

    constructor(@Inject(MAT_DIALOG_DATA) public data: ViewFlowLogInput,
                public dialogRef: MatDialogRef<ViewFlowLogComponent>,
                private caseService: CaseService, private flowService: FlowsService) {
    }

    ngOnInit(): void {
        this.flow = this.flowService.flowByCode(this.data.flowData.code);
        this.subscriptions.add(this.caseService.getCaseTextResource(this.data.caseId, DataElement.urls(this.data.logElement)[0]),
            (logData: string) => {
                this.logData = logData;
            });
    }

    get flowTime(): number {
        const startDate = new Date(this.data.flowData.start_time);
        const endDate = new Date(this.data.flowData.end_time);
        return endDate.getTime() - startDate.getTime();
    }

    public onClose() {
        this.dialogRef.close(0);
    }
}
