<div class="fill-parent reports-container">
    <div class="reports-selection-container">
        <div class="reports-selection">
            <mat-form-field *ngIf="ready" appearance="fill">
                <mat-label>{{ 'reportSelectScopeTitle'|translate }}</mat-label>
                <mat-select [(ngModel)]="currentScope" [disabled]="inProgress">
                    @for (key of getTemplates(); track key) {
                        <mat-option [value]="key">{{ getTemplateTitle(key)|translate }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="m-l-50">
                <mat-label>{{ 'reportSelectDatesLabel'|translate }}</mat-label>
                <mat-date-range-input [rangePicker]="picker" [disabled]="inProgress">
                    <input matStartDate placeholder="{{'startDate'|translate}}" [(ngModel)]="startDate">
                    <input matEndDate placeholder="{{'endDate'|translate}}" [(ngModel)]="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
        <button mat-raised-button class="report-range-btn" (click)="submitQuery()" [disabled]="disableSubmit()">
            {{ 'submit'|translate }}
        </button>
    </div>
    <div class="reports-ranges">
        <button mat-raised-button class="report-range-btn" (click)="setCurrentMonth()"
                [disabled]="inProgress">
            {{ 'reportsRangeCurrentMonth'|translate }}
        </button>
        <button mat-raised-button class="report-range-btn" (click)="setPrevMonth()"
                [disabled]="inProgress">
            {{ 'reportsRangePrevMonth'|translate }}
        </button>
        <button mat-raised-button class="report-range-btn" (click)="setCurrentQuarter()"
                [disabled]="inProgress">
            {{ 'reportsRangeCurrentQuarter'|translate }}
        </button>
        <button mat-raised-button class="report-range-btn" (click)="setPrevQuarter()"
                [disabled]="inProgress">
            {{ 'reportsRangePrevQuarter'|translate }}
        </button>
        <button mat-raised-button class="report-range-btn" (click)="setCurrentYear()"
                [disabled]="inProgress">
            {{ 'reportsRangeCurrentYear'|translate }}
        </button>
        <button mat-raised-button class="report-range-btn" (click)="setPrevYear()"
                [disabled]="inProgress">
            {{ 'reportsRangePrevYear'|translate }}
        </button>
        <button mat-raised-button class="report-range-btn" (click)="setAll()" [disabled]="inProgress">
            {{ 'all'|translate }}
        </button>
    </div>
</div>
