<div class="main-nav-notifications-container" *ngIf="notifications.length > 0">
    <button mat-icon-button [matMenuTriggerFor]="notificationsMenu">
        <mat-icon class="icon-white">{{ icon }}</mat-icon>
    </button>
    <span *ngIf="unread" class="notification-dot"></span>
    <mat-menu #notificationsMenu>
        @for (n of notifications; track n.id) {
            <button mat-menu-item (click)="showNotification(n)" [ngClass]="nClass(n)">
                <span>{{ n.title }}</span>
            </button>
        }
    </mat-menu>
</div>
