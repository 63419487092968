import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, bufferCount, from, Observable, reduce, Subject } from 'rxjs';
import { ApiService, InboundSeries, InboundSeriesSaveResult, UploadResponse } from '../../services/api.service';
import { mergeMap, tap } from 'rxjs/operators';

export interface UploadError {
    message: string;
    status: number;  // HTTP status code
}

export class Upload {
    private result = new Subject<UploadResponse>();
    private progress = new BehaviorSubject<number>(0);

    public xhr: XMLHttpRequest = null;

    constructor(public caseId: string, public elementName: string, public fileSet: Array<File>, public enableDataIntegrity: boolean,
                public seriesId?: string, public studyId?: string, public patientId?: string) {
        if (fileSet.length === 0) {
            throw new Error('Nothing to upload');
        }
        this.fileSet.sort((a, b) => a.name.localeCompare(b.name));
    }

    public setProgress(progress: number) {
        this.progress.next(progress);
    }

    public getProgressEmitter(): Observable<number> {
        return this.progress;
    }

    public setResult(result: UploadResponse) {
        this.result.next(result);
        this.result.complete();
        this.progress.complete();
    }

    public setError(message: string) {
        this.result.error({message});
        this.result.complete();
        this.progress.complete();
    }

    public getResultEmitter(): Observable<any> {
        return this.result.asObservable();
    }
}

@Injectable()
export class UploadService {

    public activityEmitter = new EventEmitter();
    private isEmptySubject = new BehaviorSubject<boolean>(true);
    private isEmptyObservable = this.isEmptySubject.asObservable();
    private uploadQueue: Array<Upload> = [];
    private uploadInProgress: Upload = null;

    // Uploading in small batches is faster than slice-by-slice. Large batches reduce feedback to user and opportunity for concurrency.
    public filesPerRequest = 4;

    // Need some concurrency to saturate upload; further increases provide diminishing returns.
    public concurrency = 4;

    constructor(private api: ApiService) {
    }

    public isEmpty(): boolean {
        return this.uploadQueue.length === 0 && !this.uploadInProgress;
    }

    public onIsEmptyChange(): Observable<boolean> {
        return this.isEmptyObservable;
    }

    abortUploads(): void {
        // first - remove pending uploads from the queue
        while (this.uploadQueue.length > 0) {
            this.uploadQueue.shift();
        }
        // if we have an ongoing upload, abort it
        if (this.uploadInProgress) {
            if (this.uploadInProgress.xhr) {
                this.uploadInProgress.xhr.abort();
            }
            this.uploadInProgress = null;
        }
        // tell the world that the queue is now empty
        this.isEmptySubject.next(true);
    }

    public push(caseId: string, elementName: string, fileSet: Array<File>, enableDataIntegrity: boolean,
                seriesId?: string, studyId?: string, patientId?: string): Upload | null {
        if (fileSet.length > 0) {
            const upload = new Upload(caseId, elementName, fileSet, enableDataIntegrity, seriesId, studyId, patientId);
            this.uploadQueue.push(upload);
            this.isEmptySubject.next(false);
            this.activityEmitter.next(null);
            this.maybeUploadFromQueue();
            return upload;
        } else {
            return null;
        }
    }

    private maybeUploadFromQueue() {
        if (this.uploadInProgress || this.uploadQueue.length === 0) {
            return;
        }

        const upload = this.uploadInProgress = this.uploadQueue.shift();

        if (upload.seriesId) {
            return this.uploadSeries(upload);
        } else {
            return this.uploadEverything(upload);
        }
    }

    private uploadEverything(upload: Upload) {
        const formData = new FormData();
        upload.fileSet.map(file => formData.append('upload[]', file));
        formData.append('di', String(upload.enableDataIntegrity));

        const xhr = this.api.buildUploadCaseElementXHR(upload.caseId, upload.elementName);
        upload.xhr = xhr;
        xhr.upload.onprogress = (event: any) => {
            this.activityEmitter.next(null);
            upload.setProgress(event.lengthComputable ? Math.round(event.loaded * 100 / event.total) : 0);
        };
        xhr.onerror = () => {
            upload.setError('Upload failed');
            this.uploadCompleted(upload);
        };
        xhr.onabort = () => {
            upload.setError('Upload aborted');
            this.uploadCompleted(upload);
        };
        xhr.onload = () => {
            if ([200, 201].includes(xhr.status)) {
                this.uploadCompleted(upload);
                try {
                    const data = JSON.parse(xhr.response);
                    upload.setResult(data);
                } catch (_e) {
                    // pass
                }
            } else {
                upload.setError('Upload failed');
                this.uploadCompleted(upload);
            }
        };
        xhr.send(formData);
    }

    private uploadSeries(upload: Upload) {
        const totalFiles = upload.fileSet.length;
        this.api.beginInboundSeries({seriesId: upload.seriesId, studyId: upload.studyId, patientId: upload.patientId}).subscribe({
            next: (inboundSeries: InboundSeries) => {
                let filesCompleted = 0;
                from(upload.fileSet).pipe(
                    bufferCount(this.filesPerRequest),
                    mergeMap(chunk => this.api.saveInboundSeriesFiles(inboundSeries.inbound_series_id, chunk), this.concurrency),
                    tap((response: InboundSeriesSaveResult) => {
                        this.activityEmitter.next(null);
                        filesCompleted += response.count;
                        upload.setProgress(Math.round(filesCompleted * 100 / totalFiles));
                    }),
                    reduce((accumulator, chunkResponse) => accumulator + chunkResponse.count, 0)
                ).subscribe({
                    next: (_count) => {
                        this.api.commitInboundSeries(inboundSeries.inbound_series_id, upload.caseId, upload.elementName, upload.enableDataIntegrity).subscribe({
                            next: (response: UploadResponse) => {
                                upload.setResult(response);
                                this.uploadCompleted(upload);
                            },
                            error: (_err) => {
                                upload.setError('Upload failed');
                                this.uploadCompleted(upload);
                            }
                        });
                    },
                    error: (_err) => {
                        upload.setError('Upload failed');
                        this.uploadCompleted(upload);
                        this.api.abortInboundSeries(inboundSeries.inbound_series_id).subscribe({
                            next: (_abort) => {
                                // pass
                            },
                            error: (err) => {
                                console.log(`Failed to abort after failing to upload series: ${err}`);
                            }
                        });
                    }
                });
            }
        });
    }

    private uploadCompleted(upload: Upload) {
        this.activityEmitter.next(null);
        upload.setProgress(100);

        if (this.uploadInProgress === upload) {
            this.uploadInProgress = null;
        }

        if (this.isEmpty()) {
            this.isEmptySubject.next(true);
        } else {
            this.maybeUploadFromQueue();
        }
    }
}
