import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationEvent, AuthService } from './services/auth.service';
import { DEFAULT_INTERRUPTSOURCES, Idle, InterruptSource, provideNgIdle } from '@ng-idle/core';
import { UploadInterruptSource } from './case/upload/upload-interrupt-source';
import { UploadService } from './case/upload/upload.service';
import { TosService } from './services/tos.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TosComponent } from './tos/tos.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { SavedLanguageKey } from './tools/languages.class';
import { AppConfigService } from './app-config.service';
import { PermissionsService } from './services/permissions.service';
import { Permissions } from './security/permissions.class';
import { Features } from './security/feature.class';
import { User, UserService } from './services/user.service';
import { Subscriptions } from './tools/subscriptions.class';
import { RouterOutlet } from '@angular/router';

import { AuthGuardService } from './services/authguard.service';
import { LanguageService } from './services/language.service';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { SecureLinkComponent } from './secure-link/secure-link.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HelpComponent } from './help/help.component';
import { ReportsComponent } from './reports/reports.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SettingsComponent } from './settings/settings.component';
import { CaseComponent } from './case/case.component';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        TranslatePipe,
        LoginComponent,
        MainNavComponent,
        TosComponent,
        HomeComponent,
        CaseComponent,
        SettingsComponent,
        DashboardComponent,
        ReportsComponent,
        HelpComponent,
        ChangePasswordComponent,
        AcceptInvitationComponent,
        SecureLinkComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
    ],
    providers: [
        provideNgIdle(),
    ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

    private auth: AuthService = inject(AuthService);
    private permissionsService: PermissionsService = inject(PermissionsService);
    private idle: Idle = inject(Idle);
    private upload: UploadService = inject(UploadService);
    private tosService: TosService = inject(TosService);
    private userService: UserService = inject(UserService);
    public translate: TranslateService = inject(TranslateService);

    public idleSecondsRemaining: number;

    // How much time prior to the forced session logout should we start warning the user
    public sessionTimeout: number = 300; // 5 minutes

    // How much time without activity can elapse before the user is considered
    // to be idle.
    private idleThreshold = 59 * 60; // 59 minutes

    // How much time, once idle, before the user will be logged out.
    private idleTimeout = 60; // 1 minute

    private subscriptions: Subscriptions = new Subscriptions();

    constructor(appConfigService: AppConfigService, private dialog: MatDialog) {
        // AuthGuardService and LanguageServce are forced to be created immediately so that
        //  its subscriptions and associated behavior activate.
        inject(AuthGuardService);
        inject(LanguageService);
        this.setLanguages(appConfigService.get(AppConfigService.LANGUAGES));
    }

    ngOnInit(): void {
        this.idle.setIdle(this.idleThreshold);
        this.idle.setTimeout(this.idleTimeout);
        this.idle.setInterrupts(this.interruptSources());

        this.subscriptions.limit(this.auth.afterLogin).subscribe({
            next: (authenticationEvent: AuthenticationEvent) => {
                this.idle.watch();

                this.permissionsService.prefetch([
                    Permissions.accountCreate(),
                    Permissions.accountViewAll(),
                    Permissions.anyAccountInvite(),
                    Permissions.anyCaseTransfer(),
                    Permissions.auditQuery(),
                    Permissions.caseCreateForUser(authenticationEvent.userId),
                    Permissions.featureAvailable(Features.DISABLE_DI),
                    Permissions.featureAvailable(Features.DOWNLOAD_ALPHA),
                    Permissions.featureAvailable(Features.ENABLE_ALL_TARGETS),
                    Permissions.featureAvailable(Features.ENABLE_AUTO_DETECT),
                    Permissions.groupCreate(),
                    Permissions.listArchivedCases(),
                    Permissions.listFlows(),
                    Permissions.reportsQuery(),
                    Permissions.userAdmin(),
                    Permissions.userCreate(),
                    Permissions.userView(authenticationEvent.userId),
                ]);

                // Group- and account-related permissions can be prefetched too, but they require knowing more about the user than is
                // available from the authentication event itself.
                this.userService.getUser(authenticationEvent.userId).subscribe({
                    next: (user: User) => {
                        const accountPermissions = user.account_id ? [
                            Permissions.accountView(user.account_id),
                            Permissions.accountModify(user.account_id),
                            Permissions.accountDelete(user.account_id),
                            Permissions.accountInvite(user.account_id),
                            Permissions.listAccountCases(user.account_id),
                        ] : [];
                        const groupPermissions = user.memberships.flatMap(m => [
                            Permissions.groupView(m.group_id),
                            Permissions.groupAdmin(m.group_id),
                            Permissions.groupDelete(m.group_id),
                        ]);
                        this.permissionsService.prefetch([
                            ...accountPermissions,
                            ...groupPermissions,
                        ]);
                    }
                });

                this.idle.onIdleStart.subscribe(() => {
                    this.idleSecondsRemaining = this.idle.getTimeout();
                });

                this.idle.onTimeoutWarning.subscribe(secondsRemaining => {
                    this.idleSecondsRemaining = secondsRemaining;
                });

                this.idle.onTimeout.subscribe(() => {
                    this.auth.logout('Session expired');
                });

                this.auth.afterLogout.subscribe({
                    next: () => {
                        this.idle.stop();
                    }
                });
            }
        });

        this.auth.start();
        this.tosService.start();
        this.subscriptions.limit(this.tosService.showTos).subscribe({
            next: (launchTos: boolean) => this.showTos(launchTos)
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
        this.idle.stop();
        this.tosService.stop();
        this.auth.stop();
    }

    public get isIdle(): boolean {
        return this.idle.isRunning() && this.idle.isIdling();
    }

    public get showNavBar(): boolean {
        return this.auth.isAuthed();
    }

    public showIdleWarning(sessionSecondsRemaining: number | null = null): boolean {
        return this.isIdle && this.idleSecondsRemaining < (sessionSecondsRemaining ?? this.sessionSecondsRemaining);
    }

    public showSessionWarning(): boolean {
        const sessionSecondsRemaining = this.sessionSecondsRemaining;
        return sessionSecondsRemaining > 0
            && sessionSecondsRemaining < this.sessionTimeout
            && !this.showIdleWarning(sessionSecondsRemaining);
    }

    public get sessionSecondsRemaining(): number {
        // If there is an authenticated user, convert the remaining time from milliseconds to seconds. But if the user is not authenticated
        // or if the user's session has expired.
        return Math.max(0, this.auth.isAuthed() ? this.auth.getSessionSecondsRemaining() : 0);
    }

    private setLanguages(languages: Array<string>) {
        this.translate.addLangs(languages);
        const latestLang = localStorage.getItem(SavedLanguageKey);
        if (latestLang && this.translate.langs.includes(latestLang)) {
            this.translate.use(latestLang);
        }
        else {
            localStorage.setItem(SavedLanguageKey, languages[0]);
            this.translate.use(languages[0]);
        }
    }

    private interruptSources(): Array<InterruptSource> {
        return [
            ...DEFAULT_INTERRUPTSOURCES,
            new UploadInterruptSource(this.upload),
        ];
    }

    private showTos(launchTos: boolean) {
        if (launchTos) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.data = {content: this.tosService.getContent()};

            const dialogRef = this.dialog.open(TosComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(
                (tosAccepted: boolean) => this.tosService.onTosAccepted(tosAccepted)
            );
        }
    }
}
