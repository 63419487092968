import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { PermissionsService } from '../../../services/permissions.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '../../../services/account.service';
import { InviteUserComponent } from '../invite-user/invite-user.component';
import { NewUserDirective, NewUserForm } from '../new-user.directive';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../material.module';
import { TranslatePipe } from '@ngx-translate/core';

export interface CreateUserForm extends NewUserForm {
    password: FormControl<string>;
    first_name: FormControl<string>;
    last_name: FormControl<string>;
    phone: FormControl<string>;
}

@Component({
    selector: 'app-create-user',
    standalone: true,
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        TranslatePipe,
    ],
    templateUrl: './create-user.component.html',
    styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent extends NewUserDirective implements OnInit, OnDestroy {

    public password = new FormControl<string>('', {validators: [Validators.required], nonNullable: true});
    public firstName = new FormControl<string>('', {nonNullable: true});
    public lastName = new FormControl<string>('', {nonNullable: true});
    public phoneNumber = new FormControl<string>('', {nonNullable: true});

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<InviteUserComponent>,
                permissionsService: PermissionsService, accountService: AccountService,
                private userService: UserService) {
        super(data, dialogRef, permissionsService, accountService);
        this.mainForm = new FormGroup<CreateUserForm>({
            email: this.email,
            password: this.password,
            first_name: this.firstName,
            last_name: this.lastName,
            phone: this.phoneNumber,
            account: this.account,
        });
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    saveUser() {
        this.inProgress = true;
        this.userService.createNewUser(this.payloadFromForm(this.mainForm.getRawValue())).subscribe(this.saveObserver);
    }

    private payloadFromForm(rawData: any): any {
        return {
            email: rawData.email,
            password: rawData.password,
            first_name: rawData.first_name,
            last_name: rawData.last_name,
            phone: rawData.phone,
            account_id: rawData.account.accountId,
        };
    }
}
