export class Feature {
    constructor(public readonly name: string, public readonly key: string, public readonly sortOrder: number) {
    }
}

export const Features = {
    DOWNLOAD_ALPHA: new Feature('DOWNLOAD_ALPHA', 'settingsUsersProfileFeatureDownloadAlpha', 0),
    DISABLE_DI: new Feature('DISABLE_DI', 'settingsUsersProfileFeatureDisableDI', 2),
    ENABLE_ALL_ELECTRODE_MODELS: new Feature('ENABLE_ALL_ELECTRODE_MODELS', 'settingsUsersProfileFeatureViewAllElectrodeModels', 3),
    ENABLE_ALL_TARGETS: new Feature('ENABLE_ALL_TARGETS', 'settingsUsersProfileFeatureEnableAllTargets', 4),
    STRONG_PASSWORD: new Feature('STRONG_PASSWORD', 'settingsUsersProfileFeatureStrongPassword', 5),
    REPORTS: new Feature('REPORTS', 'settingsUsersProfileFeatureReports', 6),
    ENABLE_AUTO_DETECT: new Feature('ENABLE_AUTO_DETECT', 'settingsUsersProfileFeatureAutoDetect', 7),
    ADMIN_PHI: new Feature('ADMIN_PHI', 'settingsUsersProfileFeatureAdminPHI', 8),
    SKIP_ANONYMIZATION: new Feature('SKIP_ANONYMIZATION', 'settingsUsersProfileFeatureSkipAnonymization', 9),
};

export const AllFeatures: Feature[] =
    Object.keys(Features).map(k => Features[k]).sort((a: Feature, b: Feature) => a.sortOrder - b.sortOrder);
