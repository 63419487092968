import { Component, OnDestroy, OnInit } from '@angular/core';
import { BooleanResponse, UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriptions } from '../tools/subscriptions.class';
import { PasswordPolicy } from '../tools/password-policy.class';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

    public failed = false;
    public failureMessage: string = ''

    public model = {
        newPassword: '',
        confirmPassword: ''
    };

    // public only for testing; not for general use
    public changingPassword: boolean;

    private subscriptions = new Subscriptions();

    constructor(private userService: UserService, private authService: AuthService, private router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.subscriptions.add(this.route.queryParams, params => {
            // Reset when the query params change
            this.changingPassword = false;
            this.failed = false;
            this.failureMessage = '';

            this.subscriptions.add(this.authService.authCodeLogin(params.auth_code),
                _ => {
                },
                e => {
                    this.failed = true;
                    this.failureMessage = e.message;
                });
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
    }

    get passwordPolicy(): PasswordPolicy {
        return this.authService.currentPasswordPolicy();
    }

    get showNonCompliantPassword(): boolean {
        return this.model.newPassword && !this.passwordPolicy.accept(this.model.newPassword);
    }

    changePassword() {
        this.changingPassword = true;
        this.subscriptions.add(this.userService.changePasswordWithToken(this.authService.currentUserId, this.model.newPassword),
            (response: BooleanResponse) => {
                this.router.navigate(response.result ? ['/home'] : ['/login']).then();
            },
            (error) => {
                // console.log(error.message);
                this.failed = true;
                this.failureMessage = error.message;
                this.changingPassword = false;
            }
        );
    }

    passwordsDontMatch() {
        return this.model.newPassword &&
            this.model.confirmPassword &&
            this.model.newPassword !== this.model.confirmPassword;
    }

    readyToChange() {
        return this.authService.isAuthed() &&
            !this.changingPassword &&
            !this.failed &&
            this.model.newPassword &&
            this.model.confirmPassword &&
            this.model.newPassword === this.model.confirmPassword &&
            this.passwordPolicy.accept(this.model.newPassword);
    }
}
