import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../../services/account.service';
import { Account, AccountState } from '../../../services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionsService } from '../../../services/permissions.service';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { Permissions } from '../../../security/permissions.class';
import { ConfirmCancelDialogComponent } from '../../../tools/confirm-dialog.component';
import { compareUsersByDescription, User, userDescription, UserService } from '../../../services/user.service';
import { Values } from '../../../tools/values.class';

export class EmailRow {
    rowId: number;
    email: string;
}

export class AccountModel {
    id: string;
    created: string;
    updated: string;
    name: string;
    description: string;
    state: AccountState;
    address: string;
    phone: string;
    autopublish: boolean;
    emailRows: EmailRow[];
    nextEmailRowId: number;
}

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('emailPaginator', {static: true}) emailPaginator: MatPaginator;
    @ViewChild('endpointPaginator', {static: true}) endpointPaginator: MatPaginator;

    model: AccountModel = {
        id: null,
        created: '',
        updated: '',
        name: '',
        description: '',
        state: AccountState.ACTIVE,
        address: '',
        phone: '',
        autopublish: false,
        emailRows: [],
        nextEmailRowId: 0,
    };

    accountAsLoaded = null;

    // English only - account states can be only english at this stage
    availableAccountStates: AccountState[] = [AccountState.ACTIVE, AccountState.SUSPENDED];
    availableUsers: User[] = [];
    // noinspection SpellCheckingInspection

    listAccountCasesAllowed = false;
    modifyAccountAllowed = false;
    deleteAccountAllowed = false;

    displayedEmailColumns = ['email', 'delete'];

    emailDataSource = new MatTableDataSource<EmailRow>();

    private lifetimeSubscriptions = new Subscriptions();
    private subscriptions = new Subscriptions();

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private accountService: AccountService,
                private permissionService: PermissionsService, private userService: UserService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        // The account component needs the accountId to load account permissions. We will subscribe for the
        // lifetime of the component to the activateRoute params change to get the accountId when it changes.
        // Once the accountId changes, we load account permissions and data.
        this.lifetimeSubscriptions.add(this.activatedRoute.params, () => {
            this.setupNewAccount();
        });
    }

    private setupNewAccount() {
        this.subscriptions.cancel();
        this.model.id = this.activatedRoute.snapshot.params.accountId;
        this.subscriptions.limit(this.userService.getUsers()).subscribe(users => {
            this.availableUsers = users.sort(compareUsersByDescription);
        });
        this.permissionService.batch(this.subscriptions, [
            [Permissions.listAccountCases(this.model.id), allowed => {
                this.listAccountCasesAllowed = allowed;
            }],
            [Permissions.accountModify(this.model.id), allowed => {
                this.modifyAccountAllowed = allowed;
            }],
            [Permissions.accountDelete(this.model.id), allowed => {
                this.deleteAccountAllowed = allowed;
            }],
        ])
        this.loadAccountData();
    }

    ngAfterViewInit() {
        this.emailDataSource.paginator = this.emailPaginator;
    }

    ngOnDestroy() {
        this.subscriptions.cancel();
        this.lifetimeSubscriptions.cancel();
    }

    disableSaveAccount() {
        return Values.equal(this.accountFromModel(this.model), this.accountAsLoaded);
    }

    updateAccountInfo() {
        const payload = {
            name: this.model.name,
            description: this.model.description,
            state: this.model.state,
            address: this.model.address,
            phone: this.model.phone,
            emails: this.model.emailRows.map(row => row.email),
            autopublish: this.model.autopublish,
        };
        this.subscriptions.add(this.accountService.updateAccount(this.model.id, payload), response => {
            this.readAccountData(response);
        }, error => {
            alert(error.message);
        });
    }

    deleteAccount() {
        this.confirm('settingsAccountsDeleteAccount', 'settingsAccountsDeleteAccountContent',
            'delete', 'cancel', () => {
                this.subscriptions.add(this.accountService.deleteAccount(this.model.id), () => {
                    // noinspection JSIgnoredPromiseFromCall
                    this.router.navigate(['/settings/accounts']);
                });
            });
    }

    addEmail() {
        this.model.emailRows.push({
            rowId: this.model.nextEmailRowId,
            email: ''
        });
        this.model.nextEmailRowId += 1;
        this.emailDataSource.data = this.model.emailRows;
        setTimeout(() => this.emailDataSource.paginator.lastPage(), 1);
    }

    removeEmail(rowId: number) {
        this.prepareForLastPageDisappearing(this.emailDataSource.paginator);

        this.model.emailRows = this.model.emailRows.filter(row => row.rowId !== rowId);
        this.emailDataSource.data = this.model.emailRows;
    }

    trackUserById(_index: number, user: User): string {
        return user.id;
    }

    trackStateByValue(_index: number, state: AccountState): string {
        return AccountState[state];
    }

    userDescription(user: User): string {
        return userDescription(user);
    }

    private loadAccountData() {
        this.subscriptions.add(this.accountService.getAccount(this.model.id),
            (account: Account) => {
                if (account == null) {
                    this.router.navigate(['settings/not-found']).then();
                }
                else {
                    this.readAccountData(account);
                }
            },
            () => {
                this.router.navigate(['settings/not-found']).then();
            }
        );
    }

    private readAccountData(account: Account) {
        this.model = this.modelFromAccount(account);
        this.emailDataSource.data = this.model.emailRows;

        // Make a deep copy of the account data for later comparison
        this.accountAsLoaded = Values.deepCopy(account);
    }

    private confirm(title: string, contentText: string, actionText: string, cancelText: string, callbackFunction: () => void, cancelFunction?: () => void): void {
        const confirmationTemplate = {
            title,
            contentText,
            cancelText,
            actionText
        };
        const dialogRef = this.dialog.open(ConfirmCancelDialogComponent, {
            width: '400px', data: confirmationTemplate
        });

        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                callbackFunction();
            }
            else if (cancelFunction !== undefined) {
                cancelFunction();
            }
        });
    }

    private prepareForLastPageDisappearing(paginator: MatPaginator) {
        // If we're on the last page and removing one item would decrease the number of pages, move to the prior page.
        if (!paginator.hasNextPage() && paginator.hasPreviousPage()) {
            const oldPageCount = Math.ceil(paginator.length / paginator.pageSize);
            const newPageCount = Math.ceil((paginator.length - 1) / paginator.pageSize);
            if (newPageCount < oldPageCount) {
                paginator.previousPage();
            }
        }
    }

    private accountFromModel(model: AccountModel): Account {
        return {
            id: model.id,
            created: model.created,
            updated: model.updated,
            name: model.name,
            description: model.description,
            state: model.state,
            emails: model.emailRows.map(row => row.email),
            address: model.address,
            phone: model.phone,
            autopublish: model.autopublish,
        };
    }

    private modelFromAccount(account: Account): AccountModel {
        let nextEmailRowId = 0;
        return {
            id: account.id,
            created: account.created,
            updated: account.updated,
            name: account.name,
            description: account.description,
            state: account.state,
            address: account.address,
            phone: account.phone,
            autopublish: account.autopublish,
            emailRows: account.emails.map(email => {
                return {rowId: nextEmailRowId++, email};
            }),
            nextEmailRowId: account.emails.length,
        };
    }

    public viewCases(metaKey: boolean): void {
        // the key in queryParams (accountId) need to match the ACCOUNT_CONTEXT_KEY in context-item.class.ts
        const extras = {queryParams: {accountId: this.model.id}};
        const url = this.router.serializeUrl(this.router.createUrlTree(['/home'], extras));
        metaKey ? window.open(url, '_blank') : this.router.navigate(['/home'], extras);
    }
}
