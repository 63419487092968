import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Notification } from '../../services/api.service';
import { NotificationsService } from '../notifications.service';

@Component({
    selector: 'app-notification-dlg',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {

    public index = 0;

    constructor(@Inject(MAT_DIALOG_DATA) public data: Notification, private dialogRef: MatDialogRef<NotificationComponent>,
                private service: NotificationsService) {
    }

    public dismiss() {
        this.service.dismiss(this.data);
        this.dialogRef.close();
    }
}
