import { Injectable } from '@angular/core';
import { ApiService, SearchResponse } from '../../services/api.service';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class SearchService {

    constructor(private api: ApiService) {
    }

    query(text: string, scopes: Array<string>): Observable<SearchResponse> {
        return this.api.postSearchQuery(text, scopes);
    }
}
