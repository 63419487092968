import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Case, CaseService } from '../../case.service';
import { Subject } from 'rxjs';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { RegistrationMethod } from '../../registration-method.class';
import { MaterialModule } from '../../../material.module';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-case-tools-choose-registration-method',
    standalone: true,
    imports: [
        FormsModule,
        MaterialModule,
    ],
    templateUrl: './choose-registration-method.component.html',
    styleUrls: ['./choose-registration-method.component.scss']
})
export class ChooseRegistrationMethodComponent implements OnInit, OnDestroy {
    private static NO_METHOD_CODE = '';

    @Input() caseUpdated: Subject<Case>;

    private subscriptions = new Subscriptions();

    @Input() methodField = '';
    @Input() availableMethods: Array<RegistrationMethod> = [];
    @Input() kind = ''; // e.g., 'Planning to PostOp'

    caseId: string;
    noMethod: RegistrationMethod = new RegistrationMethod(ChooseRegistrationMethodComponent.NO_METHOD_CODE,
        '(default)');
    selectedMethod: string = ChooseRegistrationMethodComponent.NO_METHOD_CODE;

    constructor(private caseService: CaseService) {
    }

    ngOnInit() {
        this.subscriptions.add(this.caseUpdated, data => {
            this.caseId = data.id;
            this.selectedMethod = data[this.methodField] || ChooseRegistrationMethodComponent.NO_METHOD_CODE;
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
    }

    methodChoices(): Array<RegistrationMethod> {
        return [this.noMethod].concat(this.availableMethods);
    }

    save() {
        const payload = {};
        payload[this.methodField] = this.selectedMethod || null;
        this.caseService.updateCase(this.caseId, payload).subscribe(data => {
            this.caseUpdated.next(data);
        });
    }
}
