import { Component } from '@angular/core';
import { UploadService } from './upload.service';
import { TranslateService } from '@ngx-translate/core';
import { UploadDirective } from './upload.directive';

@Component({
    selector: 'app-case-files-upload-transform',
    templateUrl: './upload-transform.component.html',
    styleUrls: ['./upload.component.scss']
})
export class UploadTransformComponent extends UploadDirective {

    constructor(uploadService: UploadService, translate: TranslateService) {
        super(uploadService, translate);
    }

    getFileCount(): number {
        const report = this.validUploadReport();
        return report ? 1 : this.uploadCount;
    }

    protected getMinFileCount(): number {
        return 1;
    }
}
