import { Component, Input } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { MatSelectChange } from '@angular/material/select';
import { environment } from '../../environments/environment';
import { SavedLanguageKey } from '../tools/languages.class';

import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'app-select-language',
    standalone: true,
    imports: [
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        TranslatePipe,
    ],
    templateUrl: './select-language.component.html',
    styleUrls: ['./select-language.component.scss']
})
export class SelectLanguageComponent {

    @Input() color: string;

    selected: string;

    constructor(public translate: TranslateService) {
        this.selected = translate.currentLang;
    }

    enableLangSelection(): boolean {
        return environment.enableLanguages;
    }

    onSelectLanguage(event: MatSelectChange) {
        this.selected = event.value;
        this.translate.use(this.selected);
        localStorage.setItem(SavedLanguageKey, this.selected);
    }
}
