<mat-card>
    <mat-card-title i18n="@@settingsUsersCreateNewUserTitle"></mat-card-title>
    <mat-card-content class="create-user-content-container">
        <form [formGroup]="mainForm" novalidate>
            <mat-form-field class="width-80-p">
                <mat-label>{{ 'settingsUsersCreateUserEmail'|translate }}</mat-label>
                <input matInput [formControl]="email" data-testid="create-user-email" type="email">
            </mat-form-field>
            <mat-form-field class="width-80-p">
                <mat-label>{{ 'settingsUsersCreateUserInitialPassword'|translate }}</mat-label>
                <input matInput [formControl]="password" autocomplete="new-password" data-testid="create-user-pw"
                       type="password">
            </mat-form-field>
            <mat-form-field class="width-80-p">
                <mat-label>{{ 'settingsUsersCreateUserFirstName'|translate }}</mat-label>
                <input matInput [formControl]="firstName" data-testid="create-user-first-name" type="text">
            </mat-form-field>
            <mat-form-field class="width-80-p">
                <mat-label>{{ 'settingsUsersCreateUserLastName'|translate }}</mat-label>
                <input matInput [formControl]="lastName" data-testid="create-user-last-name" type="text">
            </mat-form-field>
            <mat-form-field class="width-80-p">
                <mat-label>{{ 'settingsUsersCreateUserPhoneNumber'|translate }}</mat-label>
                <input matInput [formControl]="phoneNumber" type="tel">
            </mat-form-field>
            <mat-form-field class="width-80-p m-b-40">
                <mat-label>{{ 'settingsUsersInviteAccount'|translate }}</mat-label>
                <input matInput [formControl]="account" type="text" data-testid="create-user-account"
                       [matAutocomplete]="auto">
                <mat-autocomplete
					#auto="matAutocomplete"
                    [displayWith]="displayAccount">
                    @for (ca of filteredAccounts | async; track ca.accountId) {
                        <mat-option [value]="ca">{{ ca.name }}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
            <br>
            <button mat-raised-button
                    (click)="saveUser()" [disabled]="submitDisabled()"
                    class="m-r-40"
                    color="primary"
                    data-testid="create-user-create"
                    i18n="@@settingsUsersCreateUserButtonCreate">
                {{ 'settingsUsersCreateUserButtonCreate'|translate }}
            </button>
            <button mat-raised-button
                    (click)="closeDialog()"
                    [disabled]="inProgress"
                    data-testid="create-user-cancel"
                    i18n="@@settingsUsersCreateUserButtonClose">
                {{ 'close'|translate }}
            </button>
        </form>
        <div class="create_user_notification">
            @if (success) {
                <p
                        i18n="@@settingsUsersCreateUserMessageSuccess"
                        class="create-user-success-message mat-typography">
                    {{ 'settingsUsersCreateUserMessageSuccess'|translate }}!
                </p>
            }
            @if (error) {
                <p
                        i18n="@@settingsUsersCreateUserMessageError"
                        class="create-user-error-message mat-typography">
                    {{ 'settingsUsersCreateUserMessageError'|translate }}
                </p>
            }
        </div>
    </mat-card-content>
</mat-card>

