import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscriptions } from '../tools/subscriptions.class';
import { Router, RouterModule } from '@angular/router';
import { AuthenticationFailure, AuthService, LoginResponse, TooManyRequests } from '../services/auth.service';
import { AuthGuardService } from '../services/authguard.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
    ChangePasswordComponent,
    ChangePasswordMode,
    ChangePasswordResult
} from '../change-password/change-password.component';
import { ConfirmationTemplate, ConfirmCancelDialogComponent } from '../tools/confirm-dialog.component';

import { MaterialModule } from '../material.module';
import { SelectLanguageComponent } from '../select-language/select-language.component';

interface LoginForm {
    email: FormControl<string>;
    password: FormControl<string>;
}

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [
        RouterModule,
        MaterialModule,
        TranslatePipe,
        ReactiveFormsModule,
        SelectLanguageComponent,
        ConfirmCancelDialogComponent,
    ],
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    email = new FormControl<string>('', [Validators.required, Validators.email]);
    password = new FormControl<string>('', [Validators.required]);
    loginForm = new FormGroup<LoginForm>({
        email: this.email,
        password: this.password,
    });

    loginInProgress = false;

    failureMessage = '';
    notificationMessage = '';

    private subscriptions = new Subscriptions();

    constructor(public router: Router, private auth: AuthService, private authGuard: AuthGuardService,
                private translate: TranslateService, private dialog: MatDialog) {
    }

    ngOnInit() {
        this.failureMessage = this.auth.logoutReason ?? '';
    }

    ngOnDestroy() {
        this.subscriptions.cancel();
    }

    login() {
        this.loginInProgress = true;
        this.failureMessage = '';
        this.notificationMessage = '';

        this.doLogin(this.email.value, this.password.value);
    }

    private doLogin(email: string, pw: string): void {
        this.subscriptions.add(this.auth.login(email, pw),
            (response: LoginResponse) => {
                this.loginInProgress = false;
                // if the password expired we have to change the password NOW
                if (response.expired) {
                    // we need to open the change password dialog in expired password more
                    this.openChangePasswordDialog(ChangePasswordMode.expired);
                }
                else {
                    // if the password did not expire yet, but we are within the final SUGGEST_PERIOD window
                    // Suggest the user to change the password
                    if (response.expire_in !== null && response.expire_in < AuthService.SUGGEST_PW_CHANGE) {
                        this.router.navigateByUrl(this.authGuard.afterLoginUrl).then(() => {
                            this.openSuggestPasswordChangeDialog(response.expire_in);
                        });
                    }
                    else {
                        this.router.navigateByUrl(this.authGuard.afterLoginUrl).then();
                    }
                }
            },
            error => {
                this.loginInProgress = false;
                if (error instanceof AuthenticationFailure) {
                    this.translate.get('loginIncorrectUsernameOrPassword').subscribe(translatedText =>
                        this.failureMessage = translatedText
                    );
                }
                else if (error instanceof TooManyRequests) {
                    this.translate.get('loginTooManyLoginRequests').subscribe(translatedText =>
                        this.failureMessage = translatedText
                    );
                }
                else {
                    this.translate.get('loginUnableToProcessLogin').subscribe(translatedText =>
                        this.failureMessage = translatedText
                    );
                }
            }
        );
    }

    private openChangePasswordDialog(mode: ChangePasswordMode) {
        const changePasswordDialogOptions = {
            width: '800px',
            data: {mode: mode},
        };
        const dialogRef = this.dialog.open(ChangePasswordComponent, changePasswordDialogOptions);
        this.subscriptions.add(dialogRef.afterClosed(), (changeOutcome: ChangePasswordResult) => {
            if (changeOutcome.result) {
                this.doLogin(this.email.value, changeOutcome.password);
            }
            // if the password was not changed (cancel), we do nothing. User will stay on the page he was
        });
    }

    private openSuggestPasswordChangeDialog(expireInSec: number) {
        const dayInSeconds: number = 24 * 60 * 60;
        this.translate.get('loginSuggestNewPasswordContent', {days: Math.floor(expireInSec / dayInSeconds)}).subscribe(
            (translated: string) => {
                const confirmationTemplate: ConfirmationTemplate = {
                    title: 'loginSuggestNewPasswordTitle',
                    contentText: translated,
                    cancelText: 'loginChangePasswordLater', actionText: 'loginChangePasswordNow'
                };
                const dialogRef = this.dialog.open(ConfirmCancelDialogComponent, {
                    width: '400px', data: confirmationTemplate
                });

                dialogRef.afterClosed().subscribe(confirmed => {
                    if (confirmed) {
                        this.openChangePasswordDialog(ChangePasswordMode.authenticated);
                    }
                });
            }
        );
    }
}
