import { InterruptArgs, InterruptSource } from '@ng-idle/core';
import { UploadService } from './upload.service';
import { Subscription } from 'rxjs';

export class UploadInterruptSource extends InterruptSource {

    private subscription: Subscription;

    constructor(private uploadService: UploadService) {
        super(null, null);

        this.attachFn = () => {
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
            this.subscription = this.uploadService.activityEmitter.subscribe(() => {
                this.trigger();
            });
        };

        this.detachFn = () => {
            if (this.subscription) {
                this.subscription.unsubscribe();
                this.subscription = null;
            }
        };
    }

    public trigger(innerArgs?: any): void {
        this.onInterrupt.emit(new InterruptArgs(this, innerArgs));
    }
}