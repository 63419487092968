export class Role {
    constructor(public readonly name: string, public readonly labelKey: string, public readonly sortOrder: number) {
    }
}

export const Roles = {
    USER: new Role('USER', 'settingsUsersProfileRoleUser', 0),
    ADMIN: new Role('ADMIN', 'settingsUsersProfileRoleAdmin', 1),
    SUPERVISOR: new Role('SUPERVISOR', 'settingsUsersProfileRoleSupervisor', 2)
};

export const AllRoles = Object.values(Roles).sort((a: Role, b: Role) => a.sortOrder - b.sortOrder);
