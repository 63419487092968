import { Component, OnDestroy } from '@angular/core';
import { ReportDirective } from '../report.directive';
import { ReportsService } from '../reports.service';
import { QuartersService } from '../../services/quarters.service';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-security-report',
    templateUrl: './security-report.component.html',
    styleUrls: ['./security-report.component.scss', '../reports.component.scss'],
    providers: []
})
export class SecurityReportComponent extends ReportDirective implements OnDestroy {

    constructor(reportsService: ReportsService, qService: QuartersService) {
        super(reportsService, qService);
    }

    public reset(clearSelection: boolean = false): void {
        super.reset();
        if (clearSelection) {
            this.selectedTimeRange = null;
        }
    }

    public disableSubmit(): boolean {
        return this.startDate === null && this.endDate === null;
    }

    public submitQuery(): void {
        this.inProgress = true;
        this.rs.getSecurityReport(this.startDate, this.endDate).subscribe({
            next: (reportBody: any) => {
                saveAs(reportBody, 'security.xlsx');
            },
            error: () => {
                this.inProgress = false;
            },
            complete: () => {
                this.inProgress = false;
            }
        });
        this.reset(true);
    }

    public onDatePickerSelection() {
        if (this.startDate !== null && this.endDate !== null) {
            this.matchDateRangeAsNeeded();
        }
    }
}
