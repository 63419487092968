import { Component, Inject, OnInit } from '@angular/core';
import { GroupCandidate, GroupService } from '../../../services/group.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { GroupPermission } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { map, startWith } from 'rxjs/operators';
import { userDescription, userSearchableText } from '../../../services/user.service';
import { MoreValidators } from '../../../tools/more-validators.class';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../material.module';
import { TranslatePipe } from '@ngx-translate/core';

interface AddMemberForm {
    user: FormControl<string>;
    groupAdmin: FormControl<boolean>;
    caseManager: FormControl<boolean>;
}

@Component({
    selector: 'app-add-member',
    standalone: true,
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        TranslatePipe,
    ],
    templateUrl: './add-member.component.html',
    styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {

    public candidateUsers: Array<GroupCandidate> = [];
    public queryPending = true;

    public user = new FormControl(null, [Validators.required, MoreValidators.hasSelectedObject]);
    public groupAdmin = new FormControl(false);
    public caseManager = new FormControl(false);

    public addMemberForm = new FormGroup<AddMemberForm>({
        user: this.user,
        groupAdmin: this.groupAdmin,
        caseManager: this.caseManager,
    });

    public filteredUsers: Observable<GroupCandidate[]>;

    private subscriptions = new Subscriptions();
    private readonly groupId: string;
    private submitInProgress = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<AddMemberComponent>,
                private groupService: GroupService) {
        this.groupId = data.groupId;
    }

    public ngOnInit() {
        this.subscriptions.limit(this.groupService.getGroupCandidates(this.groupId)).subscribe({
            next: groupCandidates => {
                this.candidateUsers = groupCandidates;
                this.queryPending = false;
            }
        });

        this.filteredUsers = this.user.valueChanges.pipe(
            startWith(''),
            map(search => (typeof search === 'string') ? this.filteredCandidates(search) : [])
        );
    }

    public displayUser(user: GroupCandidate | null): string {
        return user ? userDescription(user) : '';
    }

    private filteredCandidates(search: string): GroupCandidate[] {
        const lcSearch = search.toLocaleLowerCase();
        return this.candidateUsers.filter(cu => userSearchableText(cu).toLocaleLowerCase().includes(lcSearch));
    }

    public onInvite() {
        this.submitInProgress = true;
        const permissions = [];
        if (this.groupAdmin.value) {
            permissions.push(GroupPermission.GroupAdmin);
        }
        if (this.caseManager.value) {
            permissions.push(GroupPermission.CaseManager);
        }
        this.groupService.createUserInvitation(this.user.value.email, {
            groupId: this.groupId,
            permissions
        }).subscribe({
            next: () => {
                this.dialogRef.close(true);
            },
            error: (error) => {
                alert(error.message);
                this.clearForm();
            },
            complete: () => {
                this.clearForm();
            }
        });
    }

    private clearForm() {
        this.user.setValue(null);
        this.groupAdmin.setValue(false);
        this.caseManager.setValue(false);
        this.submitInProgress = false;
    }

    public disableInvitation() {
        return this.submitInProgress || !this.user.valid;
    }
}
