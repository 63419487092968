import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ToolsModule } from '../tools/tools.module';
import { ReportsComponent } from './reports.component';
import { UsageReportComponent } from './usage-report/usage-report.component';
import { ReportsNavigationService } from './reports-navigation.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { DetailedReportComponent } from './detailed-report/detailed-report.component';
import { ReportsService } from './reports.service';
import { SecurityReportComponent } from './security-report/security-report.component';

@NgModule({
    declarations: [
        ReportsComponent,
        UsageReportComponent,
        DetailedReportComponent,
        SecurityReportComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        MaterialModule,
        TranslateModule,
        RouterModule,
        ToolsModule,
        ReactiveFormsModule,
    ],
    providers: [
        ReportsNavigationService,
        ReportsService
    ]
})
export class ReportsModule {
}
