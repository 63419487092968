import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { HelpComponent } from './help.component';
import { AboutComponent } from './about/about.component';
import { HelpDocumentComponent } from './help-document/help-document.component';
import { NoHelpDocumentComponent } from './help-document/no-help-document.component';
import { HelpDocumentService } from './help-document/help-document.service';
import { HelpVideoComponent } from './help-video/help-video.component';
import { HelpVideoService } from './help-video/help-video.service';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { ToolsModule } from '../tools/tools.module';

@NgModule({
    declarations: [
        HelpComponent,
        HelpDocumentComponent,
        NoHelpDocumentComponent,
        HelpVideoComponent,
        AboutComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        VgCoreModule,
        TranslateModule,
        RouterModule,
        ToolsModule,
        NgOptimizedImage
    ],
    providers: [
        HelpDocumentService,
        HelpVideoService
    ]
})
export class HelpModule {

}
