import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Case, CaseService } from '../../case.service';
import { Subject } from 'rxjs';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { saveAs } from 'file-saver';
import { MaterialModule } from '../../../material.module';

@Component({
    selector: 'app-case-admin-create-mrb',
    standalone: true,
    imports: [
        MaterialModule
    ],
    templateUrl: './create-mrb.component.html',
    styleUrls: ['./create-mrb.component.scss']
})
export class CreateMrbComponent implements OnInit, OnDestroy {

    @Input() caseUpdated: Subject<Case>;

    inProgress = false;
    private subscriptions = new Subscriptions();

    caseId: string;

    constructor(private caseService: CaseService) {
    }

    ngOnInit(): void {
        this.subscriptions.add(this.caseUpdated, data => {
            this.caseId = data.id;
        });
    }

    ngOnDestroy() {
        this.subscriptions.cancel();
    }

    onDownloadMrb() {
        this.inProgress = true;
        this.subscriptions.add(this.caseService.getReviewMrb(this.caseId),
            (blob: Blob) => {
                this.inProgress = false;
                saveAs(blob, `${this.caseId}.mrb`);
            },
            () => {
                this.inProgress = false;
            }
        );
    }
}
