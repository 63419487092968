import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AfterViewInit, Directive, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Directive()
export abstract class TableDirective<T> implements AfterViewInit {

    @ViewChild(MatPaginator, {static: true}) matPaginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) matSort: MatSort;

    protected constructor(
        public readonly displayedColumns: string[], public readonly dataSource: MatTableDataSource<T>) {
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.matPaginator;
        this.dataSource.sort = this.matSort;
    }

    protected navigateFromClick(router: Router, metaKey: boolean, commands: any[], extras = {}): void {
        const url = router.serializeUrl(router.createUrlTree(commands, extras));
        metaKey ? window.open(url, '_blank') : router.navigate(commands, extras);
    }
}
