<h2 mat-dialog-title class="mat-typography">{{template.title | translate}}</h2>
<form>
    <mat-dialog-content>
        <p class="mat-typography">{{template.contentText | translate}}</p>
        <p>
            <mat-checkbox *ngIf="hasPreliminaryConfirmationText()"
                          name="preliminaryConfirmation"
                          [(ngModel)]="enableFinalConfirmation">
                {{template.preliminaryConfirmationText | translate}}
            </mat-checkbox>
        </p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button
                *ngIf="showCancel()"
                data-testid="dlg-confirm-cancel"
                [mat-dialog-close]=false>
            {{template.cancelText | translate}}
        </button>
        <button mat-raised-button
                *ngIf="showAction()"
                data-testid="dlg-confirm-action"
                [disabled]="!enableFinalConfirmation"
                [mat-dialog-close]=true>
            {{template.actionText | translate}}
        </button>
    </mat-dialog-actions>
</form>
