import { Injectable } from '@angular/core';
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import PriorityQueue from '../priority-queue/priority-queue';

class Entry {
    public event = new Subject<HttpEvent<any>>();

    constructor(public request: HttpRequest<any>, public priority: number, public next: HttpHandler, public id: number) {
    }
}

@Injectable()
export class HttpPriorityInterceptor implements HttpInterceptor {
    private static HEADER = 'X-Request-Priority';

    private queue = new PriorityQueue<Entry>({comparator: (a, b) => b.priority - a.priority});
    private inProgressTable = new Map<number, Entry>();
    private inProgressLimit = 5;
    private entryId = 0;

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const clone = req.clone({
            // reportProgress: true,
            headers: req.headers.delete(HttpPriorityInterceptor.HEADER)
        });

        const priority = this.extractPriority(req);
        const entry = new Entry(clone, priority, next, ++this.entryId);
        if (priority >= 0) {
            this.queue.queue(entry);
            this.drain();
        }
        else {
            // Negative priorities are "background" requests; start them after a short delay to give other
            // higher priority requests that might be submitted later to jump to the head of the queue.
            // (Once a request is submitted, it cannot be retracted, and we limit the number of in-progress
            // submissions, so we don't want to immediately fill up the queue with low priority requests.)
            setTimeout(() => {
                this.queue.queue(entry);
                this.drain();
            }, -priority < 100 ? -priority : 100 /*ms*/);
        }

        return entry.event;
    }

    private extractPriority(req: HttpRequest<any>) {
        const priority = req.headers.get(HttpPriorityInterceptor.HEADER);
        return priority ? parseFloat(priority) : 0;
    }

    private drain() {
        while (this.queue.length > 0 && this.inProgressTable.size < this.inProgressLimit) {
            const entry = this.queue.dequeue();

            entry.next.handle(entry.request).subscribe({
                next: (evt) => {
                    entry.event.next(evt);

                    if (evt.type === HttpEventType.Response) {
                        this.inProgressTable.delete(entry.id);
                        this.drain();
                    }
                },
                error: (error) => {
                    entry.event.error(error);
                    this.inProgressTable.delete(entry.id);
                    this.drain();
                },
                complete: () => {
                    entry.event.complete();
                }
            });

            this.inProgressTable.set(entry.id, entry);
        }
    }
}
