import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HelpDocument } from './help-document/help-document.service';
import { MenuItem } from '../tools/menu-item/menu-item.interface';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})
export class HelpComponent {

    private videoMenuItems: MenuItem = {
        titleKey: 'helpTrainingVideos',
        children: [
            {
                titleKey: 'helpVideo-01',
                route: '/help/video',
                documentId: 'video-01'
            },
            {
                titleKey: 'helpVideo-02',
                route: '/help/video',
                documentId: 'video-02'
            },
            {
                titleKey: 'helpVideo-03',
                route: '/help/video',
                documentId: 'video-03'
            }
        ]
    };

    helpMenuItems: Array<MenuItem> = [
        {
            titleKey: 'helpProductLabel',
            route: '/help/about'
        },
        {
            titleKey: 'helpInstructionsForUse',
            route: '/help/doc',
            documentId: HelpDocument.IFU
        },
        {
            titleKey: 'helpTermsOfService',
            route: '/help/doc',
            documentId: HelpDocument.TOS
        },
        {
            titleKey: 'helpUserPrivacyNotice',
            route: '/help/doc',
            documentId: HelpDocument.UPN
        },
        {
            titleKey: 'helpPrivacyTemplates',
            hidden: environment.geography !== 'eu', // can be list.includes if we have more than 2 in the future
            children: [
                {
                    titleKey: 'helpEuPatientPrivacyNotice',
                    route: '/help/doc',
                    documentId: HelpDocument.PPN
                }
            ]
        }
    ];

    constructor(public router: Router) {
        if (environment.enableVideos) {
            this.helpMenuItems.push(this.videoMenuItems);
        }
    }
}
