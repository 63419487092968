<div class="forgot-password">
    <mat-card appearance="outlined" class="forgot-password-card">
        <mat-card-title class="forgot-password-card-title" i18n="@@forgotPasswordTitle">
            {{ 'forgotPasswordTitle'|translate }}
        </mat-card-title>
        <mat-card-content>
            <p i18n="@@forgotPasswordFirstLine">{{ 'forgotPasswordFirstLine'|translate }}.</p>
            <p i18n="@@forgotPasswordSecondLine">
                {{ 'forgotPasswordSecondLine'| translate }}.
            </p>
            <form [formGroup]="forgotPasswordForm" novalidate>
                <mat-form-field class="forgot-password-input">
                    <mat-label>{{ 'forgotPasswordEmailPlaceholder'|translate }}</mat-label>
                    <input matInput (input)="resetResult()" formControlName="email">
                    @if (email.hasError('required')) {
                        <mat-error i18n="@@forgotPasswordMissingEmailError">
                            {{ 'forgotPasswordMissingEmailError'|translate }}
                        </mat-error>
                    }
                    @else if (email.hasError('email')) {
                        <mat-error i18n="@@forgotPasswordInvalidEmailError">
                            {{ 'forgotPasswordInvalidEmailError'|translate }}
                        </mat-error>
                    }
                </mat-form-field>
                <br>
                <button mat-raised-button
                        i18n="@@forgotPasswordSubmit"
                        color="primary"
                        class="submit-button"
                        (click)="forgotPassword()"
                        [disabled]="!forgotPasswordForm.valid || result">
                    {{ 'submit'|translate }}
                </button>
            </form>
            <a routerLink="/login" i18n="@@forgotPasswordResetPasswordLoginLink">
                {{ 'forgotPasswordResetPasswordLoginLink'|translate }}
            </a>
        </mat-card-content>
    </mat-card>
    @if (result !== null) {
        @if (result) {
            <p class="mat-typography m-l-20" i18n="@@forgotPasswordResultSuccess">
                {{ 'forgotPasswordResultSuccess'|translate }}.
            </p>
        }
        @else {
            <p class="mat-typography m-l-20 c-warn" i18n="@@forgotPasswordResultError">
                {{ 'forgotPasswordResultError'|translate }}: {{ resultMessage }}
            </p>
        }
    }
</div>
