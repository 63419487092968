<mat-sidenav-container class="fill-parent">
    <mat-sidenav class="sis-sidenav" mode="side" opened="true" disableClose>
        <div class="side-nav-top-space"></div>
        <mat-nav-list>
            @for (item of reportsMenu; track item) {
                <app-menu-item class="mat-typography" [item]="item"></app-menu-item>
            }
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="sis-sidenav-content">
        <app-usage-reports [hidden]="!(selectedView === OVERVIEW)"></app-usage-reports>
        <app-detailed-reports [hidden]="!(selectedView === DETAILED)"></app-detailed-reports>
        <app-security-report [hidden]="!(selectedView === SECURITY)"></app-security-report>
    </mat-sidenav-content>
</mat-sidenav-container>
