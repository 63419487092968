import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from '../tools/menu-item/menu-item.interface';
import { filter } from 'rxjs/operators';
import { Subscriptions } from '../tools/subscriptions.class';
import { MaterialModule } from '../material.module';
import { TranslatePipe } from '@ngx-translate/core';
import { UsageReportComponent } from './usage-report/usage-report.component';
import { DetailedReportComponent } from './detailed-report/detailed-report.component';
import { SecurityReportComponent } from './security-report/security-report.component';
import { MenuItemComponent } from '../tools/menu-item/menu-item.component';

@Component({
    selector: 'app-reports',
    standalone: true,
    imports: [
        MaterialModule,
        TranslatePipe,
        MenuItemComponent,
        UsageReportComponent,
        DetailedReportComponent,
        SecurityReportComponent,
    ],
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {

    public static snapshotRoute = '/reports/overview';
    public static detailsRoute = '/reports/detailed';
    public static securityRoute = '/reports/security';

    readonly OVERVIEW = 'overview';
    readonly DETAILED = 'detailed';
    readonly SECURITY = 'security';

    reportsMenu: Array<MenuItem> = [
        {titleKey: 'reportsMenuSnapshot', route: ReportsComponent.snapshotRoute},
        {titleKey: 'reportsMenuDetails', route: ReportsComponent.detailsRoute},
        {titleKey: 'reportsMenuSecurity', route: ReportsComponent.securityRoute}
    ];

    private subscriptions = new Subscriptions();
    selectedView = this.OVERVIEW;

    constructor(public router: Router) {
    }

    ngOnInit(): void {
        this.subscriptions.limit(this.router.events).pipe(
            filter(event => event instanceof NavigationEnd)).subscribe(
            (event: NavigationEnd) => this.onRouterEvent(event.url)
        );
        this.onRouterEvent(this.router.routerState.snapshot.url);
    }

    private onRouterEvent(url: string): void {
        for (const view of [this.OVERVIEW, this.DETAILED, this.SECURITY]) {
            if (url.endsWith(view)) {
                this.selectedView = view;
                return;
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
    }
}
