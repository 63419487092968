import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupService } from '../../../services/group.service';
import { TableDirective } from '../../../tools/table.directive';
import { Archived, Group, GroupMember, GroupPermission } from '../../../services/api.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddMemberComponent } from '../add-member/add-member.component';
import { PermissionsService } from '../../../services/permissions.service';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { Permission, Permissions } from '../../../security/permissions.class';
import { ConfirmCancelDialogComponent } from '../../../tools/confirm-dialog.component';
import { AuthService } from '../../../services/auth.service';
import { map, take } from 'rxjs/operators';
import {
    TransferCasesComponent,
    TransferQueries,
    TransferUser
} from '../../users/transfer-cases/transfer-cases.component';
import { Case, CaseService } from '../../../case/case.service';
import { Observable } from 'rxjs';
import { userLastAndFirstName } from '../../../services/user.service';
import { MaterialModule } from '../../../material.module';
import { FormsModule } from '@angular/forms';
import { LocalDatePipe } from '../../../tools/local-date.pipe';
import { TranslatePipe } from '@ngx-translate/core';

import { SecureLinkComponent } from '../../../secure-link/secure-link.component';

export class GroupTransferQueries implements TransferQueries {
    constructor(private groupId: string, private oldUserId: string, private caseService: CaseService, private groupService: GroupService) {
    }

    getCases(_oldUserId: string, archived: Archived = Archived.exclude): Observable<Case[]> {
        return this.caseService.getCasesForUser(
            this.oldUserId, {groupId: this.groupId, archived: Archived[archived]}
        );
    }

    getUsers(): Observable<TransferUser[]> {
        return this.groupService.getGroup(this.groupId).pipe(
            map((group: Group) => group.members.map((m: GroupMember) => {
                return {
                    id: m.user_id,
                    email: m.email,
                    first_name: m.first_name,
                    last_name: m.last_name,
                };
            }))
        );
    }
}

@Component({
    selector: 'app-group',
    standalone: true,
    imports: [
        FormsModule,
        LocalDatePipe,
        MaterialModule,
        SecureLinkComponent,
        TranslatePipe,
    ],
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss']
})

export class GroupComponent extends TableDirective<GroupMember> implements OnInit, OnDestroy {

    GROUP_ADMIN: GroupPermission = GroupPermission.GroupAdmin;
    CASE_MANAGER: GroupPermission = GroupPermission.CaseManager;

    groupId: string = null;
    groupName = '';
    created = '';
    updated = '';

    manageGroupAllowed = false;
    viewCasesAllowed = true; // if you can see the group, you can see (that is, link to) the group's cases
    deleteGroupAllowed = false;

    private lifetimeSubscriptions = new Subscriptions();
    private subscriptions = new Subscriptions();
    private cantSaveChanged = false;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private groupService: GroupService,
                private caseService: CaseService, private permissionService: PermissionsService,
                private authService: AuthService, private dialog: MatDialog) {
        super(['name', 'email', 'status', 'admin', 'edit', 'transfer', 'delete'], new MatTableDataSource<GroupMember>());
    }

    ngOnInit() {
        // The group component needs the groupId to load group permissions. We will subscribe for the
        // lifetime of the component to the activateRoute params change to get the groupId when it changes.
        // Once the groupId changes, we load group permissions and group data.
        this.lifetimeSubscriptions.add(this.activatedRoute.params, () => {
            this.setupNewGroup();
        });
    }

    private setupNewGroup(): void {
        this.subscriptions.cancel();
        this.groupId = this.activatedRoute.snapshot.params.groupId;
        this.permissionService.batch(this.subscriptions, [
            [Permissions.groupAdmin(this.groupId), allowed => {
                this.manageGroupAllowed = allowed;
            }],
            [Permissions.groupDelete(this.groupId), allowed => {
                this.deleteGroupAllowed = allowed;
            }],
        ]);
        this.loadGroupData();
    }

    ngOnDestroy() {
        this.subscriptions.cancel();
        this.lifetimeSubscriptions.cancel();
    }

    onInputChange() {
        this.cantSaveChanged = false;
    }

    disableSaveGroup() {
        return this.groupName.length === 0 || this.cantSaveChanged;
    }

    updateGroupInfo() {
        const payload = {name: this.groupName};
        this.subscriptions.add(this.groupService.updateGroup(this.groupId, payload), response => {
            this.readGroupData(response);
        });
    }

    public viewCases(metaKey: boolean) {
        // the groupId key need to match the GROUP_CONTEXT_KEY in context-item.class.ts
        this.navigateFromClick(this.router, metaKey, ['/home'], {queryParams: {groupId: this.groupId}});
        // this.router.navigate(['/home'], {queryParams: {groupId: this.groupId}}).then();
    }

    deleteGroup() {
        this.confirm('settingsGroupsDeleteGroupTitle',
            'settingsGroupsDeleteGroupContent',
            'delete', () => {
                this.subscriptions.add(this.groupService.deleteGroup(this.groupId), () => {
                    // User is no longer an admin of the group, since the group does not exist. This could
                    // affect the visibility/accessibility of other parts of the UI.
                    this.permissionService.invalidate();
                    this.lostAccess();
                });
            });
    }

    openAddMemberDialog() {
        const addMemberDialogOptions = {width: '600px', data: {groupId: this.groupId}};
        const dialogRef = this.dialog.open(AddMemberComponent, addMemberDialogOptions);
        this.subscriptions.add(dialogRef.afterClosed(), data => {
            if (data != null) {
                this.loadGroupData();
            }
        });
    }

    transferCases(memberId: string) {
        const transferCasesDialogOptions = {
            height: '640px',
            width: '960px',
            data: {
                queries: new GroupTransferQueries(this.groupId, memberId, this.caseService, this.groupService),
                oldUserId: memberId
            },
        };
        /*let dialogRef =*/
        this.dialog.open(TransferCasesComponent, transferCasesDialogOptions);
    }

    removeMember(memberId: string) {
        const userId = this.authService.currentUserId;
        if (memberId === userId) {
            this.confirm('Confirm Removal',
                'Are you sure you would like to remove yourself from this group? You will no longer be able to edit it.',
                'Remove', () => {
                    this.doRemoveMember(memberId,
                        () => this.maybeLostAccess()
                    );
                });
        }
        else {
            this.doRemoveMember(memberId, () => this.loadGroupData());
        }
    }

    private doRemoveMember(memberId: string, callbackFn: () => void) {
        this.subscriptions.add(this.groupService.removeGroupMember(this.groupId, memberId),
            () => {
                callbackFn();
            },
            error => {
                alert(error.message);
            }
        );
    }

    private loadGroupData() {
        this.subscriptions.add(this.groupService.getGroup(this.groupId),
            groupData => {
                this.readGroupData(groupData);
                this.dataSource.data = groupData.members;
                this.permissionService.prefetch(groupData.members.map(m => Permissions.userView(m.user_id)));
            },
            () => {
                this.router.navigate(['settings/not-found']).then();
            }
        );
    }

    private readGroupData(response: Group) {
        this.groupName = response.name;
        this.created = response.created;
        this.updated = response.updated;
    }

    displayName(member: GroupMember): string {
        return userLastAndFirstName(member);
    }

    displayStatus(member: GroupMember): string {
        return member.disabled ? 'DISABLED' : member.status;
    }

    hasAdminPermission(permissions: string[]) {
        return permissions.includes(GroupPermission.GroupAdmin);
    }

    hasEditPermission(permissions: string[]) {
        return permissions.includes(GroupPermission.CaseManager);
    }

    onChangePermission(permission: GroupPermission, event: MatCheckboxChange, member: GroupMember) {
        if (!event.checked && permission === GroupPermission.GroupAdmin && member.user_id === this.authService.currentUserId) {
            this.confirm('Confirm Removal',
                'Are you sure you would like to remove yourself as a group administrator? You will no longer be able to edit this group.',
                'Remove', () => {
                    this.doChangePermission(permission, event, member, () => {
                        this.maybeLostAccess();
                    });
                },
                () => {
                    // Refresh the UI, which is now out of sync with the underlying data
                    this.loadGroupData();
                });
        }
        else {
            this.doChangePermission(permission, event, member);
        }
    }

    viewUser(groupMember: GroupMember): Permission {
        return Permissions.userView(groupMember.user_id);
    }

    public onUserClick(groupMember: GroupMember, metaKey: boolean) {
        this.navigateFromClick(this.router, metaKey, ['/settings/user', groupMember.user_id]);
    }

    private doChangePermission(permission: GroupPermission, event: MatCheckboxChange, member: GroupMember, callbackFn?: () => void) {
        if (event.checked) {
            member.permissions.push(permission);
        }
        else {
            const index = member.permissions.indexOf(permission);
            if (index > -1) {
                member.permissions.splice(index, 1);
            }
        }
        this.subscriptions.add(this.groupService.updateGroupMembership(this.groupId, member.user_id, member.permissions),
            response => {
                member.permissions = response.permissions;
                if (callbackFn !== undefined) {
                    callbackFn();
                }
            }
        );
    }

    private confirm(title: string, contentText: string, actionText: string, callbackFn: () => void, cancelFn?: () => void): void {
        const dialogData = {
            title,
            contentText,
            cancelText: 'Cancel',
            actionText,
        };
        const dialogRef = this.dialog.open(ConfirmCancelDialogComponent, {
            width: '400px', data: dialogData
        });

        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                callbackFn();
            }
            else if (cancelFn !== undefined) {
                cancelFn();
            }
        });

    }

    private maybeLostAccess(): void {
        this.permissionService.invalidate();

        this.subscriptions.add(this.permissionService.hasPermission(Permissions.groupAdmin(this.groupId)).pipe(take(1)),
            allowed => {
                if (!allowed) {
                    this.lostAccess();
                }
            });
    }

    private lostAccess(): void {
        // Give other subscribers a chance to observe the change in permissions before attempting the redirect.
        //
        // WARNING: if the permission queries do not provide results before the timeout expires, the user may end up on
        // /settings/groups without being able to do anything there. The problem (as of 2018-01-30) is that
        // SettingsNavigationService uses ReplaySubject's to provide access to the most recent result, but this means
        // that the most recent results are still available temporarily when new queries are triggered (e.g., when
        // PermissionsService signals that permissions have been invalidated). Since the navigation guard samples the
        // observable at a particular point in time, it's possible to see stale data. Currently, we just introduce a
        // significant delay. A correct solution might require something like a modified ReplaySubject whose retained
        // values could be flushed.

        // TODO: Address the above warning

        // noinspection JSIgnoredPromiseFromCall
        setTimeout(() => this.router.navigate(['/settings/groups']), 300);
    }
}
