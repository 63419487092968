import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DashboardNavigationService } from './dashboard-navigation.service';

import { MaterialModule } from '../material.module';
import { TranslatePipe } from '@ngx-translate/core';
import { FlowsComponent } from './flows/flows.component';

export interface DashboardMenuItem {
    titleKey: string;
    route: string;
}

@Component({
    selector: 'app-settings',
    standalone: true,
    imports: [
        MaterialModule,
        TranslatePipe,
        RouterModule,
        FlowsComponent
    ],
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    private static flowsRoute = '/dashboard/flows';

    permits = {};

    dashboardMenu: Array<DashboardMenuItem> = [
        {
            titleKey: 'dashboardMenuRunningFlows',
            route: DashboardComponent.flowsRoute,
        },
    ];

    constructor(public router: Router, private dashboardNavigationService: DashboardNavigationService) {
    }

    public ngOnInit() {
        this.dashboardNavigationService.canActivateListFlows().subscribe(allowed => {
            this.permits[DashboardComponent.flowsRoute] = allowed;
        });
    }

    permittedMenu(): Array<DashboardMenuItem> {
        const result = [];
        for (const item of this.dashboardMenu) {
            if (this.permits[item.route]) {
                result.push(item);
            }
        }
        return result;
    }
}
