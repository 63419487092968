import { Component } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-no-settings',
    standalone: true,
    imports: [
        TranslatePipe
    ],
    template: '<span class="mat-typography" i18n="@@settingsNotAvailable">{{"settingsNotAvailable"|translate}}</span>',
})
export class NoSettingsComponent {

}
