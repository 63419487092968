import { Injectable } from '@angular/core';
import {
    Account,
    AccountState,
    ApiService,
    DeleteAccountResponse,
    NewInvitationResponse
} from './api.service';
import { Observable } from 'rxjs';

export { Account, AccountState, DeleteAccountResponse, compareAccountsByName };

@Injectable()
export class AccountService {

    constructor(private api: ApiService) {
    }

    public createAccount(name: string, state: AccountState = AccountState.ACTIVE, emails: string[] = []): Observable<Account> {
        return this.api.createAccount(name, state, emails);
    }

    public getAccount(accountId: string): Observable<Account> {
        return this.api.getAccount(accountId);
    }

    public updateAccount(accountId: string, updates: any): Observable<Account> {
        return this.api.updateAccount(accountId, updates);
    }

    public deleteAccount(accountId: string): Observable<DeleteAccountResponse> {
        return this.api.deleteAccount(accountId);
    }

    public getAccounts(asUser: string | null = null, forCases: boolean = false): Observable<Account[]> {
        return this.api.getAccounts(asUser, forCases);
    }

    public createUserInvitation(email: string, options: { accountId: string }): Observable<NewInvitationResponse> {
        return this.api.createUserInvitation(email, options);
    }
}

function compareAccountsByName(a: Account, b: Account): number {
    return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
}
