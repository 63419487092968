<h2 mat-dialog-title class="mat-typography">{{ template.title | translate }}</h2>
<form>
    <mat-dialog-content>
        <p class="mat-typography">{{ template.contentText | translate }}</p>
        <p>
            @if (hasPreliminaryConfirmationText()) {
                <mat-checkbox
                        name="preliminaryConfirmation"
                        [(ngModel)]="enableFinalConfirmation">
                    {{ template.preliminaryConfirmationText | translate }}
                </mat-checkbox>
            }
        </p>
    </mat-dialog-content>
    <mat-dialog-actions>
        @if (showCancel()) {
            <button mat-raised-button

                    data-testid="dlg-confirm-cancel"
                    [mat-dialog-close]=false>
                {{ template.cancelText | translate }}
            </button>
        }
        @if (showAction()) {
            <button mat-raised-button

                    data-testid="dlg-confirm-action"
                    [disabled]="!enableFinalConfirmation"
                    [mat-dialog-close]=true>
                {{ template.actionText | translate }}
            </button>
        }
    </mat-dialog-actions>
</form>
