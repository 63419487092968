import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '../services/permissions.service';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { NavigationService, NavigationServiceEntry } from '../tools/navigation.service';
import { Permissions } from '../security/permissions.class';

@Injectable()
export class DashboardNavigationService extends NavigationService {

    constructor(permissionsService: PermissionsService, authService: AuthService, router: Router) {
        super(permissionsService, authService, router);
    }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        if (state.url.endsWith('/flows')) {
            return this.maybeRedirectChild(this.canActivateListFlows());
        }
        else if (state.url.endsWith('/dashboard')) {
            const table = [
                new NavigationServiceEntry(this.canActivateListFlows(), 'flows'),
            ];
            return this.maybeRedirect(state, table);
        }
        else {
            // This should only be used as a guard on paths that it knows how to handle.
            return false;
        }
    }

    public canActivateDashboard(): Observable<boolean> {
        return this.stableTransitions(this._requireCanActivateDashboard());
    }

    public canActivateListFlows(): Observable<boolean> {
        return this.stableTransitions(this._requireCanListFlows());
    }

    private _requireCanActivateDashboard(): Observable<boolean> {
        return this._requireCanListFlows();
    }

    private _requireCanListFlows(): Observable<boolean> {
        return this.cachedObservable('canListFlows', () => this.stablePermission(Permissions.listFlows()));
    }
}
