import { Injectable } from '@angular/core';
import {
    ApiService,
    BooleanResponse,
    DeleteUserResponse,
    MessageResponse,
    RestrictionsResponse,
    TokenResponse,
    UserResponse
} from './api.service';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';

export {
    BooleanResponse, MessageResponse, TokenResponse, UserResponse as User, DeleteUserResponse,
    DescribableUser, compareUsersByDescription, userDescription, userSearchableText, userLastAndFirstName
};

@Injectable({providedIn: 'root'})
export class UserService {

    private userResponseCache = new Map<string, ReplaySubject<UserResponse>>();
    private cacheInvalidationDelay = 200;  // ms

    constructor(private api: ApiService) {
    }

    public getUsers(forUser: string | null = null): Observable<Array<UserResponse>> {
        return this.api.getUsers(forUser);
    }

    public createNewUser(newUser: any): Observable<any> {
        return this.api.createNewUser(newUser);
    }

    public getUser(userId: string): Observable<UserResponse> {
        if (!userId) {
            // We really should not ever ask for a null user, but if it happens, respond with an empty observable.
            return EMPTY;
        }
        // Especially when the user first logs in, there are a number of requests for the current user from various components.
        // Instead of sending a bunch of requests, we'll send just the first and cache the rest.
        let result = this.userResponseCache.get(userId);

        if (!result) {
            // When we make a request where we will be caching the observable, we need to make sure that new subscribers will see the
            // response even if the response has already arrived back from the server. So we use a ReplaySubject and subscribe it
            // directly to the API response observable.
            result = new ReplaySubject<UserResponse>(1);
            this.userResponseCache.set(userId, result);
            this.api.getUser(userId).subscribe(result);
            setTimeout(() => this.userResponseCache.delete(userId), this.cacheInvalidationDelay);
        }

        return result.asObservable();
    }

    public updateUser(userId: string, updates: any): Observable<any> {
        this.userResponseCache.delete(userId);
        return this.api.updateUser(userId, updates);
    }

    public deleteUser(userId: string): Observable<DeleteUserResponse> {
        this.userResponseCache.delete(userId);
        return this.api.deleteUser(userId);
    }

    public changePasswordWithToken(userId: string, newPassword: string, oldPassword?: string): Observable<BooleanResponse> {
        return this.api.changePassword(userId, newPassword, oldPassword);
    }

    public changePasswordNotAuthenticated(exToken: string, userId: string, newPassword: string, oldPassword?: string): Observable<BooleanResponse> {
        return this.api.changePasswordNotAuthenticated(exToken, userId, newPassword, oldPassword);
    }

    public resetPassword(email: string): Observable<BooleanResponse> {
        return this.api.resetPassword(email);
    }

    public checkRestrictions(userId: string): Observable<RestrictionsResponse> {
        return this.api.checkRestrictions(userId);
    }

    public clearRestrictions(userId: string): Observable<RestrictionsResponse> {
        return this.api.clearRestrictions(userId);
    }

    public flushCache() {
        this.userResponseCache.clear();
    }
}

interface DescribableUser {
    email: string;
    first_name?: string;
    last_name?: string;
}

function userDescription(user: DescribableUser): string {
    const lastAndFirstName = userLastAndFirstName(user);
    return lastAndFirstName ? `${lastAndFirstName} (${user.email})` : user.email;
}

function userSearchableText(user: DescribableUser): string {
    // Return non-null, non-empty strings separated by spaces
    return [user.last_name, user.first_name, user.email].filter(x => !!x).join(' ');
}

function userLastAndFirstName(user: DescribableUser): string {
    return [user.last_name, user.first_name].filter(x => !!x).join(', ');
}

function compareUsersByDescription(a: DescribableUser, b: DescribableUser): number {
    return userDescription(a).localeCompare(userDescription(b), undefined, {sensitivity: 'base'});
}
