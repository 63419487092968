import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsNavigationService } from './settings-navigation.service';

export interface SettingsMenuItem {
    titleKey: string;
    route: string;
}

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    private static usersRoute = '/settings/users';
    private static groupsRoute = '/settings/groups';
    private static accountsRoute = '/settings/accounts';

    permits = {};

    settingsMenu: Array<SettingsMenuItem> = [
        {
            titleKey: 'settingsMenuUsers',
            route: SettingsComponent.usersRoute,
        },
        {
            titleKey: 'settingsMenuGroups',
            route: SettingsComponent.groupsRoute,
        },
        {
            titleKey: 'settingsMenuAccounts',
            route: SettingsComponent.accountsRoute,
        },
    ];

    constructor(public router: Router, private settingsNavigationService: SettingsNavigationService) {
    }

    public ngOnInit() {
        this.settingsNavigationService.canActivateSettingsUsers().subscribe(allowed => {
            this.permits[SettingsComponent.usersRoute] = allowed;
        });
        this.settingsNavigationService.canActivateSettingsGroups().subscribe(allowed => {
            this.permits[SettingsComponent.groupsRoute] = allowed;
        });
        this.settingsNavigationService.canActivateSettingsAccounts().subscribe(allowed => {
            this.permits[SettingsComponent.accountsRoute] = allowed;
        });
    }

    public permittedMenu(): Array<SettingsMenuItem> {
        return this.settingsMenu.filter((item: SettingsMenuItem) => this.permits[item.route]);
    }
}
