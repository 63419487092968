import { EventEmitter, Injectable } from '@angular/core';
import { AuthenticationEvent, AuthService } from './auth.service';
import { Subscriptions } from '../tools/subscriptions.class';
import { ApiService, TosResponse } from './api.service';
import { UserService } from './user.service';

@Injectable({providedIn: 'root'})
export class TosService {

    private subscriptions = new Subscriptions();

    private currentUserId: string = null;

    private content: string;
    private requiredVersion: number;

    public readonly showTos: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private auth: AuthService, private api: ApiService, private userService: UserService) {
    }

    public getContent(): string {
        return this.content;
    }

    public start(): void {
        this.subscriptions.add(this.auth.afterLogin, loginResult => this.onLogin(loginResult));
        this.subscriptions.add(this.auth.beforeLogout, logoutResult => this.onLogout(logoutResult));
    }

    public stop(): void {
        this.subscriptions.cancel();
    }

    private onLogin(result: AuthenticationEvent): void {
        this.currentUserId = result.userId;
        this.api.getTos().subscribe((response: TosResponse) => this.onTosResponse(response));
    }

    private onLogout(logoutResult: AuthenticationEvent) {
        if (!logoutResult.authenticated && this.currentUserId === logoutResult.userId) {
            this.currentUserId = null;
        }
    }

    private onTosResponse(response: TosResponse) {
        this.content = response.tos_content;
        this.requiredVersion = response.tos_version;

        // No TOS on the server side, disable TOS and allow usage
        if (!this.requiredVersion || !this.content) {
            return;
        }

        // User did not accept or accepted an old version, show dialog with new version
        if (!this.auth.acceptedTos || this.auth.acceptedTos < this.requiredVersion) {
            this.showTos.emit(true);
            return;
        }
        // User accepted the latest required version
    }

    onTosAccepted(accepted: boolean) {
        if (accepted) {
            // update the server that the user accepted the TOS of this version
            this.userService.updateUser(this.currentUserId, {accepted_tos: this.requiredVersion});
        }
        else {
            this.auth.logout('Please accept Terms Of Service');
        }
    }
}
