import { Injectable } from '@angular/core';
import * as THREE from 'three';
import { Case, CaseService } from '../../case.service';
import { DataElement } from '../../data-element.class';
import { Observable, Subject } from 'rxjs';
import { ElectrodeModelService } from '../../electrode-model/electrode-model.service';
import { ElectrodeDescription } from '../../../services/api.service';
import * as cc from '../../case.constants';
import { DbsTarget } from '../../case.constants';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { map } from 'rxjs/operators';
import * as sc from './stl.constants';

export interface ElectrodeStlReport {
    side: string;
    index: number;
}

export class LoadStlReport {
    public electrodes: Array<ElectrodeStlReport> = new Array<ElectrodeStlReport>();

    constructor(public brainStructures: Array<string>) {
    }

    has(structure: string): boolean {
        return this.brainStructures.includes(structure);
    }
}

@Injectable()
export class StlViewService {

    private readonly fixedParts: Array<string> = [cc.TIP, cc.CONTACTS, cc.MARKER, cc.RING];
    private readonly electrodeParts: Array<string> = [...this.fixedParts, cc.ELECTRODE];

    private readonly selectedElectrodeMaterial = new THREE.MeshLambertMaterial({
        name: 'selectedElectrode',
        color: sc.SELECTED_ELECTRODE_COLOR,
        side: THREE.DoubleSide
    });

    private partsList: Array<sc.ThreeElement> = new Array<sc.ThreeElement>();
    private mGroupAll: THREE.Object3D = new THREE.Object3D();
    private mSubGroup: Map<string, THREE.Object3D> = new Map<string, THREE.Object3D>();
    private mHiddenElements: Map<string, THREE.Object3D> = new Map<string, THREE.Object3D>();
    private stlLoader: STLLoader = new STLLoader();
    private objLoader: OBJLoader = new OBJLoader();

    constructor(private caseService: CaseService, private electrodeService: ElectrodeModelService) {
    }

    public initParts(data: Case): void {
        this.partsList.push(...sc.BRAIN_ELEMENTS.filter(e => data.supported_structures.includes(e.soi)));
        this.partsList.push(...sc.ELECTRODE_ELEMENTS);
        // if the opacity changed, we need to reset it, since this is a constant object.
        this.updatePlaneOpacity(cc.INITIAL_IMG_OPACITY);
    }

    get groupAll(): THREE.Object3D {
        return this.mGroupAll;
    }

    public get subGroup(): Map<string, THREE.Object3D> {
        return this.mSubGroup;
    }

    public loadElectrode(model: string, side: string, sideIndex: number): Subject<number> {
        const urls = this.electrodeService.getResources(model);
        const elementsToLoad = urls.length;
        let elementsLoaded = 0;
        const rv = new Subject<number>();

        const asyncLoadFile = (part: sc.ThreeElement, url: string) => {
            this.electrodeService.downloadElement(url).subscribe(
                data => loadBlob(part, data, url)
            );
        };

        const loadBlob = (part: sc.ThreeElement, data: Blob, url: string) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                // Save the geometry in case we will need it later (to add and remove elements)
                const stlAsGeometry = this.stlLoader.parse(reader.result as ArrayBuffer);
                this.addMeshIfIncluded(part.idFromData(side, sideIndex), stlAsGeometry, this.partByTag(part.tag), (mesh: THREE.Mesh) => {
                    mesh.name = part.name(url, side, sideIndex);
                    mesh.renderOrder = 1;
                });
                elementsLoaded++;
                if (elementsLoaded === elementsToLoad) {
                    rv.next(elementsLoaded);
                    rv.complete();
                }
            };
            reader.readAsArrayBuffer(data);
        };

        for (const url of urls) {
            const part = this.getPartFromUrl(url);
            if (part) {
                asyncLoadFile(part, url);
            }
        }
        return rv;
    }

    public loadParts(caseId: string, urls: string[]): Subject<number> {
        let elementsToLoad = 0;
        let elementsLoaded = 0;
        const rv = new Subject<number>();
        const asyncLoadFile = (part: sc.ThreeElement, caseIdentifier: string, url: string) => {
            this.caseService.getCaseResource(caseIdentifier, url, -10).subscribe(
                data => loadBlob(part, data, url)
            );
        };

        const loadBlob = (part: sc.ThreeElement, data: Blob, url: string) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                // Save the geometry in case we will need it later (to add and remove elements)
                const stlAsGeometry = this.stlLoader.parse(reader.result as ArrayBuffer);
                this.addMeshIfIncluded(part.idFromUrl(url), stlAsGeometry, this.partByTag(part.tag), (mesh: THREE.Mesh) => {
                    mesh.name = part.tag;
                    mesh.renderOrder = 1;
                });

                elementsLoaded++;
                if (elementsLoaded === elementsToLoad) {
                    rv.next(elementsLoaded);
                    rv.complete();
                }
            };
            reader.readAsArrayBuffer(data);
        };

        for (const url of urls) {
            const part = this.getPartFromUrl(url);
            if (part) {
                elementsToLoad += 1;
                asyncLoadFile(part, caseId, url);
            }
        }
        return rv;
    }

    public createAxesHead(position: THREE.Vector3, caseId: string): Observable<THREE.Object3D> {
        const headReady = new Subject<THREE.Object3D>();
        // a model of the head centered around (0, 0, 0)
        const head = new THREE.Object3D();
        head.name = 'axes-head';

        this.caseService.getHeadModel(caseId).subscribe((stlData: Blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                // eslint-disable-next-line no-bitwise
                const bufferView = new Uint8Array(reader.result as ArrayBuffer).map(byte => byte ^ 0xDA);
                const decoder = new TextDecoder();
                const objMesh = this.objLoader.parse(decoder.decode(bufferView)).children[0] as THREE.Mesh;
                const material = new THREE.MeshLambertMaterial({
                        color: sc.HEAD_MODEL_COLOR, transparent: false, side: THREE.DoubleSide
                    }
                );
                const headMesh = new THREE.Mesh(objMesh.geometry, material);
                headMesh.name = 'axes-head';
                head.add(headMesh);
                if (position.length() > 0) {
                    head.translateOnAxis(position.clone().normalize(), position.length());
                }
                headReady.next(head);
                headReady.complete();
            };
            reader.readAsArrayBuffer(stlData);
        });
        return headReady;
    }

    public createAxesBox(axisLength: number): Observable<THREE.Object3D> {
        const axesReady = new Subject<THREE.Object3D>();
        const axesTextures = [
            {name: 'r.png', rotation: Math.PI / 2, index: 0},
            {name: 'l.png', rotation: -Math.PI / 2, index: 1},
            {name: 'a.png', rotation: Math.PI, index: 2},
            {name: 'p.png', rotation: 0, index: 3},
            {name: 's.png', rotation: 0, index: 4},
            {name: 'i.png', rotation: 0, index: 5}
        ];
        const box = new THREE.Object3D();
        box.name = 'axes-cube';
        const axesMaterials: Array<THREE.MeshBasicMaterial> = new Array<THREE.MeshBasicMaterial>(axesTextures.length);
        const loadingManager: THREE.LoadingManager = new THREE.LoadingManager(
            () => {
                const boxGeometry = new THREE.BoxGeometry(axisLength, axisLength, axisLength);
                const boxMesh = new THREE.Mesh(boxGeometry, axesMaterials);
                boxMesh.name = 'axes-cube-mesh';
                box.add(boxMesh);
                axesReady.next(box);
                axesReady.complete();
            }
        );
        const textureLoader = new THREE.TextureLoader(loadingManager);
        textureLoader.setPath('/assets/img/');
        for (const textureData of axesTextures) {
            textureLoader.load(textureData.name, (texture: THREE.Texture) => {
                texture.rotation = textureData.rotation;
                texture.center = new THREE.Vector2(0.5, 0.5);
                axesMaterials[textureData.index] = new THREE.MeshBasicMaterial({map: texture, transparent: false});
            });
        }
        return axesReady;
    }

    public getStlUrls(caseData: any, dataElement: DataElement, clearElectrodes: boolean = false): Observable<Array<string>> {
        let result = this.caseService.getStlUrls(caseData, dataElement.name);
        if (clearElectrodes) {
            result = result.pipe(
                map(urls => urls.filter(url => {
                    const part = this.getPartFromUrl(url);
                    return !part || !this.electrodeParts.includes(part.tag);
                }))
            );
        }
        return result;
    }

    public hasContactsMesh(tag: string): boolean {
        return this.mSubGroup.has(tag);
    }

    public getContactMeshes(side: string, sideIndex: number): Array<THREE.Mesh> {
        const tag = `${cc.CONTACTS}_${side}_${sideIndex}`;
        if (this.hasContactsMesh(tag)) {
            return this.mSubGroup.get(tag).children as Array<THREE.Mesh>;
        }
        return [];
    }

    /***
     * Return the THREE.Mesh that is the first child in the Object#d object
     * @param tag - the tag for the electrode element
     * @param side - side (Left|Right)
     * @param sideIndex (index of the electrode on that side)
     */
    public getElectrodeMesh(tag: string, side: string, sideIndex: number): THREE.Mesh {
        const fullTag = `${tag}_${side}_${sideIndex}`;
        if (this.subGroup.has(fullTag)) {
            return this.mSubGroup.get(fullTag).children[0] as THREE.Mesh;
        }
        // if we could not find the element in the subGroup it may exist but currently not displayed
        if (this.mHiddenElements.has(fullTag)) {
            return this.mHiddenElements.get(fullTag).children[0] as THREE.Mesh;
        }
        return null;
    }

    public updatePlaneOpacity(value: number): void {
        const planeElement: sc.ThreeElement = this.partByTag(cc.T1_PLANE);
        const planeMaterial: THREE.ShaderMaterial = planeElement.material as THREE.ShaderMaterial;
        planeMaterial.uniforms['opacity'].value = value;
    }

    public addPlane(position: THREE.Vector3, texture: THREE.DataArrayTexture, sliceIndex: number, w: number, h: number): void {
        const element = this.mSubGroup.get(cc.T1_PLANE);
        if (element) {
            this.removePart(cc.T1_PLANE, element);
        }
        this.addSliceTexture(position, texture, sliceIndex, w, h);
    }

    public updateElementDisplay(tag: string, visible: boolean) {
        if (visible) {
            const element = this.mHiddenElements.get(tag);
            this.mHiddenElements.delete(tag);
            this.mSubGroup.set(tag, element);
            this.mGroupAll.add(element);
        }
        else {
            const element = this.mSubGroup.get(tag);
            this.mHiddenElements.set(tag, element);
            this.mSubGroup.delete(tag);
            this.mGroupAll.remove(element);
        }
    }

    // Public for testing
    public getPartFromUrl(url: string): sc.ThreeElement {
        return this.partsList.find(part => part.re && part.re.test(url));
    }

    public partByTag(tag: string): sc.ThreeElement {
        return this.partsList.find(part => part.tag === tag);
    }

    public getStlLoadReport(): LoadStlReport {
        const availableStructures = cc.BRAIN_STRUCTURES.filter(s => this.getObject(s) !== null);
        const stlReport = new LoadStlReport(availableStructures);
        // add all the electrodes on each side to the report, based on mesh elements
        for (const side of cc.SIDES) {
            let index = 1;
            while (this.getObject(`${cc.ELECTRODE}_${(side)}_${index}`) !== null) {
                stlReport.electrodes.push({side, index});
                index += 1;
            }
        }
        return stlReport;
    }

    public setElectrodeSelection(tag: string, side: string, sideIndex: number) {
        const mesh: THREE.Mesh = this.getElectrodeMesh(tag, side, sideIndex);
        if (mesh) {
            mesh.material = this.selectedElectrodeMaterial;
        }
    }

    public clearElectrodeSelection(tag: string, side: string, sideIndex: number) {
        const mesh: THREE.Mesh = this.getElectrodeMesh(tag, side, sideIndex);
        if (mesh) {
            mesh.material = this.partByTag(cc.ELECTRODE).material;
        }
    }

    public getAllMeshes(): Array<THREE.Mesh> {
        const meshes = new Array<THREE.Mesh>();
        [...this.mSubGroup.values(), ...this.mHiddenElements.values()].forEach(
            obj => obj.children.forEach(child => meshes.push(child as THREE.Mesh))
        );
        return meshes;
    }

    public getPlaneMesh(): THREE.Mesh | null {
        const obj = this.getObject(cc.T1_PLANE);
        if (obj && obj.children.length > 0) {
            return obj.children[0] as THREE.Mesh;
        }
        return null;
    }

    public getElectrodeMeshes(side: string, sideIndex: number): Array<THREE.Mesh> {
        const meshes: Array<THREE.Mesh> = new Array<THREE.Mesh>();
        meshes.push(...this.getContactMeshes(side, sideIndex));
        const markerMesh = this.getElectrodeMesh(cc.MARKER, side, sideIndex);
        if (markerMesh) {
            meshes.push(markerMesh);
        }
        meshes.push(this.getElectrodeMesh(cc.ELECTRODE, side, sideIndex));
        const tipMesh = this.getElectrodeMesh(cc.TIP, side, sideIndex);
        if (tipMesh) {
            meshes.push(tipMesh);
        }
        return meshes;
    }

    public getInitialTopRingCom(e: ElectrodeDescription, label: string): THREE.Vector3 {
        const eData = this.electrodeService.findModelData(e.model);
        const side = e.side.toLowerCase();
        const contacts = this.getContactMeshes(side, e.side_index);
        const topMeshName = `contact_${side}_${e.side_index}_${eData.getContact(label)}`;
        const contactMesh = contacts.find(mesh => mesh.name === topMeshName);
        contactMesh.geometry.computeBoundingBox();
        return new THREE.Vector3(0, 0, (contactMesh.geometry.boundingBox.max.z + contactMesh.geometry.boundingBox.min.z) / 2);
    }

    private addSliceTexture(position: THREE.Vector3, texture: THREE.DataArrayTexture, sliceIndex: number, w: number, h: number): void {
        const planeElement: sc.ThreeElement = this.partByTag(cc.T1_PLANE);
        const planeMaterial: THREE.ShaderMaterial = planeElement.material as THREE.ShaderMaterial;
        planeMaterial.uniforms['diffuse'].value = texture;
        planeMaterial.uniforms['size'].value = new THREE.Vector2(w, h);
        planeMaterial.uniforms['depth'].value = sliceIndex;
        const planeGeometry = new THREE.PlaneGeometry(w, h);
        this.addMeshIfIncluded(cc.T1_PLANE, planeGeometry, planeElement, (mesh: THREE.Mesh) => {
            mesh.name = cc.T1_PLANE;
            mesh.renderOrder = 2;
            mesh.position.x = position.x;
            mesh.position.y = position.y;
            mesh.position.z = position.z;
        });
    }

    public getObject(part: string): THREE.Object3D | null {
        return this.mSubGroup.has(part) ? this.mSubGroup.get(part) : this.mHiddenElements.has(part) ? this.mHiddenElements.get(part) : null;
    }

    get allObjects(): Array<THREE.Object3D> {
        return [...this.mSubGroup.values(), ...this.mHiddenElements.values()];
    }

    /**
     * All parts shall be created. Parts that are not in the target shall be added to hidden parts.
     * Parts that are included in the target shall be added to mSubGroup.
     * @param part name of the part that needs a mesh
     * @param geometry
     * @param stlElement
     * @param adjustments a function that will receive the newly created mesh and should assign the name for the mesh.
     * @private
     */
    private addMeshIfIncluded(part: string, geometry: THREE.BufferGeometry, stlElement: sc.ThreeElement, adjustments = null): void {
        if (!this.mSubGroup.has(part) && !this.mHiddenElements.has(part)) {
            // if first time then create a subgroup for this part
            const object = this.createNewPart(part);
            this.shouldBeDisplayed(part) ? this.showPart(part, object) : this.hidePart(part, object);
        }
        const mesh = new THREE.Mesh(geometry, stlElement.material);
        if (adjustments) {
            adjustments(mesh);
        }
        this.getObject(part).add(mesh);
    }

    private createNewPart(part: string): THREE.Object3D {
        const object = new THREE.Object3D();
        object.name = part;
        return object;
    }

    private shouldBeDisplayed(part: string): boolean {
        return (this.fixedParts.some(p => part.startsWith(p)) || part === cc.T1_PLANE);
    }

    private showPart(tag: string, element: THREE.Object3D) {
        this.mHiddenElements.delete(tag);
        this.mSubGroup.set(tag, element);
        this.mGroupAll.add(element);
    }

    private hidePart(tag: string, element: THREE.Object3D) {
        // save to hidden elements and remove from the active lists
        this.mHiddenElements.set(tag, element);
        this.removePart(tag, element);
    }

    private removePart(tag: string, element: THREE.Object3D) {
        this.mSubGroup.delete(tag);
        this.mGroupAll.remove(element);
    }

    public filterApproved(approvedTargets: Array<DbsTarget>, stlUrls: string[]): Array<string> {
        const approvedBrainStruct = approvedTargets.map(t => t.associatedStructures).flat();
        return stlUrls.filter(
            url => approvedBrainStruct.some(brainStruct => url.includes(`${brainStruct.toUpperCase()}_`))
        );
    }
}
