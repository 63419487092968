import BinaryHeapStrategy from './binary-heap-strategy';

export type Comparator<T> = (a: T, b: T) => number;

export interface Options<T> {
    comparator: Comparator<T>;
    initialValues?: T[];
}

export interface QueueStrategy<T> {
    queue(value: T): void;
    dequeue(): T;
    peek(): T;
    clear(): void;
}
export default class PriorityQueue<T> {
    private mLength = 0;
    public get length() { return this.mLength; }

    private strategy: QueueStrategy<T>;

    public constructor(options: Options<T>) {
        this.mLength = options.initialValues ? options.initialValues.length : 0;
        this.strategy = new BinaryHeapStrategy(options);
    }

    public queue(value: T) {
        this.mLength++;
        this.strategy.queue(value);
    }

    public dequeue() {
        if (!this.mLength) { throw new Error('Empty queue'); }
        this.mLength--;
        return this.strategy.dequeue();
    }

    // noinspection JSUnusedGlobalSymbols
    public peek() {
        if (!this.mLength) { throw new Error('Empty queue'); }
        return this.strategy.peek();
    }

    public clear() {
        this.mLength = 0;
        this.strategy.clear();
    }
}
