import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscriptions } from '../../tools/subscriptions.class';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HelpVideoService } from './help-video.service';
import { VideoResponse } from '../../services/api.service';
import { MaterialModule } from '../../material.module';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-help-video',
    standalone: true,
    imports: [
        MaterialModule,
        VgCoreModule,
        TranslatePipe
    ],
    templateUrl: './help-video.component.html',
    styleUrls: ['./help-video.component.scss'],
    providers: [
        HelpVideoService
    ]
})
export class HelpVideoComponent implements OnInit, OnDestroy {

    videoId: string;
    videoUrl: SafeResourceUrl | null = null;
    errorMessage = false;
    private subscriptions = new Subscriptions();

    constructor(private router: Router, private route: ActivatedRoute, private sanitizer: DomSanitizer, private service: HelpVideoService) {
    }

    ngOnInit(): void {
        this.subscriptions.add(this.route.queryParams, params => {
            this.videoId = params.id;
            this.errorMessage = false;
            this.videoUrl = null;
            this.subscriptions.add(this.service.getVideoUrl(this.videoId),
                (response: VideoResponse) => {
                    if (response.result) {
                        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.url);
                    }
                    else {
                        this.errorMessage = true;
                    }
                },
                error => {
                    if (error.message == 'Not Found') {
                        this.router.navigate(['help/not-found']).then();
                    }
                }
            );
        });
    }

    get videoReady(): boolean {
        return this.videoUrl != null;
    }

    ngOnDestroy() {
        this.subscriptions.cancel();
    }
}
