<mat-card appearance="outlined">
    <mat-card-title class="dicom-details-header-container">
        <span>{{ titleText }}</span>
        <span class="dicom-details-subtitle">{{ subTitle }}</span>
    </mat-card-title>
    <mat-card-content>
        <div class="scrollable-container">
            <mat-table [dataSource]="rows">
                <ng-container matColumnDef="instanceNumber" i18n="@@dicomDetailsInstanceNumber">
                    <mat-header-cell *matHeaderCellDef
                                     class="mat-column-75-center">{{ 'dicomDetailsInstanceNumber'|translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-75-center">
                        {{ row.slice.instanceNumber }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="x-position" i18n="@@dicomDetailsSliceXPosition">
                    <mat-header-cell *matHeaderCellDef
                                     class="mat-column-75-center">{{ 'dicomDetailsSliceXPosition'|translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-75-center">
                        {{ formatNumber(row.slice.pos.x) }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="y-position" i18n="@@dicomDetailsSliceYPosition">
                    <mat-header-cell *matHeaderCellDef
                                     class="mat-column-75-center">{{ 'dicomDetailsSliceYPosition'|translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-75-center">
                        {{ formatNumber(row.slice.pos.y) }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="z-position" i18n="@@dicomDetailsSliceZPosition">
                    <mat-header-cell *matHeaderCellDef
                                     class="mat-column-75-center">{{ 'dicomDetailsSliceZPosition'|translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-75-center">
                        {{ formatNumber(row.slice.pos.z) }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="distance" i18n="@@dicomDetailsSliceDistance">
                    <mat-header-cell *matHeaderCellDef
                                     class="mat-column-75-center">{{ 'dicomDetailsSliceDistance'|translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-75-center"
                              [ngClass]="distanceClass(row)">
                        {{ row.displayedDistance }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="filePath" i18n="@@dicomDetailsSliceFilePath">
                    <mat-header-cell *matHeaderCellDef
                                     class="mat-column-400">{{ 'dicomDetailsSliceFilePath'|translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <span class="truncate-text-left max-width-90-p">{{ getName(row.slice) }}</span>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="includedSlicesColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let slice; columns: includedSlicesColumns"></mat-row>
            </mat-table>
            <span *ngIf="ds.extractedSlices.length > 0" class="dicom-details-extracted">
                {{ 'dicomDetailsExcludedTable'|translate }}
            </span>
            <mat-table [dataSource]="ds.extractedSlices" *ngIf="ds.extractedSlices.length > 0">
                <ng-container matColumnDef="instanceNumber" i18n="@@dicomDetailsInstanceNumber">
                    <mat-header-cell *matHeaderCellDef
                                     class="mat-column-75-center">{{ 'dicomDetailsInstanceNumber'|translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let slice" class="mat-column-75-center">
                        {{ slice.instanceNumber }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="x-position" i18n="@@dicomDetailsSliceXPosition">
                    <mat-header-cell *matHeaderCellDef
                                     class="mat-column-75-center">{{ 'dicomDetailsSliceXPosition'|translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let slice" class="mat-column-75-center">
                        {{ formatNumber(slice.pos.x) }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="y-position" i18n="@@dicomDetailsSliceYPosition">
                    <mat-header-cell *matHeaderCellDef
                                     class="mat-column-75-center">{{ 'dicomDetailsSliceYPosition'|translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let slice" class="mat-column-75-center">
                        {{ formatNumber(slice.pos.y) }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="z-position" i18n="@@dicomDetailsSliceZPosition">
                    <mat-header-cell *matHeaderCellDef
                                     class="mat-column-75-center">{{ 'dicomDetailsSliceZPosition'|translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let slice" class="mat-column-75-center">
                        {{ formatNumber(slice.pos.z) }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="filePath" i18n="@@dicomDetailsSliceFilePath">
                    <mat-header-cell *matHeaderCellDef class="mat-column-200">
                        {{ 'dicomDetailsSliceFilePath'|translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let slice" class="mat-column-200">
                        <span class="truncate-text-left max-width-90-p">{{ getName(slice) }}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="excludeFor" i18n="@@dicomDetailsSliceExcludeFor">
                    <mat-header-cell *matHeaderCellDef class="mat-column-400">
                        {{ 'dicomDetailsSliceExcludeFor'|translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let slice" class="mat-column-400">
                        <span class="warning-text truncate-text">{{ slice.extractionReasonKey|translate }}</span>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="excludedSlicesColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let slice; columns: excludedSlicesColumns;"></mat-row>
            </mat-table>
        </div>
    </mat-card-content>
</mat-card>
