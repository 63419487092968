import { Component, OnDestroy, OnInit } from '@angular/core';
import { GroupService } from '../../services/group.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Group } from '../../services/api.service';
import { NewGroupComponent } from './new-group/new-group.component';
import { Router } from '@angular/router';
import { TableDirective } from '../../tools/table.directive';
import { PermissionsService } from '../../services/permissions.service';
import { Permissions } from '../../security/permissions.class';
import { Subscriptions } from '../../tools/subscriptions.class';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.scss']
})

export class GroupsComponent extends TableDirective<Group> implements OnInit, OnDestroy {

    createGroupsAllowed = false;

    private subscriptions = new Subscriptions();

    constructor(private router: Router, private groupService: GroupService,
                private permissionService: PermissionsService, private dialog: MatDialog) {
        super(['name', 'created', 'updated'], new MatTableDataSource<Group>());
    }

    ngOnInit() {
        this.readGroups();
        this.subscriptions.limit(
            this.permissionService.hasPermission(Permissions.groupCreate())
        ).subscribe(allowed => {
            this.createGroupsAllowed = allowed;
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
    }

    onActivate(group: Group, metaKey: boolean) {
        this.navigateFromClick(this.router, metaKey, ['settings', 'group', group.id]);
    }

    openCreateGroupDialog() {
        const newGroupDialogOptions = {width: '600px'};
        const dialogRef = this.dialog.open(NewGroupComponent, newGroupDialogOptions);

        dialogRef.afterClosed().subscribe(data => {
            if (data != null) {
                this.readGroups();
            }
        });
    }

    private readGroups() {
        this.subscriptions.add(this.groupService.getGroups(), groups => {
            this.dataSource.data = [];

            const visibleGroups = [];
            let count = 0;
            for (const group of groups) {
                const permission = Permissions.groupView(group.id);
                this.subscriptions.add(this.permissionService.hasPermission(permission).pipe(take(1)), allowed => {
                    if (allowed) {
                        visibleGroups.push(group);
                    }
                    count += 1;
                    if (count === groups.length) {
                        this.dataSource.data = visibleGroups;
                    }
                });
            }
        });
    }
}
