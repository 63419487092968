import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscriptions } from '../../tools/subscriptions.class';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HelpDocumentService } from './help-document.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-help-doc',
    standalone: true,
    imports: [
        TranslatePipe
    ],
    templateUrl: './help-document.component.html',
    styleUrls: ['./help-document.component.scss'],
    providers: [
        HelpDocumentService
    ]
})
export class HelpDocumentComponent implements OnInit, OnDestroy {

    public url: SafeResourceUrl;
    public downloadMsWordUrl?: SafeResourceUrl;
    public contentType = 'application/pdf';
    public downloadMsWordFilename?: string;

    private subscriptions = new Subscriptions();
    private documentId: string;

    constructor(private translate: TranslateService, private sanitizer: DomSanitizer, private route: ActivatedRoute,
                private documentService: HelpDocumentService) {
    }

    ngOnInit(): void {
        this.subscriptions.add(this.translate.onLangChange, () => {
            this.update();
        });

        this.subscriptions.add(this.route.queryParams, params => {
            this.documentId = params.id;
            this.contentType = this.documentService.getContentType(this.documentId);
            this.downloadMsWordFilename = this.documentService.getMsWordDownloadFilenameKey(this.documentId);
            this.update();
        });
    }

    ngOnDestroy() {
        this.subscriptions.cancel();
    }

    private update() {
        const assetUrl = `assets/${this.documentId}/${environment.geography}/${this.documentId}-${this.translate.currentLang}`;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`${assetUrl}.pdf`);
        this.downloadMsWordUrl = this.downloadMsWordFilename ?
            this.sanitizer.bypassSecurityTrustResourceUrl(`${assetUrl}.docx`) : null;
    }
}
