import { Component, OnDestroy, OnInit } from '@angular/core';
import { BooleanResponse, UserService } from '../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscriptions } from '../tools/subscriptions.class';

interface ForgotPasswordForm {
    email: FormControl<string>;
}

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit, OnDestroy {

    email = new FormControl<string>('', [Validators.required, Validators.email]);
    forgotPasswordForm = new FormGroup<ForgotPasswordForm>({
        email: this.email,
    });

    result: boolean = null;
    resultMessage: string;

    private subscriptions: Subscriptions = new Subscriptions();

    constructor(private userService: UserService, private route: ActivatedRoute, private translate: TranslateService) {
    }

    ngOnInit() {
        this.subscriptions.add(this.route.queryParams, params => {
            this.email.setValue(params.email || '');
        });
    }

    ngOnDestroy() {
        this.subscriptions.cancel();
    }

    forgotPassword() {
        this.resetResult();

        this.subscriptions.add(this.userService.resetPassword(this.email.value),
            (messageResponse: BooleanResponse) => {
                this.result = messageResponse.result;
                this.resultMessage = messageResponse.message;
                if (this.result) {
                    this.email.disable();
                }
            },
            (error) => {
                console.log('Failed to reset password:', error.message);
                this.result = false;
                this.translate.get('resetPasswordSystemError').subscribe(translatedText =>
                    this.resultMessage = translatedText
                );
            }
        );
    }

    resetResult() {
        this.result = null;
        this.resultMessage = null;
    }
}
