<div class="fill-parent about-container">
    <mat-card appearance="outlined" class="about-card">
        <mat-card-title class="about-card-title">
            <div class="about-title-text">
                <span i18n="@@helpAboutApplicationVersion">
                    {{ 'helpAboutApplicationVersion' + env.geography.toUpperCase()|translate: {'version': env.version} }}
                </span>
                <div class="about-udi">
                    <img ngSrc="../../../assets/img/udi.png" class="about-udi-icon" alt="" height="78" width="115">
                    <span class="about-udi-text-style">{{ UDI }}</span>
                </div>
            </div>
            <img ngSrc="../../../assets/img/logo.png" class="about-logo-icon" alt="" height="104" width="225" priority>
        </mat-card-title>
        <mat-card-content class="height-85-p">
            <div class="about-content" *ngIf="enableEu">
                <div class="about-content-left-side">
                    <div class="about-content-item">
                        <div class="image-and-text-row">
                            <img ngSrc="../../../assets/img/manufacturer.png" alt="" height="148" width="148"
                                 class="about-manufacturer-icon">
                            <div class="m-l-30">
                                <p class="about-address-header" i18n="@@helpAboutManufacturer">
                                    {{ 'helpAboutManufacturer'|translate }}
                                </p>
                                <p class="about-address-line">Surgical Information Sciences, Inc.</p>
                                <p class="about-address-line">5201 Eden Avenue, Suite 300</p>
                                <p class="about-address-line">Edina, MN 55436, USA</p>
                                <p class="about-address-line">
                                    {{ 'helpAboutReleaseDate'|translate }}:&nbsp;{{ releaseDate }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="about-content-item">
                        <div>
                            <div class="m-l-30">
                                <p class="about-assistance-header" i18n="@@helpAboutUserAssistanceInEU">
                                    <span [innerHTML]="'helpAboutUserAssistanceInEU'|translate"></span>:
                                </p>
                                <p class="about-assistance-line" i18n="@@helpAboutPhone">
                                    {{ 'phone'|translate }}:&nbsp;<a href="tel:+17633072401">+1 (763) 307-2401</a>
                                </p>
                                <p class="about-assistance-line" i18n="@@helpAboutEmail">
                                    {{ 'email'|translate }}:&nbsp;<a href="mailto:support@surgicalis.com">support&#64;surgicalis.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="about-content-item">
                        <div class="image-and-text-row" i18n="@@helpAboutCautionFederalLawInUS">
                            <img ngSrc="../../../assets/img/rx-only.png" alt="" height="45" width="116"
                                 class="about-icon-rx-only m-l-30">
                            <div class="m-l-30">
                                <p><b>{{ 'caution'|translate }}:</b><br>{{ 'helpAboutCautionFederalLawInUS'|translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="about-content-right-side">
                    <div class="about-content-item">
                        <div class="eu-warning">
                            <img ngSrc="../../../assets/img/eifu.png" class="about-eifu-icon" alt=""
                                 width="141" height="186">
                            <p i18n="@@helpAboutConsultIFU" class="m-l-20">
                                {{ 'helpAboutConsultIFU1'|translate }}<br><br>
                                {{ 'helpAboutConsultIFU2'|translate }}
                                <a href="mailto:support@surgicalis.com">support&#64;surgicalis.com</a>
                            </p>
                        </div>
                    </div>
                    <div class="about-content-item">
                        <div class="image-and-text-row">
                            <img ngSrc="../../../assets/img/ec_rep.png" alt="" class="about-icon-ec-rep"
                                 height="208" width="556">
                            <div class="about-eu-contact m-l-30">
                                <p class="about-address-line">Emergo Europe B.V</p>
                                <p class="about-address-line">Westervoortsedijk 60</p>
                                <p class="about-address-line"> 6827 AT {{ 'helpAboutTheHague'|translate }}</p>
                                <p class="about-address-line">{{ 'helpAboutTheNetherlands'|translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="about-content-item about-content-space-between">
                        <div class="about-content-item-ce-mark">
                            <img ngSrc="../../../assets/img/ce_mark.png" class="about-icon-ce-mark" alt=""
                                 height="104" width="148">
                            <p class="about-content-ce-mark-text">2460</p>
                        </div>
                        <img ngSrc="../../../assets/img/md.png" class="about-icon-md" alt="" height="120" width="187">
                    </div>
                </div>
            </div>
            <div class="about-content" *ngIf="!enableEu">
                <div class="about-content-left-side">
                    <div class="about-content-item">
                        <div class="image-and-text-row">
                            <img ngSrc="../../../assets/img/manufacturer.png" alt="" height="148" width="148"
                                 class="about-manufacturer-icon">
                            <div class="m-l-30">
                                <p class="about-address-header" i18n="@@helpAboutManufacturer">
                                    {{ 'helpAboutManufacturer'|translate }}
                                </p>
                                <p class="about-address-line">Surgical Information Sciences, Inc.</p>
                                <p class="about-address-line">5201 Eden Avenue, Suite 300</p>
                                <p class="about-address-line">Edina, MN 55436, USA</p>
                                <p class="about-address-line">
                                    {{ 'helpAboutReleaseDate'|translate }}:&nbsp;{{ releaseDate }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="about-content-item">
                        <div>
                            <div class="m-l-30">
                                <p class="about-assistance-header" i18n="@@helpAboutUserAssistanceInUS">
                                    <span [innerHTML]="'helpAboutUserAssistanceInUS'|translate"></span>:
                                </p>
                                <p class="about-assistance-line" i18n="@@helpAboutPhone">
                                    {{ 'phone'|translate }}:&nbsp;<a href="tel:7633072401">763-307-2401</a>
                                </p>
                                <p class="about-assistance-line" i18n="@@helpAboutEmail">
                                    {{ 'email'|translate }}:&nbsp;<a href="mailto:support@surgicalis.com">support&#64;surgicalis.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="about-content-item">
                        <div class="image-and-text-row" i18n="@@helpAboutCautionFederalLawInUS">
                            <img ngSrc="../../../assets/img/rx-only.png" alt="" height="45" width="116"
                                 class="about-icon-rx-only m-l-30">
                            <div class="m-l-30">
                                <p><b>{{ 'caution'|translate }}:</b><br>{{ 'helpAboutCautionFederalLawInUS'|translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="about-content-right-side">
                    <div class="about-content-item">
                        <div class="eu-warning">
                            <img ngSrc="../../../assets/img/eifu.png" class="about-eifu-icon" alt=""
                                 width="141" height="186">
                            <p i18n="@@helpAboutConsultIFU" class="m-l-20">
                                {{ 'helpAboutConsultIFU1'|translate }}<br><br>
                                {{ 'helpAboutConsultIFU2'|translate }}
                                <a href="mailto:support@surgicalis.com">support&#64;surgicalis.com</a>
                            </p>
                        </div>
                    </div>
                    <div class="about-content-item"></div>
                    <div class="about-content-item"></div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
