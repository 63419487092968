import { NgModule } from '@angular/core';
import { LocalDatePipe } from './local-date.pipe';
import { ConfirmCancelDialogComponent } from './confirm-dialog.component';
import { MaterialModule } from '../material.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MinuteSecondsPipe } from './min-sec.pipe';
import { NotFoundComponent } from './not-found/not-found.component';
import { ContextSelectorComponent } from './context-selector/context-selector.component';

@NgModule({
    declarations: [
        LocalDatePipe,
        MinuteSecondsPipe,
        ConfirmCancelDialogComponent,
        MenuItemComponent,
        NotFoundComponent,
        ContextSelectorComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslateModule,
    ],
    providers: [],
    exports: [
        LocalDatePipe,
        MinuteSecondsPipe,
        MenuItemComponent,
        NotFoundComponent,
        ContextSelectorComponent
    ]
})

export class ToolsModule {
}
