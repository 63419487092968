<mat-sidenav-container class="fill-parent">
    <mat-sidenav
            class="sis-sidenav"
            disableClose
            mode="side"
            opened="true">
        <div class="side-nav-top-space"></div>
        <mat-nav-list>
            @for (menuItem of permittedMenu(); track menuItem.titleKey) {
                <app-menu-item class="sis-side-nav-item" [item]="menuItem" [attr.data-testid]="menuItem.titleKey">
                    {{ menuItem.titleKey | translate }}
                </app-menu-item>
            }
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="sis-sidenav-content">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
