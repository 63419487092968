import * as THREE from 'three';

export const COORDINATE_SYSTEM_LPS = 'lps';
export const LPS2RAS = new THREE.Vector3(-1, -1, 1);

// noinspection JSUnusedGlobalSymbols
export const COORDINATE_SYSTEM_RAS = 'ras';
export const NO_FLIP = new THREE.Vector3(1, 1, 1);

export function flipVector(cs: string): THREE.Vector3 {
    return cs === COORDINATE_SYSTEM_LPS ? LPS2RAS : NO_FLIP;
}
