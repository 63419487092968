@if (!hide) {
    <mat-list-item
            class="sis-side-nav-item"
            [activated]="isActive(item)"
            [ngClass]="getClass(indentLevel)"
            (click)="onItemSelected(item, $event.metaKey)" mat-list-item>
        <span>{{ item.titleKey|translate }}</span>
        <span class="twistie-separator"></span>
        @if (item.children && item.children.length) {
            <span>
        <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'" class="menu-twistie">
          expand_more
        </mat-icon>
      </span>
        }
    </mat-list-item>
}
@if (expanded) {
    <div>
        @for (child of item.children; track child) {
            <app-menu-item
                    [indentLevel]="indentLevel + 1"
                    [item]="child"
                    class="menu-child">
            </app-menu-item>
        }
    </div>
}
