<div class="fill-parent reports-container">
    <div class="usage-report-header">
        <div class="reports-selection-container">
            <div class="reports-selection">
                <div>
                    <mat-radio-group class="usage-context-buttons" [(ngModel)]="selectedContext" ngDefaultControl
                                     (ngModelChange)="updateContextList()">
                        @for (uc of usageContextList; track uc.label) {
                            <mat-radio-button [value]="uc">{{ uc.label | translate }}</mat-radio-button>
                        }
                    </mat-radio-group>
                </div>
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'reportSnapshotSelectContext'|translate }}</mat-label>
                    <input matInput type="text" [formControl]="contextControl" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" panelWidth="400px">
                        @for (option of filteredOptions | async; track option.trackId) {
                            <mat-option [value]="option"
                                        (click)="optionClicked($event, option)">
                                <mat-icon class="usage-report-auto-complete-item-icon">{{ option.iconKey }}</mat-icon>
                                <div class="usage-report-auto-complete-item-div">
                                    <div class="usage-report-auto-complete-item-desc-text">
                                        {{ option.display }}
                                    </div>
                                    <mat-checkbox [checked]="option.selected" (change)="toggleSelection(option)"
                                                  (click)="$event.stopPropagation()">
                                    </mat-checkbox>
                                </div>
                            </mat-option>
                        }
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'reportSelectDatesLabel'|translate }}</mat-label>
                    <mat-date-range-input [rangePicker]="picker" [disabled]="inProgress">
                        <input matStartDate placeholder="{{'startDate'|translate}}" [(ngModel)]="startDate">
                        <input matEndDate placeholder="{{'endDate'|translate}}" [(ngModel)]="endDate">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <button mat-raised-button class="usage-report-submit-btn"
                        (click)="submitQuery()" [disabled]="disableSubmit()">
                    {{ 'submit'|translate }}
                </button>
            </div>
            <div class="reports-range">
                <button mat-raised-button class="report-range-btn"
                        (click)="setCurrentMonth()" [disabled]="inProgress">
                    {{ 'reportsRangeCurrentMonth'|translate }}
                </button>
                <button mat-raised-button class="report-range-btn" (click)="setPrevMonth()"
                        [disabled]="inProgress">
                    {{ 'reportsRangePrevMonth'|translate }}
                </button>
            </div>
            <div class="reports-range">
                <button mat-raised-button class="report-range-btn" (click)="setCurrentQuarter()"
                        [disabled]="inProgress">
                    {{ 'reportsRangeCurrentQuarter'|translate }}
                </button>
                <button mat-raised-button class="report-range-btn" (click)="setPrevQuarter()"
                        [disabled]="inProgress">
                    {{ 'reportsRangePrevQuarter'|translate }}
                </button>
            </div>
            <div class="reports-range">
                <button mat-raised-button class="report-range-btn" (click)="setCurrentYear()"
                        [disabled]="inProgress">
                    {{ 'reportsRangeCurrentYear'|translate }}
                </button>
                <button mat-raised-button class="report-range-btn" (click)="setPrevYear()"
                        [disabled]="inProgress">
                    {{ 'reportsRangePrevYear'|translate }}
                </button>
                <button mat-raised-button class="report-range-btn" (click)="setAll()" [disabled]="inProgress">
                    {{ 'reportsRangeAllTimes'|translate }}
                </button>
            </div>
        </div>

        <div class="usage-report-container">
            <div class="usage-report-row">
                <div class="usage-report-column" *ngIf="caseData.length > 0">
                    <div class="usage-report-title-container">
                        <p class="mat-typography usage-item-title">{{ 'reportCasesAndFlows'|translate }}</p>
                    </div>
                    <mat-table [dataSource]="caseData">
                        <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                            <mat-cell class="usage-report-flex-start" *matCellDef="let statRow"
                                      [ngClass]="rowClass(statRow)">
                                {{ statRow.titleKey|translate }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                            <mat-cell class="usage-number-column" *matCellDef="let statRow"
                                      [ngClass]="rowClass(statRow)">
                                {{ formatItem(statRow) }}
                            </mat-cell>
                        </ng-container>
                        <mat-row *matRowDef="let statRow; columns: displayedColumns;" class="table-row">
                        </mat-row>
                    </mat-table>
                </div>
                <div class="usage-report-column" *ngIf="caseLeadData.length > 0 || leadsData.length > 0">
                    <div class="usage-report-leads-leads">
                        <div *ngIf="caseLeadData.length > 0" class="m-b-40">
                            <div class="usage-report-title-container">
                                <p class="mat-typography usage-item-title">{{ 'reportSnapshotLeadSummary'|translate }}</p>
                            </div>
                            <mat-table [dataSource]="caseLeadData">
                                <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                                    <mat-cell class="usage-report-flex-start" *matCellDef="let statRow">
                                        {{ statRow.titleKey|translate }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                                    <mat-cell class="usage-number-column" *matCellDef="let statRow">
                                        {{ formatItem(statRow) }}
                                    </mat-cell>
                                </ng-container>
                                <mat-row *matRowDef="let statRow; columns: displayedColumns;" class="table-row">
                                </mat-row>
                            </mat-table>
                        </div>
                        <div *ngIf="leadsData.length > 0">
                            <div class="usage-report-title-container">
                                <p class="mat-typography usage-item-title">{{ 'reportLeads'|translate }}</p>
                            </div>
                            <mat-table [dataSource]="leadsData">
                                <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                                    <mat-cell class="usage-report-flex-start" *matCellDef="let leadRow">
                                        {{ getElectrodeModel(leadRow.model)|translate }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                                    <mat-cell class="usage-number-column" *matCellDef="let leadRow">
                                        {{ formatLeadCount(leadRow.count) }}
                                    </mat-cell>
                                </ng-container>
                                <mat-row *matRowDef="let leadRow; columns: displayedColumns;" class="table-row">
                                </mat-row>
                            </mat-table>
                        </div>
                    </div>
                </div>
                <div class="usage-report-column"
                     *ngIf="usersData.length > 0 || accountsData.length > 0 || groupsData.length > 0">
                    <div class="usage-report-users-accounts-groups">
                        <div *ngIf="usersData.length > 0" class="m-b-40">
                            <div class="usage-report-title-container">
                                <p class="mat-typography usage-item-title">{{ 'reportUsers'|translate }}</p>
                            </div>
                            <mat-table [dataSource]="usersData">
                                <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                                    <mat-cell class="usage-report-flex-start" *matCellDef="let statRow">
                                        {{ statRow.titleKey|translate }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                                    <mat-cell class="usage-number-column" *matCellDef="let statRow">
                                        {{ formatItem(statRow) }}
                                    </mat-cell>
                                </ng-container>
                                <mat-row *matRowDef="let statRow; columns: displayedColumns;" class="table-row">
                                </mat-row>
                            </mat-table>
                        </div>
                        <div *ngIf="accountsData.length > 0" class="m-b-40">
                            <div class="usage-report-title-container">
                                <p class="mat-typography usage-item-title">{{ 'reportAccounts'|translate }}</p>
                            </div>
                            <mat-table [dataSource]="accountsData">
                                <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                                    <mat-cell class="usage-report-flex-start" *matCellDef="let statRow">
                                        {{ statRow.titleKey|translate }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                                    <mat-cell class="usage-number-column" *matCellDef="let statRow">
                                        {{ formatItem(statRow) }}
                                    </mat-cell>
                                </ng-container>
                                <mat-row *matRowDef="let statRow; columns: displayedColumns;" class="table-row">
                                </mat-row>
                            </mat-table>
                        </div>
                        <div *ngIf="groupsData.length > 0">
                            <div class="usage-report-title-container">
                                <p class="mat-typography usage-item-title">{{ 'reportGroups'|translate }}</p>
                            </div>
                            <mat-table [dataSource]="groupsData">
                                <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                                    <mat-cell class="usage-report-flex-start" *matCellDef="let statRow">
                                        {{ statRow.titleKey|translate }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                                    <mat-cell class="usage-number-column" *matCellDef="let statRow">
                                        {{ formatItem(statRow) }}
                                    </mat-cell>
                                </ng-container>
                                <mat-row *matRowDef="let statRow; columns: displayedColumns;" class="table-row">
                                </mat-row>
                            </mat-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="usage-report-footer">
        <button mat-raised-button class="report-range-btn" (click)="submitExport()"
                [disabled]="disableSubmit()">
            {{ 'reportSnapshotExportCsv'|translate }}
        </button>
        <button mat-raised-button class="usage-report-clear-btn" (click)="clear()"
                [disabled]="disableClear()">
            {{ 'clear'|translate }}
        </button>
    </div>
</div>
