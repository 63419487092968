<div class="content-wrapper">
    @if (showNavBar) {
        <app-main-nav></app-main-nav>
    }
    @if (showIdleWarning()) {
        <div class="bg-warning text-center" i18n="@@appIdleLogoutWarning">
            {{ 'appIdleLogoutWarning'|translate }} {{ idleSecondsRemaining }} {{ 'seconds'|translate }}.
        </div>
    }
    @if (showSessionWarning()) {
        <div class="bg-warning text-center" i18n="@@appSessionLogoutWarning">
            {{ 'appSessionLogoutWarning'|translate }} {{ sessionSecondsRemaining }} {{ 'seconds'|translate }}.
        </div>
    }
    <router-outlet></router-outlet>
</div>
