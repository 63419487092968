<div class="create-mrb-container">
    <div class="buffer"></div>
    <button mat-raised-button class="case-review-button" (click)="onDownloadMrb()" [disabled]="inProgress">
        Create MRB
    </button>
    <div class="buffer">
        @if (inProgress) {
            <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
        }
    </div>
</div>
