import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { CoordinateSystem, DbsTarget, Elements, IMAGE_CS } from '../case.constants';
import { DataElement } from '../data-element.class';
import { SlicesService } from './slices.service';
import { Subscriptions } from '../../tools/subscriptions.class';
import { Case, CaseService } from '../case.service';
import { ElectrodeDescription, ElectrodesDetectionReport, HeadPose } from '../../services/api.service';
import { LoadStlReport } from './stl-view/stl-view.service';
import { SlicesReport } from './stl-view/stl-view.component';
import { LeadSimulatorReadiness } from './lead-sim/lead-simulator-readiness.class';
import { ElementDisplayEvent } from './stl-options/stl-options.component';
import { BehaviorSubject, concat, of, ReplaySubject, Subject } from 'rxjs';
import { ElectrodeContext, RollChangeEvent } from './lead-sim/lead-simulator-controller.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-case-lead-location',
    templateUrl: './lead-location.component.html',
    styleUrls: ['./lead-location.component.scss'],
    // the service is injected on component level and will be destroyed with the Component.
    providers: [SlicesService],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('showSpecialMessage', [
            state('enabled', style({opacity: 1})),
            state('disabled', style({opacity: 0})),
            transition('enabled => disabled', animate('500ms')),
            transition('disabled => enabled', animate('500ms'))
        ])
    ],
})

export class LeadLocationComponent implements OnInit, OnDestroy {
    @Input() containerWidth: number;
    @Input() containerHeight: number;

    @Input() caseData: Case;
    @Input() caseUpdated: Subject<Case>;
    @Input() approvedTargets: Array<DbsTarget>;

    @Output() slicesData: ReplaySubject<ArrayBuffer> = new ReplaySubject<ArrayBuffer>(1);
    @Output() shareStlReport: EventEmitter<LoadStlReport> = new EventEmitter<LoadStlReport>();
    @Output() sliceChanged: Subject<number> = new Subject<number>();
    @Output() opacityChanged: Subject<number> = new Subject<number>();
    @Output() enableDisplay: EventEmitter<LeadSimulatorReadiness> = new EventEmitter<LeadSimulatorReadiness>();
    @Output() electrodesReportReady: ReplaySubject<ElectrodesDetectionReport> = new ReplaySubject<ElectrodesDetectionReport>(1);
    @Output() notifyDisplayElementEvent: Subject<ElementDisplayEvent> = new Subject<ElementDisplayEvent>();
    @Output() changeCoordinates: Subject<CoordinateSystem> = new BehaviorSubject<CoordinateSystem>(IMAGE_CS);
    @Output() headPoseReport: Subject<HeadPose> = new ReplaySubject<HeadPose>(1);
    @Output() slicesLoadedReport: Subject<SlicesReport> = new Subject<SlicesReport>();
    @Output() electrodeRollChange: Subject<RollChangeEvent> = new ReplaySubject<RollChangeEvent>(5);
    @Output() electrodeContextChange: Subject<ElectrodeContext> = new ReplaySubject<ElectrodeContext>(1);
    @Output() loadingPlanningToPostop: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('stlview', {static: true}) stlView: ElementRef;

    private subscriptions = new Subscriptions();
    private slicesFinished = false;

    public electrodesReport: ElectrodesDetectionReport = {electrodes: [], noReportAvailable: true};
    public unclearedDetections: Array<ElectrodeDescription> = Array<ElectrodeDescription>();
    public displaySpecialMessage = 'disabled';
    public specialMessageText = '';

    private stlConcluded = false;

    constructor(private caseService: CaseService, private slicesService: SlicesService,
                private translate: TranslateService) {
    }

    ngOnInit(): void {
        // Try to get an electrode detection report from the case's elements.
        // If no such report exists, use the default report that we've initialized (with .noReportAvailable=true).
        // To make this happen, we just concatenate the observable from the case
        // service with an observable that immediately yields the existing report; then we just take the first item from the sequence.
        this.subscriptions.limit(concat(this.caseService.getElectrodeDetectionReport(this.caseData),
            of(this.electrodesReport))).pipe(take(1)).subscribe({
            next: (report: ElectrodesDetectionReport) => {
                this.electrodesReport = this.reviewElectrodeReport(report);
                this.electrodesReportReady.next(this.electrodesReport);
            },
            error: () => {
                this.electrodesReportReady.next(this.electrodesReport);
            }
        });

        this.loadingPlanningToPostop.emit(true);
        this.subscriptions.add(this.slicesService.loadPlanningToPostop(this.caseData, Elements.PLANNING_ALPHA_REGTO_POSTOP_CT),
            (result: boolean) => {
                this.slicesData.next(result ? this.slicesService.getNifti() : null);
            },
            () => {
            },
            () => {
                this.slicesFinished = true;
                this.loadingPlanningToPostop.emit(false);
            }
        );

        this.subscriptions.add(this.caseService.getPostopHeadPose(this.caseData),
            (headPose: HeadPose) => {
                this.headPoseReport.next(headPose);
            }
        );
    }

    ngOnDestroy(): void {
        this.slicesService.clear();
        this.subscriptions.cancel();
    }

    getElectrodeVisualizationElements(): Array<DataElement> {
        return [Elements.ELECTRODE_VISUALIZATION_STL_LEFT, Elements.ELECTRODE_VISUALIZATION_STL_RIGHT];
    }

    get allDownloadConcluded(): boolean {
        return this.slicesFinished && this.stlConcluded;
    }

    get downloadSlicesConcluded(): boolean {
        return this.slicesFinished;
    }

    onStlLoaded(stlUpdate: LoadStlReport) {
        this.stlConcluded = true;
        this.shareStlReport.emit(stlUpdate);
        this.enableViewsIfNeeded();
    }

    private enableViewsIfNeeded() {
        if (this.stlConcluded) {
            this.enableDisplay.emit({
                stlAvailable: true
            });
            this.enableMessageDisplayIfNeeded();
        }
    }

    public get leadLocationWidth(): number {
        const w = this.containerWidth - 600 > 0 ? (this.containerWidth - 600) * 0.9 : 0;
        return (w < this.leadLocationHeight) ? this.leadLocationHeight : w;
    }

    public get leadLocationHeight(): number {
        return this.containerHeight - 200 > 0 ? this.containerHeight - 200 : 0;
    }

    private reviewElectrodeReport(data: any): ElectrodesDetectionReport {
        const report: ElectrodesDetectionReport = data as ElectrodesDetectionReport;
        this.unclearedDetections = report.electrodes.filter(detection => ('cleared' in detection && !detection.cleared));
        report.electrodes = report.electrodes.filter(detection => 'model' in detection);
        return report;
    }

    private enableMessageDisplayIfNeeded() {
        if (this.unclearedDetections.length > 0) {
            this.setSpecialMessageText();
            this.subscriptions.add(this.translate.onLangChange, () => this.setSpecialMessageText());
            this.displaySpecialMessage = 'enabled';
        }
    }

    private setSpecialMessageText() {
        const messageKey = this.unclearedDetections.length === 1 ? 'caseLeadLocationOneElectrodeRemoved' : 'caseLeadLocationMultipleElectrodeRemoved';
        this.subscriptions.add(
            this.translate.get(messageKey, {nonClearedDetected: this.unclearedDetections.length}),
            (translated: string) => {
                this.specialMessageText = translated;
            }
        );
    }
}
