export class Visibility {
    constructor(public readonly code: number) {

    }

    // in the future we'll probably have additional methods that will express a more detailed permissions system
    public admin(): boolean {
        return this.code === Visibilities.ADMIN.code;
    }

    public user(): boolean {
        return this.code === Visibilities.USER.code;
    }
}

export const Visibilities = {
    ADMIN: new Visibility(1),
    USER: new Visibility(2)
};
