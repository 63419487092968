<form>
    <mat-form-field appearance="outline" subscriptSizing="dynamic" [ngClass]="requestedClass">
        <mat-label>{{ labelKey|translate }}</mat-label>
        <input type="text" matInput
               [formControl]="contextControl"
               [matAutocomplete]="auto">
        <mat-autocomplete
			#auto="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="onContextChange()">
            @for (option of filteredOptions | async; track option.trackId) {
                <mat-option [value]="option">
                    <mat-icon>{{ option.iconKey }}</mat-icon>
                    {{ option.display }}
                </mat-option>
            }
        </mat-autocomplete>
    </mat-form-field>
</form>

