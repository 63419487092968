<h2 mat-dialog-title i18n="@@settingsGroupsAddMemberTitle">{{ 'settingsGroupsAddMemberTitle'|translate }}</h2>
<form [formGroup]="addMemberForm" novalidate>
    <mat-dialog-content>
        <!-- We have a pending request to get candidates -->
        @if (queryPending) {
            <div>
                <p class="mat-typography">{{ 'settingsGroupsAddMemberCandidatesPending'|translate }}</p>
            </div>
        } @else {
            <!-- We are not waiting for the request -->
            @if (candidateUsers.length > 0) {
                <!-- We have candidates - show list + permissions checkboxes -->
                <div>
                    <p>
                        <mat-form-field class="width-80-p" data-testid="group-invite-select">
                            <mat-label>{{ 'settingsGroupsAddMemberUserPlaceholder'|translate }}</mat-label>
                            <input matInput type="text" [formControl]="user" [matAutocomplete]="auto">
                            <mat-autocomplete
								#auto="matAutocomplete"
                                [displayWith]="displayUser">
                                @for (ca of filteredUsers | async; track ca.id) {
                                    <mat-option [value]="ca" data-testid="group-invite-users">
                                        {{ displayUser(ca) }}
                                    </mat-option>
                                }
                            </mat-autocomplete>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-checkbox [formControl]="groupAdmin" i18n="@@settingsGroupsAddMemberIsAdmin"
                                      data-testid="group-invite-admin">
                            {{ 'settingsGroupsAddMemberIsAdmin'|translate }}
                        </mat-checkbox>
                    </p>
                    <p>
                        <mat-checkbox [formControl]="caseManager" i18n="@@settingsGroupsAddMemberCanEditCases"
                                      data-testid="group-invite-case-manager">
                            {{ 'settingsGroupsAddMemberCanEditCases'|translate }}
                        </mat-checkbox>
                    </p>
                </div>
            } @else {
                <!-- No candidates - show message -->
                <div>
                    <p class="mat-typography">{{ 'settingsGroupsAddMemberNoCandidatesAvailable'|translate }}</p>
                </div>
            }
        }
    </mat-dialog-content>
    <mat-dialog-actions>
        <button i18n="@@settingsGroupsAddMemberButtonCancel"
                [mat-dialog-close]=null mat-raised-button data-testid="group-invite-cancel">
            {{ 'cancel'|translate }}
        </button>
        @if (!queryPending && candidateUsers.length > 0) {
            <button
                    i18n="@@settingsGroupsAddMemberButtonInvite"
                    (click)="onInvite()"
                    [disabled]="disableInvitation()"
                    data-testid="group-invite-confirm"
                    mat-raised-button>
                {{ 'invite'|translate }}
            </button>
        }
    </mat-dialog-actions>
</form>
