import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './users/users.component';
import { GroupsComponent } from './groups/groups.component';
import { CreateUserComponent } from './users/create-user/create-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SettingsComponent } from './settings.component';
import { RouterModule } from '@angular/router';
import { NewGroupComponent } from './groups/new-group/new-group.component';
import { ToolsModule } from '../tools/tools.module';
import { SettingsNavigationService } from './settings-navigation.service';
import { GroupComponent } from './groups/group/group.component';
import { AddMemberComponent } from './groups/add-member/add-member.component';
import { NoSettingsComponent } from './no-settings.component';
import { ProfileComponent } from './users/profile/profile.component';
import { TransferCasesComponent } from './users/transfer-cases/transfer-cases.component';
import { AccountsComponent } from './accounts/accounts.component';
import { NewAccountComponent } from './accounts/new-account/new-account.component';
import { AccountComponent } from './accounts/account/account.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponentsModule } from '../app-components.module';
import { InviteUserComponent } from './users/invite-user/invite-user.component';

@NgModule({
    declarations: [
        AccountComponent,
        AccountsComponent,
        AddMemberComponent,
        CreateUserComponent,
        GroupComponent,
        GroupsComponent,
        InviteUserComponent,
        NewAccountComponent,
        NewGroupComponent,
        NoSettingsComponent,
        ProfileComponent,
        SettingsComponent,
        TransferCasesComponent,
        UsersComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MaterialModule,
        FormsModule,
        CommonModule,
        RouterModule,
        ToolsModule,
        TranslateModule,
        AppComponentsModule,
        ReactiveFormsModule,
    ],
    providers: [
        SettingsNavigationService,
    ]
})
export class SettingsModule {
}
