<mat-card appearance="outlined" class="choose-registration-method-box">
    <mat-card-title class="sis-card-title">
        <span class="mat-typography sis-bold">Choose {{ kind }} Registration Method</span>
    </mat-card-title>
    <mat-card-content class="choose-registration-method-content">
        <mat-form-field class="width-90-p">
            <mat-select [(ngModel)]="selectedMethod" (selectionChange)="save()">
                @for (method of methodChoices(); track method.code) {
                    <mat-option [value]="method.code">{{ method.description }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </mat-card-content>
</mat-card>
