import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CaseService } from '../case/case.service';
import { RouterModule } from '@angular/router';
import { NewCaseComponent } from '../new-case/new-case.component';
import { ToolsModule } from '../tools/tools.module';
import { MaterialModule } from '../material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ToolsModule,
        MaterialModule,
        RouterModule,
        TranslateModule
    ],
    declarations: [
        HomeComponent,
        NewCaseComponent,
    ],
    providers: [
        CaseService,
    ]
})

export class HomeModule {
}
