import { Injectable } from '@angular/core';
import { PermissionsService } from '../services/permissions.service';
import { Observable, of } from 'rxjs';
import { Permissions } from '../security/permissions.class';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { NavigationService, NavigationServiceEntry } from '../tools/navigation.service';
import { ACCOUNT_ADMIN, GroupPermission, UserResponse } from '../services/api.service';
import { atLeastOneOf, replay } from '../rxjs/observables';
import { map, switchMap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class SettingsNavigationService extends NavigationService {

    constructor(permissionsService: PermissionsService, authService: AuthService, router: Router,
                private userService: UserService) {
        super(permissionsService, authService, router);
    }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        if (state.url.endsWith('/users')) {
            return this.maybeRedirectChild(this.canActivateSettingsUsers());
        }
        else if (state.url.endsWith('/groups')) {
            return this.maybeRedirectChild(this.canActivateSettingsGroups());
        }
        else if (state.url.endsWith('/accounts')) {
            return this.maybeRedirectChild(this.canActivateSettingsAccounts());
        }
        else if (state.url.endsWith('/settings')) {
            const table = [
                new NavigationServiceEntry(this.canActivateSettingsUsers(), 'users'),
                new NavigationServiceEntry(this.canActivateSettingsGroups(), 'groups'),
                new NavigationServiceEntry(this.canActivateSettingsAccounts(), 'accounts'),
            ];
            return this.maybeRedirect(state, table);
        }
        else {
            // This should only be used as a guard on paths that it knows how to handle.
            return false;
        }
    }

    public canActivateSettings(): Observable<boolean> {
        return this._requireCanActivateSettings();
    }

    public canActivateSettingsUsers(): Observable<boolean> {
        return this._requireCanActivateSettingsUsers();
    }

    public canActivateSettingsGroups(): Observable<boolean> {
        return this._requireCanActivateSettingsGroups();
    }

    public canActivateSettingsAccounts(): Observable<boolean> {
        return this._requireCanActivateSettingsAccounts();
    }

    private _requirePermissionUserAdmin(): Observable<boolean> {
        return this.cachedObservable('permissionUserAdmin', () => this.stablePermission(Permissions.userAdmin()));
    }

    private _requireAccountAdmin(): Observable<boolean> {
        // Rather than looking for a security permission here, we're looking for a permission stored in the current user (if there is one).
        // This is a bit of a shortcut since actually using Settings|Users involves several permissions, some of which are referenced
        // indirectly. For example, listing users for an account is handled by GET /api/v2/user without a separate permission. Inviting
        // new users requires Permissions.account_invite()
        return this.cachedObservable('accountAdmin', () => {
            return replay(this.authService.onAuthState.pipe(
                switchMap(event => {
                    if (event.authenticated) {
                        return this.userService.getUser(event.userId).pipe(
                            map((user: UserResponse) => {
                                // To be considered an account admin, a user has to be assigned to an account and have the ACCOUNT_ADMIN user
                                // permission.
                                const active = user.status === 'ACTIVE' && !user.disabled;
                                const hasAccount = !!user.account_id;
                                const isAccountAdmin = (user.permissions ?? []).includes(ACCOUNT_ADMIN);
                                return active && hasAccount && isAccountAdmin;
                            })
                        );
                    }
                    else {
                        // Unauthenticated users cannot be account admins
                        return of(false);
                    }
                })
            ), 1);
        });
    }

    private _requireActiveGroupAdmin(): Observable<boolean> {
        // Being a group admin means being authenticated and having a group admin permission as an active member of at least one group.
        return this.cachedObservable('activeGroupAdmin', () => {
            return replay(this.authService.onAuthState.pipe(
                switchMap(event => {
                    if (event.authenticated) {
                        return this.userService.getUser(event.userId).pipe(
                            map((user: UserResponse) => {
                                return (user.memberships || []).some(m =>
                                    m.status === 'ACTIVE' && m.permissions.includes(GroupPermission.GroupAdmin)
                                );
                            })
                        );
                    }
                    else {
                        return of(false);
                    }
                })
            ), 1);
        });
    }

    private _requirePermissionViewAccounts(): Observable<boolean> {
        return this.cachedObservable('permissionViewAccounts', () => this.stablePermission(Permissions.accountViewAll()));
    }

    private _requireCanActivateSettingsUsers(): Observable<boolean> {
        return this.cachedObservable('canActivateSettingsUsers', () => this.replayAtLeastOneOf([
            this._requirePermissionUserAdmin(),
            this._requireAccountAdmin(),
        ]));
    }

    private _requireCanActivateSettingsGroups(): Observable<boolean> {
        return this.cachedObservable('canActivateSettingsGroups', () => this.replayAtLeastOneOf([
            this._requirePermissionUserAdmin(),
            this._requireActiveGroupAdmin(),
        ]));
    }

    private _requireCanActivateSettingsAccounts(): Observable<boolean> {
        // Nothing else to do since we just alias mCanActivateSettingsAccounts to mPermissionViewAccounts
        return this._requirePermissionViewAccounts();
    }

    private _requireCanActivateSettings(): Observable<boolean> {
        return this.cachedObservable('canActivateSettings', () => this.replayAtLeastOneOf([
            this._requireCanActivateSettingsUsers(),
            this._requireCanActivateSettingsGroups(),
            this._requireCanActivateSettingsAccounts(),
        ]));
    }

    private replayAtLeastOneOf(components: Array<Observable<boolean>>): Observable<boolean> {
        return replay(atLeastOneOf(components), 1);
    }
}
