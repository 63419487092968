import { Component } from '@angular/core';
import { UploadService } from './upload.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { UploadDirective } from './upload.directive';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';

@Component({
    selector: 'app-case-files-upload-transform',
    standalone: true,
    imports: [
        CommonModule,
        MaterialModule,
        TranslatePipe
    ],
    templateUrl: './upload-transform.component.html',
    styleUrls: ['./upload.component.scss']
})
export class UploadTransformComponent extends UploadDirective {

    constructor(uploadService: UploadService, translate: TranslateService) {
        super(uploadService, translate);
    }

    getFileCount(): number {
        const report = this.validUploadReport();
        return report ? 1 : this.uploadCount;
    }

    protected getMinFileCount(): number {
        return 1;
    }
}
