import { Component, OnDestroy, OnInit } from '@angular/core';
import { TableDirective } from '../../tools/table.directive';
import { compareUsersByDescription, userLastAndFirstName, UserService } from '../../services/user.service';
import { Subscriptions } from '../../tools/subscriptions.class';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionsService } from '../../services/permissions.service';
import { Router } from '@angular/router';
import { Permissions } from '../../security/permissions.class';
import { CreateUserComponent } from './create-user/create-user.component';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { ComponentType } from '@angular/cdk/overlay';
import { MaterialModule } from '../../material.module';
import { LocalDatePipe } from '../../tools/local-date.pipe';
import { TranslatePipe } from '@ngx-translate/core';

export interface UserRow {
    id: string;
    name: string;
    email: string;
    created: string;
    updated: string;
}

@Component({
    selector: 'app-users',
    standalone: true,
    imports: [
        LocalDatePipe,
        MaterialModule,
        TranslatePipe,
    ],
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent extends TableDirective<UserRow> implements OnInit, OnDestroy {
    allowCreateUser = false;
    allowInviteUser = false;

    private subscriptions = new Subscriptions();

    constructor(private router: Router, private userService: UserService,
                private permissionsService: PermissionsService, private dialog: MatDialog) {
        super(['name', 'email', 'created', 'updated'], new MatTableDataSource<UserRow>());
    }

    ngOnInit() {
        this.readUsers();

        this.subscriptions.limit(this.permissionsService.hasPermission(Permissions.userCreate())).subscribe(allowed => {
            this.allowCreateUser = allowed;
        });

        this.subscriptions.limit(this.permissionsService.hasPermission(Permissions.anyAccountInvite())).subscribe(allowed => {
            this.allowInviteUser = allowed;
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
    }

    private readUsers() {
        this.subscriptions.add(this.userService.getUsers(), users => {
            this.dataSource.data = users.sort(compareUsersByDescription).map(item => {
                return {
                    id: item.id,
                    name: userLastAndFirstName(item),
                    email: item.email,
                    created: item.created,
                    updated: item.updated,
                };
            });
        });
    }

    onActivate(user: UserRow, metaKey: boolean) {
        this.navigateFromClick(this.router, metaKey, ['settings', 'user', user.id]);
    }

    openCreateUserDialog() {
        this.openNewUserDialog(CreateUserComponent);
    }

    openInviteUserDialog() {
        this.openNewUserDialog(InviteUserComponent);
    }

    private openNewUserDialog<T>(componentClass: ComponentType<T>) {
        const newUserDialogOptions = {width: '600px'};
        const dialogRef = this.dialog.open(componentClass, newUserDialogOptions);

        dialogRef.afterClosed().subscribe(() => {
            this.readUsers();
        });
    }
}
