import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Subscriptions } from '../tools/subscriptions.class';
import { SettingsNavigationService } from '../settings/settings-navigation.service';
import { DashboardNavigationService } from '../dashboard/dashboard-navigation.service';
import { ChangePasswordComponent, ChangePasswordMode } from '../change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { ReportsNavigationService } from '../reports/reports-navigation.service';
import { NavigationEnd, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

enum MainNavItems {
    Cases, Settings, Dashboard, Reports, About
}

class MainNavItem {
    constructor(public route: string, public id: MainNavItems, public titleKey: string) {
    }
}

@Component({
    selector: 'app-main-nav',
    templateUrl: './main-nav.component.html',
    styleUrls: ['./main-nav.component.scss'],
})

export class MainNavComponent implements OnInit, OnDestroy {

    private readonly HOME = new MainNavItem('/home', MainNavItems.Cases, 'mainNavAllCases');
    private readonly SETTINGS = new MainNavItem('/settings', MainNavItems.Settings, 'mainNavSettings');
    private readonly DASHBOARD = new MainNavItem('/dashboard', MainNavItems.Dashboard, 'mainNavDashboard');
    private readonly REPORTS = new MainNavItem('/reports', MainNavItems.Reports, 'mainNavReports');
    readonly HELP = new MainNavItem('/help', MainNavItems.About, 'about');

    private navigateItems = new Array<MainNavItem>();
    currentUser: string;

    displaySearchBar = false;
    displayNotifications = false;
    allowSettings = false;
    allowDashboard = false;
    allowReports = false;

    selectedItem: MainNavItem = null;
    subscriptions = new Subscriptions();

    constructor(private auth: AuthService, private settingsNavigationService: SettingsNavigationService,
                private dashboardNavigationService: DashboardNavigationService,
                private reportsNavigationService: ReportsNavigationService,
                private router: Router,
                private dialog: MatDialog) {
    }

    get navItems(): Array<MainNavItem> {
        return this.navigateItems;
    }

    ngOnInit() {
        this.subscriptions.add(this.router.events.pipe(
            filter((event: NavigationEnd) => event instanceof NavigationEnd),
            map(() => this.onNavigationEnd())
        ));

        this.subscriptions.add(combineLatest([
            this.auth.onAuthState,
            this.dashboardNavigationService.canActivateDashboard(),
            this.settingsNavigationService.canActivateSettings(),
            this.reportsNavigationService.canActivateReports()
        ]), data => {
            this.currentUser = data[0].authenticated ? data[0].username : null;
            this.allowDashboard = data[1];
            this.allowSettings = data[2];
            this.allowReports = data[3];
            this.displaySearchBar = data[0].authenticated;
            this.displayNotifications = data[0].authenticated;
            this.initializeNavItems();
            this.onNavigationEnd();
        });
    }

    private initializeNavItems() {
        this.navigateItems = new Array<MainNavItem>();
        if (this.currentUser) {
            this.navigateItems.push(this.HOME);
        }
        if (this.allowSettings) {
            this.navigateItems.push(this.SETTINGS);
        }
        if (this.allowDashboard) {
            this.navigateItems.push(this.DASHBOARD);
        }
        if (this.allowReports) {
            this.navigateItems.push(this.REPORTS);
        }
    }

    ngOnDestroy(): void {
        this.navigateItems = [];
        this.subscriptions.cancel();
    }

    get userId(): string {
        return this.auth.currentUserId;
    }

    public onEditProfile(metaKey: boolean) {
        const url = '/profile';
        metaKey ? window.open(url, '_blank') : this.router.navigate([url]).then();
    }

    public onChangePassword() {
        const changePasswordDialogOptions = {
            width: '800px',
            data: {mode: ChangePasswordMode.authenticated},
        };
        this.dialog.open(ChangePasswordComponent, changePasswordDialogOptions);
    }

    public onLogout() {
        this.auth.logout();
    }

    onNavigate(item: MainNavItem, metaKey: boolean) {
        metaKey ? window.open(item.route, '_blank') : this.router.navigate([item.route]);
    }

    onNavigationEnd(): void {
        const url = this.router.url;
        const i = this.navItems.find(item => url.startsWith(item.route));
        i != null ? this.selectedItem = i : (url.startsWith(this.HELP.route) ? this.selectedItem = this.HELP : this.selectedItem = null);
    }

    public getColor(navItem: MainNavItem): string {
        return (this.selectedItem != null && this.selectedItem.id === navItem.id) ? 'accent' : '';
    }
}
