import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AutoCompleteItem } from '../../tools/context-item.class';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { SearchService } from './search.service';
import {
    AccountDescriptor,
    CaseDescriptor,
    GroupDescriptor,
    SearchResponse,
    SearchResult,
    UserDescriptor
} from '../../services/api.service';
import { Router } from '@angular/router';
import { userDescription } from '../../services/user.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { CommonModule } from '@angular/common';

class SearchItem {

    constructor(public readonly id: string, public readonly name: string, public readonly iconKey: string,
                private route: string, public readonly cssClass: string = '') {
    }

    public onSelect(router: Router): Promise<boolean> {
        return router.navigate([this.route, this.id]);
    }
}

@Component({
    selector: 'app-search-bar',
    standalone: true,
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
    ],
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit {

    private static readonly SCOPE_CASES = 'cases';
    private static readonly SCOPE_USERS = 'users';
    private static readonly SCOPE_ACCOUNTS = 'accounts';
    private static readonly SCOPE_GROUPS = 'groups';

    public searchControl = new FormControl<string | null>(null);
    public filteredOptions: Observable<Array<SearchItem>>;

    private scopes = [
        SearchComponent.SCOPE_CASES,
        SearchComponent.SCOPE_GROUPS,
        SearchComponent.SCOPE_USERS,
        SearchComponent.SCOPE_ACCOUNTS
    ];

    constructor(private service: SearchService, public router: Router) {
    }

    ngOnInit(): void {
        this.setSearchFilter();
    }

    private setSearchFilter() {
        this.filteredOptions = this.searchControl.valueChanges.pipe(
            startWith(''),
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(value => this.validString(value) ? this._filter(value) : of([]))
        );
    }

    private validString(value: any): boolean {
        return (value !== null) && (typeof value === 'string') && (value.length > 0);
    }

    private _filter(value: string): Observable<Array<SearchItem>> {
        return this.service.query(value, this.scopes).pipe(
            map((response: SearchResponse) => response.results.map(item => this.resultToItem(item)))
        );
    }

    public displayFn(item: AutoCompleteItem): string {
        return item != null ? item.display : '';
    }

    private resultToItem(item: SearchResult): SearchItem {
        switch (item.scope) {
            case SearchComponent.SCOPE_CASES: {
                const cd = item.descriptors as CaseDescriptor;
                return new SearchItem(item.id, cd.name, 'topic', '/case', cd.archived ? 'archived' : '');
            }
            case SearchComponent.SCOPE_USERS: {
                const ud = item.descriptors as UserDescriptor;
                return new SearchItem(item.id, userDescription(ud), 'person', '/settings/user');
            }
            case SearchComponent.SCOPE_ACCOUNTS: {
                const ad = item.descriptors as AccountDescriptor;
                return new SearchItem(item.id, ad.name, 'business', '/settings/account');
            }
            case SearchComponent.SCOPE_GROUPS: {
                const gd = item.descriptors as GroupDescriptor;
                return new SearchItem(item.id, gd.name, 'group', '/settings/group');
            }
        }
        throw new Error(`Search result could not be converted to display item, invalid scope ${item.scope}`);
    }
}
