import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Case, CaseService, CaseStatus } from '../../../case/case.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { compareUsersByDescription, userDescription } from '../../../services/user.service';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { TableDirective } from '../../../tools/table.directive';
import { combineLatest, Observable } from 'rxjs';
import { ThemePalette } from '@angular/material/core';
import { PermissionsService } from '../../../services/permissions.service';
import { Permissions } from '../../../security/permissions.class';
import { Archived } from '../../../services/api.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { LS_KEY_ARCHIVED } from '../../../app-constants';

export class CaseRow {
    // Case data
    id: string;
    name: string;
    created: string;
    updated: string;
    archived: string | null;

    // Local data
    selected: boolean;
    status: CaseStatus;
    shared: boolean;
}

export interface TransferUser {
    id: string;
    email: string;
    first_name?: string;
    last_name?: string;
}

export interface TransferQueries {
    getUsers(): Observable<TransferUser[]>;

    getCases(oldUserId: string, archived: Archived): Observable<Case[]>;
}

@Component({
    selector: 'app-transfer-cases',
    templateUrl: './transfer-cases.component.html',
    styleUrls: ['./transfer-cases.component.scss'],
})
export class TransferCasesComponent extends TableDirective<CaseRow> implements OnInit, OnDestroy {

    queries: TransferQueries;
    oldUserId: string;

    selectableTargetUsers: TransferUser[];

    selectedNewUserId: string;
    selectedCaseCount = 0;

    transferRunning = false;             // are transfers currently being executed?
    transferVisible = false;             // should the transfer information be visible
    transferProgress = 0;                // progress of in-process transfers (0-100)
    transfersDone = 0;                   // how many transfers completed successfully (in current or most recent set)
    transfersFailed = 0;                 // how many transfers failed (in current or most recent set)
    failedTransfers = new Set<string>(); // case id's of transfers that failed (in current or most recent set)

    private viewArchived: Archived = Archived.exclude;
    canViewArchivedCases = false;
    subscriptions = new Subscriptions();

    constructor(@Inject(MAT_DIALOG_DATA) public dialogData: { oldUserId: string, queries: TransferQueries },
                public dialogRef: MatDialogRef<TransferCasesComponent>, private caseService: CaseService,
                private permissionService: PermissionsService) {
        super(['selected', 'name', 'status', 'shared', 'created', 'updated'], new MatTableDataSource<CaseRow>());

        this.oldUserId = dialogData.oldUserId;
        this.queries = dialogData.queries;
    }

    public ngOnInit() {
        const combined = combineLatest([
            this.permissionService.hasPermission(Permissions.listArchivedCases()),
            this.queries.getUsers()
        ]);

        this.subscriptions.add(combined, data => {
            this.canViewArchivedCases = data[0];
            this.selectableTargetUsers = data[1].filter(u => u.id !== this.oldUserId).sort(compareUsersByDescription);
            const savedArchive = localStorage.getItem(LS_KEY_ARCHIVED);
            this.viewArchived = this.canViewArchivedCases && savedArchive !== null ? Archived[savedArchive] : Archived.exclude;
            this.resetCases();
        });
        // If the user changes the sort order, reset back to the first page.
        this.subscriptions.add(this.matSort.sortChange, () => this.matPaginator.pageIndex = 0);
    }

    public ngOnDestroy() {
        this.subscriptions.cancel();
    }

    get availableCaseCount() {
        return this.dataSource.data.length;
    }

    public includeArchived(): boolean {
        return this.viewArchived === Archived.include;
    }

    showArchiveChanged(event: MatCheckboxChange): void {
        this.setArchiveState(event.checked);
        this.resetCases();
    }

    private setArchiveState(checked: boolean) {
        this.viewArchived = checked ? Archived.include : Archived.exclude;
        localStorage.setItem(LS_KEY_ARCHIVED, Archived[this.viewArchived]);
    }

    maybeArchivedClass(item: CaseRow): string {
        return item.archived == null ? '' : 'archived';
    }

    public get caseRows(): CaseRow[] {
        return [...this.dataSource.data];
    }

    public get transferColor(): ThemePalette {
        return this.transfersFailed > 0 ? 'warn' : 'primary';
    }

    onTransfer(): void {
        let successes = 0;
        let started = 0;

        this.transferRunning = true;
        this.transferVisible = true;
        this.transfersDone = 0;
        this.transfersFailed = 0;
        this.failedTransfers.clear();

        this.dataSource.data.filter(caseRow => caseRow.selected).forEach((caseRow: CaseRow) => {
            started += 1;
            this.recordTransferProgress(successes, this.failedTransfers.size, started);
            this.subscriptions.add(this.caseService.transferCase(caseRow.id, this.selectedNewUserId),
                () => {
                    successes += 1;
                    this.recordTransferProgress(successes, this.failedTransfers.size, started);
                },
                err => {
                    console.log(err);
                    this.failedTransfers.add(caseRow.id);
                    this.recordTransferProgress(successes, this.failedTransfers.size, started);
                });
        });
    }

    recordTransferProgress(successes: number, failures: number, started: number) {
        this.transferProgress = 100 * (successes + failures) / started;
        this.transfersDone = successes;
        this.transfersFailed = failures;
        if (successes + failures === started) {
            this.finishTransfers();
        }
    }

    enableTransfer(): boolean {
        return !this.transferRunning && !!this.selectedNewUserId && this.selectedCaseCount > 0;
    }

    userDescription(user: TransferUser): string {
        return userDescription(user);
    }

    toggle(caseRow: CaseRow): void {
        caseRow.selected = !caseRow.selected;
        this.selectedCaseCount += (caseRow.selected ? 1 : -1);
        this.transferVisible = false;
    }

    finishTransfers() {
        this.transferRunning = false;
        if (this.transfersFailed === 0) {
            setTimeout(() => this.transferVisible = false, 1500);
        }
        this.resetCases();
    }

    resetCases(): void {
        this.subscriptions.add(this.queries.getCases(this.oldUserId, this.viewArchived), cases => {
            this.setCases(cases);
        });
        this.failedTransfers.clear();
    }

    setCases(cases: Case[]) {
        this.selectedCaseCount = 0;
        this.dataSource.data = cases.map(c => {
            return {
                id: c.id,
                name: c.name,
                created: c.created,
                updated: c.updated,
                archived: c.archived,
                selected: this.failedTransfers.has(c.id),  // pre-select cases that failed in previous run
                status: this.caseService.calculateCaseStatus(c),
                shared: !!c.group_id
            };
        });
    }

    describeCaseStatus(status: CaseStatus): string {
        return this.caseService.describeCaseStatus(status);
    }

    selectAll(selected: boolean) {
        this.dataSource.data.map(caseRow => caseRow.selected = selected);
        this.selectedCaseCount = selected ? this.availableCaseCount : 0;
        this.transferVisible = false;
    }
}
