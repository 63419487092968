import { Injectable } from '@angular/core';

export const HelpDocument = {
    IFU: 'ifu',  // Instructions for Use
    TOS: 'tos',  // Terms of Service
    UPN: 'upn',  // User Privacy Notice
    PPN: 'ppn'  // EU Patient Privacy Notice (template)
};

interface HelpDocumentItem {
    id: string;
    contentType?: string;
    msWordDownloadFilenameKey?: string;
}

@Injectable()
export class HelpDocumentService {

    private items: Array<HelpDocumentItem> = [
        {
            id: HelpDocument.PPN,
            msWordDownloadFilenameKey: 'msWordEuPatientPrivacyNotice'
        }
    ];

    public getMsWordDownloadFilenameKey?(documentId: string): string {
        const item = this.items.find(helpDoc => helpDoc.id === documentId);
        return item && item.msWordDownloadFilenameKey || null;
    }

    public getContentType(documentId: string): string {
        const item = this.items.find(helpDoc => helpDoc.id === documentId);
        return item?.contentType ?? 'application/pdf';
    }
}
