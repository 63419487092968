export class ElectrodeModel {
    constructor(public value: string, public modelDescKey: string, public modelShortDescKey: string) {
    }

    get payload(): Array<string> | null {
        return [this.value];
    }
}

export enum ElectrodeModels {
    // Use ElectrodeModelService.nameToElectrodeModel(name) to convert names to ElectrodeModels
    MDT_B33005 = 'MDT_B33005',
    MDT_B33015 = 'MDT_B33015',
    MDT_3387 = 'MDT_3387',
    MDT_3389 = 'MDT_3389',
    BSC_2201 = 'BSci',
    BSC_2202 = 'BSC_2202',
    SJM_6172 = 'SJM_6172',
    SJM_6173 = 'SJM_6173'
}

export class ElectrodeData {
    private readonly eModel: ElectrodeModel;

    constructor(public value: ElectrodeModels, public modelKey: string, public shortModelKey: string,
                public resFolder: string, public resources: Array<string>, private labels: Map<number, string>,
                public canRoll: boolean, public cleared: boolean = true) {
        this.eModel = new ElectrodeModel(this.value, this.modelKey, this.shortModelKey);
    }

    public get model(): ElectrodeModel {
        return this.eModel;
    }

    public getLabel(contact: number): string {
        return this.labels.get(contact);
    }

    getContact(label: string): number {
        return (Array.from(this.labels.entries()).find(entry => entry[1] === label))[0];
    }
}

export class NoElectrode extends ElectrodeModel {
    public static NO_SELECTION = 'no-electrode';
    public static NO_SELECTION_KEY = 'caseElectrodeNone';

    constructor() {
        super(NoElectrode.NO_SELECTION, NoElectrode.NO_SELECTION_KEY, NoElectrode.NO_SELECTION_KEY);
    }

    get payload(): Array<string> | null {
        return null;
    }
}

export class AutoDetect extends ElectrodeModel {
    public static AUTO_DETECT = 'auto-detect';
    public static AUTO_DETECT_KEY = 'caseElectrodeAutoDetect';

    constructor() {
        super(AutoDetect.AUTO_DETECT, AutoDetect.AUTO_DETECT_KEY, AutoDetect.AUTO_DETECT_KEY);
    }

    get payload(): Array<string> | null {
        return [];
    }
}

export const MDT_B33005 = new ElectrodeData(ElectrodeModels.MDT_B33005, 'MDT_B33005', 'S_MDT_B33005', 'MDT_B33005',
    ['contact_0.stl', 'contact_1.stl', 'contact_2.stl', 'contact_3.stl', 'contact_4.stl', 'contact_5.stl', 'contact_6.stl', 'contact_7.stl', 'marker.stl', 'shaft.stl'],
    new Map<number, string>([[0, '0'], [1, '1a'], [2, '1b'], [3, '1c'], [4, '2a'], [5, '2b'], [6, '2c'], [7, '3']]),
    true
);

export const MDT_B33015 = new ElectrodeData(ElectrodeModels.MDT_B33015, 'MDT_B33015', 'S_MDT_B33015', 'MDT_B33015',
    ['contact_0.stl', 'contact_1.stl', 'contact_2.stl', 'contact_3.stl', 'contact_4.stl', 'contact_5.stl', 'contact_6.stl', 'contact_7.stl', 'marker.stl', 'shaft.stl'],
    new Map<number, string>([[0, '0'], [1, '1a'], [2, '1b'], [3, '1c'], [4, '2a'], [5, '2b'], [6, '2c'], [7, '3']]),
    true
);

export const MDT_3389 = new ElectrodeData(ElectrodeModels.MDT_3389, 'MDT_3389', 'S_MDT_3389', 'MDT_3389', [
        'contact_0.stl', 'contact_1.stl', 'contact_2.stl', 'contact_3.stl', 'shaft.stl', 'tip.stl'],
    new Map<number, string>([[0, '0'], [1, '1'], [2, '2'], [3, '3']]),
    false
);

export const MDT_3387 = new ElectrodeData(ElectrodeModels.MDT_3387, 'MDT_3387', 'S_MDT_3387', 'MDT_3387', [
        'contact_0.stl', 'contact_1.stl', 'contact_2.stl', 'contact_3.stl', 'shaft.stl', 'tip.stl'],
    new Map<number, string>([[0, '0'], [1, '1'], [2, '2'], [3, '3']]),
    false
);

export const BSC_2201 = new ElectrodeData(ElectrodeModels.BSC_2201, 'BSC_2201', 'S_BSC_2201', 'BSC_2201',
    ['contact_0.stl', 'contact_1.stl', 'contact_2.stl', 'contact_3.stl', 'contact_4.stl', 'contact_5.stl', 'contact_6.stl', 'contact_7.stl', 'shaft.stl', 'tip.stl'],
    new Map<number, string>([[0, '1'], [1, '2'], [2, '3'], [3, '4'], [4, '5'], [5, '6'], [6, '7'], [7, '8']]),
    false
);

export const BSC_2202 = new ElectrodeData(ElectrodeModels.BSC_2202, 'BSC_2202', 'S_BSC_2202', 'BSC_2202',
    ['contact_0.stl', 'contact_1.stl', 'contact_2.stl', 'contact_3.stl', 'contact_4.stl', 'contact_5.stl', 'contact_6.stl', 'contact_7.stl', 'marker.stl', 'shaft.stl'],
    new Map<number, string>([[0, '1'], [1, '2'], [2, '3'], [3, '4'], [4, '5'], [5, '6'], [6, '7'], [7, '8']]),
    true
);

export const SJM_6172 = new ElectrodeData(ElectrodeModels.SJM_6172, 'SJM_6172', 'S_SJM_6172', 'SJM_6172',
    ['contact_0.stl', 'contact_1.stl', 'contact_2.stl', 'contact_3.stl', 'contact_4.stl', 'contact_5.stl', 'contact_6.stl', 'contact_7.stl', 'marker.stl', 'shaft.stl', 'tip.stl'],
    new Map<number, string>([[0, '1'], [1, '2a'], [2, '2b'], [3, '2c'], [4, '3a'], [5, '3b'], [6, '3c'], [7, '4']]),
    true
);

export const SJM_6173 = new ElectrodeData(ElectrodeModels.SJM_6173, 'SJM_6173', 'S_SJM_6173', 'SJM_6173',
    ['contact_0.stl', 'contact_1.stl', 'contact_2.stl', 'contact_3.stl', 'contact_4.stl', 'contact_5.stl', 'contact_6.stl', 'contact_7.stl', 'marker.stl', 'shaft.stl', 'tip.stl'],
    new Map<number, string>([[0, '1'], [1, '2a'], [2, '2b'], [3, '2c'], [4, '3a'], [5, '3b'], [6, '3c'], [7, '4']]),
    true, false
);
