<div class="video-container">
    <div class="video-object" *ngIf="videoReady">
        <vg-player>
            <video #media [vgMedia]="$any(media)" preload="auto" autoplay controls>
                <source [src]="videoUrl" [type]="'video/mp4'">
            </video>
        </vg-player>
    </div>
    <span *ngIf="errorMessage" class="error-message mat-typography">{{ 'helpVideoErrorMessage'|translate }}</span>
</div>

