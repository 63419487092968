import { Component } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-help-no-document',
    standalone: true,
    imports: [
        TranslatePipe
    ],
    styleUrls: ['./help-document.component.scss'],
    template: '<p class="mat-typography help-doc-not-available">{{"helpDocumentNotAvailable"|translate}}</p>',
})
export class NoHelpDocumentComponent {

}
