<mat-toolbar class="mat-toolbar" color="primary">
    <mat-toolbar-row>
        <button mat-button routerLink="/home">Surgical Information Sciences</button>
        @for (navItem of navItems; track navItem.id) {
            <button mat-button
                    (click)="onNavigate(navItem, $event.metaKey)" [color]="getColor(navItem)"
                    [attr.data-testid]="navItem.titleKey">
                {{ navItem.titleKey|translate }}
            </button>
        }
        <app-search-bar *ngIf="displaySearchBar" class="main-nav-search"></app-search-bar>
        <span class="fill-remaining-space"></span>
        <app-notifications *ngIf="displayNotifications" class="main-nav-notification"></app-notifications>
        <app-select-language color="white"/>
        <span>
            <button mat-button [matMenuTriggerFor]="menu" data-testid="main-nav-user-menu">{{ currentUser }}</button>
            <mat-menu #menu="matMenu" data-testid="main-nav-user-menu-items">
              <button mat-menu-item (click)="onEditProfile($event.metaKey)"
                      i18n="@@mainNavUserEditProfile" data-testid="main-nav-user-profile">
                <div class="main-nav-user-menu-item">{{ 'mainNavUserEditProfile'|translate }}</div>
              </button>
              <button mat-menu-item (click)="onChangePassword()"
                      i18n="@@mainNavUserChangePassword" data-testid="main-nav-user-change-pw">
                <div class="main-nav-user-menu-item">{{ 'mainNavUserChangePassword'|translate }}</div>
              </button>
              <button mat-menu-item (click)="onLogout()"
                      i18n="@@mainNavUserLogout" data-testid="main-nav-user-logout">
                  <div class="main-nav-user-menu-item">{{ 'logout'|translate }}</div>
              </button>
            </mat-menu>
        </span>
        <button mat-button (click)="onNavigate(HELP, $event.metaKey)" [color]="getColor(HELP)" i18n="@@mainNavAbout"
                data-testid="main-nav-about">
            {{ HELP.titleKey|translate }}
        </button>
    </mat-toolbar-row>
</mat-toolbar>
