<div class="electrode-overview-container" [@showElectrodePanel]="displayElectrodePanel">
    <mat-card appearance="outlined" class="electrode-overview">
        <mat-card-title class="title-item mat-typography" i18n="@@caseLeadLocationReportTitle">
            {{ 'caseLeadLocationReportTitle'|translate }}
        </mat-card-title>
        <mat-card-content>
            <mat-form-field class="width-90-p">
                <mat-label i18n="@@caseLeadLocationReportSelectElectrode">
                    {{ 'caseLeadLocationReportSelectElectrode'|translate }}
                </mat-label>
                <mat-select [(value)]="selectedElectrode" (selectionChange)="onElectrodeSelected()"
                            [disabled]="!electrodesReport.electrodes.length">
                    @for (model of getElectrodes(); track model) {
                        <mat-option [value]="model">{{ getModelDescription(model)|async }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <div [style.visibility]="displayRotation() ? 'visible' : 'hidden'" class="electrode-rotation">
                <span class="mat-typography font-size-14"
                      i18n="@@caseLeadLocationReportElectrodeRotationHeader">
                    {{ 'caseLeadLocationReportElectrodeRotationHeader'|translate }}&nbsp;:&nbsp;<b>{{ cwRollValue.toFixed(0) }}&#176;</b>
                </span>
                <div>
                    <mat-slider class="width-80-p"
                                color="accent"
                                [disabled]="!selectedElectrode"
                                min="-180" max="180" step="30">
                        <input matSliderThumb value="{{cwRollValue}}"
                               (input)="onRollChanged(ngSliderThumb.value)"
							   #ngSliderThumb="matSliderThumb"/>
                    </mat-slider>
                </div>
                <div>
                    <mat-form-field class="width-90-p">
                        <mat-label i18n="@@caseLeadLocationReportSuggestedRotations">
                            {{ 'caseLeadLocationReportSuggestedRotations'|translate }}
                        </mat-label>
                        <mat-select [disabled]="!selectedElectrode || getRollCandidates(selectedElectrode).length === 0"
                                    [(value)]="rollCandidate" panelWidth="auto"
                                    (selectionChange)="onRollCandidateChange()">
                            @for (candidate of getRollCandidates(selectedElectrode); track candidate.roll) {
                                <mat-option
                                        [value]="candidate">
                                    {{ ccw2cw(getRoll(candidate)).toFixed(0) }}&#176;&nbsp;(&#177;30&#176;)
                                </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>


