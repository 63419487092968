<mat-card>
    <mat-card-title i18n="@@settingsUsersInviteNewUserTitle"></mat-card-title>
    <mat-card-content class="invite-user-content-container">
        <form [formGroup]="mainForm" novalidate>
            <mat-form-field class="width-80-p">
                <mat-label>{{ 'settingsUsersInviteUserEmail'|translate }}</mat-label>
                <input matInput type="email" [formControl]="email">
            </mat-form-field>
            @if (candidateAccounts.length > 1) {
                <mat-form-field class="width-80-p m-b-40">
                    <mat-label>{{ 'settingsUsersInviteAccount'|translate }}</mat-label>
                    <input matInput type="text" [formControl]="account" [matAutocomplete]="auto">
                    <mat-autocomplete
						#auto="matAutocomplete"
                        [displayWith]="displayAccount">
                        @for (ca of filteredAccounts | async; track ca.accountId) {
                            <mat-option [value]="ca">{{ ca.name }}</mat-option>
                        }
                    </mat-autocomplete>
                </mat-form-field>
            }
            <br>
            <button (click)="inviteUser()" [disabled]="submitDisabled()"
                    color="primary"
                    i18n="@@settingsUsersInviteUserButtonInvite"
                    mat-raised-button
                    class="m-r-40">
                {{ 'settingsUsersInviteUserButtonInvite'|translate }}
            </button>
            <button (click)="closeDialog()"
                    [disabled]="inProgress"
                    i18n="@@settingsUsersInviteUserButtonClose"
                    mat-raised-button>
                {{ 'close'|translate }}
            </button>
        </form>
        <div class="invite-user-notification">
            @if (success) {
                <p
                        i18n="@@settingsUsersInviteUserMessageSuccess"
                        class="invite-user-success-message mat-typography">
                    {{ 'settingsUsersInviteUserMessageSuccess'|translate }}!
                </p>
            }
            @if (error) {
                <p
                        i18n="@@settingsUsersInviteUserMessageError"
                        class="invite-user-error-message mat-typography">
                    {{ 'settingsUsersInviteUserMessageError'|translate }}
                </p>
            }
        </div>
    </mat-card-content>
</mat-card>

