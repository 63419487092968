import { Injectable } from '@angular/core';
import { ApiService, Notification } from '../services/api.service';
import { interval, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { catchError, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    public static shownAlerts = 'shownAlerts';

    constructor(private api: ApiService, private translate: TranslateService) {
        // we must create an object since when we log out
        // if we do not have an object we will save null, which is not good.
        localStorage.setItem(NotificationsService.shownAlerts, JSON.stringify(this.shownIds()));
    }

    public queryAlerts(): Observable<Array<Notification>> {
        // everytime the language change ...
        return this.translate.onLangChange.pipe(
            // start with the current language
            startWith({lang: this.translate.currentLang, translations: null}),
            // get the language event
            map((e: LangChangeEvent) => e.lang),
            // and if the language changed
            distinctUntilChanged(),
            // set a new timer that will fire every 5 minutes
            switchMap(language => interval(5 * 60 * 1000).pipe(startWith(-1), map((_n: number) => language))),
            // and query the system alerts in the current language
            switchMap(language => this.api.queryAlerts(language).pipe(map(nr => nr.alerts), catchError(() => of([]))))
        );
    }

    public getUnread(alerts: Array<Notification>): Array<Notification> {
        const shownIds = this.shownIds();
        return alerts.filter(alert => !shownIds.includes(alert.id));
    }

    public dismiss(alert: Notification): void {
        const shownIds = this.shownIds();
        if (!shownIds.includes(alert.id)) {
            shownIds.push(alert.id);
        }
        localStorage.setItem(NotificationsService.shownAlerts, JSON.stringify(shownIds));
    }

    private shownIds(): Array<string> {
        const savedIds = localStorage.getItem(NotificationsService.shownAlerts) ?? '[]';
        return JSON.parse(savedIds);
    }

    isRead(n: Notification): boolean {
        return this.shownIds().includes(n.id);
    }
}

/**
 * compare 2 Notifications by the end date.
 * @param a - a Notification object
 * @param b - a Notification object
 * return -1 of a.end_time > b.end_time, 0 if equal or both null, 1 if b.end_time > a.end_time
 */
export function reverseCompareNotificationsByStartDate(a: Notification, b: Notification): number {
    if (!a.start_t && !b.start_t) {
        return 0;
    }
    if (a.start_t && !b.start_t) {
        return -1;
    }
    if (!a.start_t && b.start_t) {
        return 1;
    }
    const da = a.start_t.toLocaleLowerCase();
    const db = b.start_t.toLocaleLowerCase();
    return db.localeCompare(da);
}
