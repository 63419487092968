import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Case, CaseService } from '../../../case.service';
import { CaseResponse } from '../../../../services/api.service';
import { FormControl, FormGroup } from '@angular/forms';
import { PostopErrorCodes } from '../../../case.errors';
import { Flows } from '../../../case.constants';
import { FlowReviewDirective } from '../flow-review.directive';

export interface PostopReviewForm {
    exit_code: FormControl<number>;
}

@Component({
    selector: 'app-case-postop-review',
    templateUrl: './postop-review.component.html',
    styleUrls: ['./postop-review.component.scss']
})
export class PostopReviewComponent extends FlowReviewDirective implements OnInit {

    @Input() caseUpdated: Subject<Case>;
    public flowOptions: FormGroup<PostopReviewForm>;

    constructor(caseService: CaseService) {
        super(caseService, PostopErrorCodes);
    }

    ngOnInit(): void {
        this.flowOptions = new FormGroup<PostopReviewForm>({
            exit_code: this.exitCode
        });
        super.ngOnInit();
    }

    updateFlowData(data: CaseResponse) {
        this.caseData = data;
        this.latestFlow = this.caseService.latestFlow(this.caseData, [Flows.BG_POST_OP]);
        if (this.latestFlow) {
            this.exitCode.setValue(this.latestFlow.exit_code);
            this.flowReady ? this.exitCode.enable() : this.exitCode.disable();
            this.flowOptions.markAsPristine();
        }
    }

    public applyChanges(): void {
        this.updateFlow(this.flowOptions.getRawValue());
    }

    public onSelectionChange(): void {
        if (this.flowReady && this.exitCode.value === this.latestFlow.exit_code) {
            this.flowOptions.markAsPristine();
        }
    }
}
