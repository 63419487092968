import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService, reverseCompareNotificationsByStartDate } from '../notifications.service';
import { Notification } from '../../services/api.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotificationComponent } from '../notification/notification.component';
import { Subscriptions } from '../../tools/subscriptions.class';
import { MaterialModule } from '../../material.module';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-notifications',
    standalone: true,
    imports: [
        CommonModule,
        MaterialModule,
    ],
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

    public notifications: Array<Notification> = [];

    private subscriptions = new Subscriptions();

    constructor(private service: NotificationsService, private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.subscriptions.limit(this.service.queryAlerts()).subscribe(
            (notifications: Array<Notification>) => {
                if (notifications.length > 0) {
                    this.notifications = notifications.sort(
                        (a: Notification, b: Notification) => reverseCompareNotificationsByStartDate(a, b)
                    );
                    this.popupIfNeeded();
                }
            }
        );
    }

    ngOnDestroy() {
        this.subscriptions.cancel();
    }

    private popupIfNeeded(): void {
        this.notifications.forEach(n => {
            if (n.popup && !this.service.isRead(n)) {
                this.showNotification(n);
            }
        });
    }

    get icon(): string {
        return this.unread ? 'notifications' : 'notifications_none';
    }

    get unread(): boolean {
        return this.service.getUnread(this.notifications).length > 0;
    }

    public nClass(n: Notification): string {
        return this.service.isRead(n) ? '' : 'unread';
    }

    public showNotification(n: Notification) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.data = n;
        this.dialog.open(NotificationComponent, dialogConfig);
    }
}
