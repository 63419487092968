import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Elements } from '../case.constants';
import { Subscriptions } from '../../tools/subscriptions.class';
import { DataElement } from '../data-element.class';
import { UploadReport } from '../../services/api.service';
import { Case, CaseService } from '../case.service';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { TableDirective } from '../../tools/table.directive';
import { RegistrationMethod } from '../registration-method.class';

import { MaterialModule } from '../../material.module';
import { TranslatePipe } from '@ngx-translate/core';
import { ResultsReviewComponent } from './results-review/results-review.component';
import { SegmentationReviewComponent } from './flow-review/segmentation-review/segmentation-review.component';
import { PostopReviewComponent } from './flow-review/postop-review/postop-review.component';
import { AddActivityMessageComponent } from './add-activity-message/add-activity-message.component';
import { ChooseRegistrationMethodComponent } from './choose-registration-method/choose-registration-method.component';
import { ReRunFlowComponent } from './re-run-flow/re-run-flow.component';
import { UploadTransformComponent } from '../upload/upload-transform.component';
import { LocalDatePipe } from '../../tools/local-date.pipe';

export interface AuditLogRow {
    id: string;
    created: string;
    case_id?: string;
    message: string;
}

@Component({
    selector: 'app-case-admin',
    standalone: true,
    imports: [
        LocalDatePipe,
        MaterialModule,
        TranslatePipe,
        AddActivityMessageComponent,
        ChooseRegistrationMethodComponent,
        PostopReviewComponent,
        ReRunFlowComponent,
        ResultsReviewComponent,
        SegmentationReviewComponent,
        UploadTransformComponent,
    ],
    templateUrl: './case-admin.component.html',
    styleUrls: ['./case-admin.component.scss']
})
export class CaseAdminComponent extends TableDirective<AuditLogRow> implements OnInit, AfterViewInit, OnDestroy {

    @Input() caseUpdated: Subject<Case>;

    caseData: Case;

    @Input() allowedUploadPlanningPostopTransform: boolean;
    @Input() allowedLogCaseEvent: boolean;
    @Input() allowedReRunFlow: boolean;
    @Input() allowedChoosePlanningToPostopRegistrationMethod: boolean;
    @Input() allowedAuditQuery: boolean;
    @Input() allowedCaseReview: boolean;

    private subscriptions = new Subscriptions();
    elements = Elements;

    caseId = '';
    availablePlanningToPostopRegistrationMethods: Array<RegistrationMethod> = [];

    constructor(private caseService: CaseService) {
        super(['date', 'message'], new MatTableDataSource<AuditLogRow>());
    }

    ngOnInit() {
        this.subscriptions.add(this.caseUpdated, data => {
            this.caseData = data;
            this.dataSource.data = data.audit_events ? data.audit_events : [];
        });
        this.subscriptions.add(this.caseService.listAvailablePlanningToPostopRegistrationMethods(), data => {
            this.availablePlanningToPostopRegistrationMethods = data;
        });
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
    }

    uploadCount(element: DataElement): number {
        const latestElement = this.caseService.latestElement(this.caseData, element);
        return DataElement.urlCount(latestElement);
    }

    uploadReport(element: DataElement): UploadReport | null {
        const latestElement = this.caseService.latestElement(this.caseData, element);
        return latestElement?.upload_report || null;
    }
}
