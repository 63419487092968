<h2 mat-dialog-title class="mat-typography">{{ 'caseDeleteCaseTitle' | translate }}</h2>
<form>
    <mat-dialog-content>
        <p class="mat-typography">{{ 'caseDeleteCaseContent' | translate }}</p>
        <p>
            @if (context.allowArchive && context.allowDelete) {
                <mat-checkbox
                        name="preliminaryConfirmation"
                        [(ngModel)]="deleteEntirely">
                    {{ 'caseDeleteConfirmEntirely' | translate }}
                </mat-checkbox>
            }
        </p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button
                data-testid="delete-case-cancel"
                (click)="closeDialog(false)">{{ 'cancel' | translate }}
        </button>
        <button mat-raised-button
                data-testid="delete-case-submit"
                (click)="closeDialog(true)">{{ 'delete' | translate }}
        </button>
    </mat-dialog-actions>
</form>
