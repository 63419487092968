export class UserPermission {
    constructor(public readonly name: string, public readonly key: string, public readonly sortOrder: number) {
    }
}

export const UserPermissions = {
    ACCOUNT_ADMIN: new UserPermission('ACCOUNT_ADMIN', 'settingsUsersProfilePermissionAccountAdmin', 0),
};

export const AllUserPermissions: UserPermission[] =
    Object.values(UserPermissions).sort((a: UserPermission, b: UserPermission) => a.sortOrder - b.sortOrder);
