<div [@showStlView]="stlEnabled" [style.height.px]="height" [style.width.px]="width" class="stl-wrapper"
     id="stl-view">
    <!-- TOP CROSS-HAIR --->
    <div class="bg-ui bg-btn-top text-xs-center">
        <button mat-icon-button (click)="resetCameraPosition()">
            <mat-icon>home</mat-icon>
        </button>
    </div>

    <!-- SIDE UP DOWN ARROWS -->
    <div class="bg-ui bg-btn-right">
        <div>
            <button (mousedown)="rotateUp(true)"
                    (mouseup)="rotateUp(false)"
                    mat-icon-button>
                <mat-icon>expand_less</mat-icon>
            </button>
        </div>
        <div>
            <button (mousedown)="rotateDown(true)"
                    (mouseup)="rotateDown(false)"
                    mat-icon-button>
                <mat-icon>expand_more</mat-icon>
            </button>
        </div>
    </div>

    <!-- BOTTOM LEFT RIGHT ARROWS -->
    <div class="bg-ui bg-btn-bottom">
        <div>
            <button (mousedown)="rotateLeft(true)"
                    (mouseup)="rotateLeft(false)"
                    mat-icon-button>
                <mat-icon>chevron_left</mat-icon>
            </button>
            <button (mousedown)="rotateRight(true)"
                    (mouseup)="rotateRight(false)"
                    mat-icon-button>
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>
    </div>

    <!-- MAG GLASSES -->
    <div class="bg-ui bg-btn-left">
        <div>
            <button (mousedown)="zoomIn(true)"
                    (mouseup)="zoomIn(false)"
                    mat-icon-button>
                <mat-icon>zoom_in</mat-icon>
            </button>
        </div>
        <div>
            <button (mousedown)="zoomOut(true)"
                    (mouseup)="zoomOut(false)"
                    mat-icon-button>
                <mat-icon>zoom_out</mat-icon>
            </button>
        </div>
    </div>

    <div class="bg-ui bg-axis lighten-5 axes-view"></div>
</div>
