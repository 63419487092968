import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';

import {
    AlphaImage,
    DbsTarget,
    Targets, AlphaImages,
} from '../case.constants';
import { Observable, Subject } from 'rxjs';
import { DicomViewerService } from './dicom-viewer.service';
import { Subscriptions } from '../../tools/subscriptions.class';
import { Case } from '../case.service';
import { skip } from 'rxjs/operators';

declare const papaya: any;

enum AlphaState {
    Idle, Download, Ready, PapayaLoading, Active
}

interface AlphaItem {
    alpha: AlphaImage;
    state: AlphaState;
    approved: boolean;
    images: Array<any>;
}

@Component({
    selector: 'app-case-dicom',
    templateUrl: './dicom-viewer.component.html',
    styleUrls: ['./dicom-viewer.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DicomViewerComponent implements OnInit, OnDestroy, AfterViewInit {

    // Add items in the order they are listed in ../case.constants.AlphaImage.position
    private alphaStates: Map<string, AlphaItem> = new Map<Targets, AlphaItem>(AlphaImages.map(a => [
            a.target.key, {alpha: a, state: AlphaState.Idle, approved: false, images: []}
        ])
    );

    @Input() caseData: Case;
    @Input() caseUpdated: Subject<Case>;
    @Input() approvedTargets: Array<DbsTarget>;

    // provided by the parent to indicate when this component is the active component (true)
    @Input() onActivate: Observable<boolean>;
    @Output() loadingDicom: EventEmitter<boolean> = new EventEmitter<boolean>();

    public activeItem: AlphaItem = this.alphaStates.get(Targets.STN);

    private destroyed$: Subject<boolean> = new Subject<boolean>();
    private timers = [];

    // Papaya generates console errors if a load starts for a viewer but does not finish loading before resetViewer()
    // is called. So we'll defer calling resetViewer() until after the load completes.
    private subscriptions = new Subscriptions();

    constructor(private dicomService: DicomViewerService) {
    }

    public availableItems(): Array<AlphaItem> {
        return Array.from(this.alphaStates.values()).filter(item => this.showDownloadAlpha(item));
    }

    public showDownloadButtons(): boolean {
        return this.caseData.target === Targets.ALL;
    }

    /***
     * Checks if the case has an AlphaElement of this type and the case targets include this alpha
     * @param alpha - one of the AvailableAlphas options
     * return - true if the alpha exist and approved and should be displayed based on the case target, else false.
     */
    public showDownloadAlpha(alpha: AlphaItem): boolean {
        return this.hasAlpha(alpha) && this.approvedTargets.includes(alpha.alpha.target);
    }

    private hasAlpha(alpha: AlphaItem) {
        return this.dicomService.hasAlphaElement(this.caseData, alpha.alpha.elementId);
    }

    /***
     * After the first activation of this component, unsubscribe as the component is initialized
     * and actions are done by the user.
     */
    ngOnInit() {
        this.alphaStates.forEach(item => item.approved = this.approvedTargets.includes(item.alpha.target));
        this.subscriptions.add(this.onActivate, (activated: boolean) => {
            if (activated) {
                this.subscriptions.cancel();
                this.setActiveAlpha(this.caseData.target);
                this.moveState(this.activeItem);
                this.subscriptions.add(this.caseUpdated.pipe(skip(1)), (data: Case) => {
                    this.alphaStates.forEach((item: AlphaItem) => item.approved = this.approvedTargets.includes(item.alpha.target));
                    if (data.target !== this.caseData.target) {
                        this.caseData.target = data.target;
                        if (data.target === Targets.ALL) {
                            this.activateAlpha(this.activeItem);
                        }
                        else {
                            this.activateAlpha(this.alphaStates.get(data.target));
                        }
                    }
                });
            }
        });
    }

    private setActiveAlpha(caseTarget: string) {
        // when we switch to ALL, we keep the current active item.
        this.activeItem = this.alphaStates.get(caseTarget) ?? this.activeItem;
    }

    ngAfterViewInit() {
        try {
            papaya.Container.startPapaya();
        } catch (error) {
            console.log(error);
        }
    }

    ngOnDestroy() {
        this.subscriptions.cancel();

        this.timers.forEach(timer => clearTimeout(timer));
        this.destroyed$.next(true);
        this.destroyed$.complete();
        // For any containers that have already finished loading, reset them immediately.
        if ((window as any).papayaContainers.length === 1) {
            (window as any).papaya.Container.resetViewer(0, {});
            // Clear the list of containers that papaya knows about entirely. WARNING: This assumes that this component
            // is the only thing using papaya!
            (window as any).papayaContainers = [];
        }
    }

    /***
     * If none of the images are in stable state (Active) disable the switch alpha buttons
     * Return true if none of the items is in stable mode, else false
     */
    public disableButtons(): boolean {
        return Array.from(this.alphaStates.values()).every(item => item.state !== AlphaState.Active);
    }

    /***
     * If there is an alphaItem that is being downloaded, show the PB (return true)
     * else return false (do not show the PB)
     */
    public showProgressBar(): boolean {
        return Array.from(this.alphaStates.values()).some(state => state.state === AlphaState.Download);
    }

    /***
     * show the main container of papaya if any of the items was downloaded successfully
     * return true if any of the items finished download
     */
    public showContainer(): boolean {
        return Array.from(this.alphaStates.values()).some(state => state.state >= AlphaState.Ready);
    }

    /***
     * Called when the user clicks any of the available alpha items buttons
     * Move the state of the active alpha to its next position,
     * switch to the new alpha item and trigger the state change
     * @param alphaItem from alphaStates
     */
    public activateAlpha(alphaItem: AlphaItem) {
        // When the user makes a request to view an Alpha image
        this.moveState(this.activeItem);
        this.activeItem = alphaItem;
        this.moveState(this.activeItem);
    }

    /***
     * State of the alpha images: Idle → Download → Ready → PapayaLoading → Active
     *                                                ↑                       ↓
     *                                                ← ← ← ← ← ← ← ← ← ← ← ← ←
     *
     * @param item - the alpha item that will switch state
     * @private
     */
    private moveState(item: AlphaItem) {
        switch (item.state) {
            case AlphaState.Idle:
                if (item.approved) {
                    this.loadDicomSeries(item);
                    this.loadingDicom.emit(true);
                    item.state = AlphaState.Download;
                }
                else {
                    // if the alpha is not approved, we reset the viewer and stay in the same state
                    this.clearAlphaImage();
                    item.state = AlphaState.Active;
                }
                break;
            case AlphaState.Download:
                item.state = AlphaState.Ready;
                this.moveState(item);
                break;
            case AlphaState.Ready:
                // Display the requested Alpha Image
                item.state = AlphaState.PapayaLoading;
                this.displayAlphaImage(item);
                this.loadingDicom.emit(true);
                break;
            case AlphaState.PapayaLoading:
                item.state = AlphaState.Active;
                this.loadingDicom.emit(false);
                break;
            case AlphaState.Active:
                // switch to the requested alpha image
                if (item.approved) {
                    item.state = AlphaState.Ready;
                }
                else {
                    item.state = AlphaState.Idle;
                }
                break;
        }
    }

    /***
     * load the dicom files for an alpha item. Once the download finish the item will be moved to the next state
     * @param alphaItem
     * @private
     */
    private loadDicomSeries(alphaItem: AlphaItem) {
        const ready = this.dicomService.loadDicomSeries(this.caseData, alphaItem.alpha.elementId, this.destroyed$);
        if (ready) {
            this.subscriptions.add(ready, seriesData => {
                alphaItem.images = [seriesData];
                this.moveState(alphaItem);
            });
        }
    }

    /***
     * load the item to papaya. Once papaya is done loading the images, the callback will move the state of
     * the alpha item
     * @param item
     * @private
     */
    private displayAlphaImage(item: AlphaItem) {
        this.timers.push(
            setTimeout(() => {
                const params = {
                    images: item.images,
                    loadingComplete: () => this.moveState(item),
                    noNewFiles: true,
                };
                if ((window as any).papayaContainers.length === 0) {
                    papaya.Container.addViewer('papaya1', params);
                }
                else {
                    papaya.Container.resetViewer(0, params);
                }
            }, 1)
        );
    }

    /***
     * clear the papaya view from images
     * @private
     */
    private clearAlphaImage() {
        this.timers.push(
            setTimeout(() => {
                if ((window as any).papayaContainers.length === 1) {
                    (window as any).papaya.Container.resetViewer(0, {});
                }
            }, 1)
        );
    }

}
