/**
 * Derived from three.js/examples/js/controls/TrackballControls.js at commit 22e582a
 *
 */

import * as THREE from 'three';

const STATE = {
    NONE: -1,
    ROTATE: 0,
    ZOOM: 1,
    PAN: 2,
    TOUCH_ROTATE: 3,
    TOUCH_ZOOM_PAN: 4
};

interface TrackballEvent {
    change: object;
    start: object;
    end: object;
}

export class TrackballControls extends THREE.EventDispatcher<TrackballEvent> {
    object: THREE.Camera;
    domElement: HTMLElement;

    enabled = true;
    screen = {left: 0, top: 0, width: 0, height: 0};

    rotateSpeed = 1.0;
    zoomSpeed = 1.2;
    panSpeed = 0.3;

    noRotate = false;
    noZoom = false;
    noPan = false;

    staticMoving = false;
    dynamicDampingFactor = 0.2;

    minDistance = 0;
    maxDistance = Infinity;

    keys = [65 /*A*/, 83 /*S*/, 68 /*D*/];

    target = new THREE.Vector3();

    EPS = 0.000001;
    lastPosition = new THREE.Vector3();

    target0: any;
    position0: any;
    up0: any;

    handleEvent: any;
    handleResize: any;
    update: any;
    reset: any;
    dispose: any;

    zoomOut: any;
    zoomIn: any;
    rotateUp: any;
    rotateDown: any;
    rotateLeft: any;
    rotateRight: any;

    constructor(object: THREE.Camera, domElement: HTMLElement) {
        super();

        this.object = object;
        this.domElement = domElement;

        // for reset
        this.target0 = this.target.clone();
        this.position0 = this.object.position.clone();
        this.up0 = this.object.up.clone();

        // methods
        const self = this;

        // internals

        let mState = STATE.NONE;
        let mPrevState = STATE.NONE;

        const mEye = new THREE.Vector3();

        const mMovePrev = new THREE.Vector2();
        const mMoveCurr = new THREE.Vector2();

        const mLastAxis = new THREE.Vector3();
        let mLastAngle = 0;

        const mZoomStart = new THREE.Vector2();
        const mZoomEnd = new THREE.Vector2();

        let mTouchZoomDistanceStart = 0;
        let mTouchZoomDistanceEnd = 0;

        const mPanStart = new THREE.Vector2();
        const mPanEnd = new THREE.Vector2();

        this.handleResize = function() {
            if (this.domElement === document) {
                this.screen.left = 0;
                this.screen.top = 0;
                this.screen.width = window.innerWidth;
                this.screen.height = window.innerHeight;
            }
            else {
                const box = this.domElement.getBoundingClientRect();
                // adjustments come from similar code in the jquery offset() function
                const d = this.domElement.ownerDocument.documentElement;
                this.screen.left = box.left + window.scrollX - d.clientLeft;
                this.screen.top = box.top + window.scrollY - d.clientTop;
                this.screen.width = box.width;
                this.screen.height = box.height;
            }
        };

        this.handleEvent = function(event: any) {
            if (typeof this[event.type] === 'function') {
                this[event.type](event);
            }
        };

        const getMouseOnScreen = ((() => {
            const vector = new THREE.Vector2();

            return (pageX: number, pageY: number) => {
                vector.set(
                    (pageX - self.screen.left) / self.screen.width,
                    (pageY - self.screen.top) / self.screen.height
                );
                return vector;
            };
        })());

        const getMouseOnCircle = ((() => {
            const vector = new THREE.Vector2();

            return (pageX: number, pageY: number) => {
                vector.set(
                    ((pageX - self.screen.width * 0.5 - self.screen.left) / (self.screen.width * 0.5)),
                    ((self.screen.height + 2 * (self.screen.top - pageY)) / self.screen.width) // screen.width intentional
                );
                return vector;
            };
        })());

        const rotateCamera = ((() => {
            const axis = new THREE.Vector3();
            const quaternion = new THREE.Quaternion();
            const eyeDirection = new THREE.Vector3();
            const objectUpDirection = new THREE.Vector3();
            const objectSidewaysDirection = new THREE.Vector3();
            const moveDirection = new THREE.Vector3();
            let angle: number;

            return () => {
                moveDirection.set(mMoveCurr.x - mMovePrev.x, mMoveCurr.y - mMovePrev.y, 0);
                angle = moveDirection.length();

                if (angle) {
                    mEye.copy(self.object.position).sub(self.target);

                    eyeDirection.copy(mEye).normalize();
                    objectUpDirection.copy(self.object.up).normalize();
                    objectSidewaysDirection.crossVectors(objectUpDirection, eyeDirection).normalize();

                    objectUpDirection.setLength(mMoveCurr.y - mMovePrev.y);
                    objectSidewaysDirection.setLength(mMoveCurr.x - mMovePrev.x);

                    moveDirection.copy(objectUpDirection.add(objectSidewaysDirection));

                    axis.crossVectors(moveDirection, mEye).normalize();

                    angle *= self.rotateSpeed;
                    quaternion.setFromAxisAngle(axis, angle);

                    mEye.applyQuaternion(quaternion);
                    self.object.up.applyQuaternion(quaternion);

                    mLastAxis.copy(axis);
                    mLastAngle = angle;
                }
                else if (!self.staticMoving && mLastAngle) {
                    mLastAngle *= Math.sqrt(1.0 - self.dynamicDampingFactor);
                    mEye.copy(self.object.position).sub(self.target);
                    quaternion.setFromAxisAngle(mLastAxis, mLastAngle);
                    mEye.applyQuaternion(quaternion);
                    self.object.up.applyQuaternion(quaternion);
                }

                mMovePrev.copy(mMoveCurr);
            };
        })());

        const zoomCamera = () => {
            if (mState === STATE.TOUCH_ZOOM_PAN) {
                const factor = mTouchZoomDistanceStart / mTouchZoomDistanceEnd;
                mTouchZoomDistanceStart = mTouchZoomDistanceEnd;
                mEye.multiplyScalar(factor);
            }
            else {
                const factor = 1.0 + (mZoomEnd.y - mZoomStart.y) * self.zoomSpeed;

                if (factor !== 1.0 && factor > 0.0) {
                    mEye.multiplyScalar(factor);
                }

                if (self.staticMoving) {
                    mZoomStart.copy(mZoomEnd);
                }
                else {
                    mZoomStart.y += (mZoomEnd.y - mZoomStart.y) * self.dynamicDampingFactor;
                }
            }
        };

        const panCamera = ((() => {
            const mouseChange = new THREE.Vector2();
            const objectUp = new THREE.Vector3();
            const pan = new THREE.Vector3();

            return () => {
                mouseChange.copy(mPanEnd).sub(mPanStart);

                if (mouseChange.lengthSq()) {
                    mouseChange.multiplyScalar(mEye.length() * self.panSpeed);

                    pan.copy(mEye).cross(self.object.up).setLength(mouseChange.x);
                    pan.add(objectUp.copy(self.object.up).setLength(mouseChange.y));

                    self.object.position.add(pan);
                    self.target.add(pan);

                    if (self.staticMoving) {
                        mPanStart.copy(mPanEnd);
                    }
                    else {
                        mPanStart.add(mouseChange.subVectors(mPanEnd, mPanStart).multiplyScalar(self.dynamicDampingFactor));
                    }
                }
            };
        })());

        const checkDistances = () => {
            if (!self.noZoom || !self.noPan) {
                if (mEye.lengthSq() > self.maxDistance * self.maxDistance) {
                    self.object.position.addVectors(self.target, mEye.setLength(self.maxDistance));
                    mZoomStart.copy(mZoomEnd);
                }

                if (mEye.lengthSq() < self.minDistance * self.minDistance) {
                    self.object.position.addVectors(self.target, mEye.setLength(self.minDistance));
                    mZoomStart.copy(mZoomEnd);
                }
            }
        };

        this.update = () => {
            doUpdate(() => {
                if (!self.noRotate) {
                    rotateCamera();
                }

                if (!self.noZoom) {
                    zoomCamera();
                }

                if (!self.noPan) {
                    panCamera();
                }
            });
        };

        const doUpdate = (body: any) => {
            mEye.subVectors(self.object.position, self.target);

            body();

            self.object.position.addVectors(self.target, mEye);

            checkDistances();

            self.object.lookAt(self.target);

            if (self.lastPosition.distanceToSquared(self.object.position) > self.EPS) {
                self.dispatchEvent({type: 'change'});

                self.lastPosition.copy(self.object.position);
            }
        };

        this.zoomIn = (factor: number) => {
            self.zoomOut(1.0 / factor);
        };

        this.zoomOut = (factor: number) => {
            doUpdate(() => {
                mEye.multiplyScalar(factor);
            });
        };

        const rotate = (dx: number, dy: number) => {
            doUpdate(() => {
                mMovePrev.x = mMovePrev.y = 0;
                mMoveCurr.x = dx;
                mMoveCurr.y = dy;
                rotateCamera();
            });
        };

        this.rotateDown = (factor: number = 1) => {
            rotate(0, factor);
        };

        this.rotateUp = (factor: number) => {
            rotate(0, -factor);
        };

        this.rotateLeft = (factor: number = 1) => {
            rotate(factor, 0);
        };

        this.rotateRight = (factor: number) => {
            rotate(-factor, 0);
        };

        this.reset = () => {

            mState = STATE.NONE;
            mPrevState = STATE.NONE;

            self.target.copy(self.target0);
            self.object.position.copy(self.position0);
            self.object.up.copy(self.up0);

            mEye.subVectors(self.object.position, self.target);

            self.object.lookAt(self.target);

            self.dispatchEvent({type: 'change'});

            self.lastPosition.copy(self.object.position);
        };

        // listeners

        function keydown(event: any) {

            if (self.enabled === false) {
                return;
            }

            window.removeEventListener('keydown', keydown);

            mPrevState = mState;

            if (mState !== STATE.NONE) {
                return;
            }
            else if (event.keyCode === self.keys[STATE.ROTATE] && !self.noRotate) {
                mState = STATE.ROTATE;
            }
            else if (event.keyCode === self.keys[STATE.ZOOM] && !self.noZoom) {
                mState = STATE.ZOOM;
            }
            else if (event.keyCode === self.keys[STATE.PAN] && !self.noPan) {
                mState = STATE.PAN;
            }
        }

        function keyup() {
            if (self.enabled === false) {
                return;
            }

            mState = mPrevState;

            window.addEventListener('keydown', keydown, false);
        }

        function mousedown(event: MouseEvent) {
            if (self.enabled === false) {
                return;
            }

            event.preventDefault();
            event.stopPropagation();

            if (mState === STATE.NONE) {
                mState = event.button;
            }

            if (mState === STATE.ROTATE && !self.noRotate) {
                mMoveCurr.copy(getMouseOnCircle(event.pageX, event.pageY));
                mMovePrev.copy(mMoveCurr);
            }
            else if (mState === STATE.ZOOM && !self.noZoom) {
                mZoomStart.copy(getMouseOnScreen(event.pageX, event.pageY));
                mZoomEnd.copy(mZoomStart);
            }
            else if (mState === STATE.PAN && !self.noPan) {
                mPanStart.copy(getMouseOnScreen(event.pageX, event.pageY));
                mPanEnd.copy(mPanStart);
            }

            document.addEventListener('mousemove', mousemove, false);
            document.addEventListener('mouseup', mouseup, false);

            self.dispatchEvent({type: 'start'});
        }

        function mousemove(event: MouseEvent) {
            if (self.enabled === false) {
                return;
            }

            event.preventDefault();
            event.stopPropagation();

            if (mState === STATE.ROTATE && !self.noRotate) {
                mMovePrev.copy(mMoveCurr);
                mMoveCurr.copy(getMouseOnCircle(event.pageX, event.pageY));
            }
            else if (mState === STATE.ZOOM && !self.noZoom) {
                mZoomEnd.copy(getMouseOnScreen(event.pageX, event.pageY));
            }
            else if (mState === STATE.PAN && !self.noPan) {
                mPanEnd.copy(getMouseOnScreen(event.pageX, event.pageY));
            }
        }

        function mouseup(event: MouseEvent) {
            if (self.enabled === false) {
                return;
            }

            event.preventDefault();
            event.stopPropagation();

            mState = STATE.NONE;

            document.removeEventListener('mousemove', mousemove);
            document.removeEventListener('mouseup', mouseup);
            self.dispatchEvent({type: 'end'});
        }

        function mousewheel(event: WheelEvent) {
            if (self.enabled === false) {
                return;
            }

            event.preventDefault();
            event.stopPropagation();

            switch (event.deltaMode) {
                case 2:
                    // Zoom in pages
                    mZoomStart.y -= event.deltaY * 0.025;
                    break;

                case 1:
                    // Zoom in lines
                    mZoomStart.y -= event.deltaY * 0.01;
                    break;

                default:
                    // undefined, 0, assume pixels
                    mZoomStart.y -= event.deltaY * 0.00025;
                    break;
            }

            self.dispatchEvent({type: 'start'});
            self.dispatchEvent({type: 'end'});
        }

        function touchstart(event: TouchEvent) {
            if (self.enabled === false) {
                return;
            }

            switch (event.touches.length) {
                case 1:
                    mState = STATE.TOUCH_ROTATE;
                    mMoveCurr.copy(getMouseOnCircle(event.touches[0].pageX, event.touches[0].pageY));
                    mMovePrev.copy(mMoveCurr);
                    break;

                default: { // 2 or more
                    mState = STATE.TOUCH_ZOOM_PAN;
                    const dx = event.touches[0].pageX - event.touches[1].pageX;
                    const dy = event.touches[0].pageY - event.touches[1].pageY;
                    mTouchZoomDistanceEnd = mTouchZoomDistanceStart = Math.sqrt(dx * dx + dy * dy);

                    const x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                    const y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
                    mPanStart.copy(getMouseOnScreen(x, y));
                    mPanEnd.copy(mPanStart);
                    break;
                }
            }

            self.dispatchEvent({type: 'start'});
        }

        function touchmove(event: TouchEvent) {
            if (self.enabled === false) {
                return;
            }

            event.preventDefault();
            event.stopPropagation();

            switch (event.touches.length) {
                case 1:
                    mMovePrev.copy(mMoveCurr);
                    mMoveCurr.copy(getMouseOnCircle(event.touches[0].pageX, event.touches[0].pageY));
                    break;

                default: {  // 2 or more
                    const dx = event.touches[0].pageX - event.touches[1].pageX;
                    const dy = event.touches[0].pageY - event.touches[1].pageY;
                    mTouchZoomDistanceEnd = Math.sqrt(dx * dx + dy * dy);

                    const x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                    const y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
                    mPanEnd.copy(getMouseOnScreen(x, y));
                    break;
                }
            }
        }

        function touchend(event: TouchEvent) {
            if (self.enabled === false) {
                return;
            }

            switch (event.touches.length) {
                case 0:
                    mState = STATE.NONE;
                    break;

                case 1:
                    mState = STATE.TOUCH_ROTATE;
                    mMoveCurr.copy(getMouseOnCircle(event.touches[0].pageX, event.touches[0].pageY));
                    mMovePrev.copy(mMoveCurr);
                    break;
            }

            self.dispatchEvent({type: 'end'});
        }

        function contextmenu(event: Event) {
            if (self.enabled === false) {
                return;
            }

            event.preventDefault();
        }

        this.dispose = function() {

            this.domElement.removeEventListener('contextmenu', contextmenu, false);
            this.domElement.removeEventListener('mousedown', mousedown, false);
            this.domElement.removeEventListener('wheel', mousewheel, false);

            this.domElement.removeEventListener('touchstart', touchstart, false);
            this.domElement.removeEventListener('touchend', touchend, false);
            this.domElement.removeEventListener('touchmove', touchmove, false);

            document.removeEventListener('mousemove', mousemove, false);
            document.removeEventListener('mouseup', mouseup, false);

            window.removeEventListener('keydown', keydown, false);
            window.removeEventListener('keyup', keyup, false);

        };

        this.domElement.addEventListener('contextmenu', contextmenu, false);
        this.domElement.addEventListener('mousedown', mousedown, false);
        this.domElement.addEventListener('wheel', mousewheel, false);

        this.domElement.addEventListener('touchstart', touchstart, false);
        this.domElement.addEventListener('touchend', touchend, false);
        this.domElement.addEventListener('touchmove', touchmove, false);

        window.addEventListener('keydown', keydown, false);
        window.addEventListener('keyup', keyup, false);

        this.handleResize();

        // force an update at start
        this.update();
    }

}
