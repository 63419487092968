import { ClfDicomParams, ClfDicomResult } from '../../services/api.service';
import * as THREE from 'three';
import {
    CLF_PROBABILITY_THRESHOLD,
    CT,
    ImageUse,
    MAX_IMG_ORIENTATION_DISTANCE,
    MAX_SPACING,
    MAX_THICKNESS,
    MIN_NUMBER_OF_SLICES,
    Modality,
    VALID_IMAGE_USE,
    VOXEL_SPACE_EPSILON
} from '../dicom.constants';

export type StudyId = string;
export type SeriesId = string;
export type SliceExtReasonKey = string;

export class DicomSlice {
    public static readonly EXT_REASON_KEY_BAD_ORIENTATION: SliceExtReasonKey = 'dicomDetailsExReasonBadOrientation';

    // if, for some reason, this slice is extracted from the included slices in a series, this param must be set to
    public extractionReasonKey: SliceExtReasonKey | null = null;

    constructor(public theFile: File, public instanceNumber: number, public location: number, public pos: THREE.Vector3,
                public orientation: Array<number>, public patientId: string | null, public patientName: string | null) {
    }
}

export class DicomSeries {

    private static readonly DATE_REGEX = '(?<year>[0-9]{4})(?<month>[0-9]{2})(?<day>[0-9]{2})';
    private static readonly TIME_REGEX = '(?<hour>[0-9]{2})(?<min>[0-9]{2})';
    private static readonly SPACING_THICKNESS_MISMATCH_THRESHOLD = 0.05;

    public dicomSlices: Array<DicomSlice> = new Array<DicomSlice>();
    public extractedSlices: Array<DicomSlice> = new Array<DicomSlice>();
    public imageUse: ImageUse = ImageUse.none;
    public disableDI = false;

    public readonly missingSlices: Array<number> = new Array<number>();
    public readonly seriesId: SeriesId;
    public readonly studyId: StudyId;
    private readonly seriesDesc: string;
    private readonly protocolName: string;
    private readonly echoTime: number;
    private readonly repetitionTime: number;
    private readonly inversionTime: number;
    private readonly flipAngle: number;
    private readonly mfs: number;
    private readonly ctConvolutionKernel: string[];

    public readonly modality: Modality;
    private readonly thickness: number;
    public readonly rows: number;
    public readonly columns: number;
    private readonly xySpacing: THREE.Vector2;
    private minSpacing: number | null = null;
    private maxSpacing: number | null = null;
    private clfResponse: ClfDicomResult | null = null;

    public seriesDate: Date | null = null;
    private patientIds: Set<string | null> = null;
    private patientNames: Set<string | null> = null;

    constructor(series: {
        seriesId: SeriesId,
        studyId: StudyId,
        modality: Modality,
        seriesDesc?: string,
        dateStr: string,
        timeStr: string,
        thickness: number,
        rows: number,
        columns: number,
        xySpacing: THREE.Vector2,
        protocolName?: string,
        echoTime?: number,
        repetitionTime?: number,
        inversionTime?: number,
        flipAngle?: number,
        mfs?: number,
        convKernel?: string[]
    }) {
        this.seriesId = series.seriesId;
        this.studyId = series.studyId;
        this.seriesDesc = series.seriesDesc ?? '';
        this.protocolName = series.protocolName ?? '';
        this.echoTime = series.echoTime ?? -1.0;
        this.repetitionTime = series.repetitionTime ?? -1.0;
        this.inversionTime = series.inversionTime ?? -1.0;
        this.flipAngle = series.flipAngle ?? -1.0;
        this.mfs = series.mfs ?? -1.0;
        this.ctConvolutionKernel = series.convKernel;

        this.modality = series.modality;
        this.thickness = series.thickness;
        this.xySpacing = series.xySpacing ?? new THREE.Vector2(0, 0);
        this.rows = series.rows;
        this.columns = series.columns;

        this.setSeriesDateAndTime(series.dateStr, series.timeStr);
    }

    private setSeriesDateAndTime(dateStr: string, timeStr: string) {
        let year = -1;
        let month = -1;
        let day = -1;
        if (dateStr) {
            const dateMatch = new RegExp(DicomSeries.DATE_REGEX).exec(dateStr);
            if (dateMatch && dateMatch.groups) {
                year = dateMatch.groups.year ? Number(dateMatch.groups.year) : -1;
                month = dateMatch.groups.month ? Number(dateMatch.groups.month) : -1;
                day = dateMatch.groups.day ? Number(dateMatch.groups.day) : -1;
            }
        }
        let hour = -1;
        let min = -1;
        if (timeStr) {
            const timeMatch = new RegExp(DicomSeries.TIME_REGEX).exec(timeStr);
            if (timeMatch && timeMatch.groups) {
                hour = timeMatch.groups.hour ? Number(timeMatch.groups.hour) : -1;
                min = timeMatch.groups.min ? Number(timeMatch.groups.min) : -1;
            }
        }
        if (year !== -1 && month !== -1 && day !== -1) {
            this.seriesDate = new Date(year, month - 1, day);
            if (hour !== -1 && min !== -1) {
                this.seriesDate.setHours(hour, min, 0, 0);
            }
        }
    }

    get id(): SeriesId {
        return this.seriesId;
    }

    get nSlices(): number {
        return this.dicomSlices.length;
    }

    public checkSlices(): boolean {
        return this.nSlices >= MIN_NUMBER_OF_SLICES;
    }

    get xSpacing(): number {
        return this.xySpacing.x;
    }

    get ySpacing(): number {
        return this.xySpacing.y;
    }

    /*
     * The function will return the null if the spacing was not calculated, the spacing if min and max are close,
     * and the range as an array if the min and max are not the same
     */
    get zSpacing(): null | number | Array<number> {
        if (this.minSpacing === null || this.maxSpacing === null) {
            return null;
        }
        if (Math.abs(this.maxSpacing - this.minSpacing) < DicomSeries.SPACING_THICKNESS_MISMATCH_THRESHOLD) {
            return this.minSpacing;
        }
        return [this.minSpacing, this.maxSpacing];
    }

    // this method is used from the select-dicom.component.html
    // noinspection JSUnusedGlobalSymbols
    public formatSpacing(s: null | number | Array<number>): string {
        if (s === null) {
            return '?';
        }
        if (typeof s === 'number') {
            return s.toFixed(2);
        }
        return `[${s[0].toFixed(2)}, ${s[1].toFixed(2)}]`;
    }

    get sliceThickness(): number {
        return this.thickness;
    }

    public checkThickness(): boolean {
        return this.sliceThickness <= MAX_THICKNESS;
    }

    get spacingNotEqualToThickness(): boolean {
        return this.minSpacing !== null && this.maxSpacing !== null && this.thickness !== null && (
            Math.abs(this.minSpacing - this.thickness) >= DicomSeries.SPACING_THICKNESS_MISMATCH_THRESHOLD ||
            Math.abs(this.maxSpacing - this.thickness) >= DicomSeries.SPACING_THICKNESS_MISMATCH_THRESHOLD
        );
    }

    get dateStr(): string {
        return this.seriesDate?.toISOString() ?? '';
    }

    get imageUseOptions(): Array<ImageUse> | null {
        return this.modality ? this.modality.dropDownOptions : null;
    }

    set classification(response: ClfDicomResult) {
        this.clfResponse = response;
    }

    get sequence(): string {
        return this.validClassification ? this.clfResponse.sequence : '';
    }

    get imageType(): string {
        return this.validClassification ? this.clfResponse.sequence : this.modality.name;
    }

    public asClfDicom(): ClfDicomParams {
        return new ClfDicomParams(
            this.seriesId, this.seriesDesc, this.protocolName, this.echoTime, this.repetitionTime, this.inversionTime,
            this.flipAngle, this.mfs
        );
    }

    /***
     * Check a single spacing value and return true if the value us valid and false if the value is null
     * or greater than the maximum allowed value
     * @param value - null, number or array of numbers. Only number can produce return value of true
     */
    public checkAxisSpacing(value: null | number | Array<number>): boolean {
        if (typeof value === 'number') {
            return value <= MAX_SPACING + VOXEL_SPACE_EPSILON;
        }
        // if we have more than one value we have a problem, and it is not ok
        return false;
    }

    public checkImageSpacing(): boolean {
        if (this.xSpacing === null || this.ySpacing === null || this.zSpacing === null) {
            return false;
        }
        const sValues = [this.xSpacing, this.ySpacing];
        typeof this.zSpacing === 'number' ? sValues.push(this.zSpacing) : sValues.push(...this.zSpacing);
        return sValues.every(s => this.checkAxisSpacing(s));
    }

    private get classified(): boolean {
        return this.clfResponse != null;
    }

    private get validClassification(): boolean {
        // The classification is considered valid if we either have a text and AI classification that agree
        // or we have a probability of the best prediction to be higher than threshold
        return this.classified ? ((this.clfResponse.seq_by_clf === this.clfResponse.seq_by_txt) || (this.maxClfProba() > CLF_PROBABILITY_THRESHOLD)) : false;
    }

    private maxClfProba(): number {
        if (this.classified && this.clfResponse.predicted_proba && this.clfResponse.predicted_proba.length > 0) {
            return Math.max(...this.clfResponse.predicted_proba.map(p => p[1]));
        }
        return -1;
    }

    validSelection(): boolean {
        const validSequence = VALID_IMAGE_USE.get(this.imageUse);
        return (validSequence ? validSequence.includes(this.sequence) : true) && !this.suspiciousCT;
    }

    get files(): Array<File> {
        return this.dicomSlices.map(df => df.theFile);
    }

    get consecutive(): boolean {
        return this.missingSlices.length === 0;
    }

    /**
     * return True if this series is a CT and the CT ConvolutionKernel includes 'bone', indicating a CT
     * that was acquired with Bone Reconstruction else False
     */
    get suspiciousCT(): boolean {
        return this.modality === CT && this.ctConvolutionKernel.some(code => code.toLowerCase().includes('bone'));
    }

    /**
     * This method will do things that require all slices to be ready. It should be called after all files were loaded.
     * The method will:
     * Sort the slices by ImagePatientPosition
     * Check that all InstanceNumber are consecutive and there are no missing slices
     * Calculate the spacing range - min and max based on the DICOM ImagePositionPatient values that are in each slice
     * Return: the method update internal class values and does not have a return value
     */
    public onLoadSeriesComplete(): void {
        this.clearOrientationOutliersIfPossible();
        this.sortSlices();
        this.extractPatientInfo();
        this.checkConsecutive();
        this.calcSpacingRange();
    }

    /**
     * This method will check the slices orientations remove outliers slices from dicomSlices array.
     * The method calculates the median of the imageOrientationPatient (a 6 number array representing 2 x,y,z
     * coordinates) values and set that value. If all slices are from the same series, this value should be the same
     * in all slices and the median will also be the same.
     * Once the median is available we calculate the Euclidean distance between the slice value and the median value.
     * We only keep slices that are under the threshold (0.01) in dicomSlices.
     * @private
     */
    private clearOrientationOutliersIfPossible(): void {
        function median(values: Array<number>): number {
            // Calculate an approximate median; it does not use the mean of the two center values when
            // the number of values is even. There is no protection against empty input arrays, since
            // this.dicomSlices.length > 0 is checked below. It sorts the values in place, which is safe
            // because the arrays passed below are temporary arrays not used for any other purpose.
            values.sort((a, b) => a - b);
            return values[Math.floor(values.length / 2)];
        }

        function euclideanDistance(v1: Array<number>, v2: Array<number>): number {
            return Math.hypot(...v1.map((e1, i) => e1 - v2[i]));
        }

        if (this.dicomSlices.length > 0) {
            const indices: number[] = Object.keys(this.dicomSlices[0].orientation).map(Number);
            const center: Array<number> = indices.map(i => median(this.dicomSlices.map(s => s.orientation[i])));
            const includedSlices: Array<DicomSlice> = new Array<DicomSlice>();
            this.dicomSlices.forEach(s => {
                if (euclideanDistance(s.orientation, center) > MAX_IMG_ORIENTATION_DISTANCE) {
                    s.extractionReasonKey = DicomSlice.EXT_REASON_KEY_BAD_ORIENTATION;
                    this.extractedSlices.push(s);
                }
                else {
                    includedSlices.push(s);
                }
            });
            this.dicomSlices = includedSlices;
        }
    }

    private sortSlices(): void {
        // we only sort if there are 2 or more slices
        if (this.dicomSlices.length < 2) {
            return;
        }
        const sortDirectionV = new THREE.Vector3(1, 1, 1);
        // sort by instance number or if not available, by the dot product of the position and V(1,1,1)
        this.dicomSlices.sort((a: DicomSlice, b: DicomSlice) => ((a.instanceNumber ?? a.pos.dot(sortDirectionV)) - (b.instanceNumber ?? b.pos.dot(sortDirectionV))));
        const u = this.dicomSlices[this.nSlices - 1].pos.clone().sub(this.dicomSlices[0].pos).normalize();
        // verify the sort order by the ImagePositionPatient attribute of the slice
        this.dicomSlices.sort((a: DicomSlice, b: DicomSlice) => a.pos.dot(u) - b.pos.dot(u));
    }

    get patientId(): string | null {
        if (this.patientIds !== null && this.patientIds.size === 1) {
            return this.patientIds.values().next().value;
        }
        return null;
    }

    private extractPatientInfo(): void {
        const validPatientIds = this.dicomSlices.filter(x => x.patientId !== null);
        this.patientIds = new Set(validPatientIds.map(x => x.patientId));
        const validPatientNames = this.dicomSlices.filter(x => x.patientName !== null);
        this.patientNames = new Set(validPatientNames.map(x => x.patientName));
    }

    // This get is used from multiple places - for example select-dicom-component.html
    // noinspection JSUnusedGlobalSymbols
    get patientName(): string | null {
        if (this.patientNames !== null && this.patientNames.size === 1) {
            return this.patientNames.values().next().value;
        }
        return null;
    }

    private checkConsecutive(): void {
        const numbers: Array<number> = this.dicomSlices.map(df => df.instanceNumber);
        const first = numbers[0];
        const last = numbers[numbers.length - 1];
        let index = 0;
        let expected = first;
        while (expected < last) {
            if (numbers[index] === expected) {
                index += 1;
            }
            else {
                this.missingSlices.push(expected);
            }
            expected += 1;
        }
    }

    private calcSpacingRange(): void {
        for (let i = 1; i < this.dicomSlices.length; i++) {
            const sliceSpacing = this.dicomSlices[i].pos.distanceTo(this.dicomSlices[i - 1].pos);
            if (this.minSpacing === null || this.minSpacing > sliceSpacing) {
                this.minSpacing = sliceSpacing;
            }
            if (this.maxSpacing === null || this.maxSpacing < sliceSpacing) {
                this.maxSpacing = sliceSpacing;
            }
        }
    }
}

// full list of DICOM fields available at:
// https://github.com/cornerstonejs/dicomParser/blob/master/examples/dataDictionary.js
export const TAG_DICT = {
    '(0008,0021)': {tag: '(0008,0021)', vr: 'DA', vm: '1', name: 'SeriesDate'},
    '(0008,0031)': {tag: '(0008,0031)', vr: 'TM', vm: '1', name: 'SeriesTime'},
    '(0008,0060)': {tag: '(0008,0060)', vr: 'CS', vm: '1', name: 'Modality'},
    '(0008,103E)': {tag: '(0008,103E)', vr: 'LO', vm: '1', name: 'SeriesDescription'},
    '(0010,0010)': {tag: '(0010,0010)', vr: 'PN', vm: '1', name: 'PatientName'},
    '(0010,0020)': {tag: '(0010,0020)', vr: 'LO', vm: '1', name: 'PatientID'},
    '(0018,0050)': {tag: '(0018,0050)', vr: 'DS', vm: '1', name: 'SliceThickness'},
    '(0018,0080)': {tag: '(0018,0080)', vr: 'DS', vm: '1', name: 'RepetitionTime'},
    '(0018,0081)': {tag: '(0018,0081)', vr: 'DS', vm: '1', name: 'EchoTime'},
    '(0018,0082)': {tag: '(0018,0082)', vr: 'DS', vm: '1', name: 'InversionTime'},
    '(0018,0087)': {tag: '(0018,0087)', vr: 'DS', vm: '1', name: 'MagneticFieldStrength'},
    '(0018,0088)': {tag: '(0018,0088)', vr: 'DS', vm: '1', name: 'SpacingBetweenSlices'},
    '(0018,1030)': {tag: '(0018,1030)', vr: 'LO', vm: '1', name: 'ProtocolName'},
    '(0018,1210)': {tag: '(0018,1210)', vr: 'SH', vm: '1', name: 'ConvolutionKernel'},
    '(0018,1314)': {tag: '(0018,1314)', vr: 'DS', vm: '1', name: 'FlipAngle'},
    '(0020,000E)': {tag: '(0020,000E)', vr: 'UI', vm: '1', name: 'SeriesInstanceUID'},
    '(0020,000D)': {tag: '(0020,000D)', vr: 'UI', vm: '1', name: 'StudyInstanceUID'},
    '(0020,0013)': {tag: '(0020,0013)', vr: 'IS', vm: '1', name: 'InstanceNumber'},
    '(0020,0032)': {tag: '(0020,0032)', vr: 'DS', vm: '3', name: 'ImagePositionPatient'},
    '(0020,0037)': {tag: '(0020,0037)', vr: 'DS', vm: '6', name: 'ImageOrientationPatient'},
    '(0020,1041)': {tag: '(0020,1041)', vr: 'DS', vm: '1', name: 'SliceLocation'},
    '(0028,0010)': {tag: '(0028,0010)', vr: 'US', vm: '1', name: 'Rows'},
    '(0028,0011)': {tag: '(0028,0011)', vr: 'US', vm: '1', name: 'Columns'},
    '(0028,0030)': {tag: '(0028,0030)', vr: 'DS', vm: '2', name: 'PixelSpacing'}
};

// Dicom header tags/keys
export const SERIES_DATE = TAG_DICT['(0008,0021)'];
export const SERIES_TIME = TAG_DICT['(0008,0031)'];
export const MODALITY = TAG_DICT['(0008,0060)'];
export const SERIES_DESC = TAG_DICT['(0008,103E)'];
export const P_NAME = TAG_DICT['(0018,1030)'];
export const PATIENT_NAME = TAG_DICT['(0010,0010)'];
export const PATIENT_ID = TAG_DICT['(0010,0020)'];
export const SLICE_THICKNESS = TAG_DICT['(0018,0050)'];
export const REP_TIME = TAG_DICT['(0018,0080)'];
export const ECHO_TIME = TAG_DICT['(0018,0081)'];
export const INV_TIME = TAG_DICT['(0018,0082)'];
export const MFS = TAG_DICT['(0018,0087)'];
export const CONVOLUTION_KERNEL = TAG_DICT['(0018,1210)'];
export const FLIP_ANGLE = TAG_DICT['(0018,1314)'];
export const STUDY_INSTANCE_UID = TAG_DICT['(0020,000D)'];
export const SERIES_INSTANCE_UID = TAG_DICT['(0020,000E)'];
export const INSTANCE_NUMBER = TAG_DICT['(0020,0013)'];
export const IMAGE_POSITION_PATIENT = TAG_DICT['(0020,0032)'];
export const IMAGE_ORIENTATION_PATIENT = TAG_DICT['(0020,0037)'];
export const SLICE_LOCATION = TAG_DICT['(0020,1041)'];
export const ROWS = TAG_DICT['(0028,0010)'];
export const COLUMNS = TAG_DICT['(0028,0011)'];
export const PXL_SPACING = TAG_DICT['(0028,0030)'];
