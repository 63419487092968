import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface TosData {
    content: string;
}

@Component({
    selector: 'app-tos',
    templateUrl: './tos.component.html',
    styleUrls: ['./tos.component.scss']
})
export class TosComponent {

    content = '';

    constructor(@Inject(MAT_DIALOG_DATA) public data: TosData) {
        this.content = data.content;
    }
}
