<div class="video-container">
    @if (videoReady) {
        <div class="video-object">
            <vg-player>
                <video #media [vgMedia]="$any(media)" preload="auto" autoplay controls>
                    <source [src]="videoUrl" [type]="'video/mp4'">
                </video>
            </vg-player>
        </div>
    }
    @if (errorMessage) {
        <span class="error-message mat-typography">{{ 'helpVideoErrorMessage'|translate }}</span>
    }
</div>

