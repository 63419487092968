import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountService } from './services/account.service';
import { ApiService } from './services/api.service';
import { AuthGuardService } from './services/authguard.service';
import { AuthService } from './services/auth.service';
import { GroupService } from './services/group.service';
import { InvitationService } from './services/invitation.service';
import { PermissionsService } from './services/permissions.service';
import { UserService } from './services/user.service';
import { TosService } from './services/tos.service';
import { AppConfigService } from './app-config.service';
import { LanguageService } from './services/language.service';
import { QuartersService } from './services/quarters.service';
import { NotificationsService } from './main-nav/notifications.service';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
    ],
    providers: [
        AccountService,
        ApiService,
        AuthGuardService,
        AuthService,
        GroupService,
        InvitationService,
        PermissionsService,
        UserService,
        TosService,
        NotificationsService,
        AppConfigService,
        LanguageService,
        QuartersService,
    ]
})
export class AppServicesModule {
}
