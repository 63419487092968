<div class="select-dicom-header">
    <span class="mat-typography select-dicom-title select-dicom-header-text">
        {{ 'caseOverviewSelectDicomTitle'|translate }}
    </span>
    <div class="mat-typography select-dicom-help">
        <div class="select-dicom-help-row">
            <span><b>{{ 'caseOverviewSelectDicomSupportedContrasts'|translate }}:</b></span>
            <span class="m-l-5">
                {{ 'caseOverviewSelectDicomSupportedContrastsDetails'|translate }};
            </span>
            <span class="m-l-15"><b>{{ 'caseOverviewSelectDicomRequirements'|translate }}</b>:</span>
            <span class="m-l-5">{{ 'caseOverviewSelectDicomRequirementsDetails'|translate }}</span>
        </div>
        <div class="select-dicom-help-row">
            <span *ngIf="hasValidSelectedPatientIds()">
                <b>{{ 'caseOverviewSelectDicomPrevSelectedIds'|translate }}</b>: {{ previouslySelectedIds }}
            </span>
        </div>
    </div>

</div>

<div class="mat-elevation-z8 height-350">
    <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="imageUse">
            <mat-header-cell *matHeaderCellDef class="mat-column-220">{{ 'imageUse'|translate }}</mat-header-cell>
            <mat-cell *matCellDef="let series" class="mat-column-220">
                <mat-select [(ngModel)]="series.imageUse" panelWidth=""
                            [disabled]="!modalityUploadAllowed(series.modality) || !validForUpload(series) || disablePostop(series)"
                            (selectionChange)="onSelect(series, $event.value)">
                    @for (imgUse of series.imageUseOptions; track imgUse) {
                        <mat-option [value]="imgUse"
                                    [disabled]="!imageUseUploadAllowed(imgUse) || imageUseSelected(imgUse)">
                            {{ imageUseName(imgUse) }}
                        </mat-option>
                    }
                </mat-select>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="acquired">
            <mat-header-cell *matHeaderCellDef class="mat-column-180-center"
                             mat-sort-header>{{ 'acquired'|translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let series" class="mat-column-180">{{ series.dateStr|localDate }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="patientInfo">
            <mat-header-cell *matHeaderCellDef class="mat-column-200-center" mat-sort-header>
                <div>
                    <span>{{ 'patientInfo'|translate }}</span><br>
                    <span *ngIf="patientIdMismatch" class="additional-info-blink-bold warning-text">
                        {{ 'patientIdMismatch'|translate }}
                    </span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let series" class="select-dicom-patient-info">
                <span *ngIf="validPatientInfo(series.patientId)"
                      class="select-dicom-patient-into-text truncate-text"><b>{{ 'id'|translate }}</b>: {{ series.patientId }}</span>
                <span *ngIf="validPatientInfo(series.patientName)" class="select-dicom-patient-into-text truncate-text"><b>{{ 'name'|translate }}</b>: {{ series.patientName }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="imageType">
            <mat-header-cell *matHeaderCellDef class="mat-column-180" mat-sort-header>
                {{ 'imageType'|translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let series" class="mat-column-180" [ngClass]="imageTypeClass(series)">
                <div matTooltip="{{'boneReconstructionTooltip'|translate}}"
                     [matTooltipDisabled]="!series.suspiciousCT"
                     class="image-type-container">
                    <div class="image-type-modality">{{ series.imageType }}</div>
                    <div *ngIf="series.suspiciousCT">
                        <span class="additional-info warning-text">
                            <sup>*&ngsp;</sup>{{ 'boneReconstruction'|translate }}?
                        </span>
                    </div>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="slices">
            <mat-header-cell *matHeaderCellDef class="mat-column-180" mat-sort-header>
                {{ 'slices'|translate }}&nbsp;({{ 'size'|translate }})
            </mat-header-cell>
            <mat-cell *matCellDef="let series" class="mat-column-180">
                <div>
                    <span [ngClass]="slicesClass(series)" class="bold-text">{{ series.nSlices }}</span>
                    <sup *ngIf="!series.consecutive">*</sup><span>&ngsp;</span>
                    <span>({{ series.rows }}&ngsp;x&ngsp;{{ series.columns }})</span>
                    <br *ngIf="!series.consecutive">
                    <span *ngIf="!series.consecutive" class="additional-info warning-text">
                        <sup>*&ngsp;</sup>{{ 'missingSlices'|translate: {count: series.missingSlices.length} }}
                    </span>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="spacing">
            <mat-header-cell *matHeaderCellDef class="mat-column-200" mat-sort-header>{{ 'spacing'|translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let series" class="mat-column-200">
                <div>
                    <span [ngClass]="spacingClass(series, series.xSpacing)">{{ series.xSpacing.toFixed(2) ?? '?' }}</span>&ngsp;x&ngsp;
                    <span [ngClass]="spacingClass(series, series.ySpacing)">{{ series.ySpacing.toFixed(2) ?? '?' }}</span>&ngsp;x&ngsp;
                    <span [ngClass]="spacingClass(series, series.zSpacing)">{{ series.formatSpacing(series.zSpacing) }}</span>
                    <sup *ngIf="series.spacingNotEqualToThickness">*</sup>
                    <br *ngIf="series.spacingNotEqualToThickness">
                    <span *ngIf="series.spacingNotEqualToThickness" class="additional-info"
                          [ngClass]="thicknessClass(series)">
                        <sup>*&ngsp;</sup>{{ 'sliceThickness'|translate }}:&ngsp;{{ series.sliceThickness }}
                    </span>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'seriesDescription'|translate }}</mat-header-cell>
            <mat-cell *matCellDef="let series">
                <span class="truncate-text" [matTooltip]="series.seriesDesc">{{ series.seriesDesc }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="disableDI">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column-150">
                {{ 'caseUploadDicomIgnoreDataIntegrity'|translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let series" class="mat-column-150-center">
                <mat-checkbox
                        class="disable-di-checkbox"
                        labelPosition="before"
                        i18n-matTooltip="@@caseUploadDicomIgnoreDITooltip"
                        matTooltip="{{'caseUploadDicomIgnoreDataIntegrityTooltip'|translate}}"
                        matTooltipPosition="right" [(ngModel)]=series.disableDI>
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="details">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column-100-center">
            </mat-header-cell>
            <mat-cell *matCellDef="let series" class="mat-column-100-center">
                <button mat-icon-button (click)="showDetails(series)">
                    <mat-icon>info</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let series; columns: columns;"></mat-row>
    </mat-table>
    <mat-paginator [hidePageSize]="true" [pageSize]="5"></mat-paginator>
</div>

<div mat-dialog-actions class="select-dicom-action-row">
    <div>
        <button (click)="onUploadSelected()" mat-raised-button [disabled]="disableUpload"
                data-testid="select-dicom-upload">
            {{ 'caseOverviewSelectDicomUpload'|translate }}
        </button>
        <button (click)="onCancel()" mat-raised-button
                data-testid="select-dicom-cancel">
            {{ 'cancel'|translate }}
        </button>
        <mat-checkbox *ngIf="patientIdMismatch" [(ngModel)]="patientIdMismatchAck">
            {{ 'caseOverviewSelectDicomUploadApprovePatientId' | translate }}
        </mat-checkbox>
    </div>
    <span class="mat-typography warning-text" *ngIf="errorMessageKey">{{ errorMessageKey|translate }}</span>
</div>
