import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { DataElement } from '../data-element.class';
import { Upload, UploadError, UploadService } from './upload.service';
import { interval, Subject } from 'rxjs';
import { UploadReport } from '../../services/api.service';
import { Case } from '../case.service';
import { Subscriptions } from '../../tools/subscriptions.class';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export abstract class UploadDirective implements OnInit, OnDestroy {

    @Input() caseUpdated: Subject<Case>;
    @Input() caseId: string;
    @Input() dataElement: DataElement;
    @Input() titleKey: string;
    @Input() supportedFormats: string[] = [];
    @Input() canUpload = true;
    @Input() uploadCount: number;
    @Input() elementUploadReport: UploadReport;

    // Text and messages
    uploadType: string;
    storing: string;
    uploading: string;
    currentUpload: Upload | null = null;
    progress = -1;
    progressMessage = '';
    uploadErrors = '';
    uploadError: UploadError = null;
    protected subscriptions = new Subscriptions();
    protected nextProgress = -1;
    protected timer = null;
    // Translation Keys to read from lang.json file
    private readonly storingKey: string = 'storing';
    private readonly uploadingKey: string = 'caseUploadDicomUploading';
    private uploadReport: UploadReport = null;

    protected constructor(protected uploadService: UploadService, protected translate: TranslateService) {
    }

    abstract getFileCount(): number;

    setUploadReport(value: UploadReport): void {
        this.uploadReport = value;
    }

    getUploadReport(): UploadReport {
        return this.uploadReport;
    }

    setElementUploadReport(value: UploadReport): void {
        this.elementUploadReport = value;
    }

    getElementUploadReport(): UploadReport {
        return this.elementUploadReport;
    }

    ngOnInit() {
        this.translateText();
        this.subscriptions.add(this.translate.onLangChange, () => this.translateText());
        this.subscriptions.add(this.caseUpdated, data => {
            this.caseId = data.id;
        });

        // Having failed to get the progress message, etc., to update based only on receiving the
        // progress notifications from the upload service, hack around the problem by periodically
        // checking for progress.
        // TODO: Figure out how to do this the right way.
        this.timer = interval(200).subscribe(() => this.setProgress(this.nextProgress));
    }

    private translateText() {
        this.subscriptions.add(
            this.translate.get([
                this.titleKey,
                this.storingKey,
                this.uploadingKey
            ]),
            translated => {
                this.uploadType = translated[this.titleKey];
                this.storing = translated[this.storingKey];
                this.uploading = translated[this.uploadingKey];
            }
        );
    }

    ngOnDestroy() {
        // if there are uploads in progress => abort current and cancel pending uploads
        this.uploadService.abortUploads();
        this.timer.unsubscribe();
        this.subscriptions.cancel();
    }

    upload(inputElement: HTMLInputElement) {
        const upload = this.uploadFiles(this.fileListToArray(inputElement.files), false);
        this.clearFileInput(inputElement);
        this.currentUpload = upload;
    }

    getFileCountClass(): any {
        const fileCount = this.getFileCount();
        const failed = fileCount > 0 && fileCount < this.getMinFileCount();
        return {
            'integrity-error': failed,
        };
    }

    // This method sets the value of the uploadError message that is presented on the DICOM upload component
    setUploadErrors(): void {
        const report = this.validUploadReport();
        if (!report || !report.errors) {
            this.uploadErrors = '';
        }
        if (report.errors.length === 0) {
            this.translate.get('caseUploadDicomNoErrorsDetected').subscribe(translation => {
                this.uploadErrors = translation;
            });
        }
        else if (report.di === false) { // We did not do data integrity checks
            this.translate.get('caseUploadDicomIgnoreDataIntegrityMessage').subscribe(translation => {
                this.uploadErrors = translation;
            });
        }
        else { // we did data integrity checks and there were errors so the data was not saved
            this.translate.get('caseUploadDicomNotSaved').subscribe(translation => {
                this.uploadErrors = translation;
            });
        }
    }

    getProgressMode(): string {
        if (this.progress >= 100 && !this.uploadError) {
            return 'indeterminate';
        }
        else {
            return 'determinate';
        }
    }

    protected abstract getMinFileCount(): number;

    protected uploadFiles(files: Array<File>, disableDi: boolean, seriesId?: string, studyId?: string, patientId?: string): Upload | null {
        this.uploadError = null;
        this.uploadErrors = '';
        this.uploadReport = null;
        const upload = this.uploadService.push(this.caseId, this.dataElement.name, files, !disableDi, seriesId, studyId, patientId);

        if (upload !== null) {
            this.subscriptions.add(upload.getResultEmitter(),
                data => {
                    this.caseUpdated.next(data.case);
                    this.uploadReport = data.upload_report;
                    this.nextProgress = -1;
                    this.currentUpload = null;
                    this.setUploadErrors();
                },
                err => {
                    this.uploadError = err;
                    this.currentUpload = null;
                });

            this.subscriptions.add(upload.getProgressEmitter(), data => {
                this.nextProgress = data;
            });
        }
        return upload;
    }

    protected getEventProperties(): any {
        return {
            category: 'upload',
            label: this.dataElement.name,
            caseId: this.caseId,
            dataElement: this.dataElement.name,
            warningCount: this.uploadReport.warnings.length,
            errorCount: this.uploadReport.errors.length,
            dataIntegrity: this.uploadReport.di
        };
    }

    protected setProgress(newProgress: number) {
        if (newProgress !== this.progress) {
            this.progress = newProgress;
            this.progressMessage = this.computeProgressMessage(newProgress);
        }
    }

    protected fileListToArray(fileList: FileList): Array<File> {
        const result = [];
        for (let i = 0; i < fileList.length; i++) {
            result.push(fileList.item(i));
        }
        return result;
    }

    protected validUploadReport(): UploadReport | null {
        if (this.uploadReport && Object.keys(this.uploadReport).length > 0) {
            return this.uploadReport;
        }
        if (this.elementUploadReport && Object.keys(this.elementUploadReport).length > 0) {
            return this.elementUploadReport;
        }
        return null;
    }

    protected hasUploadErrors(): boolean {
        return this.validUploadReport()?.errors?.length > 0;
    }

    private computeProgressMessage(p: number) {
        return p < 0 ? '' :
            p === 0 ? `${this.uploading}...` :
                p < 100 ? `${this.uploading} - ${p}%` :
                    `${this.storing}...`;
    }

    private clearFileInput(inputElement: HTMLInputElement) {
        try {
            inputElement.value = null;
        } catch (_ex) {
            // Do Nothing
        }
        if (inputElement.value) {
            inputElement.parentNode.replaceChild(inputElement.cloneNode(true), inputElement);
        }
    }
}
