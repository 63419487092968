import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Case, CaseService } from '../case/case.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DbsTarget, DbsTargets, Targets } from '../case/case.constants';
import { Permissions } from '../security/permissions.class';
import { Features } from '../security/feature.class';
import { Subscriptions } from '../tools/subscriptions.class';
import { PermissionsService } from '../services/permissions.service';
import { NewCase } from '../services/api.service';

export interface CaseSeed {
    // userId and groupId should match elements in @NewCase
    userId: string | null;
    groupId: string | null;
}

@Component({
    selector: 'app-case-new-case',
    templateUrl: './new-case.component.html',
    styleUrls: ['./new-case.component.scss'],
})

export class NewCaseComponent implements OnInit, OnDestroy {

    public newCase: NewCase = new NewCase();

    public targets: Array<DbsTarget> = [];

    private subscriptions = new Subscriptions();
    private submitInProgress = false;

    constructor(@Inject(MAT_DIALOG_DATA) public caseSeed: CaseSeed, public dialogRef: MatDialogRef<NewCaseComponent>,
                private caseService: CaseService, private permissionsService: PermissionsService) {
        Object.assign(this.newCase, caseSeed);
    }

    public ngOnInit(): void {
        this.subscriptions.limit(
            this.permissionsService.hasPermission(Permissions.featureAvailable(Features.ENABLE_ALL_TARGETS))
        ).subscribe({
            next: (enableAll: boolean) => {
                this.setTargets(enableAll);
            },
            error: () => {
                this.setTargets(false);
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
    }


    public onSubmit(): void {
        this.submitInProgress = true;
        this.caseService.createNewCase(this.newCase).subscribe({
            next: (data: Case) => {
                this.dialogRef.close(data);
            },
            error: (error) => {
                alert(error.message);
            },
            complete: () => {
                this.resetNewCase();
            }
        });
    }

    private resetNewCase() {
        this.newCase = new NewCase();
        this.submitInProgress = false;
    }

    public enableSubmission(): boolean {
        const hasName = this.newCase.name.length > 0;
        const targetSelected = this.newCase.target != null;
        return hasName && targetSelected && !this.submitInProgress;
    }

    private setTargets(enableAll: boolean) {
        this.targets = Array.from(DbsTargets.values()).filter(t => enableAll || t.key !== Targets.ALL);
        this.targets = this.targets.sort((a, b) => (a.position > b.position) ? 1 : -1);
    }
}
