<mat-card appearance="outlined" class="activity-message-box">
    <mat-card-title class="activity-message-header">
        <span class="mat-typography activity-message-title">Add Message to Activity Log</span>
        <button mat-raised-button
                (click)="addActivityMessage()"
                [disabled]="!activityMessage || loggingMessage">
            Add
        </button>
    </mat-card-title>
    <mat-card-content class="activity-message-content-container">
        <div class="activity-message-selection">
            <span class="mat-typography sis-bold m-r-20">{{ 'level'|translate }}:&nbsp;</span>
            <mat-select class="width-70-p" [(ngModel)]="level">
                @for (lvl of levels; track lvl) {
                    <mat-option [value]="lvl">{{ levelAsString(lvl)|translate }}</mat-option>
                }
            </mat-select>
        </div>
        <div class="activity-message-text-box">
            <mat-form-field class="activity-message-text-area">
                <mat-label>Enter Activity Log Message</mat-label>
                <textarea matInput
                          id="activityMessage"
                          [rows]="3"
                          [(ngModel)]="activityMessage"
                          [ngModelOptions]="{standalone: true}"
                          [disabled]="loggingMessage">
                </textarea>
            </mat-form-field>
        </div>
        <div class="activity-message-report">
            @if (!errorMessage) {
                <span class="mat-typography">{{ successMessage }}</span>
            } @else {
                <span class="mat-typography activity-message-error">{{ errorMessage }}</span>
            }
        </div>
    </mat-card-content>
</mat-card>
