<div class="fill-parent">
    <div class="detailed-reports-container">
        @if (ready) {
            <mat-form-field appearance="fill">
                <mat-label>{{ 'reportSelectScopeTitle'|translate }}</mat-label>
                <mat-select [(ngModel)]="currentScope" [disabled]="inProgress">
                    @for (key of getTemplates(); track key) {
                        <mat-option [value]="key">{{ getTemplateTitle(key)|translate }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field class="width-300">
                <mat-label>{{ 'reportSelectDatesRange'|translate }}</mat-label>
                <mat-select (selectionChange)="onDateRangeSelection()" [(ngModel)]="selectedTimeRange"
                            data-testid="reports-detailed-range-select">
                    @for (time of timeOptions; track time.titleKey) {
                        <mat-option data-testid="reports-detailed-range-options" [value]="time">
                            {{ time.titleKey|translate }}
                        </mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="m-l-50">
                <mat-label>{{ 'reportSelectDatesLabel'|translate }}</mat-label>
                <mat-date-range-input [rangePicker]="picker" [disabled]="inProgress" (selectionchange)="onDatePickerSelection()">
                    <input matStartDate placeholder="{{'startDate'|translate}}" [(ngModel)]="startDate">
                    <input matEndDate placeholder="{{'endDate'|translate}}" [(ngModel)]="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        }
        <div class="report-actions">
            <button mat-raised-button class="report-range-btn" (click)="submitQuery()" [disabled]="disableSubmit()">
                {{ 'submit'|translate }}
            </button>
            <button mat-raised-button class="report-range-btn" [disabled]="disableClear()" (click)="reset()">
                {{ 'clear'|translate }}
            </button>
        </div>

    </div>
</div>
