import { Component } from '@angular/core';

@Component({
    selector: 'app-help-no-document',
    styleUrls: ['./help-document.component.scss'],
    template: '<p class="mat-typography help-doc-not-available">{{"helpDocumentNotAvailable"|translate}}</p>',
})
export class NoHelpDocumentComponent {

}
