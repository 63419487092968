import { Component, OnDestroy } from '@angular/core';
import { ReportDirective } from '../report.directive';
import { ReportsService } from '../reports.service';
import { QuartersService } from '../../services/quarters.service';
import { saveAs } from 'file-saver';
import { MaterialModule } from '../../material.module';
import { FormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-security-report',
    standalone: true,
    imports: [
        FormsModule,
        MaterialModule,
        TranslatePipe,
    ],
    templateUrl: './security-report.component.html',
    styleUrls: ['./security-report.component.scss', '../reports.component.scss'],
})
export class SecurityReportComponent extends ReportDirective implements OnDestroy {

    constructor(reportsService: ReportsService, qService: QuartersService) {
        super(reportsService, qService);
    }

    public submitQuery(): void {
        this.inProgress = true;
        this.rs.getSecurityReport(this.startDate, this.endDate).subscribe({
            next: (reportBody: any) => {
                saveAs(reportBody, 'security.xlsx');
            },
            error: () => {
                this.inProgress = false;
            },
            complete: () => {
                this.inProgress = false;
            }
        });
        this.reset();
    }
}
