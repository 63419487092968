<div class="overview-container">
    <div class="overview-top-container">
        <div class="overview-info-container">
            <mat-form-field class="overview-name">
                <mat-label>{{ 'name'|translate }}</mat-label>
                <input matInput
                       data-testid="case-overview-name"
                       [(ngModel)]="name"
                       (ngModelChange)="onInputChange()">
            </mat-form-field>
            <mat-form-field class="overview-description">
                <mat-label>{{ 'description'|translate }}</mat-label>
                <textarea matInput
                          cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5"
                          data-testid="case-overview-description"
                          id="caseDescription"
                          [(ngModel)]="description"
                          (ngModelChange)="onInputChange()"
                          [ngModelOptions]="{standalone: true}">
                    </textarea>
            </mat-form-field>
            <button mat-raised-button
                    class="overview-save-case-btn"
                    type="submit"
                    i18n="@@caseOverviewButtonSaveChanges"
                    data-testid="case-overview-save-changes"
                    [disabled]="cannotSaveChanges && !electrodesSelectionChanged"
                    (click)="saveCaseChanges()">
                {{ 'caseOverviewButtonSaveChanges'|translate }}
            </button>
        </div>
        <div class="overview-share-container">
            <span>
                <mat-form-field class="overview-group">
                    <mat-label>{{ 'caseOverviewVisibleToGroupPlaceholder'|translate }}</mat-label>
                    <mat-select [(ngModel)]="groupId" (ngModelChange)="shareChanged()">
                        @for (group of shareGroups; track group.id) {
                            <mat-option [value]="group.id">{{ group.name || 'None' }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <app-secure-link
                        *ngIf="cannotSaveChanges && hasGroup()"
                        class="overview-group-link"
                        [permission]="canViewGroup()"
                        [target]="groupTarget()"
                        [hideWhenForbidden]="true">
                    <mat-icon>link</mat-icon>
                </app-secure-link>
            </span>
            <app-case-electrode-models
                    class="overview-electrode-model-container"
                    [caseUpdated]="caseUpdated"
                    (electrodesUpdate)="electrodesChanged($event)">
            </app-case-electrode-models>
        </div>
        <div class="overview-control-container">
            <button mat-raised-button
                    i18n="@@caseOverviewButtonSubmit"
                    class="overview-control-btn"
                    [disabled]="!enableSubmitCase()"
                    data-testid="case-overview-submit-case"
                    (click)="submitCase()">
                {{ 'submit'|translate }}
            </button>
            <button mat-raised-button
                    class="overview-control-btn"
                    *ngIf="showDownloadAlpha(stnAlpha)"
                    i18n="@@caseOverviewButtonDownloadStnAlpha"
                    i18n-matTooltip="@@caseOverviewButtonDownloadStnAlphaTooltip"
                    matTooltip="{{'caseOverviewButtonDownloadAlphaTooltip'|translate}}"
                    matTooltipPosition="before"
                    data-testid="case-overview-download-stn-alpha"
                    [disabled]="downloadInProgress || !canDownloadAlpha(stnAlpha)"
                    (click)="downloadAlpha(stnAlpha)">
                <span *ngIf="!downloadInProgress">{{ 'caseOverviewButtonDownloadStnAlpha'|translate }}</span>
                <mat-spinner *ngIf="downloadInProgress" diameter="20" strokeWidth="2"></mat-spinner>
            </button>
            <button mat-raised-button
                    class="overview-control-btn"
                    *ngIf="showDownloadAlpha(gpAlpha)"
                    i18n="@@caseOverviewButtonDownloadGpiAlpha"
                    i18n-matTooltip="@@caseOverviewButtonDownloadGpiAlphaTooltip"
                    matTooltip="{{'caseOverviewButtonDownloadAlphaTooltip'|translate}}"
                    matTooltipPosition="before"
                    data-testid="case-overview-download-gp-alpha"
                    [disabled]="downloadInProgress || !canDownloadAlpha(gpAlpha)"
                    (click)="downloadAlpha(gpAlpha)">
                <span *ngIf="!downloadInProgress">{{ 'caseOverviewButtonDownloadGpiAlpha'|translate }}</span>
                <mat-spinner *ngIf="downloadInProgress" diameter="20" strokeWidth="2"></mat-spinner>
            </button>
            <button mat-raised-button
                    class="overview-control-btn"
                    *ngIf="showDownloadAlpha(vimAlpha)"
                    i18n="@@caseOverviewButtonDownloadVimAlpha"
                    i18n-matTooltip="@@caseOverviewButtonDownloadVimAlphaTooltip"
                    matTooltip="{{'caseOverviewButtonDownloadAlphaTooltip'|translate}}"
                    matTooltipPosition="before"
                    data-testid="case-overview-download-vim-alpha"
                    [disabled]="downloadInProgress || !canDownloadAlpha(vimAlpha)"
                    (click)="downloadAlpha(vimAlpha)">
                <span *ngIf="!downloadInProgress">{{ 'caseOverviewButtonDownloadVimAlpha'|translate }}</span>
                <mat-spinner *ngIf="downloadInProgress" diameter="20" strokeWidth="2"></mat-spinner>
            </button>
            <button mat-raised-button
                    [matMenuTriggerFor]="selectAlphaToDownload"
                    class="overview-control-btn"
                    *ngIf="useSingleDownloadAlpha()"
                    matTooltip="{{'caseOverviewButtonDownloadAlphaTooltip'|translate}}"
                    matTooltipPosition="after"
                    data-testid="case-overview-download-alpha-options"
                    [disabled]="downloadInProgress || !hasAtLeastOneApprovedAlphaImage()">
                <span *ngIf="!downloadInProgress">{{ 'caseOverviewButtonDownloadAlpha'|translate }}</span>
                <mat-spinner *ngIf="downloadInProgress" diameter="20" strokeWidth="2"></mat-spinner>
            </button>
            <mat-menu #selectAlphaToDownload="matMenu" xPosition="after" yPosition="below">
                @for (a of alphaOptions; track a) {
                    <button mat-menu-item [value]="a" (click)="downloadAlpha(a)">
                        {{ a.target.titleKey|translate }}
                    </button>
                }
            </mat-menu>
            <button mat-raised-button
                    class="overview-control-btn"
                    i18n="@@caseOverviewButtonPublishPrediction"
                    *ngIf="allowedPublishPrediction"
                    [disabled]="!canPublishPrediction"
                    (click)="publishPrediction()">
                {{ 'caseOverviewButtonPublishPrediction'|translate }}
            </button>
            <button mat-raised-button
                    class="overview-control-btn"
                    i18n="@@caseOverviewButtonPublishPostOp"
                    *ngIf="allowedPublishPostOp"
                    [disabled]="!canPublishPostOp"
                    (click)="publishPostOp()">
                {{ 'caseOverviewButtonPublishPostOp'|translate }}
            </button>
            <button mat-raised-button
                    class="overview-control-btn"
                    i18n="@@caseOverviewButtonUnpublish"
                    *ngIf="allowedUnpublish"
                    [disabled]="!canUnpublish()"
                    (click)="unpublishCase()">
                {{ 'caseOverviewButtonUnpublish'|translate }}
            </button>
            <button mat-raised-button
                    class="overview-control-btn"
                    i18n="@@caseOverviewButtonRestoreCase"
                    *ngIf="showRestoreButton()"
                    [disabled]="disableRestoreButton()"
                    (click)="restoreCase()">
                {{ 'caseRestoreCase'|translate }}
            </button>
            <button mat-raised-button
                    class="overview-control-btn"
                    data-testid="case-overview-delete"
                    i18n="@@caseOverviewButtonDeleteCase"
                    color="warn"
                    [disabled]="deleteInProgress || !enableDeleteButton()"
                    (click)="deleteCase()">
                {{ 'caseDeleteCase'|translate }}
            </button>
            <span
                    *ngIf="deleteInProgress"
                    class="mat-typography overview-delete-blink">
                {{ 'caseDeleteInProgress'|translate }}&#8230;
            </span>
        </div>
    </div>
    <!--Start Upload Section-->
    <p class="mat-typography overview-title" i18n="@@caseOverviewUploadTitle">
        {{ 'caseOverviewUploadTitle'|translate }}
    </p>
    <div class="overview-upload">
        <app-case-scan-dicom
                *ngIf="this.allowedUploadTargeting || this.allowedUploadPlanning || this.allowedUploadPostop"
                class="overview-upload-item overview-upload-dad-area"
                [caseUpdated]="caseUpdated"
                [uploadInProgress]="uploadInProgress"
                [canUploadPlanning]="canUploadPlanning && allowedUploadPlanning"
                [canUploadTargeting]="canUploadTargeting && allowedUploadTargeting"
                [canUploadPostop]="canUploadPostOp && allowedUploadPostop"
                [allowDisableDataValidation]="allowedDisableDataValidation"
                [uploadDicomRequestEmitter]="notifyUploadDicomRequest">
        </app-case-scan-dicom>
        <app-case-files-upload-dicom
                *ngIf="allowedUploadPlanning"
                class="overview-upload-item"
                [caseUpdated]="caseUpdated"
                [dataElement]="elements.T1_DICOM"
                [titleKey]="'caseUploadTypePlanning'"
                [supportedFormats]="['T1']"
                [canUpload]="canUploadPlanning && allowedUploadPlanning"
                [uploadCount]="uploadCount(elements.T1_DICOM)"
                [elementUploadReport]="uploadReport(elements.T1_DICOM)"
                [uploadDicomRequestHandler]="notifyUploadDicomRequest">
        </app-case-files-upload-dicom>
        <app-case-files-upload-dicom
                *ngIf="allowedUploadTargeting"
                class="overview-upload-item"
                [caseUpdated]="caseUpdated"
                [dataElement]="elements.T2_DICOM"
                [titleKey]="'caseUploadTypeTargeting'"
                [supportedFormats]="['T2']"
                [canUpload]="canUploadTargeting && allowedUploadTargeting"
                [uploadCount]="uploadCount(elements.T2_DICOM)"
                [elementUploadReport]="uploadReport(elements.T2_DICOM)"
                [uploadDicomRequestHandler]="notifyUploadDicomRequest">
        </app-case-files-upload-dicom>
        <app-case-files-upload-dicom
                *ngIf="allowedUploadPostop"
                class="overview-upload-item"
                [caseUpdated]="caseUpdated"
                [dataElement]="elements.POSTOP_DICOM"
                [titleKey]="'caseUploadTypePostOp'"
                [supportedFormats]="['CT']"
                [canUpload]="canUploadPostOp && allowedUploadPostop"
                [uploadCount]="uploadCount(elements.POSTOP_DICOM)"
                [elementUploadReport]="uploadReport(elements.POSTOP_DICOM)"
                [uploadDicomRequestHandler]="notifyUploadDicomRequest">
        </app-case-files-upload-dicom>
    </div>
    <!--Start Activity Log-->
    <div class="width-100-p">
        <div class="activity-log-header">
            <p class="mat-typography overview-title" i18n="@@caseOverviewActivityLogTitle">
                {{ 'caseOverviewActivityLogTitle'|translate }}
            </p>
            <div class="legend mat-typography">
                <mat-checkbox *ngIf="allowedHideDebug" [(ngModel)]="activityLogHideDebug"
                              (change)="reloadActivityLog()">
                    {{ 'caseOverviewActivityLogHideDebug'|translate }}
                </mat-checkbox>
                <svg class="error">
                    <circle r="6" cx="50%" cy="50%"></circle>
                </svg>
                <a class="m-l-5"
                   i18n="@@caseOverviewActivityLogLegendError">{{ 'error'|translate }}</a>
                <svg class="warning">
                    <circle r="6" cx="50%" cy="50%"></circle>
                </svg>
                <a class="m-l-5"
                   i18n="@@caseOverviewActivityLogLegendWarning">{{ 'warning'|translate }}</a>
                <svg class="info">
                    <circle r="6" cx="50%" cy="50%"></circle>
                </svg>
                <a class="m-l-5"
                   i18n="@@caseOverviewActivityLogLegendInfo">{{ 'information'|translate }}</a>
            </div>
        </div>
        <mat-table class="mat-elevation-z8 m-3" [dataSource]="activityLogDataSource">
            <ng-container matColumnDef="date" i18n="@@caseOverviewActivityLogTableDate">
                <mat-header-cell *matHeaderCellDef>{{ 'date'|translate }}</mat-header-cell>
                <mat-cell *matCellDef="let activityRow" class="overview-activity-log-date">
                    <span>{{ activityRow.created | localDate }}</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="level">
                <mat-header-cell *matHeaderCellDef><!--empty--></mat-header-cell>
                <mat-cell *matCellDef="let activityRow" class="overview-align-center">
                    <span [ngClass]="getActivityRowClass(activityRow)">
                        <mat-icon *ngIf="activityIcon(activityRow)" class="overview-transform">
                            {{ activityIcon(activityRow) }}
                        </mat-icon>
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="message" i18n="@@caseOverviewActivityLogTableMessage">
                <mat-header-cell *matHeaderCellDef>{{ 'message'|translate }}</mat-header-cell>
                <mat-cell *matCellDef="let activityRow"><span
                        [ngClass]="getActivityRowClass(activityRow)">{{ activityRow.message }}</span>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="activityLogDisplayedColumns"></mat-header-row>
            <mat-row *matRowDef="let activityRow; columns: activityLogDisplayedColumns;"
                     class="table-row"
                     (click)="onActivate(activityRow)">
            </mat-row>
        </mat-table>
    </div>
</div>
