<div class="stl-options-main-container" [@showStlOptions]="stlEnabled">
    <mat-card appearance="outlined" class="stl-options-card">
        <mat-card-content class="stl-options-card-content">
            @if (enableCoordinatesChange) {
                <div class="stl-options-row">
                    <mat-select class="width-60-p" [(ngModel)]="coordinateSystem"
                                (selectionChange)="onChangeImageCoordinates()">
                        @for (cs of coordinateSystems; track cs.id) {
                            <mat-option [value]="cs">{{ cs.titleKey|translate }}</mat-option>
                        }
                    </mat-select>
                </div>
            }
            @if (hasSlices) {
                <div class="stl-options-row stl-options-img-opacity-row">
                <span i18n="@@caseLeadLocationStlOptionsImageOpacity" class="m-r-5">
                    {{ 'caseLeadLocationStlOptionsImageOpacity'|translate }} ({{ imageOpacity|number: '1.1' }}):
                </span>
                    <div class="image-opacity-container">
                        <mat-slider class="width-50-p" min="0" [max]="1" step="0.1" [disabled]="disableSlider"
                                    color="accent">
                            <input matSliderThumb #ngSliderThumb="matSliderThumb"
                                   (input)="onImageOpacityChange(ngSliderThumb.value)" [(value)]="imageOpacity"/>
                        </mat-slider>
                    </div>
                </div>
            }
            @if (hasSlices) {
                <div class="stl-options-row-slices">
                    <div class="stl-options-row-slice-pos mat-typography">
                    <span i18n="@@caseLeadLocationStlOptionsCurrentSlice">{{ 'caseLeadLocationStlOptionsCurrentSlice'|translate }}
                        : <b>{{ getSlicesIndex() }}</b></span>
                    </div>
                    <div class="stl-options-row-slice-slider">
                        <span class="stl-options-slider-text m-r-5 mat-typography">0</span>
                        <mat-slider class="width-100-p"
                                    min="0"
                                    [max]="numOfSlices"
                                    step="1"
                                    color="accent"
                                    [disabled]="disableSlider">
                            <input matSliderThumb
                                   (input)="onSlicesSliderChange(ngSliderThumb.value)"
								   #ngSliderThumb="matSliderThumb"
                                   [(value)]="sliceIndex"/>
                        </mat-slider>
                        <span class="stl-options-slider-text m-l-5 mat-typography">{{ numOfSlices }}</span>
                    </div>
                </div>
            }
            <div class="stl-options-container">
                <div class="stl-options-column">
                    <span class="stl-options-side-text mat-typography">{{ 'stlOptionsElementLeft'|translate }}</span>
                    @for (element of leftElements; track element.id()) {
                        <mat-checkbox (change)="onDisplayElementChange(element.id(), $event.checked)"
                                      [checked]="element.checked"
                                      [disabled]="disabled(element)"
                                      class="stl-options-item mat-typography">
                            {{ element.titleKey | translate }}
                        </mat-checkbox>
                    }
                </div>
                <div class="stl-options-column">
                    <span class="stl-options-side-text mat-typography">{{ 'stlOptionsElementRight'|translate }}</span>
                    @for (element of rightElements; track element.id()) {
                        <mat-checkbox (change)="onDisplayElementChange(element.id(), $event.checked)"
                                      [checked]="element.checked"
                                      [disabled]="disabled(element)"
                                      class="stl-options-item mat-typography">
                            {{ element.titleKey | translate }}
                        </mat-checkbox>
                    }
                </div>
                <div class="stl-options-column">
                    <div class="stl-options-side-text">
                        <mat-checkbox
                                [checked]="allSelected"
                                [indeterminate]="someSelected"
                                (change)="onBulkElementDisplaysChange($event.checked)">{{ 'stlOptionsElementSelectAll'|translate }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>

