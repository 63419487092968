import { Component, Inject, LOCALE_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { formatDate, NgOptimizedImage } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { TranslatePipe } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

interface EnvArgs {
    version: string;
    geography: string;
}

@Component({
    selector: 'app-about',
    standalone: true,
    imports: [
        FormsModule,
        MaterialModule,
        NgOptimizedImage,
        TranslatePipe,
    ],
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})

export class AboutComponent {

    private readonly RELEASE_DATE = new Date(environment.releaseDate);

    // Turn x.y.z-alpha.* into 0x0y0z
    public readonly UDI = '(01)0860001580907' + environment.version.split('-')[0].split('.').map(v => v.padStart(2, '0')).join('');

    constructor(@Inject(LOCALE_ID) public locale: string) {
    }

    get geography(): string {
        return environment.geography;
    }

    get releaseDate(): string {
        return formatDate(this.RELEASE_DATE, 'YYYY-MM', this.locale, 'UTC');
    }

    protected readonly env: EnvArgs = environment;
}
