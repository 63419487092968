import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MenuItem } from './menu-item.interface';
import { Subscriptions } from '../subscriptions.class';
import { MaterialModule } from '../../material.module';
import { TranslatePipe } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-menu-item',
    standalone: true,
    imports: [
        CommonModule,
        MaterialModule,
        TranslatePipe,
    ],
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    animations: [
        trigger('indicatorRotate', [
            state('collapsed', style({transform: 'rotate(0deg)'})),
            state('expanded', style({transform: 'rotate(180deg)'})),
            transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4,0.0,0.2,1)')),
        ])
    ]
})
export class MenuItemComponent implements OnInit, OnDestroy {

    @Input() item: MenuItem;
    @Input() indentLevel: number = 1;

    expanded = false;
    @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;

    private subscriptions = new Subscriptions();

    constructor(public router: Router) {
    }

    ngOnInit() {
        this.subscriptions.add(this.router.events, (event: Event) => {
            if (event instanceof NavigationEnd) {
                this.onUrlChange(event.urlAfterRedirects);
            }
        });
        // set initial state to open if we are on the url of one of the children
        if (this.hasChildren(this.item)) {
            this.expanded = this.item.children.some((x: MenuItem) => this.router.url.indexOf(this.url(x)) === 0);
            this.ariaExpanded = this.expanded;
        }
    }

    ngOnDestroy() {
        this.subscriptions.cancel();
    }

    onItemSelected(item: MenuItem, metaKey: boolean) {
        if (this.hasChildren(item)) {
            this.expanded = !this.expanded;
        }
        else {
            if (item.route) {
                const extras = {queryParams: this.queryParams(item)};
                const url = this.router.serializeUrl(this.router.createUrlTree([item.route], extras));
                metaKey ? window.open(url, '_blank') : this.router.navigate([item.route], extras);
            }
        }
    }

    /**
     * This method is used by the @if of the menu item to hide or show the element based on the hidden value.
     * The default is that the item will be displayed (getter return false)
     */
    get hide(): boolean {
        return this.item?.hidden ?? false;
    }

    public isActive(menuItem: MenuItem): boolean {
        if ((menuItem.activeUrls ?? []).some(url => this.router.url.startsWith(url))) {
            return true;
        }
        return this.router.url === this.url(menuItem);
    }

    public getClass(indentLevel: number): any {
        const result = {
            'expanded': this.expanded
        };
        result[`indent-${indentLevel}`] = true;
        return result;
    }

    private onUrlChange(url: string): void {
        if (this.item.route) {
            this.expanded = url.indexOf(this.url(this.item)) === 0;
            this.ariaExpanded = this.expanded;
        }
    }

    private queryParams(menuItem: MenuItem): any {
        const result: {
            id?: string;
        } = {};

        if (menuItem.documentId) {
            result.id = menuItem.documentId;
        }
        return result;
    }

    private url(menuItem: MenuItem): string {
        if (menuItem.documentId) {
            return `${menuItem.route}?id=${menuItem.documentId}`;
        }
        else {
            return menuItem.route;
        }
    }

    private hasChildren(item: MenuItem): boolean {
        return item.children && (item.children.length > 0);
    }
}
