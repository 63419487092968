import * as THREE from 'three';
import * as cc from '../../case.constants';

export const HEAD_MODEL_COLOR = 0xC0C0C0;
export const ELECTRODE_COLOR = 0xB0B0B0;
export const SELECTED_ELECTRODE_COLOR = 0xB0B044;

// CCS Based Center points
export const CCS_CENTER_STN = new THREE.Vector3(0, 11.6, -4.8);
export const CCS_CENTER_GPI = new THREE.Vector3(0, 4.5, -1.4);
export const CCS_CENTER_VIM = new THREE.Vector3(0, 19.1, 5.1);

class IdFields {
    side?: string;
    sideIndexGroup?: number;
    contactIdGroup?: number;
}

export class ThreeElement {
    constructor(public re: RegExp, public tag: string, public idFields: IdFields, public material: THREE.Material | null) {
    }

    public include(_t: string): boolean {
        return true;
    }

    public idFromUrl(url: string): string {
        const m = url.match(this.re);
        return this.idFromData(this.side(), this.sideIndex(m));
    }

    public idFromData(side: string, sideIndex: number): string {
        return `${this.tag}_${side}_${sideIndex}`;
    }

    public name(_url: string, side: string, sideIndex: number): string {
        return this.idFromData(side, sideIndex);
    }

    protected side(): string {
        if (this.idFields.side === undefined) {
            throw new Error('Expected side to be provided');
        }
        return this.idFields.side;
    }

    protected sideIndex(m: RegExpMatchArray): number {
        if (this.idFields.sideIndexGroup === undefined) {
            throw new Error('Expected sideIndexGroup to be provided');
        }
        return Number(m[this.idFields.sideIndexGroup]);
    }
}

export class ThreeBrainElement extends ThreeElement {
    constructor(re: RegExp, tag: string, idFields: IdFields, mesh: THREE.MeshLambertMaterial, public soi: string, private includedIn: Array<cc.Targets>) {
        super(re, tag, idFields, mesh);
    }

    public idFromUrl(_url: string): string {
        return `${this.tag}`;
    }

    public include(t: string): boolean {
        return this.includedIn.includes(cc.Targets[t]);
    }
}

export class ThreeContactElement extends ThreeElement {
    constructor(re: RegExp, tag: string, public idFields: IdFields, mesh: THREE.MeshLambertMaterial) {
        super(re, tag, idFields, mesh);
    }

    public name(url: string, side: string, sideIndex: number): string {
        if (this.idFields.contactIdGroup === undefined) {
            throw new Error('Expected contactIdGroup to be provided');
        }
        const contactId = this.re.exec(url)[this.idFields.contactIdGroup];
        return `contact_${side}_${sideIndex}_${contactId}`;
    }
}

// Brain Part - STN (Left and Right)
export const STL_LEFT_STN = new ThreeBrainElement(new RegExp(/STN_L_smooth.stl/),
    cc.STN_L, {side: cc.LEFT},
    new THREE.MeshLambertMaterial({
            name: cc.STN_L, color: 0x5CBB5D, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.STN, [cc.Targets.STN, cc.Targets.ALL]
);
export const STL_RIGHT_STN = new ThreeBrainElement(new RegExp(/STN_R_smooth.stl/),
    cc.STN_R, {side: cc.RIGHT},
    new THREE.MeshLambertMaterial({
            name: cc.STN_R, color: 0x5CBB5D, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.STN, [cc.Targets.STN, cc.Targets.ALL]
);
// Brain Part - RN (Left and Right)
export const STL_LEFT_RN = new ThreeBrainElement(new RegExp(/RN_L_smooth.stl/),
    cc.RN_L, {side: cc.LEFT},
    new THREE.MeshLambertMaterial({
            name: cc.RN_L, color: 0xB05D5D, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.RN, [cc.Targets.STN, cc.Targets.ALL]
);
export const STL_RIGHT_RN = new ThreeBrainElement(new RegExp(/RN_R_smooth.stl/),
    cc.RN_R, {side: cc.RIGHT},
    new THREE.MeshLambertMaterial({
            name: cc.RN_R, color: 0xB05D5D, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.RN, [cc.Targets.STN, cc.Targets.ALL]
);
// Brain Part - GPE (Left and Right)
export const STL_LEFT_GPE = new ThreeBrainElement(new RegExp(/GPE_L_smooth.stl/),
    cc.GPE_L, {side: cc.LEFT},
    new THREE.MeshLambertMaterial({
            name: cc.GPE_L, color: 0x480000, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.GPE, [cc.Targets.GP, cc.Targets.ALL]
);
export const STL_RIGHT_GPE = new ThreeBrainElement(new RegExp(/GPE_R_smooth.stl/),
    cc.GPE_R, {side: cc.RIGHT},
    new THREE.MeshLambertMaterial({
            name: cc.GPE_R, color: 0x480000, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.GPE, [cc.Targets.GP, cc.Targets.ALL]
);
// Brain Part - GPI (Left and Right)
export const STL_LEFT_GPI = new ThreeBrainElement(new RegExp(/GPI_L_smooth.stl/),
    cc.GPI_L, {side: cc.LEFT},
    new THREE.MeshLambertMaterial({
            name: cc.GPI_L, color: 0xE6CC8A, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.GPI, [cc.Targets.GP, cc.Targets.ALL]
);
export const STL_RIGHT_GPI = new ThreeBrainElement(new RegExp(/GPI_R_smooth.stl/),
    cc.GPI_R, {side: cc.RIGHT},
    new THREE.MeshLambertMaterial({
            name: cc.GPI_R, color: 0xE6CC8A, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.GPI, [cc.Targets.GP, cc.Targets.ALL]
);
// Brain Part - VIM (Left and Right)
export const STL_LEFT_VIM = new ThreeBrainElement(new RegExp(/VIM_L_smooth.stl/),
    cc.VIM_L, {side: cc.LEFT},
    new THREE.MeshLambertMaterial({
            name: cc.VIM_L, color: 0x000076, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.VIM, [cc.Targets.VIM, cc.Targets.ALL]
);
export const STL_RIGHT_VIM = new ThreeBrainElement(new RegExp(/VIM_R_smooth.stl/),
    cc.VIM_R, {side: cc.RIGHT},
    new THREE.MeshLambertMaterial({
            name: cc.VIM_R, color: 0x000076, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.VIM, [cc.Targets.VIM, cc.Targets.ALL]
);
// Brain Part - VC (Left and Right)
export const STL_LEFT_VC = new ThreeBrainElement(new RegExp(/VC_L_smooth.stl/),
    cc.VC_L, {side: cc.LEFT},
    new THREE.MeshLambertMaterial({
            name: cc.VC_L, color: 0x0C5100, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.VC, [cc.Targets.VIM, cc.Targets.ALL]
);
export const STL_RIGHT_VC = new ThreeBrainElement(new RegExp(/VC_R_smooth.stl/),
    cc.VC_R, {side: cc.RIGHT},
    new THREE.MeshLambertMaterial({
            name: cc.VC_R, color: 0x0C5100, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.VC, [cc.Targets.VIM, cc.Targets.ALL]
);
// Brain Part - VO (Left and Right)
export const STL_LEFT_VO = new ThreeBrainElement(new RegExp(/VO_L_smooth.stl/),
    cc.VO_L, {side: cc.LEFT},
    new THREE.MeshLambertMaterial({
            name: cc.VO_L, color: 0xFB00FF, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.VO, [cc.Targets.VIM, cc.Targets.ALL]
);
export const STL_RIGHT_VO = new ThreeBrainElement(new RegExp(/VO_R_smooth.stl/),
    cc.VO_R, {side: cc.RIGHT},
    new THREE.MeshLambertMaterial({
            name: cc.VO_R, color: 0xFB00FF, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.VO, [cc.Targets.VIM, cc.Targets.ALL]
);
// Brain Part - MD (Left and Right)
export const STL_LEFT_MD = new ThreeBrainElement(new RegExp(/MD_L_smooth.stl/),
    cc.MD_L, {side: cc.LEFT},
    new THREE.MeshLambertMaterial({
            name: cc.MD_L, color: 0x8FCC2B, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.MD, [cc.Targets.VIM, cc.Targets.ALL]
);
export const STL_RIGHT_MD = new ThreeBrainElement(new RegExp(/MD_R_smooth.stl/),
    cc.MD_R, {side: cc.RIGHT},
    new THREE.MeshLambertMaterial({
            name: cc.MD_R, color: 0x8FCC2B, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.MD, [cc.Targets.VIM, cc.Targets.ALL]
);
// Brain Part - PU (Left and Right)
export const STL_LEFT_PU = new ThreeBrainElement(new RegExp(/PU_L_smooth.stl/),
    cc.PU_L, {side: cc.LEFT},
    new THREE.MeshLambertMaterial({
            name: cc.PU_L, color: 0xA72C61, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.PU, [cc.Targets.VIM, cc.Targets.ALL]
);
export const STL_RIGHT_PU = new ThreeBrainElement(new RegExp(/PU_R_smooth.stl/),
    cc.PU_R, {side: cc.RIGHT},
    new THREE.MeshLambertMaterial({
            name: cc.PU_R, color: 0xA72C61, transparent: true, opacity: 0.5, side: THREE.FrontSide
        }
    ), cc.PU, [cc.Targets.VIM, cc.Targets.ALL]
);

export const BRAIN_ELEMENTS = new Array<ThreeBrainElement>(
    STL_LEFT_STN, STL_RIGHT_STN,
    STL_LEFT_RN, STL_RIGHT_RN,
    STL_LEFT_GPE, STL_RIGHT_GPE,
    STL_LEFT_GPI, STL_RIGHT_GPI,
    STL_LEFT_VIM, STL_RIGHT_VIM,
    STL_LEFT_VC, STL_RIGHT_VC,
    STL_LEFT_VO, STL_RIGHT_VO,
    STL_LEFT_MD, STL_RIGHT_MD,
    STL_LEFT_PU, STL_RIGHT_PU,
);

// Electrode Parts - Shaft, Left and Right, Index
export const STL_SHAFT = new ThreeElement(new RegExp(/shaft.stl/),
    cc.ELECTRODE, {},
    new THREE.MeshLambertMaterial({name: 'electrode_local', color: ELECTRODE_COLOR, side: THREE.DoubleSide})
);
export const STL_LEFT_SHAFT = new ThreeElement(new RegExp(/Shaft_L_(\d+).stl/),
    cc.ELECTRODE, {side: cc.LEFT, sideIndexGroup: 1},
    new THREE.MeshLambertMaterial({name: 'electrode_left', color: ELECTRODE_COLOR, side: THREE.DoubleSide})
);
export const STL_RIGHT_SHAFT = new ThreeElement(new RegExp(/Shaft_R_(\d+).stl/),
    cc.ELECTRODE, {side: cc.RIGHT, sideIndexGroup: 1},
    new THREE.MeshLambertMaterial({name: 'electrode_right', color: ELECTRODE_COLOR, side: THREE.DoubleSide})
);
// Electrode Parts - Tip, Left and Right, Index
export const STL_TIP = new ThreeElement(new RegExp(/tip.stl/),
    cc.TIP, {},
    new THREE.MeshLambertMaterial({name: 'tip_local', color: 0xB0B0B0, side: THREE.DoubleSide})
);
export const STL_LEFT_TIP = new ThreeElement(new RegExp(/Tip_L_(\d+).stl/),
    cc.TIP, {side: cc.LEFT, sideIndexGroup: 1},
    new THREE.MeshLambertMaterial({name: 'tip_left', color: 0xB0B0B0, side: THREE.DoubleSide})
);
export const STL_RIGHT_TIP = new ThreeElement(new RegExp(/Tip_R_(\d+).stl/),
    cc.TIP, {side: cc.RIGHT, sideIndexGroup: 1},
    new THREE.MeshLambertMaterial({name: 'tip_right', color: 0xB0B0B0, side: THREE.DoubleSide})
);
// Electrode Parts - Contacts - Left and Right, Side Index and Contact Index
export const STL_CONTACT = new ThreeContactElement(new RegExp(/contact_(\d+).stl/),
    cc.CONTACTS, {contactIdGroup: 1},
    new THREE.MeshLambertMaterial({name: 'contacts_local', color: 0x808B96, side: THREE.DoubleSide})
);
export const STL_LEFT_CONTACT = new ThreeContactElement(new RegExp(/Contact_(\d+)_L_(\d+).stl/),
    cc.CONTACTS,
    {side: cc.LEFT, sideIndexGroup: 2, contactIdGroup: 1},
    new THREE.MeshLambertMaterial({name: 'contacts_left', color: 0x808B96, side: THREE.DoubleSide})
);
export const STL_RIGHT_CONTACT = new ThreeContactElement(new RegExp(/Contact_(\d+)_R_(\d+).stl/),
    cc.CONTACTS, {side: cc.RIGHT, sideIndexGroup: 2, contactIdGroup: 1},
    new THREE.MeshLambertMaterial({name: 'contacts_right', color: 0x808B96, side: THREE.DoubleSide})
);
// Electrode Parts - Marker - Left and Right, Side Index
export const STL_LEFT_MARKER = new ThreeElement(new RegExp(/Marker_L_(\d+).stl/),
    cc.MARKER, {side: cc.LEFT, sideIndexGroup: 1},
    new THREE.MeshLambertMaterial({name: 'marker_left', color: 0x808B96, side: THREE.DoubleSide})
);
export const STL_RIGHT_MARKER = new ThreeElement(new RegExp(/Marker_R_(\d+).stl/),
    cc.MARKER, {side: cc.RIGHT, sideIndexGroup: 1},
    new THREE.MeshLambertMaterial({name: 'marker_right', color: 0x808B96, side: THREE.DoubleSide})
);
export const STL_MARKER = new ThreeElement(new RegExp(/marker.stl/),
    cc.MARKER, {},
    new THREE.MeshLambertMaterial({name: 'marker_local', color: 0x808B96, side: THREE.DoubleSide})
);
// UI Parts - Elements created only on the client
export const STL_SELECTED_CONTACT = new ThreeElement(null, cc.SELECTED_CONTACT, {},
    new THREE.MeshLambertMaterial({name: 'selected_contact', color: 0x0000FF, side: THREE.DoubleSide})
);
export const T1_VERTEX_SHADER: string = `
    uniform vec2 size;
    out vec2 vUv;

    void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        vUv.xy = position.xy / size + 0.5;
    }
`;

export const T1_FRAGMENT_SHADER: string = `
    precision highp float;
    precision highp int;
    precision highp sampler2DArray;

    uniform sampler2DArray diffuse;
    in vec2 vUv;
    uniform int depth;
    uniform float opacity;
    
    out vec4 outColor;
    
    void main() {
        vec4 color = texture(diffuse, vec3(vUv, depth));
        // lighten a bit
        outColor = vec4(color.rrr * 1.5, opacity);
    }
`;

export const T1_PLANE = new ThreeElement(null, cc.T1_PLANE, {},
    new THREE.ShaderMaterial({
        uniforms: {
            // diffuse and size are placeholders and will be updated later when we have the image data
            diffuse: {value: null},
            size: {value: null},
            depth: {value: 0},
            opacity: {value: cc.INITIAL_IMG_OPACITY},
        },
        vertexShader: T1_VERTEX_SHADER,
        fragmentShader: T1_FRAGMENT_SHADER,
        transparent: true,
        side: THREE.DoubleSide,
        glslVersion: THREE.GLSL3
    }));

export const ELECTRODE_ELEMENTS = new Array<ThreeElement>(
    STL_SHAFT, STL_LEFT_SHAFT, STL_RIGHT_SHAFT,
    STL_TIP, STL_LEFT_TIP, STL_RIGHT_TIP,
    STL_MARKER, STL_LEFT_MARKER, STL_RIGHT_MARKER,
    STL_CONTACT, STL_LEFT_CONTACT, STL_RIGHT_CONTACT, STL_SELECTED_CONTACT,
    T1_PLANE
);
