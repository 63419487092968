import { Component, OnInit } from '@angular/core';
import { AlphaMap, Elements, Targets } from '../../../case.constants';
import { CaseResponse, SegValidationItem } from '../../../../services/api.service';
import { PredictionValidationComponent } from '../../prediction-validation/prediction-validation.component';
import { CaseService } from '../../../case.service';
import { MatDialog } from '@angular/material/dialog';
import { SegmentationErrorCodes } from '../../../case.errors';
import { FlowReviewDirective } from '../flow-review.directive';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../../material.module';
import { TranslatePipe } from '@ngx-translate/core';

interface SegmentationReviewForm {
    approved_targets: FormArray<FormControl<boolean>>;
    exit_code: FormControl<number>;
}

interface ApprovedTarget {
    disabled: boolean;
    approved: boolean;
    key: string;
    titleKey: string;
}

@Component({
    selector: 'app-case-segmentation-review',
    standalone: true,
    imports: [
        MaterialModule,
        ReactiveFormsModule,
        TranslatePipe,
    ],
    templateUrl: './segmentation-review.component.html',
    styleUrls: ['./segmentation-review.component.scss']
})
export class SegmentationReviewComponent extends FlowReviewDirective implements OnInit {

    public targets: Array<ApprovedTarget> = [
        {disabled: true, approved: false, key: Targets.STN, titleKey: 'stn'},
        {disabled: true, approved: false, key: Targets.GP, titleKey: 'gp'},
        {disabled: true, approved: false, key: Targets.VIM, titleKey: 'vim'}
    ];

    public flowOptions: FormGroup<SegmentationReviewForm>;
    private tControls: FormArray<FormControl<boolean>>;
    private dialogOpen = false;

    constructor(caseService: CaseService, private dialog: MatDialog) {
        super(caseService, SegmentationErrorCodes);
    }

    get targetsControls(): Array<FormControl> {
        return this.tControls.controls as Array<FormControl>;
    }

    ngOnInit(): void {
        this.tControls = new FormArray<FormControl<boolean>>(this.targets.map(target => {
            return new FormControl<boolean>({value: target.approved, disabled: target.disabled});
        }));
        this.flowOptions = new FormGroup<SegmentationReviewForm>({
            approved_targets: this.tControls,
            exit_code: this.exitCode
        });
        super.ngOnInit();
    }

    updateFlowData(data: CaseResponse): void {
        this.caseData = data;
        this.latestFlow = this.caseService.latestTargetingFlow(this.caseData);
        if (this.latestFlow) {
            this.tControls.controls.forEach((c: FormControl<boolean>, i: number) => {
                c.setValue(this.latestFlow.approved_targets.includes(this.targets[i].key));
                this.flowReady && this.caseService.hasElement(this.caseData, AlphaMap.get(this.targets[i].key).elementId) ? c.enable() : c.disable();
            });
            this.exitCode.setValue(this.latestFlow.exit_code);
            this.flowReady ? this.exitCode.enable() : this.exitCode.disable();
            this.flowOptions.markAsPristine();
        }
    }

    private equal(a: Array<string>, b: Array<string>): boolean {
        return a.length === b.length && a.every(item => b.includes(item));
    }

    onSelectionChange(): void {
        if (this.flowReady && this.exitCode.value === this.latestFlow.exit_code && this.equal(this.approvedTargets(), this.latestFlow.approved_targets)) {
            this.flowOptions.markAsPristine();
        }
    }

    public applyChanges(): void {
        const payload: any = {};
        const newApproved = this.approvedTargets();
        if (!this.equal(newApproved, this.latestFlow.approved_targets)) {
            payload.approved_targets = newApproved;
        }

        if (this.exitCode.value !== this.latestFlow.exit_code) {
            payload.exit_code = this.exitCode.value;
        }
        this.updateFlow(payload);
    }

    private approvedTargets(): Array<string> {
        const arr = [];
        this.targetsControls.forEach((item, i) => {
            if (item.value) {
                arr.push(this.targets[i].key);
            }
        });
        return arr;
    }

    public disablePredictionValidation(): boolean {
        if (this.dialogOpen || !this.caseData) {
            return true;
        }
        const de = this.caseService.latestElement(this.caseData, Elements.SEGMENTATION_VALIDATION);
        return !de || !de.value;
    }

    public showPredictionValidation(): void {
        const de = this.caseService.latestElement(this.caseData, Elements.SEGMENTATION_VALIDATION);
        const pvdOptions = {
            height: `${Math.round(window.innerHeight * 0.8)}px`,
            width: `${Math.round(window.innerWidth * 0.8)}px`,
            data: de.value as Array<SegValidationItem>
        };
        this.dialogOpen = true;
        const dialogRef = this.dialog.open(PredictionValidationComponent, pvdOptions);
        this.subscriptions.limit(dialogRef.afterClosed()).subscribe(() => {
            this.dialogOpen = false;
        });
    }
}
