import { Component, Inject, OnInit } from '@angular/core';
import { DicomSeries, DicomSlice } from '../dicom.class';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

interface Row {
    slice: DicomSlice;
    index: number;
    distance: number | null;
    displayedDistance: string;
}

@Component({
    selector: 'select-dicom-details',
    templateUrl: './select-dicom-details.component.html',
    styleUrls: ['./select-dicom-details.component.scss']
})
export class SelectDicomDetailsComponent implements OnInit {

    public includedSlicesColumns: Array<string> = ['instanceNumber', 'x-position', 'y-position', 'z-position', 'distance', 'filePath'];
    public excludedSlicesColumns: Array<string> = ['instanceNumber', 'x-position', 'y-position', 'z-position', 'filePath','excludeFor'];

    public rows: Array<Row>;

    public titleText: string = '';
    public subTitle: string = '';

    constructor(@Inject(MAT_DIALOG_DATA) public ds: DicomSeries, private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.rows = this.ds.dicomSlices.map((slice, index) => {
            const dist = index > 0 ? slice.pos.distanceTo(this.ds.dicomSlices[index - 1].pos) : null;
            return {
                slice: slice,
                index: index,
                distance: dist,
                displayedDistance: dist !== null ? dist.toFixed(2) : ''
            };
        });
        this.setTitles();
    }

    private setTitles() {
        this.translate.get(['dicomDetailsTitle', 'dicomDetailsSubTitle'], {
            seriesLocalPath: this.getPath(),
            validSlices: this.ds.dicomSlices.length,
            excludedSlices: this.ds.extractedSlices.length
        }).subscribe(
            (translated: string[]) => {
                this.titleText = translated['dicomDetailsTitle'];
                this.subTitle = translated['dicomDetailsSubTitle'];
            }
        );
    }

    public formatNumber(value: number): string {
        return value?.toFixed(2) ?? '';
    }

    public getPath(): string {
        const uniquePaths = new Set(this.ds.dicomSlices.map(s => {
            const path = this.getName(s);
            return path.substring(0, path.lastIndexOf('/'));
        }));
        return new Array(...uniquePaths).sort().join(', ');
    }

    public getName(slice: DicomSlice): string {
        return slice?.theFile?.webkitRelativePath ?? '';
    }

    public distanceClass(row: Row): string {
        if (row.index > 1 && this.rows[row.index - 1].displayedDistance !== row.displayedDistance) {
            return 'warning-text';
        }
        return '';
    }
}
