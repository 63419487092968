import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Case, CaseService } from '../../case.service';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { DataElementResponse, FlowResponse } from '../../../services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { compareFlowsByEndDate, FlowRow, FlowsService } from '../../../services/flows.service';
import { LocalDatePipe } from '../../../tools/local-date.pipe';
import { ViewFlowLogComponent } from '../view-flow-log/view-flow-log.component';

import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../../material.module';
import { TranslatePipe } from '@ngx-translate/core';
import { CreateMrbComponent } from '../create-mrb/create-mrb.component';

const NO_SELECTION: FlowResponse = {
    case_id: '',
    code: -1,
    created: '',
    id: '',
    updated: '',
    user_id: '',
    visibility: 0
};

type FlowId = string;

@Component({
    selector: 'app-case-results-review',
    standalone: true,
    imports: [
        FormsModule,
        MaterialModule,
        TranslatePipe,
        CreateMrbComponent,
    ],
    templateUrl: './results-review.component.html',
    styleUrls: ['./results-review.component.scss']
})
export class ResultsReviewComponent implements OnInit, OnDestroy {

    @Input() caseUpdated: Subject<Case>;
    @Input() allowedAdvancedReview: boolean;

    private lifetimeSubscriptions = new Subscriptions();

    private datePipe = new LocalDatePipe();

    private caseData: Case;
    private dialogOpen = false;

    public flows: Array<FlowResponse> = [];
    public selectedFlow: FlowResponse;
    public logElements: Map<FlowId, DataElementResponse> = new Map<FlowId, DataElementResponse>();

    constructor(private caseService: CaseService, private flowService: FlowsService, private dialog: MatDialog) {
        this.selectedFlow = NO_SELECTION;
    }

    public ngOnInit(): void {
        this.lifetimeSubscriptions.add(this.caseUpdated, (data: Case) => {
            this.caseData = data;
            // we will have the flow.log DE only for flows that completed (with or without errors)
            const endedFlows = this.caseData.flows.filter(f => f.end_time).sort(compareFlowsByEndDate);
            // check if any of the flows (that ended) have flow_log data element
            this.logElements = this.caseService.getFlowLogs(this.caseData, endedFlows);
            this.flows = this.caseData.flows.filter(f => this.logElements.has(f.id));
        });
    }

    public ngOnDestroy() {
        this.lifetimeSubscriptions.cancel();
    }

    public formatFlow(f: FlowRow): string {
        if (f.code === NO_SELECTION.code) {
            return 'None';
        }
        return `${(this.flowService.flowByCode(f.code).flowCode)} - ${this.datePipe.transform(f.end_time)}`;
    }

    get flowsList(): Array<FlowRow> {
        return [NO_SELECTION].concat(this.flows);
    }

    public disableSubmitFlow(): boolean {
        return this.selectedFlow.code === NO_SELECTION.code || this.dialogOpen;
    }

    public showFlowLog() {
        // open a dialog with the flow_id as data. The dialog will request the data and display it
        this.dialogOpen = true;
        const dialogConfig = {
            height: `${Math.round(window.innerHeight * 0.8)}px`,
            width: `${Math.round(window.innerWidth * 0.8)}px`,
            data: {
                caseId: this.caseData.id,
                flowData: this.selectedFlow,
                logElement: this.logElements.get(this.selectedFlow.id)
            }
        };
        const dialogRef = this.dialog.open(ViewFlowLogComponent, dialogConfig);
        this.lifetimeSubscriptions.limit(dialogRef.afterClosed()).subscribe(() => {
            // do not change selection on dialog close. Maybe the user want to view the log again.
            this.dialogOpen = false;
        });
    }
}
