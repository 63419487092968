import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UploadService } from './upload.service';
import { DicomUploadReport } from '../../services/api.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { DataElement } from '../data-element.class';
import { DicomSeries } from '../overview/dicom.class';
import { Observable } from 'rxjs';
import { UploadDirective } from './upload.directive';
import {
    MAX_SPACING,
    MAX_SPACING_INCONSISTENCY,
    MAX_THICKNESS,
    MIN_NUMBER_OF_SLICES,
    VOXEL_SPACE_EPSILON,
} from '../dicom.constants';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';

export class UploadDicomRequest {
    constructor(public element: DataElement, public series: DicomSeries) {
    }
}

@Component({
    selector: 'app-case-files-upload-dicom',
    standalone: true,
    imports: [
        CommonModule,
        MaterialModule,
        TranslatePipe
    ],
    templateUrl: './upload-dicom.component.html',
    styleUrls: ['./upload.component.scss']
})
export class UploadDicomComponent extends UploadDirective implements OnInit, OnDestroy {

    @Input() uploadDicomRequestHandler: Observable<UploadDicomRequest>;

    private readonly successUploadTooltipKey: string = 'caseUploadSequenceSuccessTooltip';
    private readonly riskUploadTooltipKey: string = 'caseUploadSequenceRiskTooltip';

    constructor(uploadService: UploadService, translate: TranslateService) {
        super(uploadService, translate);
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscriptions.add(this.uploadDicomRequestHandler,
            (uploadDicom: UploadDicomRequest) => {
                if (uploadDicom.element === this.dataElement) {
                    this.uploadFiles(uploadDicom.series.files, uploadDicom.series.disableDI, uploadDicom.series.seriesId,
                        uploadDicom.series.studyId, uploadDicom.series.patientId);
                }
            }
        );
    }

    setUploadReport(value: DicomUploadReport): void {
        super.setUploadReport(value);
    }

    getUploadReport(): DicomUploadReport {
        return super.getUploadReport() as DicomUploadReport;
    }

    setElementUploadReport(value: DicomUploadReport): void {
        super.setElementUploadReport(value);
    }

    getElementUploadReport(): DicomUploadReport {
        return super.getElementUploadReport() as DicomUploadReport;
    }

    getFileCount(): number {
        const report = this.validUploadReport();
        return report && report.num_slices ? report.num_slices : this.uploadCount;
    }

    getSequence(): string {
        const report = this.validUploadReport();
        return report && report.sequence ? report.sequence : '';
    }

    hasSequence(): boolean {
        const report = this.validUploadReport();
        return report && report.sequence.length > 0;
    }

    getSequenceClass(): any {
        const successClass = this.supportedFormats.includes(this.getSequence()) && !this.hasUploadErrors();
        return successClass ? {'upload-sequence-icon-ok': true} : {'upload-sequence-icon-warn': true};
    }

    getUploadTooltip(): string {
        const report = this.validUploadReport();
        if (report && report.errors && report.errors.length > 0 && report.di) {
            // we had DI checks and there were errors => the data was not saved => no tooltip
            return '';
        }
        if (this.supportedFormats.includes(this.getSequence()) && !this.hasUploadErrors()) {
            // if we have a valid sequence and there are no errors - the data was saved
            return this.successUploadTooltipKey;
        }
        else {
            // the sequence is not Ok or there are errors/warnings - cautious text
            return this.riskUploadTooltipKey;
        }
    }

    getSliceThickness(): number {
        const report = this.validUploadReport();
        return report && report.slice_thickness ? report.slice_thickness : -1;
    }

    thicknessClass(): any {
        return {
            'integrity-error': this.getSliceThickness() > MAX_THICKNESS
        };
    }

    get xSpacing(): number | null {
        const report = this.validUploadReport();
        return report && report.x_spacing ? report.x_spacing : null;
    }

    get ySpacing(): number | null {
        const report = this.validUploadReport();
        return report && report.y_spacing ? report.y_spacing : null;
    }

    get zSpacing(): Array<number> {
        const report = this.validUploadReport();
        if (!report || !report.z_spacing_range) {
            return [];
        }
        if (report.z_spacing_range.length === 1) {
            return [report.z_spacing_range[0]];
        }
        if (Math.abs(report.z_spacing_range[1] - report.z_spacing_range[0]) <= MAX_SPACING_INCONSISTENCY) {
            return [(report.z_spacing_range[1] + report.z_spacing_range[0]) / 2];
        }
        return report.z_spacing_range;
    }

    public spacingClass(value: number): any {
        return {
            'integrity-error': value > (MAX_SPACING + VOXEL_SPACE_EPSILON)
        };
    }

    protected validUploadReport(): DicomUploadReport | null {
        return super.validUploadReport() as DicomUploadReport;
    }

    getEventProperties(): any {
        const properties = super.getEventProperties();
        const report = this.getUploadReport();
        properties.sliceCount = report.num_slices || 0;
        properties.sequence = report.sequence || '';
        properties.imageUse = report.image_use || '';
        return properties;
    }

    getMinFileCount(): number {
        return MIN_NUMBER_OF_SLICES;
    }

    getMaxSpacing(): number {
        return MAX_SPACING;
    }
}
