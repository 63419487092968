export class Values {
    // Utility methods for value-like objects; object cycles are not handled, nor non-data types (like functions). All value-holding
    // properties are expected to be own properties, not higher in the prototype chain.

    public static equal(a: any, b: any): boolean {
        if (typeof (a) !== typeof (b)) {
            return false;
        }
        if (a instanceof Object && b instanceof Object) {
            for (const k in a) {
                if (Object.prototype.hasOwnProperty.call(a, k)) {
                    if (!Values.equal(a[k], b[k])) {
                        return false;
                    }
                }
            }
            for (const k in b) {
                if (Object.prototype.hasOwnProperty.call(b, k) && !Object.prototype.hasOwnProperty.call(a, k)) {
                    return false;
                }
            }
            return true;
        }
        else {
            return a === b;
        }
    }

    public static deepCopy(a: any) {
        const result = {};
        for (const k in a) {
            if (Object.prototype.hasOwnProperty.call(a, k)) {
                const value = a[k];
                if (value instanceof Object) {
                    result[k] = Values.deepCopy(value);
                }
                else {
                    result[k] = value;
                }
            }
        }
        return result;
    }
}
