import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'minuteSeconds'})
export class MinuteSecondsPipe implements PipeTransform {

    constructor() {
    }

    transform(milliseconds: number): string {
        const minutes = Math.floor(milliseconds / 1000 / 60);
        const seconds = Math.round((milliseconds - (minutes * 1000 * 60)) / 1000);
        return minutes.toString() + ':' + seconds.toString().padStart(2, '0');
    }
}
