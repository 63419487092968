const PREFIX_TERMINATOR = '/';

export class Compressor {
    private lastPrefix = '';

    public compress(url: string): string {
        const [prefix, suffix] = urlSplit(url);
        if (!prefix) {
            // A URL without a prefix cannot be compressed
            return url;
        } else if (prefix === this.lastPrefix) {
            // A URL with the same prefix as the prior prefix can be compressed down to just its suffix
            return suffix;
        } else {
            // A new prefix has been encountered. This URL cannot be compressed, but remember this prefix
            // for possible compression of subsequent items.
            this.lastPrefix = prefix;
            return url;
        }
    }

    public expand(compressedUrl: string): string {
        const [prefix, suffix] = urlSplit(compressedUrl);
        if (!prefix) {
            // If there was no prefix, this item needs to be expanded by prepending the prior prefix.
            return this.lastPrefix + suffix;
        } else {
            this.lastPrefix = prefix;
            return compressedUrl;
        }
    }
}

export function urlSplit(url: string): string[] {
    let p = url.lastIndexOf(PREFIX_TERMINATOR);
    if (p >= 0) {
        p += 1;
        return [url.slice(0, p), url.slice(p)];
    } else {
        return ['', url];
    }
}

export function urlPrefix(url: string): string {
    return urlSplit(url)[0];
}

export function urlSuffix(url: string): string {
    return urlSplit(url)[1];
}

export function urlCompress(urls: string[]): string[] {
    // Cannot compress a list of fewer than two items
    if (urls.length < 2) {
        return urls;
    }

    // If there are no slashes in any of the items after the first, the list is already compressed and can be
    // returned unchanged. (Note that this will not identify *all* lists that were already compressed, only
    // those that share a single prefix.)
    if (!urls.slice(1).some(url => url.includes(PREFIX_TERMINATOR))) {
        return urls;
    }

    const c = new Compressor();
    return urls.map(url => c.compress(url));
}

export function urlExpand(compressedUrls: string[]): string[] {
    // Cannot expand a list of fewer than two items
    if (compressedUrls.length < 2) {
        return compressedUrls;
    }

    // If all the URL's have a slash, they are not compressed, so return the list unchanged.
    if (compressedUrls.every(url => url.includes(PREFIX_TERMINATOR))) {
        return compressedUrls;
    }

    const c = new Compressor();
    return compressedUrls.map(url => c.expand(url));
}
