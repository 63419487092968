import { Injectable } from '@angular/core';
import { ApiService, ReportsTemplatesResponse, ReportTemplate, SnapshotReportResponse } from '../services/api.service';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ReportsService {

    public static readonly ACCOUNTS = 'accounts';
    public static readonly CASES = 'cases';
    public static readonly USERS = 'users';
    public static readonly GROUPS = 'groups';
    public static readonly IMAGES = 'images';

    // reports supported by the client
    public readonly supportedTemplates = new Map<string, string>([
        [ReportsService.ACCOUNTS, 'reportAccounts'],
        [ReportsService.CASES, 'reportCases'],
        [ReportsService.USERS, 'reportUsers'],
        [ReportsService.GROUPS, 'reportGroups'],
        [ReportsService.IMAGES, 'reportImages']
    ]);
    // reports supported by the server and the client
    availableTemplates = new Map<string, string>();

    private templatesReady: ReplaySubject<boolean> = null;

    constructor(private apiService: ApiService) {
    }

    public getTemplatesReady(): Observable<boolean> {
        if (this.availableTemplates.size === 0 && this.templatesReady == null) {
            this.templatesReady = new ReplaySubject<boolean>(1);
            this.getTemplates();
        }
        return this.templatesReady;
    }

    private getTemplates(): void {
        this.apiService.getReportTemplates().subscribe({
            next: (data: ReportsTemplatesResponse) => {
                const templates: Array<ReportTemplate> = data.templates;
                // for each report supported by the client, if supported by the server as well => save
                Array.from(this.supportedTemplates).forEach(report => {
                    if (templates.find(rt => rt.name === report[0])) {
                        this.availableTemplates.set(report[0], report[1]);
                    }
                });
                this.templatesReady.next(true);
            },
            error: () => {
                this.templatesReady.next(false);
            }
        });
    }

    public getDetailedReport(template: string, startDate: Date, endDate: Date): Observable<Blob> {
        const startStr = startDate.toISOString().slice(0, -1);
        const endStr = this.advanceOneDay(endDate).toISOString().slice(0, -1);
        return this.apiService.getDetailedReport(template, startStr, endStr);
    }

    public getJsonUsageReport(startDate: Date, endDate: Date, context: string, ids: Array<string>): Observable<SnapshotReportResponse> {
        const startStr = startDate.toISOString().slice(0, -1);
        const endStr = this.advanceOneDay(endDate).toISOString().slice(0, -1);
        return this.apiService.getJsonUsageReport(startStr, endStr, context, ids);
    }

    public getCsvUsageReport(startDate: Date, endDate: Date, context: string, ids: Array<string>): Observable<Blob> {
        const startStr = startDate.toISOString().slice(0, -1);
        const endStr = this.advanceOneDay(endDate).toISOString().slice(0, -1);
        return this.apiService.getCsvUsageReport(startStr, endStr, context, ids);
    }

    public getSecurityReport(startDate: Date, endDate: Date): Observable<Blob> {
        const startStr = startDate.toISOString().slice(0, -1);
        const endStr = this.advanceOneDay(endDate).toISOString().slice(0, -1);
        return this.apiService.getSecurityReport(startStr, endStr);
    }

    private advanceOneDay(d: Date): Date {
        return new Date(d.getTime() + (24 * 60 * 60 * 1000));
    }
}
