<mat-card appearance="outlined" class="seg-review-container">
    <mat-card-title class="sis-card-title">
        <span class="mat-typography sis-bold">Segmentation Review</span>
    </mat-card-title>
    <mat-card-content class="seg-review-content-container">
        <div class="seg-review-container-row height-50">
            <button mat-raised-button class="width-70-p"
                    [disabled]="disablePredictionValidation()"
                    (click)="showPredictionValidation()">
                View Segmentation Validation
            </button>
        </div>
        <form [formGroup]="flowOptions">
            <div class="seg-review-container-special-row">
                <div class="seg-review-column seg-review-item">
                    <span class="mat-typography">Approved Targets</span>
                    @for (fc of targetsControls; track $index; ) {
                        <div class="seg-review-item-check-box-item">
                            <mat-checkbox [formControl]="fc" (change)="onSelectionChange()">
                                {{ targets[$index].titleKey|translate }}
                            </mat-checkbox>
                        </div>
                    }
                </div>
                <div class="seg-review-column-divider">
                    <mat-divider vertical class="width-90-p"></mat-divider>
                </div>
                <mat-form-field class="seg-review-column seg-review-item">
                    <mat-label>Exit Code</mat-label>
                    <mat-select [formControl]="exitCode" class="seg-review-item"
                                (selectionChange)="onSelectionChange()" panelWidth="">
                        @for (code of codes; track code) {
                            <mat-option [value]="code.value">
                                {{ code.value }}&nbsp;-&nbsp;{{ code.descKey|translate }}
                            </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
        <mat-card-actions class="seg-review-container-row height-50">
            <button mat-raised-button class="width-70-p"
                    (click)="applyChanges()" [disabled]="!flowOptions.dirty">
                Apply Changes
            </button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>
