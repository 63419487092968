export enum ImageUse {
    // the ImageUse items are listed in the order of the UI upload/report boxes to always upload left to right
    // not matter the order that the user selected the series to upload
    none, planning, targeting, postop
}

export class Modality {
    constructor(public name: string, public dropDownOptions: Array<ImageUse>, public sortOrder: number) {
    }
}

export const MIN_NUMBER_OF_SLICES = 20;
export const VOXEL_SPACE_EPSILON = 0.005;
export const MAX_SPACING = 2.0;
export const MAX_SPACING_INCONSISTENCY = 0.01;
export const MAX_THICKNESS = 2.0;
export const CLF_PROBABILITY_THRESHOLD = 0.6;
export const MAX_IMG_ORIENTATION_DISTANCE = 0.01;

export const MR: Modality = new Modality('MR', [ImageUse.none, ImageUse.targeting, ImageUse.planning], 0);
export const CT: Modality = new Modality('CT', [ImageUse.none, ImageUse.postop], 1);

export const MODALITIES: Map<string, Modality> = new Map<string, Modality>([[MR.name, MR], [CT.name, CT]]);

export const T1 = 'T1';
export const PD = 'PD';

export const T2 = 'T2';
export const FGATIR = 'FGATIR';
export const FLAIR = 'FLAIR';
export const STIR = 'STIR';
export const DWI = 'DWI';
export const SWI = 'SWI';
export const GRE = 'GRE';

export const ALL_PLANNING: Array<string> = [T1, PD];
export const ALL_TARGETING: Array<string> = [T2, FGATIR, FLAIR, STIR, SWI, DWI, GRE];

export const VALID_PLANNING: Array<string> = [T1];
export const VALID_TARGETING: Array<string> = [T2];

export const VALID_IMAGE_USE: Map<ImageUse, Array<string>> = new Map<ImageUse, Array<string>>([
    [ImageUse.planning, VALID_PLANNING],
    [ImageUse.targeting, VALID_TARGETING]
]);
