import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivityLogLevel, Case, CaseService } from '../../case.service';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { Subject } from 'rxjs';


@Component({
    selector: 'app-case-add-activity-message',
    templateUrl: './add-activity-message.component.html',
    styleUrls: ['./add-activity-message.component.scss']
})
export class AddActivityMessageComponent implements OnInit, OnDestroy {

    @Input() caseUpdated: Subject<Case>;

    public levels: Array<ActivityLogLevel> = new Array<ActivityLogLevel>(
        ActivityLogLevel.DEBUG, ActivityLogLevel.INFO, ActivityLogLevel.WARNING, ActivityLogLevel.ERROR
    );

    public caseId: string;

    public activityMessage: string;
    public loggingMessage = false;

    public successMessage = '';
    public errorMessage: null | string = null;

    private timerHandle = null;
    private subscriptions = new Subscriptions();

    public level: ActivityLogLevel = ActivityLogLevel.INFO;

    constructor(private caseService: CaseService) {
    }

    public ngOnInit(): void {
        this.subscriptions.add(this.caseUpdated, data => {
            this.caseId = data.id;
        });
    }

    public ngOnDestroy() {
        this.subscriptions.cancel();
    }

    public addActivityMessage() {
        this.reset();
        this.loggingMessage = true;
        this.subscriptions.add(this.caseService.logCaseEvent(this.caseId, this.level, this.activityMessage), updatedCase => {
            this.activityMessage = '';
            this.loggingMessage = false;
            this.caseUpdated.next(updatedCase);
            this.successMessage = 'Activity message added successfully';
            this.errorMessage = null;
            this.scheduleReset();
        }, error => {
            this.loggingMessage = false;
            this.errorMessage = error.message;
            this.successMessage = '';
            this.scheduleReset();
        });
    }

    private scheduleReset() {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
        }
        this.timerHandle = setTimeout(() => this.reset(), 3000);
    }

    private reset() {
        this.errorMessage = null;
        this.successMessage = '';
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = null;
        }
    }

    public levelAsString(lvl: ActivityLogLevel): string {
        return ActivityLogLevel[lvl].toLowerCase();
    }
}
