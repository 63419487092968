import { Injectable } from '@angular/core';
import { NavigationService, NavigationServiceEntry } from '../tools/navigation.service';
import { PermissionsService } from '../services/permissions.service';
import { AuthService } from '../services/auth.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Permissions } from '../security/permissions.class';

@Injectable({providedIn: 'root'})
export class ReportsNavigationService extends NavigationService {

    constructor(permissionsService: PermissionsService, authService: AuthService, router: Router) {
        super(permissionsService, authService, router);
    }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        const children = ['overview', 'detailed', 'security'];
        for (const child of children) {
            if (state.url.endsWith(`/${child}`)) {
                return this.maybeRedirectChild(this.canActivateReports());
            }
        }
        if (state.url.endsWith('/reports')) {
            const canActivate = this.canActivateReports();
            const table = children.map((child: string) => new NavigationServiceEntry(canActivate, child));
            return this.maybeRedirect(state, table);
        }
        // This should only be used as a guard on paths that it knows how to handle.
        return false;
    }

    public canActivateReports(): Observable<boolean> {
        return this.cachedObservable('canActivateReports', () => this.stablePermission(Permissions.reportsQuery()));
    }
}
