import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgIdleModule } from '@ng-idle/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { AppComponent } from './app.component';
import { appRoutes } from './routes';
import { HomeModule } from './home/home.module';
import { CaseModule } from './case/case.module';
import { LoginComponent } from './login/login.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HttpPriorityInterceptor } from './tools/http-priority-interceptor';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MaterialModule } from './material.module';
import { RequiredEmailStateMatcher } from './tools/state-matchers';
import { SettingsModule } from './settings/settings.module';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { HelpModule } from './help/help.module';
import { TosComponent } from './tos/tos.component';
import { AppConfigService } from './app-config.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SelectLanguageComponent } from './select-language/select-language.component';
import { AppServicesModule } from './app-services.module';
import { AppComponentsModule } from './app-components.module';
import { ReportsModule } from './reports/reports.module';
import { SearchComponent } from './search/search.component';
import { NotificationComponent } from './main-nav/notification/notification.component';
import { NotificationsComponent } from './main-nav/notifications/notifications.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

export function initializeConfig(appConfig: AppConfigService) {
    return () => appConfig.load();
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        MainNavComponent,
        SearchComponent,
        NotificationComponent,
        NotificationsComponent,
        ChangePasswordComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
        AcceptInvitationComponent,
        TosComponent,
        SelectLanguageComponent,
    ],
    bootstrap: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        AppServicesModule,
        AppComponentsModule,
        HelpModule,
        HomeModule,
        CaseModule,
        SettingsModule,
        DashboardModule,
        FormsModule,
        ReactiveFormsModule,
        ReportsModule,
        RouterModule.forRoot(appRoutes),
        NgIdleModule.forRoot(),
        MaterialModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        {provide: APP_INITIALIZER, useFactory: initializeConfig, deps: [AppConfigService], multi: true},
        {provide: ErrorStateMatcher, useClass: RequiredEmailStateMatcher},
        {provide: HTTP_INTERCEPTORS, useClass: HttpPriorityInterceptor, multi: true},
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {float: 'auto'}},
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class AppModule {
}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
