<div class="fill-parent">
    <div class="height-50 width-100-p"></div>
    <div class="users-main">
        <div class="mat-elevation-z8">
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="name" i18n="@@settingsUsersTableName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'name'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let userRow"> {{userRow.name}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="email" i18n="@@settingsUsersTableEmail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'email'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let userRow"> {{userRow.email}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="created" i18n="@@settingsUsersTableCreated">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'created'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let userRow"> {{userRow.created | localDate}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="updated" i18n="@@settingsUsersTableUpdated">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'updated'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let userRow"> {{userRow.updated | localDate}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let userRow; columns: displayedColumns;"
                         class="table-row"
                         data-testid="settings-users-rows"
                         (click)="onActivate(userRow, $event.metaKey)">
                </mat-row>
            </mat-table>
            <mat-paginator [pageSize]="10" [hidePageSize]="true"></mat-paginator>
        </div>
        <div class="users-buttons">
            <button mat-fab
                    class="create-user-btn"
                    data-testid="settings-users-create"
                    i18n-matTooltip="@@settingsUsersCreateNewTooltip"
                    matTooltip="{{'settingsUsersCreateNewTooltip'|translate}}"
                    matTooltipPosition="before"
                    color="accent"
                    *ngIf="allowCreateUser"
                    (click)="openCreateUserDialog()">
                <mat-icon>add</mat-icon>
            </button>
            <button mat-fab
                    class="invite-user-btn"
                    i18n-matTooltip="@@settingsUsersInviteNewTooltip"
                    matTooltip="{{'settingsUsersInviteNewTooltip'|translate}}"
                    matTooltipPosition="before"
                    color="accent"
                    *ngIf="allowInviteUser"
                    (click)="openInviteUserDialog()">
                <mat-icon>person_add</mat-icon>
            </button>
        </div>
    </div>
</div>
