<mat-card>
    <mat-card-title class="upload-header">
        <div class="upload-header-text">
            <span class="mat-typography upload-title">{{ uploadType }} DICOM</span>
            <span class="mat-typography upload-sub-title" i18n="@@caseUploadDicomSubTitle">
                {{ 'caseUploadDicomSubTitle'|translate: {'formatsList': supportedFormats.join(', ')} }}
            </span>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div class="upload-report">
            <p class="mat-typography upload-report-item" i18n="@@caseUploadDicomUploadedFilesTitle">
                <b>{{ 'caseUploadDicomUploadedFilesTitle'|translate }} (&ge; {{ getMinFileCount() }}):</b>
                <span [ngClass]="getFileCountClass()"> {{ getFileCount() }}</span>
            </p>
            <div class="mat-typography upload-report-details">
                <div class="upload-report-sequence">
                    <p class="m-r-10" i18n="@@caseUploadDicomDetectedSequenceTitle">
                        <b>{{ 'caseUploadDicomDetectedSequenceTitle'|translate }}:</b>&nbsp;
                    </p>
                    @if (hasSequence()) {
                        <mat-icon
                                [ngClass]="getSequenceClass()"
                                [matTooltip]="getUploadTooltip()| translate: {'type': uploadType}"
                                matTooltipPosition="after">
                            done
                        </mat-icon>
                    }
                </div>
                <p class="m-0" i18n="@@caseUploadDicomSliceThicknessTitle">
                    <b>
                        {{ 'caseUploadDicomSliceThicknessTitle'|translate }}&nbsp;(&le;&nbsp;{{ getMaxSpacing() }}mm):
                    </b>
                    @if (getSliceThickness() > -1) {
                        <span [ngClass]="thicknessClass()">&nbsp;{{ getSliceThickness().toFixed(2) }}</span>
                    }
                </p>
                <p class="m-0" i18n="@@caseUploadDicomSpacingTitle">
                    <span>
                        <span><b>{{ 'caseUploadDicomSpacingTitle'|translate }}&nbsp;(mm&nbsp;|&nbsp;X,Y</b></span>
                        <!-- if we have an x and y spacing but no z, it is an "old" report, do not show the Z -->
                        @if (!(xSpacing !== null && ySpacing !== null && zSpacing.length === 0)) {
                            <span><b>,Z</b></span>
                        }
                        <span><b>&nbsp;|&nbsp;&le;&nbsp;{{ getMaxSpacing() }}mm):&nbsp;</b></span>
                    </span>
                    @if (xSpacing !== null) {
                        <span [ngClass]="spacingClass(xSpacing)">{{ xSpacing.toFixed(2) }}</span>
                    }
                    @if (ySpacing !== null) {
                        <span>,&nbsp;</span>
                        <span [ngClass]="spacingClass(ySpacing)">{{ ySpacing.toFixed(2) }}</span>
                    }
                    @if (zSpacing.length > 0) {
                        <span>,&nbsp;</span>
                        <span>
                            @if (zSpacing.length > 1) {
                                <span>[</span>
                            }
                            <span [ngClass]="spacingClass(zSpacing[0])">{{ zSpacing[0].toFixed(2) }}</span>
                            @if (zSpacing.length > 1) {
                                <span>,&nbsp;</span>
                                <span [ngClass]="spacingClass(zSpacing[1])">
                                    {{ zSpacing[1].toFixed(2) }}
                                </span>
                                <span>]</span>
                            }
                    </span>
                    }
                </p>
            </div>
            <p class="mat-typography upload-report-item m-b-10"
               i18n="@@caseUploadDicomUploadErrors" matTooltip="{{uploadErrors}}">
                <b>{{ 'caseUploadDicomUploadErrors'|translate }}:</b> {{ uploadErrors }}
            </p>
        </div>
        <div class="upload-progress">
            <mat-progress-bar mode="{{getProgressMode()}}" color="primary" value="{{progress}}"></mat-progress-bar>
        </div>
        <div class="upload-progress-report">
            @if (uploadError) {
                <p class="mat-typography upload-error">{{ uploadError.message }}</p>
            }
            @else {
                <p class="mat-typography">{{ progressMessage }}</p>
            }
        </div>
    </mat-card-content>
</mat-card>
