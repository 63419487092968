import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportsService } from '../reports.service';
import { ReportDirective } from '../report.directive';
import { Group, SnapshotReportResponse } from '../../services/api.service';
import { combineLatest, Observable } from 'rxjs';
import { compareUsersByDescription, User, UserService } from '../../services/user.service';
import { compareGroupsByName, GroupService } from '../../services/group.service';
import { map, startWith } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { QuartersService } from '../../services/quarters.service';
import { ElectrodeModelService } from '../../case/electrode-model/electrode-model.service';
import { Account, AccountService, compareAccountsByName } from '../../services/account.service';
import {
    AutoCompleteAccount,
    AutoCompleteGroup,
    AutoCompleteItem,
    AutoCompleteUser
} from '../../tools/context-item.class';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { TranslatePipe } from '@ngx-translate/core';

class UsageContext {
    constructor(public label: string, public value: string, public apiKey: string | null) {
    }
}

const CASES = ReportsService.CASES;
const USERS = ReportsService.USERS;
const ACCOUNTS = ReportsService.ACCOUNTS;
const GROUPS = ReportsService.GROUPS;

export const CONTEXT_ALL: UsageContext = new UsageContext('all', '', null);
export const CONTEXT_USERS: UsageContext = new UsageContext('reportUsers', USERS, 'user_id');
export const CONTEXT_GROUPS: UsageContext = new UsageContext('reportGroups', GROUPS, 'group_id');
export const CONTEXT_ACCOUNTS: UsageContext = new UsageContext('reportAccounts', ACCOUNTS, 'account_id');

interface StatItem {
    apiGroup: string;
    apiKey: string;
    titleKey: string;
    percentFrom?: Array<string>;
    format: any;
    hasValue?: boolean;
    value?: any;
    indent?: boolean;
    btm_border?: boolean;
}

class StatItems {
    public activeItems: StatItem[] = [];

    constructor(public readonly allItems: StatItem[]) {
    }

    public load(report: SnapshotReportResponse) {
        this.activeItems = [];
        this.allItems.forEach(item => {
            item.hasValue = false;
            item.value = null;
            if (report) {
                const group = report[item.apiGroup];
                if (group) {
                    const value = group[item.apiKey];
                    if (value !== undefined) {
                        item.hasValue = true;
                        item.value = value;
                        this.activeItems.push(item);
                    }
                }
            }
        });
    }

    public clear() {
        this.activeItems = [];
    }

    public hasData(): boolean {
        return this.activeItems.length > 0;
    }
}

interface LeadItem {
    model: string;
    count: number;
}

class UsageData {
    readonly caseItems = new StatItems([
        {
            apiGroup: CASES, apiKey: 'total_cases', titleKey: 'reportSnapshotCasesCount', format: this.formatInt,
            btm_border: true
        },
        {
            apiGroup: CASES, apiKey: 'prediction_flows_total', titleKey: 'reportSnapshotPredictionFlowsCount',
            format: this.formatInt
        },
        {
            apiGroup: CASES, apiKey: 'prediction_flows_successful', titleKey: 'reportSnapshotPredictionFlowsSuccess',
            percentFrom: ['prediction_flows_total'], format: this.formatNumAndPercent
        },
        {
            apiGroup: CASES, apiKey: 'prediction_published_auto', titleKey: 'reportSnapshotPredictionPublishAuto',
            percentFrom: ['prediction_published_auto', 'prediction_published_manual', 'prediction_unpublished'],
            format: this.formatNumAndPercent, indent: true
        },
        {
            apiGroup: CASES, apiKey: 'prediction_published_manual', titleKey: 'reportSnapshotPredictionPublishManual',
            percentFrom: ['prediction_published_auto', 'prediction_published_manual', 'prediction_unpublished'],
            format: this.formatNumAndPercent, indent: true
        },
        {
            apiGroup: CASES, apiKey: 'prediction_unpublished', titleKey: 'reportSnapshotPredictionUnpublished',
            percentFrom: ['prediction_published_auto', 'prediction_published_manual', 'prediction_unpublished'],
            format: this.formatNumAndPercent, indent: true,
        },
        {
            apiGroup: CASES, apiKey: 'prediction_flows_excluded', titleKey: 'reportSnapshotPredictionFlowsExcluded',
            percentFrom: ['prediction_flows_total'], format: this.formatNumAndPercent
        },
        {
            apiGroup: CASES, apiKey: 'prediction_flows_failed', titleKey: 'reportSnapshotPredictionFlowsFailed',
            percentFrom: ['prediction_flows_total'], format: this.formatNumAndPercent
        },
        {
            apiGroup: CASES, apiKey: 'prediction_flows_incomplete', titleKey: 'reportSnapshotPredictionFlowsIncomplete',
            percentFrom: ['prediction_flows_total'], format: this.formatNumAndPercent, btm_border: true
        },
        {
            apiGroup: CASES, apiKey: 'postop_flows_total', titleKey: 'reportSnapshotPostOpFlowsCount',
            format: this.formatInt
        },
        {
            apiGroup: CASES, apiKey: 'postop_flows_successful', titleKey: 'reportSnapshotPostOpFlowsSuccess',
            percentFrom: ['postop_flows_total'], format: this.formatNumAndPercent
        },
        {
            apiGroup: CASES, apiKey: 'postop_published_auto',
            percentFrom: ['postop_published_auto', 'postop_published_manual', 'postop_unpublished'],
            titleKey: 'reportSnapshotPostopPublishAuto', format: this.formatNumAndPercent, indent: true
        },
        {
            apiGroup: CASES, apiKey: 'postop_published_manual',
            percentFrom: ['postop_published_auto', 'postop_published_manual', 'postop_unpublished'],
            titleKey: 'reportSnapshotPostopPublishManual', format: this.formatNumAndPercent, indent: true
        },
        {
            apiGroup: CASES, apiKey: 'postop_unpublished',
            percentFrom: ['postop_published_auto', 'postop_published_manual', 'postop_unpublished'],
            titleKey: 'reportSnapshotPostopUnpublished', format: this.formatNumAndPercent, indent: true
        },
        {
            apiGroup: CASES, apiKey: 'postop_flows_excluded', titleKey: 'reportSnapshotPostOpFlowsExcluded',
            percentFrom: ['postop_flows_total'], format: this.formatNumAndPercent
        },
        {
            apiGroup: CASES, apiKey: 'postop_flows_failed', titleKey: 'reportSnapshotPostOpFlowsFailed',
            percentFrom: ['postop_flows_total'], format: this.formatNumAndPercent
        },
        {
            apiGroup: CASES, apiKey: 'postop_flows_incomplete', titleKey: 'reportSnapshotPostOpFlowsIncomplete',
            percentFrom: ['postop_flows_total'], format: this.formatNumAndPercent
        },
    ]);

    readonly caseLeadItems = new StatItems([
        {
            apiGroup: CASES, apiKey: 'total_leads', titleKey: 'reportSnapshotTotalLeads', format: this.formatInt
        },
        {
            apiGroup: CASES, apiKey: 'directional_leads', titleKey: 'reportSnapshotDirectionalLeads',
            percentFrom: ['total_leads'], format: this.formatNumAndPercent
        },
        {
            apiGroup: CASES, apiKey: 'lead_orientations', titleKey: 'reportSnapshotOrientationDetected',
            percentFrom: ['directional_leads'], format: this.formatNumAndPercent
        },
    ]);

    readonly userItems = new StatItems([
        {
            apiGroup: USERS, apiKey: 'total_users', titleKey: 'reportSnapshotUsersCount', format: this.formatInt
        },
        {
            apiGroup: USERS, apiKey: 'active_users', titleKey: 'reportSnapshotUsersActive',
            percentFrom: ['total_users'], format: this.formatNumAndPercent
        },
    ]);

    readonly accountItems = new StatItems([
        {
            apiGroup: ACCOUNTS, apiKey: 'total_accounts',
            titleKey: 'reportSnapshotAccountsCount', format: this.formatInt
        },
        {
            apiGroup: ACCOUNTS, apiKey: 'active_accounts', titleKey: 'reportSnapshotAccountActive',
            percentFrom: ['total_accounts'], format: this.formatNumAndPercent
        },
    ]);

    readonly groupItems = new StatItems([
        {
            apiGroup: GROUPS, apiKey: 'total_groups', titleKey: 'reportSnapshotGroupsCount', format: this.formatInt
        },
        {
            apiGroup: GROUPS, apiKey: 'active_groups', titleKey: 'reportSnapshotGroupActive',
            percentFrom: ['total_groups'], format: this.formatNumAndPercent
        },
    ]);

    leadsData: Array<LeadItem> = [];

    private readonly allItems = [this.caseItems, this.caseLeadItems, this.userItems, this.accountItems, this.groupItems];
    private readonly itemMap = new Map<string, StatItem>();

    constructor() {
        this.allItems.forEach(items => {
            items.allItems.forEach(item => {
                this.itemMap.set(item.apiKey, item);
            });
        });
    }

    public clear() {
        this.allItems.forEach(items => {
            items.clear();
        });
        this.leadsData = [];
    }

    public hasData(): boolean {
        return this.allItems.some(item => item.hasData());
    }

    set fromResponse(data: SnapshotReportResponse) {
        this.allItems.forEach(items => items.load(data));
        Object.entries(data.leads).forEach(lead => this.leadsData.push({model: lead[0], count: lead[1]}));
    }

    get leadCount(): number {
        return this.leadsData.reduce((a, b) => (a + b.count), 0);
    }

    private formatInt(item: StatItem) {
        return Math.round(item.value);
    }

    private formatNumAndPercent(item: StatItem, data: UsageData): string {
        // the method assumes that if it was called the item have been initialized with denominators
        const sum = item.percentFrom.reduce((total: number, key: string) => total + data.itemMap.get(key).value, 0);
        return sum ? `${item.value}/${sum} (${Math.round((item.value / sum) * 100)}%)` : `${item.value} (--)`;
    }
}

@Component({
    selector: 'app-usage-reports',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        TranslatePipe,
    ],
    templateUrl: './usage-report.component.html',
    styleUrls: ['./usage-report.component.scss', '../reports.component.scss'],
})
export class UsageReportComponent extends ReportDirective implements OnInit, OnDestroy {

    displayedColumns = ['param', 'value'];

    // we now have multi select option in the auto complete control so the content is a list of items
    selectedItems: Array<AutoCompleteItem> = new Array<AutoCompleteItem>();
    contextControl: FormControl<AutoCompleteItem[] | null> = new FormControl<AutoCompleteItem[] | null>({
        value: null,
        disabled: true
    });
    filteredOptions: Observable<AutoCompleteItem[]>;
    lastFilter: string = '';

    data: UsageData = new UsageData();
    contextList: AutoCompleteItem[] = new Array<AutoCompleteItem>();

    public readonly usageContextList: Array<UsageContext> = [
        CONTEXT_ALL, CONTEXT_ACCOUNTS, CONTEXT_GROUPS, CONTEXT_USERS
    ];

    selectedContext: UsageContext = CONTEXT_ALL;

    private users: Array<User> = [];
    private groups: Array<Group> = [];
    private accounts: Array<Account> = [];

    constructor(reportsService: ReportsService, private userService: UserService, private groupService: GroupService,
                private accountService: AccountService, qService: QuartersService, private ems: ElectrodeModelService) {
        super(reportsService, qService);
    }

    public ngOnInit() {
        this.loadContexts();
    }

    get caseData(): StatItem[] {
        return this.data.caseItems.activeItems;
    }

    get caseLeadData(): StatItem[] {
        return this.data.caseLeadItems.activeItems;
    }

    get usersData(): StatItem[] {
        return this.data.userItems.activeItems;
    }

    get accountsData(): StatItem[] {
        return this.data.accountItems.activeItems;
    }

    get groupsData(): StatItem[] {
        return this.data.groupItems.activeItems;
    }

    get leadsData(): LeadItem[] {
        return this.data.leadsData;
    }

    public optionClicked(event: Event, option: AutoCompleteItem) {
        event.stopPropagation();
        this.toggleSelection(option);
    }

    public toggleSelection(option: AutoCompleteItem) {
        option.selected = !option.selected;
        if (option.selected) {
            this.selectedItems.push(option);
        }
        else {
            const i = this.selectedItems.findIndex(value => value.id === option.id);
            this.selectedItems.splice(i, 1);
        }
        this.contextControl.setValue(this.selectedItems);
    }

    private loadContexts() {
        this.subscriptions.limit(combineLatest([
            this.userService.getUsers(), this.groupService.getGroups(), this.accountService.getAccounts()
        ])).subscribe((data) => {
            this.users = data[0];
            this.groups = data[1];
            this.accounts = data[2];
            this.updateContextList();
        });
    }

    public updateContextList(): void {
        this.selectedItems.forEach(item => item.selected = false);
        this.selectedItems.splice(0);
        this.contextList.splice(0);
        this.contextControl.setValue(this.selectedItems);
        this.selectedContext === CONTEXT_ALL ? this.contextControl.disable() : this.contextControl.enable();
        switch (this.selectedContext.value) {
            case USERS:
                this.users.sort(compareUsersByDescription).forEach(user => {
                    this.contextList.push(new AutoCompleteUser(user));
                });
                break;
            case GROUPS:
                this.groups.sort(compareGroupsByName).forEach(group => {
                    this.contextList.push(new AutoCompleteGroup(group));
                });
                break;
            case ACCOUNTS:
                this.accounts.sort(compareAccountsByName).forEach(account => {
                    this.contextList.push(new AutoCompleteAccount(account));
                });
                break;
        }
        this.filteredOptions = this.contextControl.valueChanges.pipe(
            startWith<string | AutoCompleteItem[]>(''),
            map(value => (typeof value === 'string') ? value : this.lastFilter),
            map(value => value ? this._filter(value) : this.contextList.slice())
        );
    }

    private _filter(value: string): AutoCompleteItem[] {
        this.lastFilter = value;
        return this.contextList.filter((option: AutoCompleteItem) => {
            return option.search.toLowerCase().includes(value.toLowerCase());
        });
    }

    public disableSubmit(): boolean {
        if (this.contextControl.value && typeof this.contextControl.value === 'string') {
            return true;
        }
        return super.disableSubmit();
    }

    public disableClear(): boolean {
        // if there is data in the form, data in the report or dates were selected => clear should be enabled
        return !(this.contextControl.value?.length > 0 || this.data.hasData() || this.anyDateSelected());
    }

    public displayFn(value: AutoCompleteItem[] | string): string {
        let displayValue = '';
        if (value === null) {
            return displayValue;
        }
        if (Array.isArray(value)) {
            value.forEach((item, index) => {
                if (index === 0) {
                    displayValue = item.display;
                }
                else {
                    displayValue += ', ' + item.display;
                }
            });
        }
        else {
            displayValue = value;
        }
        return displayValue;
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
    }

    public submitQuery(): void {
        const {context, ids} = this.prepareSubmission();
        this.rs.getJsonUsageReport(this.startDate, this.endDate, context, ids).subscribe({
            next: (response: SnapshotReportResponse) => {
                this.data.fromResponse = response;
            },
            error: () => {
                this.inProgress = false;
            },
            complete: () => {
                this.inProgress = false;
            }
        });
    }

    public submitExport() {
        const {context, ids} = this.prepareSubmission(false);
        this.rs.getCsvUsageReport(this.startDate, this.endDate, context, ids).subscribe({
            next: (response: Blob) => {
                saveAs(response, 'report.csv');
            },
            error: () => {
                this.inProgress = false;
            },
            complete: () => {
                this.inProgress = false;
            }
        });
    }

    private prepareSubmission(clearData: boolean = true): { context: string | null, ids: Array<string> } {
        this.inProgress = true;
        if (clearData) {
            this.data.clear();
        }
        const context = this.selectedContext.apiKey;
        let ids: Array<string> = [];
        if (this.contextControl.value) {
            ids = this.contextControl.value.map(item => item.id);
        }
        return {context, ids};
    }

    public formatLeadCount(count: number): string {
        const denominator = this.data.leadCount;
        return denominator ? `${count} (${Math.round((count / denominator) * 100)}%)` : `${count} (--)`;
    }

    public formatItem(item: StatItem): string {
        return item.format(item, this.data);
    }

    public rowClass(row: StatItem): string {
        if (row.btm_border) {
            return row.indent ? 'with-spacing with-indent' : 'with-spacing';
        }
        else {
            return row.indent ? 'with-indent' : '';
        }
    }

    public reset() {
        super.reset();
        this.data.clear();
        this.contextControl.reset();
    }

    public getElectrodeModel(model: string): string {
        return this.ems.findModelData(model).modelKey;
    }
}
