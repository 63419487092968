import { Injectable } from '@angular/core';
import { ApiService, FlowResponse } from './api.service';
import { Observable } from 'rxjs';
import { Flows } from '../case/case.constants';
import { FlowType } from '../case/flow-type.class';

export {
    compareFlowsBySubmitOrStartDate,
    compareFlowsByEndDate,
    FlowResponse as FlowRow
};

@Injectable({
    providedIn: 'root'
})

export class FlowsService {

    constructor(private api: ApiService) {
    }

    public getRunningFlows(): Observable<Array<FlowResponse>> {
        return this.api.listFlows('ALL_RUNNING');
    }

    flowByCode(code: number): FlowType {
        return Object.values(Flows).find(f => f.flowCode === code);
    }
}

function flowSortKey(flow: FlowResponse): string {
    if (flow.submitted) {
        return flow.submitted;
    }
    return flow.start_time;
}

function compareFlowsBySubmitOrStartDate(a: FlowResponse, b: FlowResponse): number {
    const da = flowSortKey(a).toLocaleLowerCase();
    const db = flowSortKey(b).toLocaleLowerCase();
    return da.localeCompare(db);
}

/**
 * compare 2 flows by the end date. The method expect the flows to have end_time so a and b
 * should include valid dates when passed
 * @param a - a flow object with valid end_time
 * @param b - a flow object with valid end_time
 * return -1 of a.end_time < b.end_time, 0 if equal, 1 if b.end_time > a.end_time
 */
function compareFlowsByEndDate(a: FlowResponse, b: FlowResponse): number {
    if (!a.end_time || !b.end_time) {
        throw Error('Invalid flow object. Flow object must have valid end_time date as ISO string');
    }
    const da = a.end_time.toLocaleLowerCase();
    const db = b.end_time.toLocaleLowerCase();
    return da.localeCompare(db);
}
