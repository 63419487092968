import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DeleteCaseInput {
    allowArchive: boolean;
    allowDelete: boolean;
}

export enum DeleteCaseDecision {
    Cancel, Archive, Delete
}

@Component({
    selector: 'app-confirm-delete-case',
    templateUrl: './delete-case.component.html',
    styleUrls: ['./delete-case.component.scss']
})
export class DeleteCaseComponent implements OnInit {

    public deleteEntirely = false;

    constructor(private dialogRef: MatDialogRef<DeleteCaseComponent>,
                @Inject(MAT_DIALOG_DATA) public readonly context: DeleteCaseInput) {
    }

    ngOnInit(): void {
        if (this.context.allowDelete && !this.context.allowArchive) {
            this.deleteEntirely = true;
        }
    }

    closeDialog(approved: boolean) {
        const decision = approved ? this.deleteEntirely ?
            DeleteCaseDecision.Delete : DeleteCaseDecision.Archive : DeleteCaseDecision.Cancel;
        this.dialogRef.close(decision);
    }
}
