import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AppConfigService {

    public static readonly CONFIDENCE_THRESHOLD: string = 'confidence_threshold';
    public static readonly LANGUAGES: string = 'languages';
    public static readonly FISCAL_YEAR_START_MONTH = 'fiscal_year_start_month';

    private readonly CONFIG_FILE = 'assets/config/app-config.json';
    private configJson: any = null;

    constructor(private http: HttpClient) {
    }

    public load(): Observable<any> {
        return this.http.get(this.CONFIG_FILE).pipe(
            tap({
                next: (response: any) => {
                    this.configJson = response;
                },
                error: (e) => {
                    console.log(`Could not load file '${this.CONFIG_FILE}': ${e}`);
                }
            })
        );
    }

    public get(key: string): any {
        if (key in this.configJson) {
            return this.configJson[key];
        }
        return null;
    }
}
