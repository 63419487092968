import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SecureLinkComponent} from './secure-link/secure-link.component';
import {AppServicesModule} from './app-services.module';
import {MaterialModule} from './material.module';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [
        SecureLinkComponent,
    ],
    imports: [
        MaterialModule,
        CommonModule,
        RouterModule,
        AppServicesModule,
    ],
    exports: [
        SecureLinkComponent,
    ]
})
export class AppComponentsModule {
}
