<div class="fill-parent">
    <div class="height-50 width-100-p"></div>
    <div class="groups-main">
        <div class="mat-elevation-z8">
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="name" i18n="@@settingsGroupsTableName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'settingsGroupsTableName'|translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let groupRow"> {{ groupRow.name }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="created" i18n="@@settingsGroupsTableCreated">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'created'|translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let groupRow"> {{ groupRow.created | localDate }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="updated" i18n="@@settingsGroupsTableUpdated">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'updated'|translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let groupRow"> {{ groupRow.updated | localDate }}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let groupRow; columns: displayedColumns;"
                         class="table-row"
                         data-testid="settings-groups-rows"
                         (click)="onActivate(groupRow, $event.metaKey)">
                </mat-row>
            </mat-table>
            <mat-paginator [pageSize]="10" [hidePageSize]="true"></mat-paginator>
        </div>
        @if (createGroupsAllowed) {
            <button mat-fab
                    class="create-group-btn"
                    i18n-matTooltip="@@settingsGroupsCreateNewTooltip"
                    matTooltip="{{'settingsGroupsCreateNewTooltip'|translate}}"
                    matTooltipPosition="before"
                    color="accent"
                    data-testid="settings-groups-create"

                    (click)="openCreateGroupDialog()">
                <mat-icon>add</mat-icon>
            </button>
        }
    </div>
</div>
