import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SelectDicomComponent, SelectedDicomInfo } from '../select-dicom/select-dicom.component';
import { ScanDicomService } from './scan-dicom.service';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { Subject } from 'rxjs';
import { Case, CaseService } from '../../case.service';
import { ImageUseToDataElement } from '../../case.constants';
import { UploadDicomRequest } from '../../upload/upload-dicom.component';
import { SeriesId } from '../dicom.class';
import { MaterialModule } from '../../../material.module';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-case-scan-dicom',
    standalone: true,
    imports: [
        CommonModule,
        MaterialModule,
        TranslatePipe,
        NgOptimizedImage,
        SelectDicomComponent,
    ],
    templateUrl: './scan-dicom.component.html',
    styleUrls: ['./scan-dicom.component.scss'],
    providers: [
        ScanDicomService,
    ]
})
export class ScanDicomComponent implements OnInit, OnDestroy {

    private static readonly IDLE_CLASS = 'c-idle';
    private static readonly OVER_CLASS = 'c-lavender';
    private static readonly DISABLE_LABEL_CLASS = 'disabled';
    private static readonly ACTIVE_LABEL_CLASS = 'active';

    @Input() caseUpdated: Subject<Case>;
    @Input() uploadDicomRequestEmitter: Subject<UploadDicomRequest>;
    @Input() allowDisableDataValidation: boolean;
    @Input() uploadInProgress: boolean;
    @Input() canUploadPlanning: boolean;
    @Input() canUploadTargeting: boolean;
    @Input() canUploadPostop: boolean;

    public case: Case;
    private subscriptions = new Subscriptions();
    private scanInProgress = false;
    private inputElement: HTMLInputElement | null = null;

    progress = 0;
    selection = '[]';
    private bgClass = ScanDicomComponent.IDLE_CLASS;

    constructor(private dicomService: ScanDicomService, private caseService: CaseService, public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.subscriptions.add(this.caseUpdated, data => {
            this.case = data;
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
    }

    dragOver(): void {
        if (!this.blockUploads()) {
            this.bgClass = ScanDicomComponent.OVER_CLASS;
        }
    }

    dragLeave(): void {
        this.bgClass = ScanDicomComponent.IDLE_CLASS;
    }

    public onFolderSelected(event: Event): void {
        this.inputElement = event.target as HTMLInputElement;
        if (this.inputElement && this.inputElement.files) {
            this.scanInProgress = true;
            this.handleSelection(this.inputElement.files);
        }
    }

    private handleSelection(selectedFiles: FileList): void {
        this.bgClass = ScanDicomComponent.IDLE_CLASS;
        this.progress = 0;
        this.selection = '[]';
        this.subscriptions.add(this.dicomService.onFilesSelected(selectedFiles),
            (progress: number) => {
                this.progress = Math.floor(progress * 20) * 5;
            },
            () => {
            },
            () => {
                this.openDialog();
            }
        );
    }

    private openDialog(): void {
        const config = new MatDialogConfig();
        config.minWidth = '80vw';
        config.maxHeight = '800px';
        config.data = {
            canUploadPlanning: this.canUploadPlanning,
            canUploadTargeting: this.canUploadTargeting,
            canUploadPostop: this.canUploadPostop,
            allowDisableDataValidation: this.allowDisableDataValidation,
            dcmMap: this.dicomService.seriesMap,
            currentDicomPatientIds: this.caseService.getDicomPatientIds(this.case)
        };
        const dialogRef = this.dialog.open(SelectDicomComponent, config);
        this.subscriptions.add(dialogRef.afterClosed(), (data: Map<string, SelectedDicomInfo>) => {
            this.onDialogClosed(data);
        });
    }

    get mainClass(): string {
        return this.bgClass;
    }

    labelClass(): any {
        return this.blockUploads() ? ScanDicomComponent.DISABLE_LABEL_CLASS : ScanDicomComponent.ACTIVE_LABEL_CLASS;
    }

    private onDialogClosed(selectionResult: Map<string, SelectedDicomInfo>): void {
        this.scanInProgress = false;
        this.progress = 0;
        if (this.inputElement) {
            this.inputElement.value = '';
            this.inputElement = null;
        }
        if (selectionResult) {
            selectionResult.forEach((value: SelectedDicomInfo, seriesId: SeriesId) => {
                const dcmSeries = this.dicomService.getDicomSeries(seriesId);
                const uploadDicomEvent = new UploadDicomRequest(ImageUseToDataElement.get(value.iu), dcmSeries);
                // broadcast the upload event to the UploadDicomComponent
                this.uploadDicomRequestEmitter.next(uploadDicomEvent);
            });
        }
    }

    blockUploads(): boolean {
        return this.scanInProgress || this.uploadInProgress || !(this.canUploadPlanning || this.canUploadTargeting || this.canUploadPostop);
    }
}
