import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BooleanResponse, UserService } from '../services/user.service';
import { FormsModule, NgForm } from '@angular/forms';
import { Subscriptions } from '../tools/subscriptions.class';
import { AuthService } from '../services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { timer } from 'rxjs';
import { PasswordPolicy } from '../tools/password-policy.class';

import { MaterialModule } from '../material.module';
import { TranslatePipe } from '@ngx-translate/core';

export enum ChangePasswordMode {
    authenticated, expired
}

export interface ChangePasswordInput {
    mode: ChangePasswordMode;
}

export interface ChangePasswordResult {
    mode: ChangePasswordMode;
    result: boolean;
    password?: string;
}

@Component({
    selector: 'app-change-password',
    standalone: true,
    imports: [
        FormsModule,
        MaterialModule,
        TranslatePipe,
    ],
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

    disableForm = false;
    result: boolean = null;
    resultMessage = '';
    model = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    };
    private subscriptions = new Subscriptions();

    constructor(private userService: UserService,
                private authService: AuthService,
                @Inject(MAT_DIALOG_DATA) private data: ChangePasswordInput,
                private dialogRef: MatDialogRef<ChangePasswordComponent>) {
    }

    get pwExpireMode(): boolean {
        return this.data.mode === ChangePasswordMode.expired;
    }

    get passwordPolicy(): PasswordPolicy {
        return this.authService.currentPasswordPolicy();
    }

    get showNonCompliantPassword(): boolean {
        return this.model.newPassword && !this.passwordPolicy.accept(this.model.newPassword);
    }

    ngOnInit() {
        this.subscriptions.add(this.dialogRef.keydownEvents(), event => {
            if (event.key === 'Escape') {
                this.onCancel();
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
    }

    changePassword(changePasswordForm: NgForm) {
        if (this.data.mode === ChangePasswordMode.authenticated) {
            this.authChangePassword(changePasswordForm);
        }
        else {
            this.unauthChangePassword(changePasswordForm);
        }
    }

    passwordsDontMatch(): boolean {
        return this.model.newPassword && this.model.confirmPassword &&
            this.model.newPassword !== this.model.confirmPassword;
    }

    readyToChange(): boolean {
        return this.model.oldPassword && this.model.newPassword && this.model.confirmPassword &&
            this.model.newPassword === this.model.confirmPassword && this.passwordPolicy.accept(this.model.newPassword);
    }

    clearResult(): void {
        this.result = null;
        this.resultMessage = '';
    }

    private authChangePassword(changePasswordForm: NgForm) {
        this.subscriptions.add(
            this.userService.changePasswordWithToken(this.authService.currentUserId, this.model.newPassword, this.model.oldPassword),
            (response: BooleanResponse) => {
                this.onPasswordChangeResult(changePasswordForm, response);
                if (response.result) {
                    this.onClose(true);
                }
            },
            (error: Error) => {
                this.onPasswordChangeError(error);
            }
        );
    }

    private unauthChangePassword(changePasswordForm: NgForm) {
        const newPw = this.model.newPassword;
        this.subscriptions.add(this.userService.changePasswordNotAuthenticated(this.authService.expireToken,
                this.authService.currentUserId, this.model.newPassword, this.model.oldPassword),
            (response: BooleanResponse) => {
                this.onPasswordChangeResult(changePasswordForm, response);
                if (response.result) {
                    this.onClose(true, newPw);
                }
            },
            (error) => {
                this.onPasswordChangeError(error);
            }
        );
    }

    private onPasswordChangeResult(changePasswordForm: NgForm, response: BooleanResponse): void {
        this.result = response.result;
        this.resultMessage = response.message;
        if (this.result) {
            Object.keys(this.model).forEach(name => {
                this.model[name] = '';
            });
            changePasswordForm.reset();
        }
    }

    private onPasswordChangeError(error: Error): void {
        console.log(error.message);
        this.result = false;
        this.resultMessage = 'changePasswordServerError';
    }

    private onClose(result: boolean = true, newPw: string = null) {
        this.disableForm = true;
        this.subscriptions.add(timer(2000), () => {
            this.dialogRef.close({mode: this.data.mode, result, password: newPw});
        });
    }

    public onCancel() {
        this.dialogRef.close({mode: this.data.mode, result: false, password: null});
    }
}
