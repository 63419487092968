@if (downloadMsWordFilename) {
    <div class="download-container">
        <a class="mat-typography" [href]="downloadMsWordUrl"
           [download]="downloadMsWordFilename|translate">{{ 'helpDocumentDownloadMsWord'|translate }}
        </a>
    </div>
}
<object
        [data]="url" width="100%" height="100%">
</object>
