import { Injectable, OnDestroy } from '@angular/core';
import { ApiService, PermissionsResponseItem } from './api.service';
import { BehaviorSubject, from, mergeAll, Observable, Observer, ReplaySubject, Subject } from 'rxjs';
import { Subscriptions } from '../tools/subscriptions.class';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

// A permission is an array, each element of which is a string or an array of strings
type PermissionElement = string | string[];
type Permission = PermissionElement[];

export class PermissionAllowed {
    permission: Permission;
    allowed: boolean;
}

class CacheNode {
    allowed: Subject<boolean> | null;
    children: { [key: string]: CacheNode };

    // Track the request that is/was responsible for populating the node to help with debugging.
    requestId: number | null;

    constructor() {
        this.allowed = null;
        this.children = {};
        this.requestId = null;
    }
}

@Injectable()
export class PermissionsService implements OnDestroy {
    // Everything should be forbidden until the service is told otherwise, presumably because a user has authenticated.
    private _userId: string | null = null;

    // A subject that will emit a value every time permissions change (due to a user logging in or out,
    // for example).
    private refresh: Subject<string> = new BehaviorSubject<string>(this._userId);

    // The root of a tree of cache nodes. The entry for a particular permission is found by walking the tree
    // using the elements of the permission.
    private cacheRoot = new CacheNode();

    // A single subject to use to respond to permission requests when there is no current user.
    private disallowed = new BehaviorSubject<boolean>(false);

    private pendingPermissionsToRequest = new Array<Permission>();
    private pendingTimeoutId: any | null = null;
    private pendingRequestId: number | null = null;
    private _flushDelay = 10;  // ms

    // A single subject to inform clients whether any permission requests are outstanding.
    public readonly anyPending = new BehaviorSubject<boolean>(false);
    private nextRequestId: number = 1;
    private pendingRequests = new Set<number>();

    private subscriptions = new Subscriptions();

    constructor(private api: ApiService) {
    }

    public ngOnDestroy() {
        this.subscriptions.cancel();
    }

    public set userId(userId: string) {
        if (userId !== this._userId) {
            this._userId = userId;
            this.invalidate();
        }
    }

    public set flushDelay(ms: number) {
        this._flushDelay = ms;
    }

    public flush() {
        this.cancelPendingFlush({discardId: false});
        this.flushNow();
    }

    public permissions(): Observable<any> {
        return this.refresh.asObservable();
    }

    public hasPermission(permission: Permission): Observable<boolean> {
        return this.refresh.pipe(
            switchMap(_ => this.hasPermissionImpl(permission)),
            // Only send changes from what has been sent before.
            distinctUntilChanged(),
        );
    }

    public hasPermissions(permissions: Permission[]): Observable<PermissionAllowed> {
        return from(permissions).pipe(
            map(permission => this.hasPermission(permission).pipe(
                map(allowed => {
                    return {permission, allowed};
                })
            )),
            mergeAll(),
        );
    }

    public prefetch(permissions: Permission[]): void {
        // Submit a single request for multiple permissions simultaneously. Responses for individual permissions
        // can then be obtained via hasPermission().
        permissions.forEach(permission => this.hasPermissionImpl(permission));
    }

    public batch(subscriptions: Subscriptions, requests: Array<[Permission, Partial<Observer<boolean>> | ((allowed: boolean) => void)]>) {
        // Submit a batch of permission requests, and subscribe to each of the responses using the paired callback.
        requests.forEach(value => {
            subscriptions.limit(this.hasPermission(value[0])).subscribe(value[1]);
        });
    }

    public invalidate() {
        // Any in-progress requests should be ignored, since they were made based on potentially out-of-date information.
        this.subscriptions.cancel();

        // While this is not a request exactly, we want to ensure that the pending signal does not oscillate due to
        // pending requests being cancelled and then new requests being immediately generated due to the refresh
        // signal. So we'll pretend there is a request "surrounding" the potential cancellation and then the refresh.
        const requestId = this.requestStarting();

        try {
            // Recreate the set of pending requests so that it contains only the request we just started. We do not
            // need to worry about anyPending notifications here because we know anyPending last emitted true, and
            // that should not change. No need to recreate something that has only what we want in it, though.
            if (this.pendingRequests.size > 1) {
                this.pendingRequests = new Set<number>([requestId]);
            }

            // Any pending requests will just be ignored, since clients will re-request the permissions when the
            // refresh signal is sent (by way of switchMap() in hasPermissions()).
            this.pendingPermissionsToRequest = [];

            // If there were requests waiting for a flushDelay to expire, we should cancel that timer and discard
            // any associated request id.
            this.cancelPendingFlush({discardId: true});

            // Recreate the cache
            this.cacheRoot = new CacheNode();

            // Signal the switch maps returned from hasPermissions() that they should re-request their permissions.
            this.refresh.next(this._userId);
        }
        finally {
            this.requestFinished(requestId);
        }
    }

    private hasPermissionImpl(permission: Permission): Observable<boolean> {
        const cacheNode = this.cacheNode(permission);

        if (cacheNode.allowed !== null) {
            // Nothing to do
        }
        else if (this._userId !== null) {
            cacheNode.allowed = new ReplaySubject(1);
            cacheNode.requestId = this.fetchPermission(permission);
        }
        else {
            cacheNode.allowed = this.disallowed;
        }
        return cacheNode.allowed.asObservable();
    }

    private fetchPermission(permission: Permission): number {
        const hadPending = this.pendingPermissionsToRequest.length > 0;
        this.pendingPermissionsToRequest.push(permission);

        if (!hadPending) {
            this.cancelPendingFlush({discardId: true});
            this.pendingRequestId = this.requestStarting();
            if (this._flushDelay > 0) {
                this.scheduleFlush();
            }
            else {
                this.flushNow();
            }
        }
        return this.pendingRequestId;
    }

    private scheduleFlush() {
        const pendingTimeoutId = setTimeout(() => {
            // Only reset the timeout id if it is still the current timeout id.
            if (this.pendingTimeoutId === pendingTimeoutId) {
                this.pendingTimeoutId = null;
            }
            this.flushNow();
        }, this._flushDelay);
        this.pendingTimeoutId = pendingTimeoutId;
    }

    private cancelPendingFlush(options: {discardId: boolean}) {
        if (this.pendingTimeoutId !== null) {
            clearTimeout(this.pendingTimeoutId);
            this.pendingTimeoutId = null;
        }

        // If we are only rescheduling the same request, we want to keep the request id.
        if (options.discardId && this.pendingRequestId !== null) {
            this.pendingRequests.delete(this.pendingRequestId);
            this.pendingRequestId = null;
        }
    }

    private flushNow() {
        // Make a copy of the list of permissions so that we can clear our member before any callbacks can fire. Similarly,
        // keep a copy of the current request id. We also save the current cache root, because, if the cache is invalidated
        // before the response comes back, we want to be extra sure that we do not apply the results of old requests to new
        // cache entries. (We cancel the subscription to the request, so this shouldn't be necessary; it's insurance.)
        const permissions = this.pendingPermissionsToRequest;
        const requestId = this.pendingRequestId;
        const cacheRoot = this.cacheRoot;

        this.pendingRequestId = null;
        this.pendingPermissionsToRequest = [];

        // This function should only get called if the array was populated, but if invalidate() was called after the requests were
        // made but before they were submitted, the pending requests will be cancelled.
        if (permissions.length === 0) {
            return;
        }

        this.subscriptions.limit(this.api.getPermissions(permissions)).subscribe({
            next: (permissionResponseItems: PermissionsResponseItem[]) => {
                // If the request id is no longer in pendingRequests, then we must have invalidated, which means this response
                // is no longer applicable.
                if (this.pendingRequests.has(requestId)) {
                    for (const item of permissionResponseItems) {
                        const cacheNode = this.cacheNode(item.permission, cacheRoot);
                        // We should only get results back for permissions that we requested, and subjects were
                        // created above for those permissions. Still, be safe.
                        if (cacheNode.allowed) {
                            cacheNode.allowed.next(item.allowed);
                            cacheNode.allowed.complete();
                        }
                    }
                    this.requestFinished(requestId);
                }
                else {
                    // This response should not have been received since we used this.subscriptions.limit()
                    // so getting here is surprising.
                    console.log('PermissionsService ignoring out-of-date response from server');
                }
            },
            error: error => {
                console.log(`Error fetching permissions ${permissions}: ${error}`);
                this.requestFinished(requestId);
            },
            complete: () => {
                this.requestFinished(requestId);
            }
        });
    }

    private requestStarting(): number {
        const requestId = this.nextRequestId;
        this.nextRequestId += 1;
        if (this.pendingRequests.add(requestId).size === 1) {
            this.anyPending.next(true);
        }
        return requestId;
    }

    private requestFinished(requestId: number) {
        if (this.pendingRequests.delete(requestId) && this.pendingRequests.size === 0) {
            this.anyPending.next(false);
        }
    }

    private cacheNode(permission: Permission, cacheRoot: CacheNode | null = null): CacheNode {
        // Walk the entry tree to find the correct entry, creating it (and any intermediate entries) if necessary.
        let node = cacheRoot ?? this.cacheRoot;
        for (const p of permission) {
            const k: string = p === null ? '*null*' : (typeof p === 'string') ? p : p.join('+');
            if (k in node.children) {
                node = node.children[k];
            }
            else {
                node = node.children[k] = new CacheNode();
            }
        }
        return node;
    }
}
