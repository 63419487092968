import { urlExpand } from '../tools/url-compression';

export class DataElement {
    constructor(public readonly name: string) {

    }

    public static urlCount(element: { id: string; url?: string[] | number } | null): number {
        if ((element?.url ?? 0) === 0) {
            // If there is no URL, the count is zero.
            return 0;
        }
        else if (typeof (element.url) === 'number') {
            // If we have a number rather than an actual list of URL's, the number of available URL's is that number.
            return element.url;
        }
        else {
            // If we have a list of URL's, return its length.
            return element.url.length;
        }
    }

    public static urls(element: { id: string; url?: string[] | number } | null): string[] {
        if ((element?.url ?? 0) === 0) {
            // If there is no URL, return an empty list.
            return [];
        }
        else if (typeof (element.url) === 'number') {
            // If we have a number rather than an actual list of URL's, generate indexed logical URL's, where
            // element.url is the number of URL's.
            const prefix = `ldm://DataElements/${element.id}/@/`;
            return Array.from(Array(element.url), (_, i) => prefix.concat(i.toString()));
        }
        else {
            // If we have a list of URL's, expand them before returning them.
            return urlExpand(element.url);
        }
    }
}
