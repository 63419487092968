<mat-card>
    <mat-card-title *ngIf="logData" class="view-log-header">
        <div class="view-log-header-text">
            <span *ngIf="flow">{{ flow.description }}</span>
            <span class="view-log-flow-info">
            <span><b>Start:&nbsp;</b></span><span>{{ data.flowData.start_time| localDate }}</span>
            <span class="m-b-15"><b>End:&nbsp;</b></span><span>{{ data.flowData.end_time| localDate }}</span>
            <span class="m-b-15"><b>Run Time:&nbsp;</b></span><span>{{ flowTime|minuteSeconds }}&nbsp;min</span>
            <span class="m-b-15"><b>Exit Code:&nbsp;</b></span>{{ data.flowData.exit_code }}
        </span>
        </div>
        <button mat-raised-button (click)="onClose()">Close</button>
    </mat-card-title>
    <mat-card-content *ngIf="logData">
        <div class="view-log-text">{{ logData }}</div>
    </mat-card-content>
</mat-card>
