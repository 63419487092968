import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectDicomService } from './select-dicom.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { ClfDicomParams, ClfDicomResponse } from '../../../services/api.service';
import {
    ALL_PLANNING,
    ALL_TARGETING,
    CT,
    ImageUse,
    MIN_NUMBER_OF_SLICES,
    Modality,
    MR,
    VALID_PLANNING,
    VALID_TARGETING
} from '../../dicom.constants';
import { DicomSeries } from '../dicom.class';
import { SelectDicomDetailsComponent } from '../select-dicom-details/select-dicom-details.component';
import { MaterialModule } from '../../../material.module';
import { LocalDatePipe } from '../../../tools/local-date.pipe';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

export interface SelectDicomInput {
    canUploadPlanning: boolean;
    canUploadTargeting: boolean;
    canUploadPostop: boolean;
    allowDisableDataValidation: boolean;
    dcmMap: Map<string, DicomSeries>;
    currentDicomPatientIds: Array<string>;
}

export interface SelectedDicomInfo {
    iu: ImageUse;
    patientId: string;
}

@Component({
    selector: 'app-select-dicom',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        LocalDatePipe,
        MaterialModule,
        TranslatePipe
    ],
    templateUrl: './select-dicom.component.html',
    styleUrls: ['./select-dicom.component.scss'],
    providers: [
        SelectDicomService,
    ]
})

export class SelectDicomComponent implements OnInit, AfterViewInit {

    public static readonly VALID_COL_CLS = 'default-text';
    public static readonly INVALID_COL_CLS = 'warning-text';

    @ViewChild(MatPaginator, {static: true}) matPaginator: MatPaginator;

    private subscriptions: Subscriptions = new Subscriptions();

    private dcmData: Array<DicomSeries>;
    public readonly dataSource: MatTableDataSource<DicomSeries> = new MatTableDataSource<DicomSeries>();
    private translatedImageUse = null;
    public errorMessageKey: string | null = '';

    public patientIdMismatchAck: boolean = false;

    private displayColumns: Array<string> = [
        'imageUse', 'acquired', 'patientInfo', 'imageType', 'slices', 'spacing', 'description', 'details'
    ];

    constructor(public dialogRef: MatDialogRef<SelectDicomComponent>, private translate: TranslateService,
                private selectDicomService: SelectDicomService, private dialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: SelectDicomInput) {
        if (data.allowDisableDataValidation) {
            this.displayColumns.push('disableDI');
        }
    }

    get columns(): Array<string> {
        return this.displayColumns;
    }

    ngOnInit(): void {
        this.dcmData = Array.from(this.data.dcmMap.values());
        if (this.dcmData.length === 0) {
            this.errorMessageKey = 'caseOverviewSelectDicomNoDicomFound';
        }
        this.subscriptions.add(this.dialogRef.keydownEvents(), event => {
            if (event.key === 'Escape') {
                this.onCancel();
            }
        });
        this.translateImageUse();
        this.classifyMrDicom();
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.matPaginator;
    }

    dicomSelected(): boolean {
        return this.selectDicomService.isSelected();
    }

    onUploadSelected(): void {
        const selected = this.selectDicomService.getSelection();
        this.selectDicomService.clearSelection();
        this.dialogRef.close(selected);
    }

    onCancel(): void {
        this.dcmData = [];
        this.data.currentDicomPatientIds = [];
        this.selectDicomService.clearSelection();
        this.subscriptions.cancel();
        this.dialogRef.close(null);
    }

    imageUseName(iu: number): string {
        if (this.translatedImageUse) {
            return this.translatedImageUse[ImageUse[iu]];
        }
        return ImageUse[iu];
    }

    public onSelect(series: DicomSeries, iu: ImageUse): void {
        this.selectDicomService.select(iu, series.id, series.patientId);
        this.updateInfoTextAfterSelection();
    }

    public disablePostop(series: DicomSeries): boolean {
        return series.modality === CT && this.selectDicomService.has(ImageUse.postop) && !this.selectDicomService.hasSeries(series.id);
    }

    public imageUseSelected(iu: ImageUse): boolean {
        return this.selectDicomService.has(iu);
    }

    public modalityUploadAllowed(modality: Modality): boolean {
        return (modality === MR) ? (this.data.canUploadPlanning || this.data.canUploadTargeting) : true;
    }

    public imageUseUploadAllowed(imgUse: ImageUse): boolean {
        switch (imgUse) {
            case ImageUse.planning:
                return this.data.canUploadPlanning;
            case ImageUse.targeting:
                return this.data.canUploadTargeting;
            case ImageUse.postop:
                return this.data.canUploadPostop;
            default:
                return true;
        }
    }

    /**
     * This method does basic data check if a DICOM series should be included in the DICOM UI list
     * @param series - a DicomSeries object
     * @return true if the series should be included in the DICOM list false if not
     */
    public validForUpload(series: DicomSeries): boolean {
        return this.data.allowDisableDataValidation ? true : this.checkIntegrity(series);
    }

    private translateImageUse() {
        let imageUseOptions = Object.keys(ImageUse).map(key => ImageUse[key]);
        imageUseOptions = imageUseOptions.filter(value => typeof value === 'string') as Array<string>;
        this.subscriptions.add(this.translate.get(imageUseOptions), translated => {
            this.translatedImageUse = translated;
        });
    }

    private classifyMrDicom() {
        const mrClfData: Array<ClfDicomParams> = this.dcmData.filter((series: DicomSeries) =>
            series.modality === MR
        ).map(series => series.asClfDicom());

        if (mrClfData.length > 0) {
            this.subscriptions.add(this.selectDicomService.classifyDicom(mrClfData),
                (clfDicomResponse: ClfDicomResponse) => {
                    clfDicomResponse.data.forEach(clfResponse => {
                        this.data.dcmMap.get(clfResponse.series_id).classification = clfResponse;
                    });
                    this.setDataSource();
                },
                (error: string) => {
                    console.error(error);
                    this.setDataSource();
                });
        }
        else {
            this.setDataSource();
        }
    }

    /**
     * Once we have the classification from the server we shall process the DICOM data and make selection if possible
     * @private
     */
    private selectSeries() {
        if (this.imageUseUploadAllowed(ImageUse.planning)) {
            this.selectImageUse(ImageUse.planning, this.dataSource.data.filter(
                (series: DicomSeries) => VALID_PLANNING.includes(series.sequence) && this.validForUpload(series)
            ));
        }
        if (this.imageUseUploadAllowed(ImageUse.targeting)) {
            this.selectImageUse(ImageUse.targeting, this.dataSource.data.filter(
                (series: DicomSeries) => VALID_TARGETING.includes(series.sequence) && this.validForUpload(series)
            ));
        }
        if (this.imageUseUploadAllowed(ImageUse.postop)) {
            this.selectImageUse(ImageUse.postop, this.dataSource.data.filter(
                (series: DicomSeries) => (series.modality === CT) && !series.suspiciousCT && this.validForUpload(series)
            ));
        }
    }

    private selectImageUse(iu: ImageUse, dicomSeriesList: Array<DicomSeries>) {
        // we only make a decision if we have one dicom series for the imageUse that meets the requirements
        if (dicomSeriesList.length === 1) {
            const dicomSeries = dicomSeriesList[0];
            dicomSeries.imageUse = iu;
            this.onSelect(dicomSeries, iu);
        }
    }

    /**
     * Check the data integrity of the ds
     * @param ds a DicomSeries object with a series that have been processed/analysed
     * @return true if DI is Ok, false if not
     */
    private checkIntegrity(ds: DicomSeries): boolean {
        return ds.checkSlices() && ds.checkImageSpacing() && ds.checkThickness() && ds.consecutive;
    }

    public spacingClass(ds: DicomSeries, s: null | number | Array<number>): string {
        return ds.checkAxisSpacing(s) ? SelectDicomComponent.VALID_COL_CLS : SelectDicomComponent.INVALID_COL_CLS;
    }

    public slicesClass(s: DicomSeries): string {
        return s.nSlices >= MIN_NUMBER_OF_SLICES ? SelectDicomComponent.VALID_COL_CLS : SelectDicomComponent.INVALID_COL_CLS;
    }

    public thicknessClass(s: DicomSeries): string {
        return s.checkThickness() ? SelectDicomComponent.VALID_COL_CLS : SelectDicomComponent.INVALID_COL_CLS;
    }

    get patientIdMismatch(): boolean {
        return this.selectDicomService.patientIdsMismatch(this.data.currentDicomPatientIds);
    }

    private patientIdMismatchApproved(): boolean {
        return this.patientIdMismatch ? this.patientIdMismatchAck : true;
    }

    get disableUpload(): boolean {
        return !this.dicomSelected() || !this.patientIdMismatchApproved();
    }

    imageTypeClass(s: DicomSeries): string {
        return this.selectDicomService.hasSeries(s.id) ? (s.validSelection() ? SelectDicomComponent.VALID_COL_CLS : SelectDicomComponent.INVALID_COL_CLS) : SelectDicomComponent.VALID_COL_CLS;
    }

    private setDataSource() {
        // for presentation, we always want to have planning, targeting, post-op images sorted in this order
        this.dataSource.data = this.dcmData.sort(compareDicomSeries);
        this.selectSeries();
    }

    private updateInfoTextAfterSelection() {
        const selectedSeriesIds = Array.from(this.selectDicomService.getSelection().keys());
        if (selectedSeriesIds.length === 0) {
            this.errorMessageKey = null;
            return;
        }
        const allValid = selectedSeriesIds.map(id => this.data.dcmMap.get(id).validSelection()).every(valid => valid);
        this.errorMessageKey = allValid ? '' : 'caseOverviewSelectDicomSelectionWarning';
    }

    hasValidSelectedPatientIds(): boolean {
        return this.data.currentDicomPatientIds.length > 0 && this.data.currentDicomPatientIds.every(id => id !== null && id.length > 0);
    }

    validPatientInfo(item: string | null): boolean {
        return item !== null && item.length > 0;
    }

    get previouslySelectedIds(): string {
        return Array.from(new Set(this.data.currentDicomPatientIds).values()).join(',');
    }

    public showDetails(s: DicomSeries) {
        this.dialog.open(SelectDicomDetailsComponent, {width: '70vw', data: s});
    }
}

function compareDicomSeries(a: DicomSeries, b: DicomSeries): number {
    const modalitySortOrder = (s: DicomSeries) => {
        return s.modality.sortOrder;
    };
    let result = modalitySortOrder(a) - modalitySortOrder(b);
    // if we have an MR and a CT return the sortOrder
    if (result !== 0) {
        return result;
    }

    const sequenceSortOrder = (s: DicomSeries) => {
        if (ALL_PLANNING.includes(s.sequence)) {
            return 0;
        }
        else if (ALL_TARGETING.includes(s.sequence)) {
            return 1;
        }
        else {
            return 2;
        }
    };
    result = sequenceSortOrder(a) - sequenceSortOrder(b);
    if (result !== 0) {
        return result;
    }
    else {
        // both are from the same image use, so return by date
        return a.seriesDate < b.seriesDate ? -1 : a.seriesDate > b.seriesDate ? 1 : 0;
    }

}
