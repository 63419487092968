import { Component, Input, OnDestroy } from '@angular/core';
import { Permission } from '../security/permissions.class';
import { PermissionsService } from '../services/permissions.service';
import { Observable, of, Subject } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { first, takeUntil } from 'rxjs/operators';
import { MaterialModule } from '../material.module';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-secure-link',
    standalone: true,
    imports: [
        MaterialModule,
        CommonModule,
        RouterModule,
    ],
    templateUrl: './secure-link.component.html',
    styleUrls: ['./secure-link.component.scss']
})
export class SecureLinkComponent implements OnDestroy {

    @Input() permission: Permission | null;
    @Input() target: string[];
    @Input() hideWhenForbidden = false;

    private destroyed$ = new Subject();

    constructor(private router: Router, private permissionsService: PermissionsService) {
    }

    ngOnDestroy() {
        this.destroyed$.next(null);
    }

    public canNavigate(): Observable<boolean> {
        if (!this.permission) {
            return of(false);
        }
        else {
            return this.permissionsService.hasPermission(this.permission).pipe(
                takeUntil(this.destroyed$)
            );
        }
    }

    public navigate() {
        this.canNavigate().pipe(first()).subscribe(allowed => {
            if (allowed) {
                this.router.navigate(this.target).then();
            }
        });
    }
}
