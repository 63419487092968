<mat-sidenav-container class="fill-parent">
    <mat-sidenav
            class="sis-sidenav"
            disableClose
            mode="side"
            opened="true">
        <div class="side-nav-top-space"></div>
        <mat-nav-list>
            @for (menuItem of permittedMenu(); track menuItem.titleKey) {
                <mat-list-item
                        [activated]="router.url === menuItem.route"
                        class="sis-side-nav-item"
                        [attr.data-testid]="menuItem.titleKey"
                        routerLink={{menuItem.route}}>{{ menuItem.titleKey | translate }}
                </mat-list-item>
            }
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="sis-sidenav-content">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
