@if (!allDownloadConcluded) {
    <div class="upper-spacing-bar">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
}

@if (downloadSlicesConcluded) {
    <div class="lead-location-container">
        <div class="lead-location-left-side">
            <div class="upper-spacing-bar"></div>
            <div class="stl-view" [style.width.px]="leadLocationWidth" [style.height.px]="leadLocationHeight">
                <app-case-stl-view
                        [height]="leadLocationHeight"
                        [width]="leadLocationWidth"
                        [dataElements]="getElectrodeVisualizationElements()"
                        [viewElem]="stlview"
                        [caseData]="caseData"
                        [caseUpdated]="caseUpdated"
                        [approvedTargets]="approvedTargets"
                        (updateStlLoaded)="onStlLoaded($event)"
                        [imageSlicesData]="slicesData"
                        [slicesLoadedReport]="slicesLoadedReport"
                        [sliceChangedEvent]="sliceChanged"
                        [imageOpacityEvent]="opacityChanged"
                        [changeCoordinatesObservable]="changeCoordinates"
                        [postopHeadPoseObservable]="headPoseReport"
                        [electrodesReportReady]="electrodesReportReady"
                        [electrodeRollListener]="electrodeRollChange"
                        [electrodeContextListener]="electrodeContextChange"
                        [elementDisplayEventHandler]="notifyDisplayElementEvent">
                </app-case-stl-view>
                <div #stlview [style.width.px]="leadLocationWidth" [style.height.px]="leadLocationHeight"></div>
            </div>
        </div>
        <div class="lead-location-right-side">
            <div class="min-height-10 width-100-p"></div>
            @if (!electrodesReport.noReportAvailable) {
                <div class="lead-location-right-box">
                    <app-case-lead-simulator-controller class="width-100-p"
                                                        [numOfUnclearedElectrodes]="unclearedDetections.length"
                                                        [stlReport]="shareStlReport"
                                                        [electrodesReady]="electrodesReportReady"
                                                        [electrodeRollChange]="electrodeRollChange"
                                                        [electrodeContextChange]="electrodeContextChange"
                                                        [postopHeadPoseObservable]="headPoseReport"
                                                        [changeCoordinatesObservable]="changeCoordinates">
                    </app-case-lead-simulator-controller>
                </div>
            }
            <div class="lead-location-right-box">
                <app-case-stl-display-options
                        class="width-100-p"
                        [caseData]="caseData"
                        [caseUpdated]="caseUpdated"
                        [approvedTargets]="approvedTargets"
                        [displayView]="enableDisplay"
                        [stlReport]="shareStlReport"
                        [slicesReportListener]="slicesLoadedReport"
                        [sliceChangeEventEmitter]="sliceChanged"
                        [imageOpacityEventEmitter]="opacityChanged"
                        [elementDisplayEventEmitter]="notifyDisplayElementEvent"
                        [changeCoordinatesEventEmitter]="changeCoordinates"
                        [headPoseReportObservable]="headPoseReport">
                </app-case-stl-display-options>
            </div>
            <div class="lead-location-right-box" [@showSpecialMessage]="displaySpecialMessage">
                <mat-card appearance="outlined" class="message-container">
                    <mat-card-content class="mat-typography lead-location-message-card">
                        <span i18n="@@caseLoadLocationNonClearedMessage">
                            <strong>{{ 'note'|translate }}</strong>:<br>{{ specialMessageText }}.
                        </span>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
}
