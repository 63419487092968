import { Injectable } from '@angular/core';
import { ApiService, BooleanResponse, InvitationResponse, NewInvitationResponse } from './api.service';
import { Observable } from 'rxjs';

export { BooleanResponse, InvitationResponse, NewInvitationResponse };

@Injectable()
export class InvitationService {

    constructor(private api: ApiService) {
    }

    public createUserInvitation(email: string, options: {accountId: string | null, groupId?: string,
                                permissions?: string[]}): Observable<NewInvitationResponse> {
        return this.api.createUserInvitation(email, options);
    }

    public getInvitation(invitationId: string): Observable<InvitationResponse> {
        return this.api.getInvitation(invitationId);
    }

    public acceptInvitation(invitationId: string): Observable<BooleanResponse> {
        return this.api.acceptInvitation(invitationId);
    }
}
