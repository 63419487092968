<mat-card appearance="outlined">
    <mat-card-title></mat-card-title>
    <mat-card-content class="pv-container">
        <div class="pv-title-area">
            <div class="pv-filters-area">
                <mat-checkbox [(ngModel)]="errors.checked" (change)="applyFilters()" class="m-r-10">
                    Show Errors
                </mat-checkbox>
                <mat-checkbox [(ngModel)]="warnings.checked" (change)="applyFilters()" class="m-r-10">
                    Show Warnings
                </mat-checkbox>
                <mat-checkbox [(ngModel)]="leftSide.checked" (change)="applyFilters()" class="m-r-10">
                    Show Left Side
                </mat-checkbox>
                <mat-checkbox [(ngModel)]="rightSide.checked" (change)="applyFilters()" class="m-r-10">
                    Show Right Side
                </mat-checkbox>
                <mat-checkbox [(ngModel)]="showOnlyProblems" (change)="applyFilters()" class="m-r-10">
                    Show Only Problems
                </mat-checkbox>
            </div>
            <div>
                <button mat-raised-button (click)="onClose()">Close</button>
            </div>
        </div>
        <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="side" i18n="@@predictionValidationSide">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="pv-column-100-start">Side</mat-header-cell>
                <mat-cell *matCellDef="let segValidationItem" class="pv-column-100-start">
                    {{ segValidationItem.side|translate }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="structure" i18n="@@predictionValidationStructure">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="pv-column-100-start">Structure
                </mat-header-cell>
                <mat-cell *matCellDef="let segValidationItem" class="pv-column-100-start">
                    {{ segValidationItem.structure|translate }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="feature" i18n="@@predictionValidationFeature">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="pv-column-250-start">Feature
                </mat-header-cell>
                <mat-cell *matCellDef="let segValidationItem" class="pv-column-250-start">
                    {{ displayFeature(segValidationItem.feature) }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="level" i18n="@@predictionValidationLevel">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="pv-column-100-start">Level
                </mat-header-cell>
                <mat-cell *matCellDef="let segValidationItem" class="pv-column-100-start">
                    {{ getLevel(segValidationItem.level) }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="analysis" i18n="@@predictionValidationAnalysis">
                <mat-header-cell *matHeaderCellDef class="pv-analysis-header">
                    {{ "predictionValidationAnalysisHeader"|translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let item">
                    @if (analysisType(item.limits[0]) === TypeNumber) {
                        <div class="pv-analysis">
                            <span>{{ item.limits[0].toFixed(NumberPrecision) }}</span>
                            <span [ngClass]="analysisClass(item.value, item.limits[0], item.limits[1])">
                            {{ formatNumber(item.value) }}
                        </span>
                            <span>{{ item.limits[1].toFixed(NumberPrecision) }}</span>
                        </div>
                    }
                    @else if (analysisType(item.limits[0]) === TypeArray) {
                        <div class="pv-analysis">
                        <span>
                            [
                            <span class="pv-arr-number">{{ item.limits[0][0].toFixed(NumberPrecision) }},</span>
                            <span class="pv-arr-number">{{ item.limits[0][1].toFixed(NumberPrecision) }},</span>
                            <span>{{ item.limits[0][2].toFixed(NumberPrecision) }}</span>
                            ]
                        </span>
                            <span>
                            [
                            <span [ngClass]="analysisClassArray(item.value, 0, item.limits[0][0], item.limits[1][0])"
                                  class="pv-arr-number">
                                {{ formatArrayValue(item.value, 0) }},
                            </span>
                            <span [ngClass]="analysisClassArray(item.value, 1, item.limits[0][1], item.limits[1][1])"
                                  class="pv-arr-number">
                                {{ formatArrayValue(item.value, 1) }},
                            </span>
                            <span [ngClass]="analysisClassArray(item.value, 2, item.limits[0][2], item.limits[1][2])">
                                {{ formatArrayValue(item.value, 2) }}
                            </span>
                            ]
                        </span>
                            <span>
                            [
                            <span class="pv-arr-number">{{ item.limits[1][0].toFixed(NumberPrecision) }},</span>
                            <span class="pv-arr-number">{{ item.limits[1][1].toFixed(NumberPrecision) }},</span>
                            <span>{{ item.limits[1][2].toFixed(NumberPrecision) }}</span>
                            ]
                        </span>
                        </div>
                    }
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let segValidationItem; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator [pageSize]="78" [hidePageSize]="true"></mat-paginator>
    </mat-card-content>
</mat-card>
