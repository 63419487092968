<div class="electrode-model-main">
    <p class="mat-typography electrode-model-header" i18n="@@caseElectrodeModelTitle">
        <span class="select-electrode-title-text">{{ 'caseElectrodeModelTitle'|translate }}</span>
        <span class="select-electrode-reminder">
            {{ electrodeSelectionPrompt }}
        </span>
    </p>
    <div class="electrode-model-side">
        <p class="mat-typography electrode-model-side-text" i18n="@@caseElectrodeModelLeftSide">
            {{ 'caseElectrodeModelLeftSide'|translate }}:
        </p>
        <mat-select class="electrode-model-selector" data-testid="left-electrode-model" panelWidth="auto"
                    [(ngModel)]="selectedLeft" (selectionChange)="onLeftSelection()">
            @for (leftElectrode of electrodes; track leftElectrode.value) {
                <mat-option [value]="leftElectrode" data-testid="left-electrode-options">
                    {{ leftElectrode.modelDescKey|translate }}
                </mat-option>
            }
        </mat-select>
    </div>
    <div class="electrode-model-side">
        <p class="mat-typography electrode-model-side-text" i18n="@@caseElectrodeModelRightSide">
            {{ 'caseElectrodeModelRightSide'|translate }}:
        </p>
        <mat-select class="electrode-model-selector" data-testid="right-electrode-model" panelWidth="auto"
                    [(ngModel)]="selectedRight" (selectionChange)="onRightSelection()">
            @for (rightElectrode of electrodes; track rightElectrode.value) {
                <mat-option [value]="rightElectrode" data-testid="right-electrode-options">
                    {{ rightElectrode.modelDescKey|translate }}
                </mat-option>
            }
        </mat-select>
    </div>
</div>
