<mat-card appearance="outlined" class="postop-review-card-container">
    <mat-card-title class="sis-card-title">
        <span class="mat-typography sis-bold">Postop Review</span>
    </mat-card-title>
    <mat-card-content class="postop-review-content">
        <form [formGroup]="flowOptions" class="postop-review-select-form">
            <mat-form-field class="width-80-p">
                <mat-label>Exit Code</mat-label>
                <mat-select [formControl]="exitCode" (selectionChange)="onSelectionChange()" panelWidth="auto">
                    @for (code of codes; track code.value) {
                        <mat-option [value]="code.value">
                            {{ code.value }}&nbsp;-&nbsp;{{ code.descKey|translate }}
                        </mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </form>
        <button mat-raised-button class="width-60-p"
                [disabled]="!flowOptions.dirty" (click)="applyChanges()">
            Apply Changes
        </button>
    </mat-card-content>
</mat-card>
