<mat-card class="alerts-dialog">
    <mat-card-title class="sis-card-title">
        <span class="mat-typography alerts-title" i18n="@@systemAlertsMainTitle">{{ data.title }}</span>
    </mat-card-title>
    <mat-card-content class="alerts-content mat-typography" [innerHTML]="data.content"></mat-card-content>
    <mat-card-actions class="alerts-action">
        <button mat-raised-button i18n="@@alertsBtnDismiss" (click)="dismiss()">
            {{ 'dismiss'|translate }}
        </button>
    </mat-card-actions>
</mat-card>

