import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardNavigationService } from './dashboard-navigation.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../material.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToolsModule } from '../tools/tools.module';
import { FlowsComponent } from './flows/flows.component';
import { DashboardComponent } from './dashboard.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        DashboardComponent,
        FlowsComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MaterialModule,
        FormsModule,
        CommonModule,
        RouterModule,
        ToolsModule,
        TranslateModule,
    ],
    providers: [
        DashboardNavigationService,
    ]
})
export class DashboardModule {
}
