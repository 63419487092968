import { Injectable } from '@angular/core';
import { ApiService, ClfDicomParams, ClfDicomResponse } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { ImageUse } from '../../dicom.constants';
import { SeriesId } from '../dicom.class';
import { SelectedDicomInfo } from './select-dicom.component';

@Injectable()
export class SelectDicomService {

    private selectedDicomInfo: Map<SeriesId, SelectedDicomInfo> = new Map<SeriesId, SelectedDicomInfo>();

    constructor(private apiService: ApiService) {
    }

    public select(newImgUse: ImageUse, seriesId: SeriesId, patientId: string): void {
        this.selectedDicomInfo.forEach((selectedData, sid) => {
            if (sid === seriesId || selectedData.iu === newImgUse || (newImgUse === ImageUse.none && sid === seriesId)) {
                // series or newImgUse are in the map
                this.selectedDicomInfo.delete(sid);
            }
        });
        if (newImgUse !== ImageUse.none) {
            this.selectedDicomInfo.set(seriesId, {iu: newImgUse, patientId});
        }
    }

    public has(imgUse: ImageUse): boolean {
        return Array.from(this.selectedDicomInfo.values()).some(sd => imgUse === sd.iu);
    }

    public patientIdsMismatch(currentIds: Array<string>): boolean {
        const selectedImageUse: Array<SelectedDicomInfo> = Array.from(this.selectedDicomInfo.values());

        // We should not use null or empty patient IDs when checking mismatches.
        const selectedPatientIds: Set<string> = new Set(selectedImageUse.map(x => x.patientId).filter(x => !!x));

        // We have a mismatch if there are multiple, distinct, non-empty patient IDs selected.
        if (selectedPatientIds.size > 1) {
            return true;
        }

        // We also have a mismatch if there are existing (non-empty) patient IDs and any of the selected IDs
        // are not among the existing patient IDs.
        const existingPatientIds: Array<string> = currentIds.filter(x => !!x);
        if (existingPatientIds.length > 0) {
            return Array.from(selectedPatientIds).some(id => !existingPatientIds.includes(id));
        }

        return false;
    }

    public hasSeries(id: string) {
        return this.selectedDicomInfo.has(id);
    }

    public clearSelection(): void {
        this.selectedDicomInfo.clear();
    }

    public getSelection(): Map<SeriesId, SelectedDicomInfo> {
        // return the content of the selection ordered by the ImageUse value
        return new Map([...this.selectedDicomInfo.entries()].sort(
            (a, b) => a[1].iu - b[1].iu
        ));
    }

    public isSelected(): boolean {
        return this.selectedDicomInfo.size > 0;
    }

    public classifyDicom(dicomParams: Array<ClfDicomParams>): Observable<ClfDicomResponse> {
        return this.apiService.classifyDicom(dicomParams);
    }
}
