import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountService, AccountState } from '../../../services/account.service';
import { Subscriptions } from '../../../tools/subscriptions.class';

@Component({
    selector: 'app-new-account',
    templateUrl: './new-account.component.html',
    styleUrls: ['./new-account.component.scss']
})
export class NewAccountComponent implements OnDestroy {

    newAccount = {
        name: '',
        state: AccountState.ACTIVE,
        emails: [],
    };
    // English Only - This is only for admins and there is no point in changing this enum for now
    accountStates = [AccountState.ACTIVE, AccountState.SUSPENDED];

    private submitInProgress = false;
    private subscriptions = new Subscriptions();

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<NewAccountComponent>,
                private accountService: AccountService) {
    }

    ngOnDestroy() {
        this.subscriptions.cancel();
    }

    onSubmit(): void {
        this.submitInProgress = true;
        this.accountService.createAccount(this.newAccount.name, this.newAccount.state,
            this.newAccount.emails).subscribe({
            next: data => {
                this.dialogRef.close(data);
            },
            error: error => {
                alert(error.message);
            },
            complete: () => {
                this.resetNewAccount();
            }
        });
    }

    private resetNewAccount() {
        this.newAccount.name = '';
        this.submitInProgress = false;
    }

    enableSubmission(): boolean {
        return this.newAccount.name.length > 0 && this.newAccount.state && !this.submitInProgress;
    }
}
