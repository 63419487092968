import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';

@Injectable({providedIn: 'root'})
export class QuartersService {

    private config: AppConfigService;

    constructor(config: AppConfigService) {
        this.config = config;
    }

    get fiscalYearStartMonth(): number {
        return this.config.get(AppConfigService.FISCAL_YEAR_START_MONTH);
    }

    private fiscalYear(date: Date) {
        const currentYear = date.getFullYear();
        return date.getMonth() < this.fiscalYearStartMonth ? currentYear - 1 : currentYear;
    }

    public quarterByIndex(index: number, now: Date): [Date, Date] {
        const fiscalYear = this.fiscalYear(now);
        return [new Date(fiscalYear, this.fiscalYearStartMonth + (index * 3), 1),
            new Date(fiscalYear, this.fiscalYearStartMonth + (index * 3) + 3, 0)];
    }

    public halfByIndex(index: number, now: Date): [Date, Date] {
        if (index > 1) {
            throw new Error(`Index should be either 0 or 1 (actual was ${index})`);
        }
        const fiscalYear = this.fiscalYear(now);
        return [new Date(fiscalYear, this.fiscalYearStartMonth + (index * 6), 1),
            new Date(fiscalYear, this.fiscalYearStartMonth + (index * 6) + 6, 0)];
    }

    public currentQuarter(now: Date): [Date, Date] {
        let month = now.getMonth();
        month = month < this.fiscalYearStartMonth ? month - this.fiscalYearStartMonth + 12 : month - this.fiscalYearStartMonth;
        return this.quarterByIndex(Math.floor(month / 3), now);
    }

    public prevQuarter(now: Date): [Date, Date] {
        let month = now.getMonth();
        month = month < this.fiscalYearStartMonth ? month - this.fiscalYearStartMonth + 12 : month - this.fiscalYearStartMonth;
        return this.quarterByIndex(Math.floor(month / 3) - 1, now);
    }
}
