import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

export function atLeastOneOf(sources: Array<Observable<boolean>>): Observable<boolean> {
    // Given an array of observables, each emitting booleans, combine them such that the resulting
    // observable emits true if the most recent value from any of the component values was true.

    // If there are no sources, then emit a single false value.
    if (sources.length === 0) {
        return of(false);
    }

    // If there is only a single source, then just emit distinct successive values from that source.
    if (sources.length === 1) {
        return sources[0].pipe(distinctUntilChanged());
    }

    // We want to see results any time any of the source observables emits a value, because the "or"
    // below can short-circuit. To do this, we'll add a "null" prefix to each observable that will
    // emit a value immediately. Then we can use combineLatest() and emit true values whenever any
    // component value is true or emit false values whenever all component values are false. If
    // some component values are false but the rest are null, we wait for the other components to emit
    // something. This way, subscribers that use take(1) will not get a false value that ignores the
    // responses of later sources after one of them emits false.
    const withPrefix = sources.map(x => x.pipe(startWith(null)));

    return combineLatest(withPrefix).pipe(
        // We only want to emit values if there is at least one true value, or if there are no null values.
        filter((data: Array<boolean | null>) => data.some(x => !!x) || data.every(x => x !== null)),
        map((data: Array<boolean | null>) => data.some(x => !!x)),
        distinctUntilChanged(), // Only report transitions from false to true or true to false
    );
}

export function replay<T>(observable: Observable<T>, n: number): Observable<T> {
    // Make the most recent `n` values emitted by the given observable available to new subscribers.
    const result = new ReplaySubject<T>(n);
    observable.subscribe(result);
    return result;
}
