import { Injectable } from '@angular/core';
import { CaseService } from '../case.service';
import { DataElement } from '../data-element.class';
import { Observable, of, Subject } from 'rxjs';
import { Subscriptions } from '../../tools/subscriptions.class';

@Injectable()
export class SlicesService {

    private slicesBuffer: ArrayBuffer = null;
    private subscriptions = new Subscriptions();

    constructor(private caseService: CaseService) {
    }

    loadPlanningToPostop(caseData: any, dataElement: DataElement): Observable<boolean> {
        if (this.caseService.hasElement(caseData, dataElement)) {
            const slicesAvailable = new Subject<boolean>();
            const urls = this.caseService.getElementUrls(caseData, dataElement);
            this.subscriptions.add(this.caseService.getCaseResourceAsArrayBuffer(caseData.id, urls[0], -90),
                data => {
                    this.slicesBuffer = data;
                    slicesAvailable.next(true);
                }, () => {
                    this.slicesBuffer = null;
                    slicesAvailable.next(false);
                }, () => {
                    slicesAvailable.complete();
                }
            );
            return slicesAvailable;
        }
        else {
            return of(false);
        }
    }

    public clear(): void {
        this.slicesBuffer = null;
        this.subscriptions.cancel();
    }

    getNifti(): ArrayBuffer {
        return this.slicesBuffer;
    }
}
