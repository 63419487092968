/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RegistrationMethod } from '../case/registration-method.class';
import { TranslateService } from '@ngx-translate/core';
import { DbsTarget } from '../case/case.constants';

type Permission = (string | string[])[];

// User permissions
export const ACCOUNT_ADMIN = 'ACCOUNT_ADMIN';

export interface BooleanResponse {
    result: boolean;
    message?: string;
}

export interface MessageResponse {
    message: string;
}

export interface LoginData {
    username: string;
    password: string;
}

export interface TokenResponse {
    access_token: string;
    user_id: string;
    expired: boolean;
    expire_in: number;
    strong_password?: boolean;
    session_expires_in?: number;
}

export interface GroupMembership {
    group_id: string;
    status: string;
    permissions?: string[];
}

export interface ContextInformation {
    context_key: string; // either: userId | groupId | accountId
    context_id: string;  // the id of the context as a string
}

export interface UserResponse {
    id: string;
    email: string;
    username?: string;
    created: string;
    updated: string;
    account_id?: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
    // noinspection JSUnusedGlobalSymbols
    role?: string;
    permissions?: string[];
    memberships?: GroupMembership[];
    status?: string;
    disabled?: boolean;
    enabled_features?: string[];
    accepted_tos: number;
    language?: string;
    home_context?: ContextInformation | null;
}

export interface TosResponse {
    tos_content: string;
    tos_version: number;
}

export interface Notification {
    id: string;
    start_t: string | null;
    end_t: string | null;
    language: string;
    title: string;
    content: string;
    popup?: boolean;
}

export interface NotificationsResponse {
    alerts: Array<Notification>;
}

export interface DeleteUserResponse {
    id: string;
    deleted_users: number;
    deleted_cases: number;
}

export interface RestrictionsResponse {
    user_id: string;
    restricted: boolean;
}

export enum AccountState {
    ACTIVE = 'ACTIVE',
    SUSPENDED = 'SUSPENDED'
}

export interface Account {
    // only id and name are returned from the API for normal users (for their own account)
    id: string;
    created?: string;
    updated?: string;
    name: string;
    description?: string;
    state?: AccountState;
    emails?: string[];
    address?: string;
    phone?: string;
    autopublish?: boolean;
}

export interface DeleteAccountResponse {
    result: string;
    deleted_count: number;
}

export enum GroupPermission {
    // The values here must match the values used by the API
    GroupAdmin = 'GROUP_ADMIN',
    CaseManager = 'CASE_MANAGER'
}

export interface GroupMember {
    user_id: string;
    status: string;
    email: string;
    first_name: string;
    last_name: string;
    disabled?: boolean;
    permissions?: GroupPermission[];
}

export interface Group {
    id: string;
    name: string;
    created: string;
    updated: string;
    members?: GroupMember[];
}

export interface GroupCandidate {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
}

// noinspection JSUnusedGlobalSymbols
export interface DeleteGroupResponse {
    id: string;
    detached_cases: number;
    detached_users: number;
    deleted_groups: number;
}

export interface FlowResponse {
    id: string;
    created: string;
    updated: string;
    case_id: string;
    user_id: string;
    code: number;
    submitted?: string;
    start_time?: string;
    end_time?: string;
    exit_code?: number;
    code_version?: string;
    cluster_name?: string;
    visibility: number;  // Visibility.code
    progress?: number;
    reg_msg?: string;
    approved_targets?: Array<string>;
}

export enum ActivityLogLevel {
    DEBUG = 1,
    INFO = 2,
    WARNING = 3,
    ERROR = 4
}

export interface ActivityLogResponse {
    id: string;
    created: string;
    updated: string;
    case_id: string;
    message: string;
    level: number;
}

export interface AuditEventResponse {
    id: string;
    created: string;
    case_id?: string;
    message: string;
}

export interface DataElementResponse {
    id: string;
    created: string;
    updated: string;
    case_id: string;
    flow_id?: string;
    user_id?: string;
    name: string;
    content_type: string;
    // Starting in API version 2, the URL array can be replaced by the number of URLs. See DataElement.urls(), .urlCount()
    url?: string[] | number;
    upload_report?: UploadReport;
    value?: ElectrodesDetectionReport | Array<SegValidationItem> | ElectrodeSelectionResponse | object;
    patient_id?: string;
}

enum QueryMode {
    list, count
}

export enum Archived {
    include, exclude
}

export interface CaseResponse {
    id: string;
    created: string;
    updated: string;
    archived?: string | null;
    user_id: string;
    account_id?: string;
    group_id?: string;
    name: string;
    planning_to_postop_registration_method?: string;
    description?: string;
    target: string;
    activity_log?: ActivityLogResponse[];
    audit_events?: AuditEventResponse[];
    flows?: FlowResponse[];
    elements?: DataElementResponse[];
    supported_structures: string[];
}

export class NewCase {
    constructor(public name: string = '', public description: string = '', public target: DbsTarget = null,
                public userId: string = null, public groupId: string = null) {
    }

    get payload(): any {
        return {
            name: this.name,
            description: this.description,
            target: this.target.key,
            user_id: this.userId,
            group_id: this.groupId
        };
    }
}

export interface InboundSeries {
    inbound_series_id: string;
    expiration_dt: string;
}

export interface InboundSeriesSaveResult {
    count: number;
}

export interface InboundSeriesAbortResult {
    count: number;
}

export interface UploadReport {
    errors: string[];
    warnings: string[];
    di: boolean;
}

export interface DicomUploadReport extends UploadReport {
    num_slices: number;
    sequence: string;
    seq_by_txt: string;
    seq_by_clf: string;
    clf_probability_map: [];
    image_use: string;
    slice_thickness: number;
    x_spacing: number;
    y_spacing: number;
    z_spacing_range?: Array<number>;
}

export interface UploadResponse {
    case: CaseResponse;
    upload_report: UploadReport;
}

export interface DeleteCaseResponse {
    case_id: string;
    result: string;
    deleted_cases: number;
    deleted_event_logs: number;
    deleted_flows: number;
    deleted_elements: number;
    deleted_patients?: number;
    deleted_series?: number;
}

// noinspection JSUnusedGlobalSymbols
export class ClfDicomParams {
    constructor(
        public series_id: string, public series_description: string, public protocol_name: string,
        public echo_time: number, public repetition_time: number, public inversion_time: number,
        public flip_angle: number, public magnetic_field_strength: number
    ) {
    }
}

export interface ClfDicomResult {
    series_id: string;
    // noinspection JSUnusedGlobalSymbols
    modality: string;
    sequence: string;
    seq_by_txt: string;
    seq_by_clf: string;
    predicted_proba: Array<[string, number]>;
}

export interface ClfDicomResponse {
    data: ClfDicomResult[];
}

// shall match the back end model from electrode_placement.py -> ElectrodeMatch.for_json()
export interface RollCandidate {
    roll: number;
    confidence: number;
    direction: Array<number>;
}

export interface ElectrodeDescription {
    schema: number;
    model?: string;
    cleared: boolean;
    name?: string;
    quality?: number;
    side: string;
    side_index?: number;
    coordinate_system?: string;
    tip?: Array<number>;
    axis?: Array<number>;
    radius?: number;
    pitch?: number;
    yaw?: number;
    roll_candidates?: Array<RollCandidate>;
    marker?: {
        center: Array<number>;
        height: number;
    };
    rings?: Array<{
        center: Array<number>;
        height: number;
        contacts: Array<string>;
    }>;
    intersections?: Array<{
        ring: number;
        structure: string;
        side: string;
    }>;
}

export interface ElectrodesDetectionReport {
    electrodes: Array<ElectrodeDescription>;

    // Not actually populated through the API, but added to the type for internal clients that have to maintain
    // backwards-compatibility with cases that have no electrode detection report available.
    noReportAvailable?: boolean;
}

export interface HeadPose {
    rotation: Array<Array<number>>;     // rotation degrees
    coordinate_system: string;
    pitch: number;
    yaw: number;
    roll: number;
    center: Array<number>;
    reference: string;
}

export const PRED_ERROR = 'error_thresholds';
export const PRED_WARN = 'warn_thresholds';

export interface SegValidationItem {
    target: string;
    structure: string;
    side: string;
    feature: string;
    value: number | Array<number> | null;
    limits: Array<number> | Array<Array<number>>;
    level: string;
    verdict: boolean;
}

export interface ElectrodeSelectionResponse {
    electrodes: {
        left: Array<string> | null;
        right: Array<string> | null;
    };
}

export interface NewInvitationResponse {
    invitation_id: string;
}

export interface InvitationResponse {
    id: string;
    accepted: string;
    user_status: string;
}

export interface PermissionResponse {
    allowed: boolean;
}

export interface PermissionsResponseItem {
    permission: Permission;
    allowed: boolean;
}

export interface VideoResponse {
    result: boolean;
    url: string;
}

export interface ReportTemplate {
    name: string;
}

export interface ReportsTemplatesResponse {
    templates: Array<ReportTemplate>;
}

export interface SnapshotReportResponse {
    cases?: {
        total_cases: number;
        prediction_flows_total: number;
        prediction_flows_succeeded: number;
        prediction_flows_excluded: number;
        prediction_flows_failed: number;
        prediction_completion_delay: number;
        prediction_publication_delay: number;
        postop_flows_total: number;
        postop_flows_succeeded: number;
        postop_flows_failed: number;
        postop_completion_delay: number;
        postop_publication_delay: number;
        total_leads: number;
        directional_leads: number;
        lead_orientations: number;
    };
    users?: {
        total_users: number;
        active_users: number;
    };
    accounts?: {
        total_accounts: number;
        active_accounts: number;
    };
    groups?: {
        total_groups: number;
        active_groups: number;
    };
    leads?: Map<string, number>;
}

export interface CaseDescriptor {
    name: string;
    description: string;
    archived: string;
}

export interface UserDescriptor {
    first_name: string;
    last_name: string;
    email: string;
}

export interface GroupDescriptor {
    name: string;
}

export interface AccountDescriptor {
    name: string;
    description: string;
    address: string;
    emails: Array<string>;
}

export interface SearchResult {
    id: string;
    scope: string;
    descriptors: AccountDescriptor | CaseDescriptor | GroupDescriptor | UserDescriptor;
}

export interface SearchResponse {
    terms: Array<string>;
    scopes: Array<string>;
    results: Array<SearchResult>;
}

function wrap<T>(observable: Observable<T>): Observable<T> {
    const result = new Subject<T>();
    observable.subscribe({
        next: (value: T) => result.next(value),
        error: (error: Error) => result.error(ApiError.map(error)),
        complete: () => result.complete()
    });
    return result;
}

@Injectable({providedIn: 'root'})
export class ApiService {

    private static TOKEN = 'TOKEN';
    private static TOKEN_OPTIONAL = 'TOKEN_OPTIONAL';
    private static JSON_REQUEST = 'JSON_REQUEST';
    private static DICOM_REQUEST = 'DICOM_REQUEST';
    private static JSON_RESPONSE = 'JSON_RESPONSE';
    private static XLSX_RESPONSE = 'XLSX_RESPONSE';
    private static CSV_RESPONSE = 'CSV_RESPONSE';
    private static ZIP_RESPONSE = 'ZIP_RESPONSE';

    private static ARG_ACCOUNT = 'account';
    private static ARG_ARCHIVED = 'archived';
    private static ARG_FOR = 'for';
    private static ARG_GROUP = 'group';
    private static ARG_LANGUAGE = 'language';
    private static ARG_QUERY_MODE = 'query_mode';

    private static X_REQUEST_PRIORITY = 'X-Request-Priority';
    private static X_LANGUAGE = 'X-Language';

    private baseURI = `${environment.rootUri}/api/v2`;

    private jwt: string | null = null;

    constructor(private http: HttpClient, private translate: TranslateService) {
    }

    set token(token: string | null) {
        this.jwt = token;
    }

    public auth(user: LoginData): Observable<TokenResponse> {
        const uri = `${this.baseURI}/auth`;

        return wrap(this.http.post<TokenResponse>(uri, user, {
            headers: this.headers(ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE),
            responseType: 'json'
        }));
    }

    getVideoResourceUrl(videoId: string): Observable<VideoResponse> {
        const uri = `${this.baseURI}/video/${videoId}`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        return wrap(this.http.get<VideoResponse>(uri, {
            headers, responseType: 'json'
        }));
    }

    public getTos(): Observable<TosResponse> {
        const uri = `${this.baseURI}/tos`;
        const headers = this.headers(ApiService.TOKEN_OPTIONAL, ApiService.JSON_RESPONSE);

        return wrap(this.http.get<TosResponse>(uri, {
            headers,
            responseType: 'json'
        }));
    }

    public queryAlerts(language?: string): Observable<NotificationsResponse> {
        const uri = `${this.baseURI}/alerts`;
        let params = new HttpParams();
        if (language) {
            params = params.set(ApiService.ARG_LANGUAGE, language);
        }
        const headers = this.headers(ApiService.TOKEN_OPTIONAL, ApiService.JSON_RESPONSE);

        return wrap(this.http.get<NotificationsResponse>(uri, {headers, params, responseType: 'json'}));
    }

    public getCasesForUser(userId: string, options?: {
        groupId?: string,
        archived?: string
    }): Observable<CaseResponse[]> {
        const uri = `${this.baseURI}/case`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);
        let params = new HttpParams().set(ApiService.ARG_FOR, userId);
        if (options) {
            if (options.groupId) {
                params = params.set(ApiService.ARG_GROUP, options.groupId);
            }
            if (options.archived) {
                params = params.set(ApiService.ARG_ARCHIVED, options.archived);
            }
        }
        return wrap(this.http.get<CaseResponse[]>(uri, {headers, params, responseType: 'json'}));
    }

    public countCasesForUser(userId: string, archived: Archived): Observable<number> {
        const uri = `${this.baseURI}/case`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);
        const params = new HttpParams().set(
            ApiService.ARG_FOR, userId).set(ApiService.ARG_QUERY_MODE, QueryMode[QueryMode.count]).set(
            ApiService.ARG_ARCHIVED, Archived[archived]);

        return wrap(this.http.get<number>(uri, {headers, params, responseType: 'json'}));
    }

    public getCasesForGroup(groupId: string, archived: Archived): Observable<CaseResponse[]> {
        const uri = `${this.baseURI}/case`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);
        const params = new HttpParams().set(ApiService.ARG_GROUP, groupId).set(ApiService.ARG_ARCHIVED, Archived[archived]);

        return wrap(this.http.get<CaseResponse[]>(uri, {headers, params, responseType: 'json'}));
    }

    public getCasesForAccount(accountId: string, archived: Archived): Observable<CaseResponse[]> {
        const uri = `${this.baseURI}/case`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);
        const params = new HttpParams().set(ApiService.ARG_ACCOUNT, accountId).set(ApiService.ARG_ARCHIVED, Archived[archived]);

        return wrap(this.http.get<CaseResponse[]>(uri, {headers, params, responseType: 'json'}));
    }

    public getCaseById(caseId: string): Observable<CaseResponse> {
        const uri = `${this.baseURI}/case/${caseId}`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        return wrap(this.http.get<CaseResponse>(uri, {headers, responseType: 'json'}));
    }

    public createNewCase(newCase: NewCase): Observable<CaseResponse> {
        const uri = `${this.baseURI}/case`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        return wrap(this.http.post<CaseResponse>(uri, newCase.payload, {headers, responseType: 'json'}));
    }

    public updateCase(caseId: string, payload: any): Observable<CaseResponse> {
        const uri = `${this.baseURI}/case/${caseId}`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        return wrap(this.http.post<CaseResponse>(uri, payload, {headers, responseType: 'json'}));
    }

    public submitCase(caseId: string): Observable<CaseResponse> {
        const uri = `${this.baseURI}/case/${caseId}/autosubmit`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        const options = {headers};

        return wrap(this.http.post<CaseResponse>(uri, '', options));
    }

    public forceSubmitCase(caseId: string, flow: number): Observable<CaseResponse> {
        const uri = `${this.baseURI}/case/${caseId}/submit`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);
        const payload = {flow_codes: [flow]};
        return wrap(this.http.post<CaseResponse>(uri, payload, {headers}));
    }

    public shareCase(caseId: string, shareOptions: { groupId?: string | null }): Observable<CaseResponse> {
        const uri = `${this.baseURI}/case/${caseId}/share`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);
        const body: any = {};
        if (shareOptions.groupId !== undefined) {
            body.group_id = shareOptions.groupId;
        }

        return wrap(this.http.post<CaseResponse>(uri, body, {headers, responseType: 'json'}));
    }

    public transferCase(caseId: string, userId: string): Observable<CaseResponse> {
        const uri = `${this.baseURI}/case/${caseId}/transfer`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);
        const body = {user_id: userId};

        return wrap(this.http.post<CaseResponse>(uri, body, {headers, responseType: 'json'}));
    }

    public logCaseEvent(caseId: string, level: number, message: string): Observable<CaseResponse> {
        const uri = `${this.baseURI}/case/${caseId}/log`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);
        const body = {level, message};

        return wrap(this.http.post<CaseResponse>(uri, body, {headers, responseType: 'json'}));

    }

    public getCaseTextResource(caseId: string, resourceUri: string, priority: number = 0): Observable<any> {
        const uri = `${this.baseURI}/fetch`;
        return wrap(this.http.get(uri, {
            headers: this.headers(ApiService.TOKEN).set(ApiService.X_REQUEST_PRIORITY, priority.toString()),
            params: new HttpParams().set('case_id', caseId).set('_id', resourceUri),
            responseType: 'text'
        }));
    }

    public getCaseJsonResource(caseId: string, resourceUri: string, priority: number = 0): Observable<any> {
        const uri = `${this.baseURI}/fetch`;
        return wrap(this.http.get(uri, {
            headers: this.headers(ApiService.TOKEN).set(ApiService.X_REQUEST_PRIORITY, priority.toString()),
            params: new HttpParams().set('case_id', caseId).set('_id', resourceUri),
            responseType: 'json'
        }));
    }

    public getHeadModel(caseId: string, priority: number = 0): Observable<Blob> {
        const uri = `${this.baseURI}/head-model`;
        return wrap(this.http.get(uri, {
            headers: this.headers(ApiService.TOKEN).set(ApiService.X_REQUEST_PRIORITY, priority.toString()),
            params: new HttpParams().set('case_id', caseId),
            responseType: 'blob'
        }));
    }

    public getCaseResource(caseId: string, resourceUri: string, priority: number = 0): Observable<Blob> {
        const uri = `${this.baseURI}/fetch`;
        const headers = this.headers(ApiService.TOKEN).set(ApiService.X_REQUEST_PRIORITY, priority.toString());

        const params = new HttpParams().set('case_id', caseId).set('_id', resourceUri);

        return wrap(this.http.get(uri, {
            headers,
            params,
            responseType: 'blob'
        }));
    }

    public getCaseResourceAsArrayBuffer(caseId: string, resourceUri: string, priority: number = 0): Observable<ArrayBuffer> {
        const uri = `${this.baseURI}/fetch`;
        const headers = this.headers(ApiService.TOKEN).set(ApiService.X_REQUEST_PRIORITY, priority.toString());

        const params = new HttpParams().set('case_id', caseId).set('_id', resourceUri);

        return wrap(this.http.get(uri, {
            headers,
            params,
            responseType: 'arraybuffer'
        }));
    }

    public getElementURLs(caseId: string, elementId: string): Observable<string[]> {
        const uri = `${this.baseURI}/case/${caseId}/element/${elementId}/urls`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);
        return wrap(this.http.get<string[]>(uri, {
            headers,
            responseType: 'json'
        }));
    }

    public getElementZipFile(caseId: string, elementId: string): Observable<Blob> {
        const uri = `${this.baseURI}/case/${caseId}/element/${elementId}/download`;
        const headers = this.headers(ApiService.TOKEN, ApiService.ZIP_RESPONSE);

        return wrap(this.http.get(uri, {headers, responseType: 'blob'}));
    }

    public getCaseReviewMrb(caseId: string): Observable<Blob> {
        const uri = `${this.baseURI}/case/${caseId}/review`;
        const headers = this.headers(ApiService.TOKEN);

        return wrap(this.http.get(uri, {headers, responseType: 'blob'}));
    }

    public update_flow(flowId: string, payload: any): Observable<CaseResponse> {
        const uri = `${this.baseURI}/flow/${flowId}`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);
        return wrap(this.http.post<CaseResponse>(uri, payload, {headers, responseType: 'json'}));
    }

    public publishCaseFlow(flowId: string): Observable<CaseResponse> {
        const uri = `${this.baseURI}/flow/${flowId}/publish`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        return wrap(this.http.post<CaseResponse>(uri, '', {headers, responseType: 'json'}));
    }

    public unpublishCase(caseId: string): Observable<CaseResponse> {
        const uri = `${this.baseURI}/case/${caseId}/unpublish`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        return wrap(this.http.post<CaseResponse>(uri, '', {headers, responseType: 'json'}));
    }

    public archiveCase(caseId: string): Observable<DeleteCaseResponse> {
        const uri = `${this.baseURI}/case/${caseId}/archive`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        return wrap(this.http.post<DeleteCaseResponse>(uri, '', {headers, responseType: 'json'}));
    }

    public restoreCase(caseId: string): Observable<CaseResponse> {
        const uri = `${this.baseURI}/case/${caseId}/restore`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        return wrap(this.http.post<CaseResponse>(uri, '', {headers, responseType: 'json'}));
    }

    public deleteCase(caseId: string, del_s3: boolean = true): Observable<DeleteCaseResponse> {
        const uri = `${this.baseURI}/case/${caseId}`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        const params = new HttpParams().set('del_s3', del_s3 ? '1' : '0');

        return wrap(this.http.delete<DeleteCaseResponse>(uri, {
            headers, params, responseType: 'json'
        }));
    }

    public listFlows(param: string): Observable<Array<FlowResponse>> {
        const uri = `${this.baseURI}/flow`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        const params = new HttpParams().set('type', param);

        return wrap(this.http.get<Array<FlowResponse>>(uri, {
            headers, params, responseType: 'json'
        }));
    }

    public getUsers(forUser: string | null = null): Observable<Array<UserResponse>> {
        const uri = `${this.baseURI}/users`;
        const options = {
            headers: this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE)
        };
        if (forUser != null) {
            options['params'] = new HttpParams().set('as_user', forUser);
        }
        return wrap(this.http.get(uri, options).pipe(map(this.toArray)));
    }

    public createNewUser(newUser: any): Observable<any> {
        const uri = `${this.baseURI}/user`;
        const options = this.headersOnly(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        return wrap(this.http.post(uri, newUser, options));
    }

    public getUser(userId: string): Observable<UserResponse> {
        const uri = `${this.baseURI}/user/${userId}`;

        return wrap(this.http.get<UserResponse>(uri, {
            headers: this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE),
            responseType: 'json'
        }));
    }

    public updateUser(userId: string, updates: any): Observable<any> {
        const uri = `${this.baseURI}/user/${userId}`;
        const options = this.headersOnly(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        return wrap(this.http.post(uri, updates, options));
    }

    public checkRestrictions(userId: string): Observable<RestrictionsResponse> {
        const uri = `${this.baseURI}/user/${userId}/restrictions`;

        return wrap(this.http.get<RestrictionsResponse>(uri, {
            headers: this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE),
            responseType: 'json'
        }));
    }

    public clearRestrictions(userId: string): Observable<RestrictionsResponse> {
        const uri = `${this.baseURI}/user/${userId}/restrictions`;

        return wrap(this.http.delete<RestrictionsResponse>(uri, {
            headers: this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE),
            responseType: 'json'
        }));
    }

    public deleteUser(userId: string): Observable<DeleteUserResponse> {
        const uri = `${this.baseURI}/user/${userId}`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);
        return wrap(this.http.delete<DeleteUserResponse>(uri, {headers, responseType: 'json'}));
    }

    public changePasswordNotAuthenticated(exToken: string, userId: string, newPassword: string, oldPassword?: string): Observable<BooleanResponse> {
        const uri = `${this.baseURI}/user/${userId}/expassword`;
        let headers = this.headers(ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);
        headers = headers.append('Authorization', exToken);
        return this._changePassword(uri, headers, newPassword, oldPassword);
    }

    public changePassword(userId: string, newPassword: string, oldPassword?: string): Observable<BooleanResponse> {
        const uri = `${this.baseURI}/user/${userId}/password`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);
        return this._changePassword(uri, headers, newPassword, oldPassword);
    }

    private _changePassword(uri: string, headers: HttpHeaders, newPassword: string, oldPassword?: string): Observable<BooleanResponse> {
        // Build the request body. The new password is required. The old password may not be necessary, as
        // administrators may be able to force a password change for another user. (Whether this is possible
        // is controlled by the permission ['USER', 'FORCE_PASSWORD', user_id])
        const body = {
            new_password: newPassword
        };
        if (oldPassword !== undefined) {
            const opk = 'old_password';
            body[opk] = oldPassword;
        }

        return wrap(this.http.post<BooleanResponse>(uri, body, {
            headers,
            responseType: 'json'
        }));
    }

    public changePasswordWithAuthCode(authCode: string, newPassword: string): Observable<TokenResponse> {
        const uri = `${this.baseURI}/auth_code/password`;
        const headers = this.headers(ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        const body = {
            auth_code: authCode,
            new_password: newPassword
        };

        return wrap(this.http.post<TokenResponse>(uri, body, {
            headers,
            responseType: 'json'
        }));
    }

    public resetPassword(email: string): Observable<BooleanResponse> {
        const uri = `${this.baseURI}/password_reset`;
        const headers = this.headers(ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        const body = {
            email,
        };

        return wrap(this.http.post<BooleanResponse>(uri, body, {
            headers,
            responseType: 'json'
        }));
    }

    public getPermission(permission: Permission): Observable<PermissionResponse> {
        const uri = `${this.baseURI}/security/allowed`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        return wrap(this.http.post<PermissionResponse>(uri, {permission}, {
            headers,
            responseType: 'json'
        }));
    }

    public getPermissions(permissions: Permission[]): Observable<PermissionsResponseItem[]> {
        const uri = `${this.baseURI}/security/multi_allowed`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        return wrap(this.http.post<PermissionsResponseItem[]>(uri, {permissions}, {
            headers,
            responseType: 'json'
        }));
    }

    public refreshToken(): Observable<TokenResponse> {
        const uri = `${this.baseURI}/token/refresh`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        return wrap(this.http.get<TokenResponse>(uri, {
            headers,
            responseType: 'json'
        }));
    }

    public redeemAuthCode(authCode: string): Observable<TokenResponse> {
        const uri = `${this.baseURI}/auth_code/${authCode}`;
        const headers = this.headers(ApiService.JSON_RESPONSE);

        return wrap(this.http.post<TokenResponse>(uri, '', {
            headers,
            responseType: 'json'
        }));
    }

    public createAccount(name: string, state: AccountState = AccountState.ACTIVE, emails = []): Observable<Account> {
        const uri = `${this.baseURI}/account`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        return wrap(this.http.post<Account>(uri, {
            name,
            state,
            emails,
        }, {
            headers,
            responseType: 'json'
        }));
    }

    public getAccount(accountId: string): Observable<Account> {
        const uri = `${this.baseURI}/account/${accountId}`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        return wrap(this.http.get<Account>(uri, {
            headers,
            responseType: 'json'
        }));
    }

    public getAccounts(asUser: string, forCases: boolean): Observable<Account[]> {
        const uri = `${this.baseURI}/account`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);
        let params: HttpParams = new HttpParams();
        params = params.append('for_cases', forCases ? 1 : 0);
        if (asUser !== null) {
            params = params.append('as_user', asUser);
        }
        return wrap(this.http.get<Account[]>(uri, {headers, params, responseType: 'json'}));
    }

    public updateAccount(accountId: string, updates: any): Observable<Account> {
        const uri = `${this.baseURI}/account/${accountId}`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        return wrap(this.http.post<Account>(uri, updates, {
            headers,
            responseType: 'json'
        }));
    }

    public deleteAccount(accountId: string): Observable<DeleteAccountResponse> {
        const uri = `${this.baseURI}/account/${accountId}`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        return wrap(this.http.delete<DeleteAccountResponse>(uri, {
            headers,
            responseType: 'json'
        }));
    }

    public createUserInvitation(email: string, options: {
        accountId?: string | null, groupId?: string,
        permissions?: string[]
    }): Observable<NewInvitationResponse> {
        const uri = `${this.baseURI}/invitation`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        const body: {
            email: string,
            account_id?: string,
            group_id?: string,
            permissions?: string[],
        } = {
            email,
        };

        if (options.accountId !== undefined) {
            body.account_id = options.accountId;
        }

        if (options.groupId !== undefined) {
            body.group_id = options.groupId;
        }

        if (options.permissions !== undefined) {
            body.permissions = options.permissions;
        }

        return wrap(this.http.post<NewInvitationResponse>(uri, body, {headers, responseType: 'json'}));
    }

    public listAccountUsers(accountId: string): Observable<Array<UserResponse>> {
        const uri = `${this.baseURI}/account/${accountId}/users`;
        const options = {
            headers: this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE)
        };
        return wrap(this.http.get(uri, options).pipe(map(this.toArray)));
    }


    public getInvitation(invitationId: string): Observable<InvitationResponse> {
        const uri = `${this.baseURI}/invitation/${invitationId}`;
        const headers = this.headers(ApiService.TOKEN_OPTIONAL, ApiService.JSON_RESPONSE);

        return wrap(this.http.get<InvitationResponse>(uri, {
            headers,
            responseType: 'json'
        }));
    }

    public acceptInvitation(invitationId: string): Observable<BooleanResponse> {
        const uri = `${this.baseURI}/invitation/${invitationId}/accept`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        return wrap(this.http.post<BooleanResponse>(uri, '', {
            headers,
            responseType: 'json'
        }));
    }

    public createGroup(name: string): Observable<Group> {
        const uri = `${this.baseURI}/group`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        return wrap(this.http.post<Group>(uri, {name}, {
            headers,
            responseType: 'json'
        }));
    }

    public getGroup(groupId: string): Observable<Group> {
        const uri = `${this.baseURI}/group/${groupId}`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        return wrap(this.http.get<Group>(uri, {
            headers,
            responseType: 'json'
        }));
    }

    public updateGroup(groupId: string, updates: any): Observable<Group> {
        const uri = `${this.baseURI}/group/${groupId}`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        return wrap(this.http.post<Group>(uri, updates, {
            headers,
            responseType: 'json'
        }));
    }

    public deleteGroup(groupId: string): Observable<DeleteGroupResponse> {
        const uri = `${this.baseURI}/group/${groupId}`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        return wrap(this.http.delete<DeleteGroupResponse>(uri, {
            headers,
            responseType: 'json'
        }));
    }

    public updateGroupMembership(groupId: string, userId: string, permissions: string[]): Observable<GroupMembership | any> {
        const uri = `${this.baseURI}/group/${groupId}/member/${userId}`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        return wrap(this.http.post<GroupMembership>(uri, {permissions}, {
            headers,
            responseType: 'json'
        }));
    }

    public removeGroupMember(groupId: string, userId: string): Observable<any> {
        const uri = `${this.baseURI}/group/${groupId}/member/${userId}`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        return wrap(this.http.delete(uri, {
            headers,
            responseType: 'json'
        }));
    }

    public getGroups(forUser: string | null): Observable<Group[]> {
        const uri = `${this.baseURI}/group`;
        const options = {
            headers: this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE)
        };
        if (forUser !== null) {
            options['params'] = new HttpParams().set('as_user', forUser);
        }
        return wrap(this.http.get<Group[]>(uri, options));
    }

    public getGroupCandidates(groupId: string): Observable<GroupCandidate[]> {
        const uri = `${this.baseURI}/group/${groupId}/candidates`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        return wrap(this.http.get<GroupCandidate[]>(uri, {
            headers,
            responseType: 'json'
        }));
    }

    public listAvailablePlanningToPostopRegistrationMethods(): Observable<RegistrationMethod[]> {
        const uri = `${this.baseURI}/planning_to_postop_registration_methods`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

        return wrap(this.http.get<RegistrationMethod[]>(uri, {
            headers,
            responseType: 'json'
        }));
    }

    public classifyDicom(featuresList: Array<ClfDicomParams>): Observable<ClfDicomResponse> {
        const uri = `${this.baseURI}/dicom/classify`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        return wrap(this.http.post<ClfDicomResponse>(uri, {series_list: featuresList}, {
            headers,
            responseType: 'json'
        }));
    }

    public getReportTemplates(): Observable<ReportsTemplatesResponse> {
        const uri = `${this.baseURI}/report`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        return wrap(this.http.get<ReportsTemplatesResponse>(uri, {
            headers,
            responseType: 'json'
        }));
    }

    public getDetailedReport(scope: string, startDate: string, endDate: string): Observable<Blob> {
        const uri = `${this.baseURI}/report/${scope}`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.XLSX_RESPONSE);

        const payload = {parameters: {start_t: startDate, end_t: endDate}};
        return wrap(this.http.post(uri, payload, {headers, responseType: 'blob'}));
    }

    public getJsonUsageReport(startDate: string, endDate: string, context: string, ids: Array<string>): Observable<SnapshotReportResponse> {
        const uri = `${this.baseURI}/report/usage`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);

        const payload = {parameters: {start_t: startDate, end_t: endDate}};
        if (context !== null && ids.length > 0) {
            payload.parameters[context] = ids;
        }
        return wrap(this.http.post<SnapshotReportResponse>(uri, payload, {headers, responseType: 'json'}));
    }

    public getCsvUsageReport(startDate: string, endDate: string, context: string, ids: Array<string>): Observable<Blob> {
        const uri = `${this.baseURI}/report/usage`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.CSV_RESPONSE);

        const payload = {parameters: {start_t: startDate, end_t: endDate}};
        if (context !== null && ids.length > 0) {
            payload.parameters[context] = ids;
        }
        return wrap(this.http.post(uri, payload, {headers, responseType: 'blob'}));
    }

    public getSecurityReport(startDate: string, endDate: string): Observable<Blob> {
        const uri = `${this.baseURI}/report/security`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.XLSX_RESPONSE);

        const payload = {parameters: {start_t: startDate, end_t: endDate}};
        return wrap(this.http.post(uri, payload, {headers, responseType: 'blob'}));
    }

    public buildUploadCaseElementXHR(caseId: string, elementName: string): XMLHttpRequest {
        const uri = `${this.baseURI}/case/${caseId}/element/${elementName}`;

        const xhr = new XMLHttpRequest();
        xhr.open('POST', uri, true);
        xhr.setRequestHeader('Authorization', this.jwtAuthorization());
        xhr.setRequestHeader(ApiService.X_LANGUAGE, this.translate.currentLang);

        return xhr;
    }

    public beginInboundSeries(identifiers: {
        seriesId: string,
        studyId?: string,
        patientId?: string
    }): Observable<InboundSeries> {
        const uri = `${this.baseURI}/inbound-series`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);
        const payload = {
            series_uid: identifiers.seriesId,
            study_uid: identifiers.studyId || null,
            patient_id: identifiers.patientId || null,
        };

        return wrap(this.http.post<InboundSeries>(uri, payload, {headers, responseType: 'json'}));
    }

    public saveInboundSeriesFiles(inboundSeriesId: string, files: File[]): Observable<InboundSeriesSaveResult> {
        const uri = `${this.baseURI}/inbound-series/${inboundSeriesId}/instance`;

        if (files.length > 1) {
            // If there are multiple files, upload using multipart/form-data.
            const formData = new FormData();
            const headers = this.headers(ApiService.TOKEN, ApiService.JSON_RESPONSE);

            files.forEach(f => formData.append('upload[]', f));

            return wrap(this.http.post<InboundSeriesSaveResult>(uri, formData, {headers, responseType: 'json'}));
        }
        else {
            // if there is only a single file, upload as application/dicom.
            const headers = this.headers(ApiService.TOKEN, ApiService.DICOM_REQUEST, ApiService.JSON_RESPONSE);
            return wrap(this.http.post<InboundSeriesSaveResult>(uri, files[0], {headers, responseType: 'json'}));
        }
    }

    public commitInboundSeries(inboundSeriesId: string, caseId: string, elementName: string, dataIntegrity: boolean = true): Observable<UploadResponse> {
        const uri = `${this.baseURI}/inbound-series/${inboundSeriesId}/commit`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);
        const payload = {
            case_id: caseId,
            element_name: elementName,
            di: dataIntegrity
        };

        return wrap(this.http.post<UploadResponse>(uri, payload, {headers, responseType: 'json'}));
    }

    public abortInboundSeries(inboundSeriesId: string): Observable<InboundSeriesAbortResult> {
        const uri = `${this.baseURI}/inbound-series/${inboundSeriesId}/abort`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);
        const payload = {};

        return wrap(this.http.post<InboundSeriesAbortResult>(uri, payload, {headers, responseType: 'json'}));
    }

    postSearchQuery(text: string, scopes: Array<string>): Observable<SearchResponse> {
        const uri = `${this.baseURI}/search`;
        const headers = this.headers(ApiService.TOKEN, ApiService.JSON_REQUEST, ApiService.JSON_RESPONSE);
        const payload = {terms: [text], scopes};
        return wrap(this.http.post<SearchResponse>(uri, payload, {headers, responseType: 'json'}));
    }

    private headersOnly(...parts: string[]): any {
        return {headers: this.headers(...parts)};
    }

    private headers(...parts: string[]): HttpHeaders {
        let result = new HttpHeaders();

        if (parts.includes(ApiService.TOKEN)) {
            if (!this.jwt) {
                throw new ApiError('Prior authentication required');
            }
            result = result.append('Authorization', this.jwtAuthorization());
        }
        else if (parts.includes(ApiService.TOKEN_OPTIONAL) && this.jwt) {
            result = result.append('Authorization', this.jwtAuthorization());
        }

        if (parts.includes(ApiService.JSON_REQUEST)) {
            result = result.append('Content-Type', 'application/json; charset=utf-8');
        }
        else if (parts.includes(ApiService.DICOM_REQUEST)) {
            result = result.append('Content-Type', 'application/dicom; charset=utf-8');
        }

        if (parts.includes(ApiService.JSON_RESPONSE)) {
            result = result.append('Accept', 'application/json');
        }
        if (parts.includes(ApiService.XLSX_RESPONSE)) {
            // noinspection SpellCheckingInspection
            result = result.append('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        }
        if (parts.includes(ApiService.CSV_RESPONSE)) {
            result = result.append('Accept', 'text/csv');
        }
        if (parts.includes(ApiService.ZIP_RESPONSE)) {
            result = result.append('Accept', 'application/zip');
        }

        result = result.append(ApiService.X_LANGUAGE, this.translate.currentLang);
        return result;
    }

    private jwtAuthorization() {
        return 'JWT ' + this.jwt;
    }

    private toArray(data: any): Array<any> {
        return data as Array<any>;
    }
}

export class ApiError implements Error {

    constructor(public message: string, public originalError?: Error) {
    }

    public get status() {
        return this.originalError ? (this.originalError as any).status || 400 : 400;
    }

    public name = 'ApiError';

    static map(error: Error): Error {
        let newError: Error = new ApiError(error.message, error);
        if (error instanceof HttpErrorResponse) {
            if (!(error.error instanceof Error)) {
                if (error.headers.get('Content-Type') === 'application/json') {
                    const body = error.error;
                    const message = body.error || body.message || 'API Response Error ' + error.status;
                    if (typeof message === 'string') {
                        newError = new ApiError(message, error);
                    }
                }
            }
        }

        return newError;
    }
}
