import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GroupService } from '../../../services/group.service';

@Component({
    selector: 'app-new-group',
    templateUrl: './new-group.component.html',
    styleUrls: ['./new-group.component.scss']
})
export class NewGroupComponent {

    newGroup = {
        name: '',
    };
    private submitInProgress = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<NewGroupComponent>,
                private groupService: GroupService) {
    }

    onSubmit(): void {
        this.submitInProgress = true;
        this.groupService.createGroup(this.newGroup.name).subscribe({
            next: (data) => {
                this.dialogRef.close(data);
            },
            error: (error) => {
                alert(error.message);
            },
            complete: () => {
                this.resetNewGroup();
            }
        });
    }

    private resetNewGroup() {
        this.newGroup.name = '';
        this.submitInProgress = false;
    }

    enableSubmission(): boolean {
        return this.newGroup.name.length > 0 && !this.submitInProgress;
    }
}
