<div class="fill-parent reports-container">
    <div class="usage-report-header">
        <div class="usage-report-header-selection">
            <div class="reports-selection-context-item">
                <div>
                    <mat-radio-group class="usage-context-buttons" [(ngModel)]="selectedContext" ngDefaultControl
                                     (ngModelChange)="updateContextList()">
                        @for (uc of usageContextList; track uc.label) {
                            <mat-radio-button [value]="uc">{{ uc.label | translate }}</mat-radio-button>
                        }
                    </mat-radio-group>
                </div>
                <mat-form-field appearance="fill" class="width-100-p">
                    <mat-label>{{ 'reportSnapshotSelectContext'|translate }}</mat-label>
                    <input matInput type="text" [formControl]="contextControl" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" panelWidth="400px">
                        @for (option of filteredOptions | async; track option.trackId) {
                            <mat-option [value]="option"
                                        (click)="optionClicked($event, option)">
                                <mat-icon class="usage-report-auto-complete-item-icon">{{ option.iconKey }}</mat-icon>
                                <div class="usage-report-auto-complete-item-div">
                                    <div class="usage-report-auto-complete-item-desc-text">
                                        {{ option.display }}
                                    </div>
                                    <mat-checkbox [checked]="option.selected" (change)="toggleSelection(option)"
                                                  (click)="$event.stopPropagation()">
                                    </mat-checkbox>
                                </div>
                            </mat-option>
                        }
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <mat-form-field class="width-300">
                <mat-label>{{ 'reportSelectDatesRange'|translate }}</mat-label>
                <mat-select (selectionChange)="onDateRangeSelection()" [(ngModel)]="selectedTimeRange"
                            data-testid="reports-usage-range-select">
                    @for (time of timeOptions; track time.titleKey) {
                        <mat-option data-testid="reports-usage-range-options" [value]="time" id="forcePadding">
                            {{ time.titleKey|translate }}
                        </mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="reports-selection-item">
                <mat-label>{{ 'reportSelectDatesLabel'|translate }}</mat-label>
                <mat-date-range-input [rangePicker]="picker" [disabled]="inProgress"
                                      (selectionchange)="onDatePickerSelection()">
                    <input matStartDate placeholder="{{'startDate'|translate}}" [(ngModel)]="startDate">
                    <input matEndDate placeholder="{{'endDate'|translate}}" [(ngModel)]="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <div class="report-actions">
                <button mat-raised-button class="report-range-btn"
                        (click)="submitQuery()" [disabled]="disableSubmit()">
                    {{ 'submit'|translate }}
                </button>
                <button mat-raised-button class="report-range-btn" [disabled]="disableClear()" (click)="reset()">
                    {{ 'clear'|translate }}
                </button>
                <button mat-raised-button class="report-range-btn" (click)="submitExport()"
                        [disabled]="disableSubmit()">
                    {{ 'reportSnapshotExportCsv'|translate }}
                </button>
            </div>
        </div>
    </div>
    <div class="usage-report-content">
        <div class="usage-report-row">
            @if (caseData.length > 0) {
                <div class="usage-report-column">
                    <div class="usage-report-title-container">
                        <p class="mat-typography usage-item-title">{{ 'reportCasesAndFlows'|translate }}</p>
                    </div>
                    <mat-table [dataSource]="caseData">
                        <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                            <mat-cell class="usage-report-flex-start" *matCellDef="let statRow"
                                      [ngClass]="rowClass(statRow)">
                                {{ statRow.titleKey|translate }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                            <mat-cell class="usage-number-column" *matCellDef="let statRow"
                                      [ngClass]="rowClass(statRow)">
                                {{ formatItem(statRow) }}
                            </mat-cell>
                        </ng-container>
                        <mat-row *matRowDef="let statRow; columns: displayedColumns;" class="table-row">
                        </mat-row>
                    </mat-table>
                </div>
            }
            @if (caseLeadData.length > 0 || leadsData.length > 0) {
                <div class="usage-report-column">
                    <div class="usage-report-leads-leads">
                        @if (caseLeadData.length > 0) {
                            <div class="m-b-40">
                                <div class="usage-report-title-container">
                                    <p class="mat-typography usage-item-title">{{ 'reportSnapshotLeadSummary'|translate }}</p>
                                </div>
                                <mat-table [dataSource]="caseLeadData">
                                    <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                                        <mat-cell class="usage-report-flex-start" *matCellDef="let statRow">
                                            {{ statRow.titleKey|translate }}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                                        <mat-cell class="usage-number-column" *matCellDef="let statRow">
                                            {{ formatItem(statRow) }}
                                        </mat-cell>
                                    </ng-container>
                                    <mat-row *matRowDef="let statRow; columns: displayedColumns;" class="table-row">
                                    </mat-row>
                                </mat-table>
                            </div>
                        }
                        @if (leadsData.length > 0) {
                            <div>
                                <div class="usage-report-title-container">
                                    <p class="mat-typography usage-item-title">{{ 'reportLeads'|translate }}</p>
                                </div>
                                <mat-table [dataSource]="leadsData">
                                    <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                                        <mat-cell class="usage-report-flex-start" *matCellDef="let leadRow">
                                            {{ getElectrodeModel(leadRow.model)|translate }}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                                        <mat-cell class="usage-number-column" *matCellDef="let leadRow">
                                            {{ formatLeadCount(leadRow.count) }}
                                        </mat-cell>
                                    </ng-container>
                                    <mat-row *matRowDef="let leadRow; columns: displayedColumns;" class="table-row">
                                    </mat-row>
                                </mat-table>
                            </div>
                        }
                    </div>
                </div>
            }
            @if (usersData.length > 0 || accountsData.length > 0 || groupsData.length > 0) {
                <div class="usage-report-column"
                >
                    <div class="usage-report-users-accounts-groups">
                        @if (usersData.length > 0) {
                            <div class="m-b-40">
                                <div class="usage-report-title-container">
                                    <p class="mat-typography usage-item-title">{{ 'reportUsers'|translate }}</p>
                                </div>
                                <mat-table [dataSource]="usersData">
                                    <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                                        <mat-cell class="usage-report-flex-start" *matCellDef="let statRow">
                                            {{ statRow.titleKey|translate }}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                                        <mat-cell class="usage-number-column" *matCellDef="let statRow">
                                            {{ formatItem(statRow) }}
                                        </mat-cell>
                                    </ng-container>
                                    <mat-row *matRowDef="let statRow; columns: displayedColumns;" class="table-row">
                                    </mat-row>
                                </mat-table>
                            </div>
                        }
                        @if (accountsData.length > 0) {
                            <div class="m-b-40">
                                <div class="usage-report-title-container">
                                    <p class="mat-typography usage-item-title">{{ 'reportAccounts'|translate }}</p>
                                </div>
                                <mat-table [dataSource]="accountsData">
                                    <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                                        <mat-cell class="usage-report-flex-start" *matCellDef="let statRow">
                                            {{ statRow.titleKey|translate }}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                                        <mat-cell class="usage-number-column" *matCellDef="let statRow">
                                            {{ formatItem(statRow) }}
                                        </mat-cell>
                                    </ng-container>
                                    <mat-row *matRowDef="let statRow; columns: displayedColumns;" class="table-row">
                                    </mat-row>
                                </mat-table>
                            </div>
                        }
                        @if (groupsData.length > 0) {
                            <div>
                                <div class="usage-report-title-container">
                                    <p class="mat-typography usage-item-title">{{ 'reportGroups'|translate }}</p>
                                </div>
                                <mat-table [dataSource]="groupsData">
                                    <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                                        <mat-cell class="usage-report-flex-start" *matCellDef="let statRow">
                                            {{ statRow.titleKey|translate }}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                                        <mat-cell class="usage-number-column" *matCellDef="let statRow">
                                            {{ formatItem(statRow) }}
                                        </mat-cell>
                                    </ng-container>
                                    <mat-row *matRowDef="let statRow; columns: displayedColumns;" class="table-row">
                                    </mat-row>
                                </mat-table>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    </div>
</div>
