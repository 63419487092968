import { Component } from '@angular/core';

import { MaterialModule } from '../../material.module';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-not-found',
    standalone: true,
    imports: [
        MaterialModule,
        TranslatePipe,
    ],
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

    constructor() {
    }
}
