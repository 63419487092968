/* eslint-disable object-shorthand */
import { Injectable } from '@angular/core';
import {
    COLUMNS,
    CONVOLUTION_KERNEL,
    DicomSeries,
    DicomSlice,
    ECHO_TIME,
    FLIP_ANGLE,
    IMAGE_ORIENTATION_PATIENT,
    IMAGE_POSITION_PATIENT,
    INSTANCE_NUMBER,
    INV_TIME,
    MFS,
    MODALITY,
    P_NAME,
    PATIENT_ID,
    PATIENT_NAME,
    PXL_SPACING,
    REP_TIME,
    ROWS,
    SERIES_DATE,
    SERIES_DESC,
    SERIES_INSTANCE_UID,
    SERIES_TIME,
    SLICE_LOCATION,
    SLICE_THICKNESS,
    STUDY_INSTANCE_UID
} from '../dicom.class';
import { DataSet, parseDicom } from 'dicom-parser';
import { Observable, Subject } from 'rxjs';
import { Vector2, Vector3 } from 'three';
import { CT, MODALITIES, MR } from '../../dicom.constants';

@Injectable({providedIn: 'any'})
export class ScanDicomService {

    filesToProcess = 0;
    dicomFilesProcessed = 0;
    otherFilesDetected = 0;
    private progress = 0;
    private readFiles: Subject<number> = new Subject<number>();
    private dcmDataMap: Map<string, DicomSeries> = new Map<string, DicomSeries>();
    private tagRegEx = new RegExp('\\((?<one>[\\dA-Fa-f]{4}),(?<two>[\\dA-Fa-f]{4})\\)');

    constructor() {
    }

    get seriesMap(): Map<string, DicomSeries> {
        return this.dcmDataMap;
    }

    onFilesSelected(selectedFiles: FileList): Observable<number> {
        this.readFiles = new Subject<number>();
        this.filesToProcess = selectedFiles.length;
        this.dicomFilesProcessed = 0;
        this.otherFilesDetected = 0;
        this.progress = 0;
        this.dcmDataMap.clear();
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < selectedFiles.length; ++i) {
            this.processFile(selectedFiles[i]);
        }
        return this.readFiles;
    }

    private processFile(f: File): void {
        const reader = new FileReader();
        reader.onloadend = () => {
            const arrayBuffer = reader.result as ArrayBuffer;
            const byteArray = new Uint8Array(arrayBuffer);
            const ds = this.parseDicom(byteArray);
            if (ds != null) {
                this.dicomFilesProcessed += 1;
                this.dsClassify(f, ds);
            }
            else {
                console.log(`File - ${f.webkitRelativePath} is probably not a DICOM file.`);
                this.otherFilesDetected += 1;
            }
            let newProgress = (this.dicomFilesProcessed + this.otherFilesDetected) / this.filesToProcess;
            newProgress = Math.floor(newProgress * 100) / 100;
            if (newProgress > this.progress) {
                this.progress = newProgress;
                this.readFiles.next(this.progress);
            }
            if (this.dicomFilesProcessed + this.otherFilesDetected === this.filesToProcess) {
                // once we finished to read all the DicomSeries, process the series as a whole:
                // sort instances, check spacing & check consecutive instance numbers
                for (const dcmSeries of this.dcmDataMap.values()) {
                    dcmSeries.onLoadSeriesComplete();
                }
                this.readFiles.complete();
            }
        };
        reader.readAsArrayBuffer(f);
    }

    private parseDicom(byteArray: Uint8Array): DataSet | null {
        try {
            return parseDicom(byteArray);
        } catch {
            return null;
        }
    }

    private dsClassify(f: File, ds: DataSet): void {
        const seriesId = ds.string(this.tagAsKey(SERIES_INSTANCE_UID.tag));
        const studyId = ds.string(this.tagAsKey(STUDY_INSTANCE_UID.tag));
        const modalityName = ds.string(this.tagAsKey(MODALITY.tag));
        const rawPosition = ds.string(this.tagAsKey(IMAGE_POSITION_PATIENT.tag));
        const rawOrientation = ds.string(this.tagAsKey(IMAGE_ORIENTATION_PATIENT.tag));
        // A valid DICOM file should have - series ID, valid modality and location.
        // If any of the 3 is missing, the file will be ignored. Only MR and CT modalities will be processed.
        if (!seriesId || !modalityName || !rawPosition || ![MR, CT].map(m => m.name).includes(modalityName)) {
            console.log(`File - ${f.webkitRelativePath} is is probably not an MR or CT DICOM file.`);
            return;
        }
        // An exception while parsing the data in the DataSet will also cause the file to be ignored
        try {
            // Read the slice info
            const instanceNumber = ds.intString(this.tagAsKey(INSTANCE_NUMBER.tag));
            const imagePosition: Array<number> = rawPosition.split('\\').map(Number);
            const imageOrientation: Array<number> = rawOrientation.split('\\').map(Number);
            const sliceLocation = ds.floatString(this.tagAsKey(SLICE_LOCATION.tag));

            // Series Info and slice info that is maintained in the series class
            const seriesDate = ds.string(this.tagAsKey(SERIES_DATE.tag));
            const seriesTime = ds.string(this.tagAsKey(SERIES_TIME.tag));
            const seriesDesc = ds.string(this.tagAsKey(SERIES_DESC.tag));
            const protocolName = ds.string(this.tagAsKey(P_NAME.tag));
            const echoTime = ds.floatString(this.tagAsKey(ECHO_TIME.tag));
            const repetitionTime = ds.floatString(this.tagAsKey(REP_TIME.tag));
            const inversionTime = ds.floatString(this.tagAsKey(INV_TIME.tag));
            const flipAngle = ds.floatString(this.tagAsKey(FLIP_ANGLE.tag));
            const mfs = ds.floatString(this.tagAsKey(MFS.tag));
            const thickness = ds.floatString(this.tagAsKey(SLICE_THICKNESS.tag));
            const rows = ds.int16(this.tagAsKey(ROWS.tag));
            const columns = ds.int16(this.tagAsKey(COLUMNS.tag));
            const rawPixelSpacing = ds.string(this.tagAsKey((PXL_SPACING.tag)));
            const pixelSpacing = rawPixelSpacing.split('\\').map(s => Number(s));
            const spacing = new Vector2(pixelSpacing[0], pixelSpacing[1]);
            let sd = this.dcmDataMap.get(seriesId);
            const modality = MODALITIES.get(modalityName);

            // Convolution Kernel is conditionally required only when modality is CT. If it's not present, use an empty list.
            const convKernel = (ds.string(this.tagAsKey(CONVOLUTION_KERNEL.tag)) ?? '').split('\\');

            const patientId = ds.string(this.tagAsKey(PATIENT_ID.tag)) ?? null;
            const patientName = ds.string(this.tagAsKey(PATIENT_NAME.tag)) ?? null;
            if (!sd) {
                sd = new DicomSeries({
                    seriesId: seriesId,
                    studyId: studyId,
                    modality: modality,
                    seriesDesc: seriesDesc,
                    dateStr: seriesDate,
                    timeStr: seriesTime,
                    thickness: thickness,
                    rows: rows,
                    columns: columns,
                    xySpacing: spacing,
                    protocolName: protocolName,
                    echoTime: echoTime,
                    repetitionTime: repetitionTime,
                    inversionTime: inversionTime,
                    flipAngle: flipAngle,
                    mfs: mfs,
                    convKernel: convKernel
                });
                this.dcmDataMap.set(seriesId, sd);
            }
            const slice = new DicomSlice(f, instanceNumber, sliceLocation, new Vector3(...imagePosition),
                imageOrientation, patientId, patientName);
            sd.dicomSlices.push(slice);

        } catch (_e: unknown) {
            console.log(`File - ${f.webkitRelativePath} is probably not a DICOM file.`);
            return;
        }
    }

    private tagAsKey(tag: string): string {
        let key = '';
        const result = this.tagRegEx.exec(tag);
        if (result && result.groups) {
            key = `x${result.groups.one}${result.groups.two}`;
        }
        return key.toLowerCase();
    }

    getDicomSeries(seriesId: string): DicomSeries | undefined {
        return this.dcmDataMap.get(seriesId);
    }
}
