<div class="reset-password">
    <mat-card appearance="outlined" class="reset-password-card">
        <mat-card-title class="reset-password-card-title" i18n="@@resetPasswordTitle">
            {{ 'resetPasswordTitle'|translate }}
        </mat-card-title>
        <mat-card-content>
            <p i18n="@@resetPasswordInstructions">{{ 'resetPasswordInstructions'| translate }}.</p>
            <div class="password-policy">
                @if (!!passwordPolicy.requirementsKey()) {
                    <p i18n="@@changePasswordStrongRequirements">
                        <b>{{ 'note'| translate }}:</b> {{ passwordPolicy.requirementsKey()|translate }}.
                    </p>
                }
            </div>
            <form #resetPasswordForm="ngForm"
                  autocomplete="off"
                  [hidden]="failed"
                  (ngSubmit)="changePassword();">
                <div class="reset-password-input-and-warning">
                    <mat-form-field>
                        <mat-label>{{ 'newPassword'| translate }}</mat-label>
                        <input matInput
                               class="reset-password-input"
                               type="password"
                               name="newPassword"
                               required
                               autocomplete="off"
                               [(ngModel)]="model.newPassword">
                    </mat-form-field>
                    <div [hidden]="!showNonCompliantPassword"
                         i18n="@@changePasswordDoNotMeetReq"
                         class="reset-password-alert m-l-20">
                        {{ 'changePasswordDoNotMeetReq'|translate }}
                    </div>
                </div>
                <br>
                <mat-form-field>
                    <mat-label>{{ 'confirmNewPassword'|translate }}</mat-label>
                    <input matInput
                           class="reset-password-input"
                           type="password"
                           name="confirmPassword"
                           required
                           autocomplete="off"
                           [(ngModel)]="model.confirmPassword">
                </mat-form-field>
                <br>
                <button mat-raised-button
                        type="submit"
                        i18n="@@resetPasswordButtonSave"
                        [disabled]="resetPasswordForm.invalid || !readyToChange()">
                    {{ 'save'|translate }}
                </button>
            </form>
        </mat-card-content>
    </mat-card>
    <p [hidden]="!passwordsDontMatch()" class="mat-typography reset-password-error" i18n="@@resetPasswordErrorNotMatch">
        {{ 'passwordsDoNotMatch'|translate }}
    </p>
    @if (failed) {
        <p class="mat-typography reset-password-error" i18n="@@resetPasswordFailedError">
            {{ failureMessage }} - <a [routerLink]="['login']">login</a>.
        </p>
    }
</div>
