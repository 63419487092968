import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Subscriptions } from './subscriptions.class';
import { PermissionsService } from '../services/permissions.service';
import { AuthService } from '../services/auth.service';
import { suppressWith } from '../rxjs/operators/suppressWith';
import { Permission } from '../security/permissions.class';
import { replay } from '../rxjs/observables';

export class NavigationServiceEntry {
    constructor(public subject: Observable<boolean>, public child: string) {
    }
}

@Injectable()
export abstract class NavigationService  implements OnDestroy {

    protected subscriptions = new Subscriptions();
    protected failureRedirectPath = '/';
    private cache = new Map<string, Observable<boolean>>()

    protected constructor(protected permissionsService: PermissionsService,
                          protected authService: AuthService, protected router: Router) {
    }

    public ngOnDestroy() {
        this.subscriptions.cancel();
    }

    // noinspection JSUnusedGlobalSymbols
    abstract canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean>;

    protected maybeRedirectChild(canActivateObservable: Observable<boolean>) {
        return this.subscriptions.limit(canActivateObservable.pipe(
            take(1),
            tap(allowed => {
                if (!allowed) {
                    this.router.navigateByUrl(this.failureRedirectPath).then();
                }
            })
        ));
    }

    protected maybeRedirect(state: RouterStateSnapshot, table: Array<NavigationServiceEntry>): Observable<boolean> {
        // Create an observable to return to the router, which will tell it whether to allow navigation
        // to the bare dashboard page. This should only occur if we cannot redirect to any of the children.
        const result = new ReplaySubject<boolean>(1);
        // We need to loop over the items in the table, but each item in the table requires an asynchronous
        // request and also depends on the result of the prior (asynchronous) operation. So we create a
        // series of subjects, each one subscribing to the previous one. All subscriptions will be active
        // only until a value is emitted on `result`.
        const initialPrior = new ReplaySubject<boolean>(1);
        let prior = initialPrior;
        for (const item of table) {
            const thisItem = item;
            const nextPrior = new ReplaySubject<boolean>(1);
            this.subscriptions.add(prior.pipe(takeUntil(result)), (priorAllowed) => {
                this.subscriptions.add(thisItem.subject.pipe(takeUntil(result)), (allowed) => {
                    // If this item is allowed and no prior items were allowed, then redirect the user to
                    // the page for this item and finish.
                    if (!priorAllowed && allowed) {
                        this.router.navigate([state.url, thisItem.child]).then();
                        result.next(false);
                        result.complete();
                    }
                    // Propagate the cumulative "allowed" state
                    nextPrior.next(priorAllowed || allowed);
                    nextPrior.complete();
                });
                prior = nextPrior;
            });
        }

        // Trigger the cascade by inject a false value
        initialPrior.next(false);

        // If none of the child pages were accessible, then allow navigation to the no-settings page.
        this.subscriptions.add(prior.pipe(takeUntil(result)), priorAllowed => {
            if (!priorAllowed) {
                this.router.navigateByUrl(this.failureRedirectPath).then();
                result.next(false);
                result.complete();
            }
        });

        return result;
    }

    protected stableTransitions<T>(observable: Observable<T>): Observable<T> {
        // Eliminate consecutive repetitions of a single value. Do not propagate values while the permission service
        // has updates in progress. Remember the current value so that new subscribers get a response immediately when
        // the current answer is already known.
        return replay(observable.pipe(
            distinctUntilChanged(),
            suppressWith(
                combineLatest([this.permissionsService.anyPending, this.authService.onAuthState]).pipe(
                    map(p => p[0])
                )
            ),
        ), 1);
    }

    protected stablePermission(permission: Permission): Observable<boolean> {
        // Wait for there to be an authentication state (other than Pending) before even triggering the permission query,
        // and then ensure there are no pending permission queries before allowing the permissions through. Remember the
        // current value so that new subscribers get a response immediately when the current answer is already known.
        return replay(this.authService.onAuthState.pipe(
            take(1),
            switchMap(_ => this.permissionsService.hasPermission(permission)),
            distinctUntilChanged(),
            suppressWith(this.permissionsService.anyPending),
        ), 1)
    }

    protected cachedObservable(key: string, factory: () => Observable<boolean>) {
        let result = this.cache.get(key);
        if (result !== undefined) {
            return result;
        }
        result = factory();
        this.cache.set(key, result);
        return result;
    }
}
