<mat-card class="tos-dialog">
    <mat-card-title class="tos-card-title" i18n="@@tosMainTitle">
        <div class="tos-title-container">
            <span class="tos-title-font">{{ 'tosTitle'|translate }}</span>
            <span class="tos-title-reference-font">{{ 'tosReference'|translate }}</span>
        </div>
    </mat-card-title>
    <mat-card-content class="tos-content" [innerHTML]="content"></mat-card-content>
    <mat-card-actions class="tos-action">
        <button mat-raised-button class="tos-button" tabindex="-1" i18n="@@tosButtonCancel" [mat-dialog-close]="false">
            {{ 'cancel'|translate }}
        </button>
        <button mat-raised-button class="tos-button" tabindex="-1" i18n="@@tosButtonAccept" [mat-dialog-close]="true">
            {{ 'accept'|translate }}
        </button>
    </mat-card-actions>
</mat-card>

