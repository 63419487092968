import { APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { appRoutes } from './routes';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AccountService } from './services/account.service';
import { ApiService } from './services/api.service';
import { AuthGuardService } from './services/authguard.service';
import { AuthService } from './services/auth.service';
import { GroupService } from './services/group.service';
import { InvitationService } from './services/invitation.service';
import { PermissionsService } from './services/permissions.service';
import { UserService } from './services/user.service';
import { TosService } from './services/tos.service';
import { NotificationsService } from './main-nav/notifications.service';
import { AppConfigService } from './app-config.service';
import { LanguageService } from './services/language.service';
import { QuartersService } from './services/quarters.service';
import { CaseService } from './case/case.service';
import { UploadService } from './case/upload/upload.service';
import { SettingsNavigationService } from './settings/settings-navigation.service';
import { DashboardNavigationService } from './dashboard/dashboard-navigation.service';
import { ReportsService } from './reports/reports.service';
import { ReportsNavigationService } from './reports/reports-navigation.service';
import { ErrorStateMatcher, provideNativeDateAdapter } from '@angular/material/core';
import { RequiredEmailStateMatcher } from './tools/state-matchers';
import { HttpPriorityInterceptor } from './tools/http-priority-interceptor';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ElectrodeModelService } from './case/electrode-model/electrode-model.service';
import { DicomViewerService } from './case/dicom-viewer/dicom-viewer.service';
import { SearchService } from './main-nav/search/search.service';

export function initializeConfig(appConfigService: AppConfigService) {
    return () => appConfigService.load();
}

const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http: HttpClient) =>
    new TranslateHttpLoader(http, 'assets/i18n/', '.json');

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({eventCoalescing: true}),
        // provideExperimentalZonelessChangeDetection(),
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(appRoutes),
        provideAnimationsAsync(),
        provideNativeDateAdapter(),
        provideTranslateService({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        ApiService,
        AuthService,
        AuthGuardService,
        PermissionsService,
        UserService,
        GroupService,
        AccountService,
        CaseService,
        UploadService,
        ElectrodeModelService,
        DicomViewerService,
        SettingsNavigationService,
        DashboardNavigationService,
        ReportsService,
        ReportsNavigationService,
        InvitationService,
        TosService,
        NotificationsService,
        AppConfigService,
        QuartersService,
        LanguageService,
        SearchService,
        {provide: APP_INITIALIZER, useFactory: initializeConfig, deps: [AppConfigService], multi: true},
        {provide: ErrorStateMatcher, useClass: RequiredEmailStateMatcher},
        {provide: HTTP_INTERCEPTORS, useClass: HttpPriorityInterceptor, multi: true},
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {float: 'auto'}},
    ]
};
