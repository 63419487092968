<div class="reports-security-container">
    <div class="report-security-selection">
        <mat-form-field class="report-sec-time-option-select">
            <mat-label>{{ 'reportSecurityTimeFrame'|translate }}</mat-label>
            <mat-select (selectionChange)="onDateRangeSelection()" [(ngModel)]="selectedTimeRange"
                        data-testid="reports-security-range-select">
                @for (time of timeOptions; track time.titleKey) {
                    <mat-option data-testid="reports-security-range-options" [value]="time">
                        {{ time.titleKey|translate }}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>{{ 'reportSelectDatesLabel'|translate }}</mat-label>
            <mat-date-range-input [rangePicker]="picker" [disabled]="inProgress"
                                  (selectionchange)="onDatePickerSelection()">
                <input matStartDate placeholder="{{'startDate'|translate}}" [(ngModel)]="startDate">
                <input matEndDate placeholder="{{'endDate'|translate}}" [(ngModel)]="endDate">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

    </div>
    <div class="reports-security-submission">
        <button mat-raised-button class="report-range-btn" (click)="submitQuery()" [disabled]="disableSubmit()">
            {{ 'submit'|translate }}
        </button>
    </div>
</div>
