import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoadStlReport } from '../stl-view/stl-view.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { SlicesReport } from '../stl-view/stl-view.component';
import { LeadSimulatorReadiness } from '../lead-sim/lead-simulator-readiness.class';
import * as cc from '../../case.constants';
import { Case } from '../../case.service';
import { skip, take } from 'rxjs/operators';
import { HeadPose } from '../../../services/api.service';
import { DbsTarget } from '../../case.constants';

class DisplayOptionsElement {
    constructor(public tag: string, public side: string, public index: string, public titleKey: string,
                public checked: boolean = true, public disabled: boolean = true,
                public action: (e: DisplayOptionsElement) => void | null = null,
                public targets: Array<cc.Targets> = null,
                public available: boolean = false) {
    }

    public id(): string {
        let id = this.tag;
        if (this.side) {
            id = `${id}_${this.side}`;
        }
        if (this.index) {
            id = `${id}_${this.index}`;
        }
        return id;
    }
}

export class ElementDisplayEvent {
    constructor(public tag: string, public visible: boolean) {
    }
}

@Component({
    selector: 'app-case-stl-display-options',
    templateUrl: './stl-options.component.html',
    styleUrls: ['./stl-options.component.scss'],

    animations: [
        trigger('showStlOptions', [
            state('enabled', style({opacity: 1})),
            state('disabled', style({opacity: 0})),
            transition('enabled => disabled', animate('500ms')),
            transition('disabled => enabled', animate('500ms'))
        ])
    ],

})
export class StlOptionsComponent implements OnInit, OnDestroy {

    public coordinateSystems = cc.COORDINATE_SYSTEMS;

    @Input() caseData: Case;
    @Input() caseUpdated: Observable<Case>;
    @Input() approvedTargets: Array<DbsTarget>;
    // Input Observables that must be reported even if there is an error or missing element (next() with null)
    @Input() displayView: Observable<LeadSimulatorReadiness>;
    @Input() stlReport: Observable<LoadStlReport>;
    @Input() slicesReportListener: Observable<SlicesReport>;
    @Input() headPoseReportObservable: Observable<HeadPose>;
    // Other subjects/events that are reported by this options component
    @Input() elementDisplayEventEmitter: Subject<ElementDisplayEvent>;
    @Input() sliceChangeEventEmitter: Subject<number>;
    @Input() imageOpacityEventEmitter: Subject<number>;
    @Input() changeCoordinatesEventEmitter: Subject<cc.CoordinateSystem>;

    private subscriptions: Subscriptions = new Subscriptions();

    public target: cc.DbsTarget;
    public elements: Array<DisplayOptionsElement> = new Array<DisplayOptionsElement>();
    public stlEnabled = 'disabled';
    public numOfSlices = -1;
    public sliceIndex = 0;
    public disableSlider = true;
    public imageOpacity = cc.INITIAL_IMG_OPACITY;
    public coordinateSystem = cc.IMAGE_CS;
    public enableCoordinatesChange = false;

    constructor() {
        this.elements.push(new DisplayOptionsElement(cc.ELECTRODE, cc.LEFT, '1', 'stlOptionsElementElectrode', false, true));
        this.elements.push(new DisplayOptionsElement(cc.RN, cc.LEFT, null, 'stlOptionsElementRN', false, true, null, [cc.Targets.STN, cc.Targets.ALL]));
        this.elements.push(new DisplayOptionsElement(cc.STN, cc.LEFT, null, 'stlOptionsElementSTN', false, true, null, [cc.Targets.STN, cc.Targets.ALL]));
        this.elements.push(new DisplayOptionsElement(cc.GPE, cc.LEFT, null, 'stlOptionsElementGPE', false, true, null, [cc.Targets.GP, cc.Targets.ALL]));
        this.elements.push(new DisplayOptionsElement(cc.GPI, cc.LEFT, null, 'stlOptionsElementGPI', false, true, null, [cc.Targets.GP, cc.Targets.ALL]));
        this.elements.push(new DisplayOptionsElement(cc.VIM, cc.LEFT, null, 'stlOptionsElementVIM', false, true, null, [cc.Targets.VIM, cc.Targets.ALL]));
        this.elements.push(new DisplayOptionsElement(cc.VC, cc.LEFT, null, 'stlOptionsElementVC', false, true, null, [cc.Targets.VIM, cc.Targets.ALL]));
        this.elements.push(new DisplayOptionsElement(cc.VO, cc.LEFT, null, 'stlOptionsElementVO', false, true, null, [cc.Targets.VIM, cc.Targets.ALL]));
        this.elements.push(new DisplayOptionsElement(cc.MD, cc.LEFT, null, 'stlOptionsElementMD', false, true, null, [cc.Targets.VIM, cc.Targets.ALL]));
        this.elements.push(new DisplayOptionsElement(cc.PU, cc.LEFT, null, 'stlOptionsElementPU', false, true, null, [cc.Targets.VIM, cc.Targets.ALL]));

        this.elements.push(new DisplayOptionsElement(cc.T1_PLANE, null, null, 'stlOptionsElementImage', true, true, (element: DisplayOptionsElement) => {
                this.disableSlider = !this.hasSlices || !element.checked;
            })
        );
        this.elements.push(new DisplayOptionsElement(cc.ELECTRODE, cc.RIGHT, '1', 'stlOptionsElementElectrode', false, true));
        this.elements.push(new DisplayOptionsElement(cc.RN, cc.RIGHT, null, 'stlOptionsElementRN', false, true, null, [cc.Targets.STN, cc.Targets.ALL]));
        this.elements.push(new DisplayOptionsElement(cc.STN, cc.RIGHT, null, 'stlOptionsElementSTN', false, true, null, [cc.Targets.STN, cc.Targets.ALL]));
        this.elements.push(new DisplayOptionsElement(cc.GPE, cc.RIGHT, null, 'stlOptionsElementGPE', false, true, null, [cc.Targets.GP, cc.Targets.ALL]));
        this.elements.push(new DisplayOptionsElement(cc.GPI, cc.RIGHT, null, 'stlOptionsElementGPI', false, true, null, [cc.Targets.GP, cc.Targets.ALL]));
        this.elements.push(new DisplayOptionsElement(cc.VIM, cc.RIGHT, null, 'stlOptionsElementVIM', false, true, null, [cc.Targets.VIM, cc.Targets.ALL]));
        this.elements.push(new DisplayOptionsElement(cc.VC, cc.RIGHT, null, 'stlOptionsElementVC', false, true, null, [cc.Targets.VIM, cc.Targets.ALL]));
        this.elements.push(new DisplayOptionsElement(cc.VO, cc.RIGHT, null, 'stlOptionsElementVO', false, true, null, [cc.Targets.VIM, cc.Targets.ALL]));
        this.elements.push(new DisplayOptionsElement(cc.MD, cc.RIGHT, null, 'stlOptionsElementMD', false, true, null, [cc.Targets.VIM, cc.Targets.ALL]));
        this.elements.push(new DisplayOptionsElement(cc.PU, cc.RIGHT, null, 'stlOptionsElementPU', false, true, null, [cc.Targets.VIM, cc.Targets.ALL]));
    }

    public ngOnInit() {
        this.target = cc.DbsTargets.get(this.caseData.target);
        const combined = combineLatest([
            this.displayView, this.stlReport, this.slicesReportListener, this.headPoseReportObservable
        ]).pipe(take(1));
        this.subscriptions.add(combined, (data: any) => {
            this.stlEnabled = data[0].stlAvailable ? 'enabled' : 'disabled';
            // Stl report = which STL files exist
            this.onStlReport(data[1]);
            this.setEnableDisable();
            // If the target changed, all elements in this target shall be back to checked
            this.enabledElements.forEach(e => this.onDisplayElementChange(e.id(), true));
            // Slices response
            this.numOfSlices = data[2] !== null ? data[2].count : -1;
            this.sliceIndex = data[2] !== null ? data[2].index : 0;
            this.elements.find(x => x.tag === cc.T1_PLANE).disabled = !this.hasSlices;
            this.disableSlider = !this.hasSlices;
            // Head pose response
            this.enableCoordinatesChange = (data[3] !== null);
            // Once the initial data was loaded, we subscribe to case changes
            this.subscriptions.add(this.caseUpdated.pipe(skip(1)), (caseData: Case) => {
                if (caseData.target !== this.target.key) {
                    this.target = cc.DbsTargets.get(caseData.target);
                    this.onTargetChanged();
                }
            });
        });
    }

    private onTargetChanged() {
        this.setEnableDisable();
        // If the target changed, all elements in this target shall be back to checked
        this.enabledStructures.forEach(e => this.onDisplayElementChange(e.id(), true));
        this.disabledStructures.forEach(e => this.onDisplayElementChange(e.id(), false));
    }

    public ngOnDestroy(): void {
        this.subscriptions.cancel();
    }

    public disabled(e: DisplayOptionsElement): boolean {
        return !e.available || e.disabled;
    }

    private checked(e: DisplayOptionsElement): boolean {
        return e.checked;
    }

    private setEnableDisable(): void {
        this.bulkElements.forEach(e => {
            e.disabled = (e.targets !== null) ? !e.targets.includes(this.target.key) : false;
        });
    }

    get leftElements(): Array<DisplayOptionsElement> {
        return this.elements.filter(element => (element.side === cc.LEFT) && (this.caseData.supported_structures.includes(element.tag) || element.tag === cc.ELECTRODE));
    }

    get rightElements(): Array<DisplayOptionsElement> {
        return this.elements.filter(element => element.side === cc.RIGHT && (this.caseData.supported_structures.includes(element.tag) || element.tag === cc.ELECTRODE));
    }

    get otherElements(): Array<DisplayOptionsElement> {
        return this.elements.filter(element => element.side === null);
    }

    get bulkElements(): Array<DisplayOptionsElement> {
        return this.elements.filter(e => e.tag !== cc.T1_PLANE);
    }

    get disabledStructures(): Array<DisplayOptionsElement> {
        return this.bulkElements.filter(e => this.disabled(e) && (e.tag !== cc.ELECTRODE));
    }

    get enabledStructures(): Array<DisplayOptionsElement> {
        return this.bulkElements.filter(e => !this.disabled(e) && (e.tag !== cc.ELECTRODE));
    }

    get disabledElements(): Array<DisplayOptionsElement> {
        return this.bulkElements.filter(this.disabled);
    }

    get enabledElements(): Array<DisplayOptionsElement> {
        return this.bulkElements.filter(e => !this.disabled(e));
    }

    get allSelected(): boolean {
        return this.enabledElements.every(this.checked);
    }

    get someSelected(): boolean {
        return this.enabledElements.some(this.checked) && !this.allSelected;
    }

    get hasSlices(): boolean {
        return this.numOfSlices !== -1;
    }

    public onBulkElementDisplaysChange(selection: boolean) {
        this.enabledElements.forEach(e => this.onDisplayElementChange(e.id(), selection));
    }

    public onDisplayElementChange(tag: string, checked: boolean) {
        const element = this.elements.find(x => x.id() === tag);
        if (checked !== element.checked) {
            element.checked = checked;
            if (element.action) {
                element.action(element);
            }
            this.elementDisplayEventEmitter.next(new ElementDisplayEvent(element.id(), element.checked));
        }
    }

    public onImageOpacityChange(value: number): void {
        this.imageOpacity = value;
        this.imageOpacityEventEmitter.next(this.imageOpacity);
    }

    public onSlicesSliderChange(value: number): void {
        this.sliceIndex = value;
        this.sliceChangeEventEmitter.next(this.sliceIndex);
    }

    public getSlicesIndex(): string {
        return this.sliceIndex.toFixed().padStart(3, '0');
    }

    public onChangeImageCoordinates() {
        this.changeCoordinatesEventEmitter.next(this.coordinateSystem);
    }

    /***
     * Sets the availability of STL models for the elements
     * @param report - STL reports that is reported by the StlViewService
     * @private
     */
    private onStlReport(report: LoadStlReport) {
        cc.BRAIN_STRUCTURES.forEach(s => {
            this.elements.find(x => x.id() === s).available = report.has(s);
        });
        report.electrodes.forEach(electrode => {
            const element = this.elements.find(x => x.id() === `${cc.ELECTRODE}_${electrode.side}_${electrode.index}`);
            if (element) {
                element.available = true;
                element.disabled = false;
            }
        });
    }
}
