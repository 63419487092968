<div class="content-wrapper">
    <app-main-nav *ngIf="showNavBar"></app-main-nav>
    <div *ngIf="showIdleWarning()" class="bg-warning text-center" i18n="@@appIdleLogoutWarning">
        {{'appIdleLogoutWarning'|translate}} {{ idleSecondsRemaining }} {{'seconds'|translate}}.
    </div>
    <div *ngIf="showSessionWarning()" class="bg-warning text-center" i18n="@@appSessionLogoutWarning">
        {{'appSessionLogoutWarning'|translate}} {{ sessionSecondsRemaining }} {{'seconds'|translate}}.
    </div>
    <router-outlet></router-outlet>
</div>
