import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Case, CaseService } from '../../case.service';
import { Flows } from '../../case.constants';
import { FlowType } from '../../flow-type.class';
import { Subject } from 'rxjs';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../../material.module';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-case-tools-rerun-flow',
    standalone: true,
    imports: [
        FormsModule,
        MaterialModule,
        TranslatePipe,
    ],
    templateUrl: './re-run-flow.component.html',
    styleUrls: ['./re-run-flow.component.scss']
})
export class ReRunFlowComponent implements OnInit, OnDestroy {

    @Input() caseUpdated: Subject<Case>;

    private subscriptions = new Subscriptions();

    caseId: string;
    noFlow: FlowType = new FlowType(0, 'Please select flow', '');
    availableFlows: Array<FlowType> = [this.noFlow].concat(Object.keys(Flows).map(key => Flows[key]));
    selectedFlow: number = this.noFlow.flowCode;

    constructor(private caseService: CaseService) {
    }

    ngOnInit() {
        this.subscriptions.add(this.caseUpdated, data => {
            this.caseId = data.id;
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
    }

    submitFlowReRun() {
        this.caseService.forceSubmitCase(this.caseId, this.selectedFlow).subscribe(data => {
            this.caseUpdated.next(data);
        });
    }
}
