<mat-card appearance="outlined" class="panel panel-primary">
    <mat-card-title>
        <div class="change-password-title-section">
            <p i18n="@@changePasswordTitle">{{'changePasswordTitle'|translate}}</p>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div class="change-password-content-header">
            <p *ngIf="pwExpireMode" i18n="@@changePasswordExpiredNote">
                {{'changePasswordExpiredNote'|translate}}.
            </p>
            <p *ngIf="!!passwordPolicy.requirementsKey()" i18n="@@changePasswordStrongRequirements">
                <b>{{'note'| translate}}:</b> {{passwordPolicy.requirementsKey()|translate}}.
            </p>
        </div>
        <form #changePasswordForm="ngForm"
              class="form-horizontal"
              autocomplete="off"
              (ngSubmit)="changePassword(changePasswordForm);">
            <div class="change-password-input-and-warning">
                <mat-form-field>
                    <mat-label>{{'changePasswordOldPasswordPlaceholder'|translate}}</mat-label>
                    <input matInput
                           id="oldPassword"
                           type="password"
                           name="oldPassword"
                           required
                           autocomplete="off"
                           class="form-control"
                           [disabled]="disableForm"
                           (input)="clearResult()"
                           [(ngModel)]="model.oldPassword">
                </mat-form-field>
            </div>
            <div class="change-password-input-and-warning">
                <mat-form-field>
                    <mat-label>{{'newPassword'|translate}}</mat-label>
                    <input matInput
                           id="newPassword"
                           type="password"
                           name="newPassword"
                           required
                           autocomplete="off"
                           class="form-control"
                           [disabled]="disableForm"
                           (input)="clearResult()"
                           [(ngModel)]="model.newPassword">
                </mat-form-field>
                <div [hidden]="!showNonCompliantPassword"
                     i18n="@@changePasswordDoNotMeetReq"
                     class="change-password-alert m-l-20">
                    {{'changePasswordDoNotMeetReq'|translate}}
                </div>
            </div>
            <div class="change-password-input-and-warning">
                <mat-form-field>
                    <mat-label>{{'confirmNewPassword'|translate}}</mat-label>
                    <input matInput
                           id="confirmPassword"
                           type="password"
                           name="confirmPassword"
                           required
                           autocomplete="off"
                           class="form-control"
                           [disabled]="disableForm"
                           (input)="clearResult()"
                           [(ngModel)]="model.confirmPassword">
                </mat-form-field>
                <div [hidden]="!passwordsDontMatch()"
                     i18n="@@changePasswordNotMatchError"
                     class="change-password-alert">
                    {{'passwordsDoNotMatch'|translate}}
                </div>
            </div>
            <div class="change-password-footer">
                <div class="change-password-footer-actions">
                    <button mat-raised-button color="primary" type="submit"
                            [disabled]="changePasswordForm.invalid || !readyToChange()">
                        {{'save'|translate}}
                    </button>
                    <button mat-raised-button (click)="onCancel()" class="m-l-20">
                        {{'cancel'|translate}}
                    </button>
                </div>
                <p class="mat-typography change-password-success-message" *ngIf="result !== null && result">
                    {{resultMessage}}
                </p>
                <p class="mat-typography change-password-error-message" *ngIf="result !== null && !result">
                    {{resultMessage|translate}}
                </p>
            </div>
        </form>
    </mat-card-content>
</mat-card>
