import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { PermissionsService } from '../../../services/permissions.service';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '../../../services/account.service';
import { NewUserDirective, NewUserForm } from '../new-user.directive';

@Component({
    selector: 'app-invite-user',
    templateUrl: './invite-user.component.html',
    styleUrls: ['./invite-user.component.scss']
})
export class InviteUserComponent extends NewUserDirective implements OnInit, OnDestroy {

    constructor(@Inject(MAT_DIALOG_DATA) data: any, dialogRef: MatDialogRef<InviteUserComponent>,
                permissionsService: PermissionsService, accountService: AccountService) {
        super(data, dialogRef, permissionsService, accountService);
        this.mainForm = new FormGroup<NewUserForm>({
            email: this.email,
            account: this.account,
        });
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    public inviteUser() {
        this.inProgress = true;
        const invitation = this.mainForm.getRawValue();
        const accountId = this.candidateAccounts.length === 1 ? this.candidateAccounts[0].accountId : invitation.account.accountId;
        this.accountService.createUserInvitation(invitation.email, {
            accountId
        }).subscribe(this.saveObserver);
    }
}
