<mat-card>
    <mat-card-title class="sis-card-title" i18n="@@createNewCaseTitle">{{'createNewCase'|translate}}</mat-card-title>
    <mat-card-content>
        <form>
            <mat-form-field class="new-case-form-100">
                <mat-label>{{'createNewCaseNamePlaceholder'|translate}}</mat-label>
                <input matInput name="caseName" data-testid="new-case-name" [(ngModel)]="newCase.name" required>
            </mat-form-field>
            <mat-form-field class="new-case-form-100">
                <mat-label>{{'description'|translate}}</mat-label>
                <textarea matInput name="caseDescription" data-testid="new-case-desc"
                          cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                          [(ngModel)]="newCase.description">
                </textarea>
            </mat-form-field>
            <mat-form-field class="new-case-form-60">
                <mat-label>{{'newCaseSelectTarget'|translate}}</mat-label>
                <mat-select data-testid="new-case-target" [(ngModel)]="newCase.target"
                            [ngModelOptions]="{standalone: true}">
                    @for (target of targets; track target.key) {
                        <mat-option [value]="target" data-testid="new-case-target-options">
                            {{target.titleKey|translate}}
                        </mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </form>
    </mat-card-content>
    <mat-card-actions class="new-case-actions">
        <button mat-raised-button
                i18n="@@createNewCaseCancelButton"
                data-testid="new-case-cancel"
                [mat-dialog-close]=null>
            {{'cancel'|translate}}
        </button>
        <button mat-raised-button
                i18n="@@createNewCaseSaveButton"
                data-testid="new-case-save"
                (click)="onSubmit()"
                [disabled]="!enableSubmission()">
            {{'save'|translate}}
        </button>
    </mat-card-actions>
</mat-card>


