import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PRED_ERROR, PRED_WARN, SegValidationItem } from '../../../services/api.service';
import { TableDirective } from '../../../tools/table.directive';
import { MatTableDataSource } from '@angular/material/table';
import { LEFT, RIGHT } from '../../case.constants';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../../material.module';
import { TranslatePipe } from '@ngx-translate/core';

interface FilterElement {
    checked: boolean;
    value: string;
}

@Component({
    selector: 'app-case-prediction-validation',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        TranslatePipe,
    ],
    templateUrl: './prediction-validation.component.html',
    styleUrls: ['./prediction-validation.component.scss']
})
export class PredictionValidationComponent extends TableDirective<SegValidationItem> implements OnInit {

    public NumberPrecision = 2;
    public TypeNumber = 'TypeNumber';
    public TypeArray = 'TypeArray';

    private readonly svFeatures: Map<string, string> = new Map<string, string>([
        ['volume', 'Volume (Cubic mm)'],
        ['shape', 'Shape (mm)'],
        ['rn_dist', 'RN Distance (mm)'],
        ['stn_rn_dist', 'STN-RN Distance (mm)'],
        ['stn_rn_angle', 'STN-RN Angle (Degrees)'],
        ['sn_rn_dist', 'SN-RN Distance (mm)'],
        ['gpe_dist', 'GPE Distance (mm)'],
        ['gpi_dist', 'GPI Distance (mm)'],
        ['gpi_gpe_dist', 'GPi-GPe Distance (mm)'],
        ['stn_com_x,stn_com_y,stn_com_z', 'STN COM'],
        ['sn_com_x,sn_com_y,sn_com_z', 'SN COM'],
        ['gpe_com_x,gpe_com_y,gpe_com_z', 'GPE COM'],
        ['gpi_com_x,gpi_com_y,gpi_com_z', 'GPI COM'],
        ['stn_ccs_x,stn_ccs_y,stn_ccs_z', 'STN CCS COM'],
        ['rn_ccs_x,rn_ccs_y,rn_ccs_z', 'RN CCS COM'],
        ['sn_ccs_x,sn_ccs_y,sn_ccs_z', 'SN CCS COM'],
        ['gpe_ccs_x,gpe_ccs_y,gpe_ccs_z', 'GPE CCS COM'],
        ['gpi_ccs_x,gpi_ccs_y,gpi_ccs_z', 'GPI CCS COM'],
    ]);

    errors: FilterElement = {checked: true, value: PRED_ERROR};
    warnings: FilterElement = {checked: true, value: PRED_WARN};
    leftSide: FilterElement = {checked: true, value: LEFT};
    rightSide: FilterElement = {checked: true, value: RIGHT};
    showOnlyProblems = true;

    constructor(@Inject(MAT_DIALOG_DATA) public predValidation: Array<SegValidationItem>,
                public dialogRef: MatDialogRef<PredictionValidationComponent>) {
        super(['side', 'structure', 'feature', 'level', 'analysis'], new MatTableDataSource<SegValidationItem>());
    }

    ngOnInit(): void {
        this.applyFilters();
    }

    applyFilters(): void {
        const levels: string[] = [this.errors, this.warnings].filter(item => item.checked).map(l => l.value);
        const sides: string[] = [this.leftSide, this.rightSide].filter(item => item.checked).map(s => s.value);
        this.dataSource.data = this.predValidation.filter(
            item => levels.includes(item.level) && (sides.includes(item.side) || item.side === null) && (this.showOnlyProblems ? !item.verdict : true)
        );
    }

    public analysisType(value: number | Array<number>): string {
        return (typeof value === 'number') ? this.TypeNumber : this.TypeArray;
    }

    public getLevel(level: string): string {
        return level === PRED_ERROR ? 'E' : 'W';
    }

    public onClose() {
        this.dialogRef.close(0);
    }

    public analysisClass(value: number | null, lower: number, upper: number): string {
        return (value === null || (typeof value !== 'number') || value < lower) ?
            'cold-text' : (value > upper) ? 'warm-text' : '';
    }

    public analysisClassArray(value: number | null, index: number, lower: number, upper: number): string {
        return (value === null || (Array.isArray(value) && value.length === 0) || value[index] < lower) ?
            'cold-text' : (value > upper) ? 'warm-text' : '';
    }

    public displayFeature(feature: string): string {
        const f = this.svFeatures.get(feature.toString());
        return f ? f : feature;
    }

    formatArrayValue(value: Array<number> | null, index: number): string {
        return (value === null || (Array.isArray(value) && value.length === 0)) ?
            'NA' : this.formatNumber(value[index]);
    }

    formatNumber(value: number | null): string {
        return (value === null || typeof value !== 'number') ? 'NA' : value.toFixed(this.NumberPrecision);
    }
}
