import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportsService } from '../reports.service';
import { saveAs } from 'file-saver';
import { ReportDirective } from '../report.directive';
import { QuartersService } from '../../services/quarters.service';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-detailed-reports',
    standalone: true,
    imports: [
        FormsModule,
        MaterialModule,
        TranslatePipe,
    ],
    templateUrl: './detailed-report.component.html',
    styleUrls: ['./detailed-report.component.scss', '../reports.component.scss']
})
export class DetailedReportComponent extends ReportDirective implements OnInit, OnDestroy {

    currentScope: string;
    template = 'cases';
    ready = false;

    constructor(reportsService: ReportsService, qService: QuartersService) {
        super(reportsService, qService);
    }

    ngOnInit(): void {
        this.subscriptions.add(this.rs.getTemplatesReady(),
            (success: boolean) => {
                if (success) {
                    this.currentScope = ReportsService.CASES;
                }
                this.ready = success;
            });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    getTemplateTitle(key: string): string {
        return this.rs.availableTemplates.get(key);
    }

    getTemplates(): Array<string> {
        return Array.from(this.rs.availableTemplates.keys());
    }

    submitQuery() {
        this.inProgress = true;
        this.rs.getDetailedReport(this.currentScope, this.startDate, this.endDate).subscribe({
            next: (reportBody) => {
                saveAs(reportBody, `${this.currentScope}.xlsx`);
            },
            error: () => {
                this.inProgress = false;
            },
            complete: () => {
                this.inProgress = false;
            }
        });
        this.reset();
    }
}
