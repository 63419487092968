import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationState, AuthService } from './auth.service';

@Injectable()
export class AuthGuardService  {

    private static AFTER_LOGIN_URL = 'afterLoginUrl';
    private mAfterLoginUrl: string = null;

    constructor(private router: Router, private authService: AuthService) {
        authService.beforeLogout.subscribe(() => {
            if (this.authService.getAuthenticationState() === AuthenticationState.Valid) {
                this.setAfterLoginUrl(null);
            }
        });
        authService.afterLogout.subscribe(() => {
            // noinspection JSIgnoredPromiseFromCall
            this.router.navigate(['/login']);
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        switch (this.authService.getAuthenticationState()) {
            case AuthenticationState.Invalid:
                // If there is definitely not an authenticated user, immediately force a login and come back to the
                // requested page after the user successfully authenticates.
                this.setAfterLoginUrl(state.url);
                // noinspection JSIgnoredPromiseFromCall
                this.router.navigate(['/login']);
                return false;
            case AuthenticationState.Pending:
                // If we are waiting to find out if the user is authenticated, allow the current navigation to succeed.
                // If the authentication eventually fails, they will be redirected to /login, and then after logging in,
                // they should return to the initially requested URL.
                this.setAfterLoginUrl(state.url);
                return true;

            case AuthenticationState.Valid:
                // If there is currently an authenticated user, allow the navigation to succeed.
                return true;
        }
    }

    get afterLoginUrl(): string {
        return this.mAfterLoginUrl || localStorage.getItem(AuthGuardService.AFTER_LOGIN_URL) || '/home';
    }

    private setAfterLoginUrl(newValue: string): void {
        // Store the value within the service because local storage is emptied when the user logs out.
        // Store the value in local storage so that it can be found if the application is reloaded
        // while the user is logged in.
        this.mAfterLoginUrl = newValue;
        localStorage.setItem(AuthGuardService.AFTER_LOGIN_URL, newValue);
    }
}
