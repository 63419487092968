import { Directive, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map, startWith, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MoreValidators } from '../../tools/more-validators.class';
import { Subscriptions } from '../../tools/subscriptions.class';
import { PermissionsService } from '../../services/permissions.service';
import { Account, AccountService } from '../../services/account.service';
import { Permissions } from '../../security/permissions.class';

export interface NewUserForm {
    email: FormControl<string>;
    account: FormControl<CandidateAccount | string>;
}

export interface CandidateAccount {
    name: string;
    accountId: string | null;
    allowed?: boolean;
}

@Directive()
export abstract class NewUserDirective implements OnInit, OnDestroy {

    public success = false;
    public error = false;
    public inProgress = false;

    public email = new FormControl<string>('', {
        validators: [Validators.required, Validators.email],
        nonNullable: true
    });
    public account = new FormControl<CandidateAccount | string>(null, {
        validators: [Validators.required, MoreValidators.hasSelectedObject],
        nonNullable: false
    });

    public mainForm: UntypedFormGroup;

    public candidateAccounts: Array<CandidateAccount> = [];
    public filteredAccounts: Observable<CandidateAccount[]>;

    protected subscriptions = new Subscriptions();

    protected saveObserver = {
        next: () => {
            this.success = true;
            this.resetNewUser();
        },
        error: () => {
            this.error = true;
            this.resetNewUser();
        }
    };

    protected constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<NewUserDirective>,
                          protected permissionsService: PermissionsService, protected accountService: AccountService) {
    }

    ngOnInit() {
        this.loadCandidateAccounts();
        this.filterAccounts();
    }

    private filterAccounts() {
        this.filteredAccounts = this.account.valueChanges.pipe(
            startWith(''),
            map(search => (typeof search === 'string') ? this.filteredCandidates(search) : [])
        );
    }

    private loadCandidateAccounts() {
        // List accounts. For each account, check if the user has permission to invite users to that account. Provide the accounts
        // that are allowed as options for the user to choose, but only if there is more than one choice. If there is exactly one
        // choice, choose it automatically. If there are zero choices, the user cannot invite users using this component.

        this.candidateAccounts = [];

        function compareCandidates(a: CandidateAccount, b: CandidateAccount): number {
            let result = a.name.localeCompare(b.name);
            if (result === 0) {
                result = a.accountId.localeCompare(b.accountId);
            }
            return result;
        }

        this.subscriptions.limit(this.accountService.getAccounts()).pipe(
            map((accounts: Array<Account>) => {
                    const candidateAccounts: Array<CandidateAccount> = accounts.map(a => {
                        return {name: a.name, accountId: a.id};
                    });

                    // Prefetch all the permissions in one call so that using hasPermission() below won't result in a
                    // bunch of individual requests.
                    this.permissionsService.prefetch(candidateAccounts.map(ca => Permissions.accountInvite(ca.accountId)));

                    return candidateAccounts;
                }
            )
        ).subscribe({
            next: (candidateAccounts: Array<CandidateAccount>) => {
                candidateAccounts.forEach((ca: CandidateAccount) => {
                    const permission = Permissions.accountInvite(ca.accountId);
                    this.subscriptions.limit(this.permissionsService.hasPermission(permission).pipe(take(1))).subscribe({
                        next: allowed => {
                            ca.allowed = allowed;

                            if (allowed) {
                                this.candidateAccounts.push(ca);
                            }

                            if (candidateAccounts.every(x => x.allowed !== undefined)) {
                                this.candidateAccounts.sort(compareCandidates);

                                if (this.candidateAccounts.length === 1) {
                                    this.account.setValue(this.candidateAccounts[0]);
                                }
                            }
                            this.filterAccounts();
                        }
                    });
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
    }

    public displayAccount(account: CandidateAccount) {
        return account && account.name ? account.name : '';
    }

    protected filteredCandidates(search: string): CandidateAccount[] {
        const lcSearch = search.toLocaleLowerCase();
        return this.candidateAccounts.filter(ca => ca.name.toLocaleLowerCase().includes(lcSearch));
    }

    public submitDisabled(): boolean {
        return this.mainForm.invalid || this.inProgress;
    }

    public resetNewUser() {
        this.mainForm.reset();
        setTimeout(() => {
            this.inProgress = false;
            this.success = false;
            this.error = false;
        }, 2000);
    }

    public closeDialog() {
        this.dialogRef.close();
    }
}
