import { Injectable } from '@angular/core';
import {
    ApiService,
    DeleteGroupResponse,
    Group,
    GroupCandidate,
    GroupMember,
    GroupMembership,
    GroupPermission,
    NewInvitationResponse
} from './api.service';
import { Observable } from 'rxjs';

export {
    Group,
    GroupMember,
    GroupPermission,
    GroupCandidate,
    NewInvitationResponse,
    DeleteGroupResponse,
    compareGroupsByName
};

@Injectable({providedIn: 'root'})
export class GroupService {

    constructor(private api: ApiService) {
    }

    public createGroup(name: string): Observable<Group> {
        return this.api.createGroup(name);
    }

    public getGroup(groupId: string): Observable<Group> {
        return this.api.getGroup(groupId);
    }

    public updateGroup(groupId: string, updates: any): Observable<Group> {
        return this.api.updateGroup(groupId, updates);
    }

    public deleteGroup(groupId: string): Observable<DeleteGroupResponse> {
        return this.api.deleteGroup(groupId);
    }

    public updateGroupMembership(groupId: string, userId: string,
                                 permissions: GroupPermission[]): Observable<GroupMembership | any> {
        return this.api.updateGroupMembership(groupId, userId, permissions);
    }

    public removeGroupMember(groupId: string, userId: string): Observable<any> {
        return this.api.removeGroupMember(groupId, userId);
    }

    public getGroupCandidates(groupId: string): Observable<GroupCandidate[]> {
        return this.api.getGroupCandidates(groupId);
    }

    // List the groups that are accessible to the current user; this could be all groups, or only the groups
    // of which the current user is a member. The user may not have permission to modify (some of) these groups.
    public getGroups(forUser: string | null = null): Observable<Group[]> {
        return this.api.getGroups(forUser);
    }

    public createUserInvitation(email: string,
                                options: {
                                    groupId: string,
                                    permissions: GroupPermission[]
                                }): Observable<NewInvitationResponse> {
        return this.api.createUserInvitation(email, options);
    }
}

function compareGroupsByName(a: Group, b: Group): number {
    return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
}
