<mat-sidenav-container class="fill-parent">
    <mat-sidenav class="sis-sidenav" mode="side" opened="true" disableClose>
        <div class="side-nav-top-space"></div>
        <mat-nav-list>
            @for (item of helpMenuItems; track item) {
                <app-menu-item class="mat-typography" [item]="item"></app-menu-item>
            }
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="sis-sidenav-content">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
