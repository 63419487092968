import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TableDirective } from '../../tools/table.directive';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { compareFlowsBySubmitOrStartDate, FlowRow, FlowsService } from '../../services/flows.service';
import { Flows } from '../../case/case.constants';
import { timer } from 'rxjs';
import { Subscriptions } from '../../tools/subscriptions.class';
import { Router } from '@angular/router';

@Component({
    selector: 'app-flows',
    templateUrl: './flows.component.html',
    styleUrls: ['./flows.component.scss']
})
export class FlowsComponent extends TableDirective<FlowRow> implements OnInit, AfterViewInit, OnDestroy {

    private static REFRESH_TIMEOUT = 60000;
    private subscriptions: Subscriptions = new Subscriptions();

    constructor(private dialog: MatDialog, private router: Router, private flowsService: FlowsService) {
        super(
            ['case_id', 'name', 'submitted', 'started', 'progress', 'environment'],
            new MatTableDataSource<FlowRow>()
        );
    }

    ngOnInit() {
        this.subscriptions.add(timer(0, FlowsComponent.REFRESH_TIMEOUT), () => {
            this.readFlows();
        });
    }

    ngOnDestroy() {
        this.subscriptions.cancel();
    }

    private readFlows() {
        this.subscriptions.add(this.flowsService.getRunningFlows(), (flows: Array<FlowRow>) => {
            this.dataSource.data = flows.sort(compareFlowsBySubmitOrStartDate).map(item => {
                return item;
            });
        });
    }

    public getFlowName(flowCode: number): string {
        for (const flow of Object.values(Flows)) {
            if (flowCode === flow.flowCode) {
                return flow.description;
            }
        }
        return '';
    }

    onActivate(flow: FlowRow, metaKey: boolean) {
        this.navigateFromClick(this.router, metaKey, ['/case', flow.case_id]);
    }
}
