import { Component, OnDestroy, OnInit } from '@angular/core';
import { BooleanResponse, UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Params, Router, RouterModule } from '@angular/router';
import { Subscriptions } from '../tools/subscriptions.class';
import { InvitationResponse, InvitationService } from '../services/invitation.service';
import { PasswordPolicy } from '../tools/password-policy.class';

import { MaterialModule } from '../material.module';
import { TranslatePipe } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

enum State {
    Loading, LoggingIn, AuthCodeLoginFailed, LoadingInvitation, InvitationNotFound, InvalidInvitation,
    AlreadyAccepted, Accept, AcceptFailed, Accepted, SetPassword, SettingPassword, SetPasswordFailed
}

const failureStates = [State.AuthCodeLoginFailed, State.InvitationNotFound, State.InvalidInvitation, State.AcceptFailed,
    State.SetPasswordFailed,];

@Component({
    selector: 'app-accept-invitation',
    standalone: true,
    imports: [
        FormsModule,
        MaterialModule,
        RouterModule,
        TranslatePipe,
    ],
    templateUrl: './accept-invitation.component.html',
    styleUrls: ['./accept-invitation.component.scss']
})
export class AcceptInvitationComponent implements OnInit, OnDestroy {

    public model = {
        newPassword: '',
        confirmPassword: ''
    };

    private afterAcceptPath = ['/home'];

    private authCode: string;
    private invitationId: string;
    public invitationEmail: string;

    private state = State.Loading;
    public failureMessage: string;

    private lifetimeSubscriptions = new Subscriptions();
    private subscriptions = new Subscriptions();

    constructor(private invitationService: InvitationService, private userService: UserService,
                private authService: AuthService, private router: Router, private route: ActivatedRoute) {
    }

    public get stateLoading(): boolean {
        return this.state === State.Loading;
    }

    public get stateAlreadyAccepted(): boolean {
        return this.state === State.AlreadyAccepted;
    }

    public get stateLoggingIn(): boolean {
        return this.state === State.LoggingIn;
    }

    public get stateSetPassword(): boolean {
        return this.state === State.SetPassword;
    }

    public get stateSettingPassword(): boolean {
        return this.state === State.SettingPassword;
    }

    public get stateLoadingInvitation(): boolean {
        return this.state === State.LoadingInvitation;
    }

    public get stateAccept(): boolean {
        return this.state === State.Accept;
    }

    public get stateAccepted(): boolean {
        return this.state === State.Accepted;
    }

    public get stateSetPasswordFailed(): boolean {
        return this.state === State.SetPasswordFailed;
    }

    public get stateInvalidInvitation(): boolean {
        return this.state === State.InvalidInvitation;
    }

    public get stateInvitationNotFound(): boolean {
        return this.state === State.InvitationNotFound;
    }

    public get stateAuthCodeLoginFailed(): boolean {
        return this.state === State.AuthCodeLoginFailed;
    }

    public get stateAcceptFailed(): boolean {
        return this.state === State.AcceptFailed;
    }

    public get failed(): boolean {
        return failureStates.includes(this.state);
    }

    ngOnInit(): void {
        this.lifetimeSubscriptions.add(this.route.queryParams, (params: Params) => {
            this.subscriptions.cancel();

            this.authCode = params.auth_code;
            this.invitationId = params.invitation_id;
            this.invitationEmail = params.email;

            this._startWorkflow();
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
        this.lifetimeSubscriptions.cancel();
    }

    get passwordPolicy(): PasswordPolicy {
        return this.authService.currentPasswordPolicy();
    }

    get showNonCompliantPassword(): boolean {
        return this.model.newPassword && !this.passwordPolicy.accept(this.model.newPassword);
    }

    forceSetPasswordState() {
        // For testing only
        this.state = State.SetPassword;
    }

    private _startWorkflow() {
        // We start by using the auth-code to login. This should provide a token that allows, if necessary, setting
        // the password without providing the old password (which may not even exist).

        // Once we have authenticated, we can retrieve the invitation and then decide what other steps are necessary.
        // Possibly, we only need to accept the invitation (e.g., for an existing user being added to a group). Or,
        // we may need to accept the invitation and then allow the user to set their password.

        // The HTML is responsible for enabling and disabling various elements depending on the current state. Some
        // states are "terminal" states, where the UI should provide a link to an appropriate page.
        this._authCodeLogin();
    }

    setPassword() {
        this.state = State.SettingPassword;
        this.subscriptions.add(this.userService.changePasswordWithToken(this.authService.currentUserId, this.model.newPassword),
            (response: BooleanResponse) => {
                if (response.result) {
                    this.router.navigate(this.afterAcceptPath).then();
                }
                else {
                    this.state = State.SetPasswordFailed;
                }
            },
            (error) => {
                this._failed(State.SetPasswordFailed, error);
            });
    }

    passwordsDontMatch() {
        return this.model.newPassword &&
            this.model.confirmPassword &&
            this.model.newPassword !== this.model.confirmPassword;
    }

    readyToChange() {
        return this.authService.isAuthed() &&
            this.state == State.SetPassword &&
            this.model.newPassword &&
            this.model.confirmPassword &&
            this.model.newPassword === this.model.confirmPassword &&
            this.passwordPolicy.accept(this.model.newPassword);
    }

    private _fetchInvitation() {
        this.state = State.LoadingInvitation;
        this.subscriptions.add(this.invitationService.getInvitation(this.invitationId),
            (invitation: InvitationResponse) => {
                if (invitation.accepted) {
                    // if the invitation was already accepted, there is nothing to do but tell the user.
                    this.state = State.AlreadyAccepted;
                }
                else if (invitation.user_status === 'PENDING') {
                    // For pending users, we accept the invitation and then allow the user to set their password.
                    this.state = State.Accept;
                    this._accept(this.invitationId, () => {
                        this.state = State.SetPassword;
                    });
                }
                else {
                    // For active users, we accept the invitation, and then we're done.
                    this.state = State.Accept;
                    this._accept(this.invitationId, () => {
                        this.state = State.Accepted;
                    });
                }
            },
            (error) => {
                this._failed(State.InvitationNotFound, error);
            });
    }

    private _authCodeLogin() {
        // Use the auth code to log in. Once logged in, the invitation can be accepted.
        this.state = State.LoggingIn;
        this.subscriptions.add(this.authService.authCodeLogin(this.authCode),
            () => {
                this._fetchInvitation();
            },
            (error) => {
                this._failed(State.AuthCodeLoginFailed, error);
            });
    }

    private _accept(invitationId: string, afterAccept: () => any) {
        this.subscriptions.add(this.invitationService.acceptInvitation(invitationId),
            () => {
                if (afterAccept) {
                    afterAccept();
                }
            },
            (error) => {
                this._failed(State.AcceptFailed, error);
            });
    }

    private _failed(errorState: State, error: any): void {
        this.state = errorState;
        this.failureMessage = error.message || 'There was a problem accepting the invitation';
    }
}
