export interface ErrorCode {
    value: number;
    descKey: string;
    osValue?: number;
}

// Error codes that can be set from the client
const FlowErrorCodes: Array<ErrorCode> = [
    {value: 0, descKey: 'error_0'},
    {value: 11, descKey: 'error_11'},
];

// Error codes that can be set from the client for the 2 flows
export const SegmentationErrorCodes: Array<ErrorCode> = FlowErrorCodes.concat({value: 301, descKey: 'error_301'});

export const PostopErrorCodes: Array<ErrorCode> = FlowErrorCodes.concat([
    {value: 202, descKey: 'error_202'},
    {value: 412, descKey: 'error_412'}
]);

