import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({name: 'localDate', standalone: true})
export class LocalDatePipe implements PipeTransform {

    protected static SIS_DATE_FORMAT = 'MM/dd/yyyy, h:mm a';
    protected datePipe: DatePipe;

    constructor() {
        this.datePipe = new DatePipe('en-US');
    }

    transform(utcString: string): string {
        if (utcString === null || utcString.length === 0) {
            return '';
        }
        const date = utcString.endsWith('Z') ? new Date(utcString) : new Date(utcString + 'Z');
        return this.datePipe.transform(date, LocalDatePipe.SIS_DATE_FORMAT);
    }
}
