import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CaseComponent } from './case.component';
import { RouterModule } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';
import { DicomViewerComponent } from './dicom-viewer/dicom-viewer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadService } from './upload/upload.service';
import { CaseService } from './case.service';
import { DicomViewerService } from './dicom-viewer/dicom-viewer.service';
import { ToolsModule } from '../tools/tools.module';
import { MaterialModule } from '../material.module';
import { UploadDicomComponent } from './upload/upload-dicom.component';
import { UploadTransformComponent } from './upload/upload-transform.component';
import { AddActivityMessageComponent } from './admin/add-activity-message/add-activity-message.component';
import { ReRunFlowComponent } from './admin/re-run-flow/re-run-flow.component';
import { ElectrodeModelComponent } from './electrode-model/electrode-model.component';
import { CaseAdminComponent } from './admin/case-admin.component';
import {
    ChooseRegistrationMethodComponent
} from './admin/choose-registration-method/choose-registration-method.component';
import { LeadLocationComponent } from './lead-location/lead-location.component';
import { StlViewComponent } from './lead-location/stl-view/stl-view.component';
import { StlOptionsComponent } from './lead-location/stl-options/stl-options.component';
import { LeadSimulatorControllerComponent } from './lead-location/lead-sim/lead-simulator-controller.component';
import { TranslateModule } from '@ngx-translate/core';
import { ScanDicomComponent } from './overview/scan-dicom/scan-dicom.component';
import { SelectDicomComponent } from './overview/select-dicom/select-dicom.component';
import { ScanDicomService } from './overview/scan-dicom/scan-dicom.service';
import { SelectDicomService } from './overview/select-dicom/select-dicom.service';
import { AppComponentsModule } from '../app-components.module';
import { ElectrodeModelService } from './electrode-model/electrode-model.service';
import { ResultsReviewComponent } from './admin/results-review/results-review.component';
import { PredictionValidationComponent } from './admin/prediction-validation/prediction-validation.component';
import { ViewFlowLogComponent } from './admin/view-flow-log/view-flow-log.component';
import { CreateMrbComponent } from './admin/create-mrb/create-mrb.component';
import { DeleteCaseComponent } from './overview/delete-case/delete-case.component';
import { SegmentationReviewComponent } from './admin/flow-review/segmentation-review/segmentation-review.component';
import { PostopReviewComponent } from './admin/flow-review/postop-review/postop-review.component';
import { SelectDicomDetailsComponent } from './overview/select-dicom-details/select-dicom-details.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        RouterModule,
        ToolsModule,
        TranslateModule,
        AppComponentsModule,
        ReactiveFormsModule,
        NgOptimizedImage,
    ],
    declarations: [
        CaseComponent,
        OverviewComponent,
        ScanDicomComponent,
        SelectDicomComponent,
        DicomViewerComponent,
        UploadDicomComponent,
        UploadTransformComponent,
        AddActivityMessageComponent,
        ReRunFlowComponent,
        ElectrodeModelComponent,
        CaseAdminComponent,
        ChooseRegistrationMethodComponent,
        LeadLocationComponent,
        StlViewComponent,
        StlOptionsComponent,
        LeadSimulatorControllerComponent,
        CreateMrbComponent,
        ResultsReviewComponent,
        PredictionValidationComponent,
        ViewFlowLogComponent,
        DeleteCaseComponent,
        SegmentationReviewComponent,
        PostopReviewComponent,
        SelectDicomDetailsComponent
    ],
    providers: [
        CaseService,
        ScanDicomService,
        SelectDicomService,
        UploadService,
        DicomViewerService,
        ElectrodeModelService
    ],
    exports: []
})
export class CaseModule {
}
