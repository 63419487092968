<div class="fill-parent">
    <div class="group-main">
        <div class="group-header">
            <div class="group-info-container">
                <mat-form-field class="width-60-p">
                    <mat-label>{{ 'settingsGroupsEditGroupNamePlaceholder'|translate }}</mat-label>
                    <input matInput [(ngModel)]="groupName" [disabled]="!manageGroupAllowed"
                           (ngModelChange)="onInputChange()">
                </mat-form-field>
                <div class="group-info-dates-container">
                    <div class="group-info-dates">
                        <span class="group-info-dates-item mat-typography" i18n="@@settingsGroupsEditGroupCreated">
                            {{ 'created'|translate }}:
                        </span><span class="mat-typography">{{ created | localDate }}</span>
                    </div>
                    <div class="group-info-dates">
                        <span class="group-info-dates-item mat-typography" i18n="@@settingsGroupsEditGroupLastUpdated">
                            {{ 'settingsGroupsEditGroupLastUpdated'|translate }}:
                        </span><span class="mat-typography">{{ updated | localDate }}</span>
                    </div>
                </div>
                <button mat-raised-button
                        class="max-width-200"
                        type="submit"
                        i18n="@@settingsGroupsEditGroupButtonSave"
                        [disabled]="disableSaveGroup()"
                        (click)="updateGroupInfo()">
                    {{ 'save'|translate }}
                </button>
            </div>
            <div class="group-control-container">
                @if (viewCasesAllowed) {
                    <button
                            mat-raised-button
                            class="action-button min-width-200 m-b-10"
                            type="button"
                            i18n="@@settingsGroupsViewCases"
                            (click)="viewCases($event.metaKey)">
                        {{ 'settingsGroupsViewCases'|translate }}
                    </button>
                }
                @if (deleteGroupAllowed) {
                    <button
                            mat-raised-button
                            i18n="@@settingsGroupsEditGroupButtonDelete"
                            class="min-width-200"
                            data-testid="group-details-delete"
                            type="submit"
                            color="warn"
                            (click)="deleteGroup()">
                        {{ 'delete'|translate }}
                    </button>
                }
            </div>
        </div>
        <div class="members-table mat-elevation-z8">
            <mat-table [dataSource]="dataSource" matSort data-testid="group-details-members">
                <ng-container matColumnDef="name" i18n="@@settingsGroupsEditGroupTableName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'name'|translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let groupMemberRow">
                        @if (displayName(groupMemberRow)) {
                            <app-secure-link
                                    [permission]="viewUser(groupMemberRow)"
                                    (click)="onUserClick(groupMemberRow, $event.metaKey)"
                                    [hideWhenForbidden]="false">
                                {{ displayName(groupMemberRow) }}
                            </app-secure-link>
                        }
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="email" i18n="@@settingsGroupsEditGroupTableEmail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'email'|translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let groupMemberRow">
                        <app-secure-link [permission]="viewUser(groupMemberRow)"
                                         (click)="onUserClick(groupMemberRow, $event.metaKey)"
                                         [hideWhenForbidden]="false">
                            {{ groupMemberRow.email }}
                        </app-secure-link>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status" i18n="@@settingsGroupsEditGroupTableStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'status'|translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let groupMemberRow"> {{ displayStatus(groupMemberRow)|translate }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="admin" i18n="@@settingsGroupsEditGroupTableAdmin">
                    <mat-header-cell
                            *matHeaderCellDef>{{ 'settingsGroupsEditGroupTableAdmin'|translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let groupMemberRow" class="group-overflow-unset">
                        <mat-checkbox
                                [disabled]="!manageGroupAllowed"
                                (change)="onChangePermission(GROUP_ADMIN, $event, groupMemberRow)"
                                [checked]="hasAdminPermission(groupMemberRow.permissions)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="edit" i18n="@@settingsGroupsEditGroupTableEditCases">
                    <mat-header-cell
                            *matHeaderCellDef>{{ 'settingsGroupsEditGroupTableEditCases'|translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let groupMemberRow" class="group-overflow-unset">
                        <mat-checkbox
                                [disabled]="!manageGroupAllowed"
                                (change)="onChangePermission(CASE_MANAGER, $event, groupMemberRow)"
                                [checked]="hasEditPermission(groupMemberRow.permissions)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="transfer" i18n="@@settingsGroupsEditGroupTableTransfer">
                    <mat-header-cell
                            *matHeaderCellDef>{{ 'settingsGroupsEditGroupTableTransfer'|translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let groupMemberRow">
                        <button mat-icon-button
                                i18n-matTooltip="@@settingsGroupsEditGroupTableTransferTooltip"
                                matTooltip="{{'settingsGroupsEditGroupTableTransferTooltip'|translate}}"
                                matTooltipPosition="after"
                                (click)="transferCases(groupMemberRow.user_id)">
                            <mat-icon>forward</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="delete" i18n="@@settingsGroupsEditGroupTableDelete">
                    <mat-header-cell *matHeaderCellDef>{{ 'delete'|translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let groupMemberRow">
                        <button mat-icon-button
                                i18n-matTooltip="@@settingsGroupsEditGroupTableDeleteTooltip"
                                matTooltip="{{'settingsGroupsEditGroupTableDeleteTooltip'|translate}}"
                                matTooltipPosition="after"
                                (click)="removeMember(groupMemberRow.user_id)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let groupMemberRow; columns: displayedColumns;">
                </mat-row>
            </mat-table>
            <mat-paginator [pageSize]="10"></mat-paginator>
        </div>
        @if (manageGroupAllowed) {
            <button mat-fab
                    class="add-user-to-group-btn"
                    i18n-matTooltip="@@settingsGroupsEditGroupAddMemberTooltip"
                    matTooltip="{{'settingsGroupsEditGroupAddMemberTooltip'|translate}}"
                    matTooltipPosition="before"
                    data-testid="group-details-invite"
                    color="accent"

                    (click)="openAddMemberDialog()">
                <mat-icon>add</mat-icon>
            </button>
        }
    </div>
</div>
