import { Directive, OnDestroy } from '@angular/core';
import { ReportsService } from './reports.service';
import { Subscriptions } from '../tools/subscriptions.class';
import { QuartersService } from '../services/quarters.service';

export class TimeRangeOption {
    constructor(
        public titleKey: string,
        private calcDatesFn: (now: Date) => [Date, Date]) {
    }

    public dates(): [Date, Date] {
        return this.calcDatesFn(new Date());
    }
}

@Directive()
export abstract class ReportDirective implements OnDestroy {

    private timeOptionsArray: Array<TimeRangeOption> = [
        ...([0, 1, 2, 3].map((index: number) =>
            new TimeRangeOption(`reportsRangeQ${index + 1}`, (now: Date) => this.qs.quarterByIndex(index, now))
        )),
        ...([0, 1].map((index: number) =>
            new TimeRangeOption(`reportsRangeH${index + 1}`, (now: Date) => this.qs.halfByIndex(index, now))
        )),
        new TimeRangeOption(`reportsRangePrevMonth`, (now: Date) => this.prevMonth(now)),
        new TimeRangeOption(`reportsRangeCurrentMonth`, (now: Date) => this.currentMonth(now)),
        new TimeRangeOption(`reportsRangePrevQuarter`, (now: Date) => this.qs.prevQuarter(now)),
        new TimeRangeOption(`reportsRangeCurrentQuarter`, (now: Date) => this.qs.currentQuarter(now)),
        new TimeRangeOption(`reportsRangePrevYear`, (now: Date) => this.prevYear(now)),
        new TimeRangeOption(`reportsRangeCurrentYear`, (now: Date) => this.currentYear(now)),
        new TimeRangeOption(`reportsRangeAllTimes`, (now: Date) => this.allTimes(now))
    ];
    public selectedTimeRange: TimeRangeOption | null = null;
    protected subscriptions = new Subscriptions();

    public inProgress: boolean = false;
    public startDate: Date = null;
    public endDate: Date = null;

    protected constructor(protected rs: ReportsService, protected qs: QuartersService) {
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
    }

    public abstract submitQuery(): void;

    public disableSubmit(): boolean {
        return this.startDate === null || this.endDate === null || this.inProgress;
    }

    public disableClear(): boolean {
        // if dates are selected, we should be able to clear
        return !this.anyDateSelected();
    }

    protected anyDateSelected(): boolean {
        return this.startDate != null || this.endDate != null;
    }

    public reset(): void {
        this.startDate = null;
        this.endDate = null;
        this.selectedTimeRange = null;
    }

    public updateStart(value: Date) {
        this.startDate = value;
    }

    public updateEnd(value: Date) {
        this.endDate = value;
    }

    public currentMonth(now: Date): [Date, Date] {
        return [new Date(now.getFullYear(), now.getMonth(), 1), new Date(now.getFullYear(), now.getMonth() + 1, 0)];
    }

    public prevMonth(now: Date): [Date, Date] {
        return [new Date(now.getFullYear(), now.getMonth() - 1, 1), new Date(now.getFullYear(), now.getMonth(), 0)];
    }

    public currentYear(now: Date): [Date, Date] {
        return [new Date(now.getFullYear(), 0, 1), new Date(now.getFullYear() + 1, 0, 0)];
    }

    public prevYear(now: Date): [Date, Date] {
        const year = now.getFullYear() - 1;
        return [new Date(year, 0, 1), new Date(year, 11, 31)];
    }

    public allTimes(now: Date): [Date, Date] {
        return [new Date(2015, 0, 1), new Date(now.getFullYear(), now.getMonth() + 1, 0)];
    }

    get timeOptions(): Array<TimeRangeOption> {
        return this.timeOptionsArray;
    }

    public onDateRangeSelection() {
        if (this.selectedTimeRange !== null) {
            [this.startDate, this.endDate] = this.selectedTimeRange.dates();
        }
    }

    protected matchDateRangeAsNeeded(): void {
        for (const option of this.timeOptionsArray) {
            const [start, end] = option.dates();
            if (start.getTime() === this.startDate.getTime() && end.getTime() === this.endDate.getTime()) {
                this.selectedTimeRange = option;
                return;
            }
        }
        this.selectedTimeRange = null;
    }

    public onDatePickerSelection() {
        if (this.startDate !== null && this.endDate !== null) {
            this.matchDateRangeAsNeeded();
        }
    }
}



